@import "../../../styles/variables";

.xw-checkbox {
  position: relative;
  user-select: none;
  margin-bottom: calc($unit / 2);
  cursor: pointer;

  #table-header-checkbox {
    pointer-events: none;
  }

  > label {
    color: var(--c_checkbox-text);
    z-index: 2;
    display: block;
    font-size: 15 * $ptToEm;
    line-height: 16 * $ptToEm;
    padding-left: 22px;
    cursor: pointer;
  }

  > div.subText {
    color: var(--c_checkbox-subtext);
    font-size: 12 * $ptToEm;
    padding-left: 24px;
    padding-top: 3px;
  }

  > input {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkbox .fill {
      transform: scale(1);
      transition: transform 0.06s linear;
    }
  }

  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    background-color: var(--c_checkbox-bg);
    border: 1px var(--c_checkbox-border) solid;
    border-radius: 2px;
    pointer-events: none;
    transition: border 0.1s ease;

    .fill {
      position: absolute;
      top: 1px;
      left: 1px;
      width: 10px;
      height: 10px;
      background-color: var(--c_checkbox-active);
      box-sizing: border-box;
      transform: scale(0);
      border-radius: 1px;
      transition: transform 0.04s linear;
    }
  }

  &:hover,
  &:focus-within {
    label {
      color: var(--c_text-link);
    }
    .checkbox {
      border-color: var(--c_checkbox-active);
      .fill {
        transform: scale(0.4);
      }
    }
  }

  &:active {
    .checkbox {
      .fill {
        transform: scale(0.4);
      }
    }
  }

  &.error {
    .checkbox {
      border-color: var(--c_checkbox-error);

      .fill {
        background-color: var(--c_checkbox-error);
      }
    }
  }

  &.disabled {
    pointer-events: none;
    .checkbox {
      border-color: var(--c_checkbox-disabled);

      .fill {
        background-color: var(--c_checkbox-disabled);

        .high-contrast & {
          background-color: transparent;
          border: 1px var(--c_checkbox-disabled) solid;
        }
      }
    }
    > label {
      color: var(--c_checkbox-disabled-label);
    }
  }

  &.large {
    > label {
      padding-left: 24px;
    }

    .checkbox {
      width: 16px;
      height: 16px;

      .fill {
        width: 12px;
        height: 12px;
      }
    }
  }

  &.partial {
    .checkbox {
      .fill {
        transform: scaleY(1) scaleX(0.4) !important;
      }
    }
  }

  &.xw-radio {
    .checkbox {
      border-radius: 16px;
      .fill {
        border-radius: 16px;
      }
    }
  }
}
