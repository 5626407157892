@import "../../styles/variables";

.xw-subscribe-asns {
  .xw-page-header {
    border-bottom: 1px solid var(--c_section-header-separator);
  }

  .xw-textarea,
  textarea {
    width: 100%;
  }

  .subscribe-asn-review {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .review-warning {
    flex: 0 0 auto;
    font-size: 14 * $ptToEm;
    line-height: 18 * $ptToEm;
    color: var(--c_textInput-label);
    padding: $unit 0;
  }

  .asn-policy-review-table {
    flex: 1 1 100%;
  }
}
