@import "../../../../styles/variables";
.xw-trust-device-details {
  .xw-page-header.with-tabs {
    top: $unit * 3;
  }
  .xw-page-header {
    padding: 0 $unit * 2;
  }
  .status-label {
    display: flex;
    .xw-button {
      padding: 0;
      margin: 0;
      height: auto;
      font-weight: normal;
    }
    .xw-label-value {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      .xw-status-value {
        .status-label-link {
          color: var(--c_text-link);
        }
      }
    }
    .ssh-drawer button {
      margin: 0 0 0 6px;
      padding: 0;
      font-weight: normal;
      height: auto;
    }
  }

  .fields {
    display: flex;
    flex: 1 1 auto;
    flex-flow: row wrap;
    margin-bottom: 5px;
    > .xw-label-value {
      min-width: 1px;
      margin-right: 24px;
      margin-bottom: 10px;
      flex-shrink: 0;
    }
  }

  .collect-dossier {
    .sub-title {
      font-weight: bold;
      font-size: 10 * $ptToEm;
      line-height: 12 * $ptToEm;
      color: var(--c_button-subtitle);
      text-transform: uppercase;
    }
    .error {
      color: var(--c_text-error);
    }
  }

  .xw-device-platform {
    position: relative;
    flex: 1 1 100%;
    padding-top: $unit * 2;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .device-common-details {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .dossier-timestamps {
        display: flex;
        flex: 0 0 auto;
        .xw-label-value {
          text-align: right;
          > label {
            color: var(--c_changes-modify);
          }
        }
      }
    }

    .xw-bucket-chart {
      position: relative;
      margin: 0 -23px 0 -23px;
      width: calc(100% + 48px);
    }

    .device-table-section {
      display: flex;
      flex: 1 1 100%;
      flex-flow: column nowrap;
      position: relative;
      .toggle {
        display: flex;
        flex: 0 0 auto;
        margin: $unit * 2 0;
        position: absolute;
        .xw-toggle {
          flex: 0 0 auto;
          margin: 0;
          .options {
            flex: 1 1 100%;
            button {
              width: 150px;
            }
          }
        }
        .find-files-link {
          margin: $unit 0 0 $unit;
          .xw-icon {
            margin: 0 calc($unit / 3);
          }
        }
      }
      .xw-table-wrapper {
        position: relative;
        left: -24px;
        width: calc(100% + 48px);
        flex: 1 1 auto;
      }
      .xw-data-table {
        left: -24px;
        top: 72px;
        width: calc(100% + 48px);
        height: calc(100% - 72px);
        display: flex;
        flex-flow: column nowrap;
        flex: 0 0 auto;
      }

      .all-ok-no-data-overlay {
        color: var(--c_text-ok);
        font-size: 16 * $ptToEm;
      }

      .device-table {
        .checkbox-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px var(--c_device-capabilities-check-active) solid;
          border-radius: 100px;
          box-sizing: border-box;
          height: 20px;
          width: 20px;
          margin-right: $unit;

          .xw-icon {
            fill: var(--c_device-capabilities-check-active);
            width: 14px;
            height: 14px;
            transition: all 0.15s ease;
            margin-left: 0px;
          }
        }
      }
    }
  }
  .xw-device-inventory {
    position: relative;
    flex: 1 1 100%;
    padding-top: $unit * 2;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .device-common-details {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .fields {
      margin-bottom: 0;
    }
    .xw-data-table {
      height: 100%;
    }
  }
}
