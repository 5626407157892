@import "../../../styles/variables";
.xw-mask-range-selector {
  padding-bottom: 2 * $unit;

  .slider-handles .handle-0 {
    .range-handle-label {
      color: var(--c_range-handle-label);
    }

    &::after {
      width: 10px;
      height: 10px;
      right: 3px;
      top: 3px;
      border-radius: 50%;
      background-color: var(--c_modification_Create);
      opacity: 1;
    }

    &.disabled {
      background: var(--c_content-bg);
    }
  }
  .slider-handles .handle-1 {
    border-color: var(--c_modification_Create);
    z-index: 3;

    .range-handle-label {
      width: 88px;
    }

    &::after {
      width: 6px;
      height: 6px;
      right: 5px;
      top: 5px;
      border-radius: 50%;
      background-color: var(--c_modification_Create);
      opacity: 1;
    }
  }

  .slider-handles .handle-2 {
    .range-handle-label {
      color: var(--c_range-handle-label);
    }
    &::after {
      width: 10px;
      height: 10px;
      right: 3px;
      top: 3px;
      border-radius: 50%;
      background-color: var(--c_range-handle-disabled-bg);
      opacity: 1;
    }

    &.disabled {
      background: var(--c_content-bg);
    }
  }

  .slider-tracks {
    font-weight: 800;
    text-align: center;
    letter-spacing: 0.5em;

    .range-track-label {
      font-size: 8px;
      padding: calc($unit / 2);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .slider-tracks .track-0 {
    cursor: default;
    background-color: var(--c_modification_Create);
    color: var(--c_modification_Create);
    .range-track-label::before {
      content: "INCLUDE";
      margin-left: calc($unit / 2);
    }
  }
  .slider-tracks .track-1 {
    cursor: default;
    background-color: transparent;
    color: var(--c_range-handle-label);
    .range-track-label::before {
      content: "IGNORE";
      margin-left: calc($unit / 2);
    }
  }

  &:hover,
  &:active {
    .range-handle {
      border: 2px solid var(--c_modification_Create);
      &.disabled {
        border: 2px solid transparent;
      }
    }
  }
}
