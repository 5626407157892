@import "../../../styles/variables";

.xw-insights-findhw-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  .xw-page-header {
    .header {
      padding: 12px 0;
    }
    .separator {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
  .xw-data-table {
    height: 100%;
  }
  .xw-insights-findhw-filter-container {
    display: flex;
    justify-content: space-between;
  }
  .xw-insights-findhw-view {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    .hardware-search-input {
      width: 30%;
      padding-left: 24px;
    }
  }
  .custom-filter-controls-section {
    display: none;
  }
  .xw-data-table {
    .hw-entity-node {
      .checkbox-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px var(--c_device-capabilities-check-active) solid;
        border-radius: 100px;
        box-sizing: border-box;
        height: 20px;
        width: 20px;
        margin-right: $unit;

        .xw-icon {
          fill: var(--c_device-capabilities-check-active);
          width: 14px;
          height: 14px;
          transition: all 0.15s ease;
          margin-left: 0px;
        }
      }
    }
  }
}
