@import "../../styles/variables";

.xw-page-asn-report {
  display: flex;
  flex: 1 1 100%;
  flex-flow: column nowrap;
  position: relative;
  .xw-data-table {
    flex: 1 1 100%;
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 0;
    left: -24px;
    width: calc(100% + 48px);
    .report-status {
      display: inline-flex;
      .in-progress {
        fill: var(--c_text-warning);
      }
      .success {
        fill: var(--c_circle-check-success);
      }
      .failure {
        fill: var(--c_text-error);
      }
      .warnings {
        fill: var(--c_text-warning);
      }
      svg {
        margin-right: 4px;
      }
    }
  }
}
