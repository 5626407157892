.create-token-modal {
  .modal-body {
    padding: 42px 24px;
    .xw-textarea,
    textarea {
      width: 100%;
    }
    .xw-dateTime .field-wrapper {
      width: 100%;
    }
  }
}
