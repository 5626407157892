@import "../../styles/variables";

.xw-chip {
  $chipBorderRadius: 100px;
  height: $unit * 1.5;
  display: inline-block;
  position: relative;
  border-radius: $chipBorderRadius;
  background-color: var(--c_chip-bg-0);
  color: var(--c_chip-text);
  padding: calc($unit / 4) $unit;
  font-size: 12 * $ptToEm;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  @for $i from 0 through 8 {
    &.color-#{$i} {
      background-color: var(--c_chip-bg-#{$i});

      &:hover .action-guard,
      &:focus .action-guard {
        background: linear-gradient(
          to left,
          var(--c_chip-bg-#{$i}) 0%,
          var(--c_chip-bg-#{$i}) 60%,
          transparent 100%
        );
      }
    }
  }

  &:not(:first-child) {
    margin-left: $unit;
  }

  button.action {
    position: absolute;
    border-radius: $chipBorderRadius;
    height: 14px;
    width: 14px;
    top: calc(50% - 7px);
    right: 2px;
    overflow: hidden;
    background-color: var(--c_chip-action-bg);
    opacity: 0;
    transition: opacity 0.15s ease;

    .xw-icon {
      fill: var(--c_chip-action);
      position: absolute;
      top: 2px;
      right: 2px;
      width: 10px;
      height: 10px;
    }

    &.add {
      opacity: 1;
    }

    &.add:hover .xw-icon {
      fill: var(--c_chip-action-add);
    }
    &.remove:hover .xw-icon {
      fill: var(--c_chip-action-remove);
    }
  }

  &:hover button.action,
  &:focus button.action {
    opacity: 1;
  }

  input[type="text"] {
    font-size: 12 * $ptToEm;
    padding: 0;
    height: auto;
    background-color: transparent;
    border-bottom: 0;
    color: var(--c_chip-text);
    width: $unit * 4;
    border-radius: 0;
    flex-grow: 1;
  }

  .action-guard {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: $unit * 2;
    transition: background-color 0.5s ease;
  }
}

.theme-high-contrast .xw-chip {
  background-color: var(--c_content-bg);
  padding: calc($unit / 6) $unit;

  &:hover .action-guard,
  &:focus .action-guard {
    background: linear-gradient(
      to left,
      var(--c_content-bg) 0%,
      var(--c_content-bg) 60%,
      transparent 100%
    );
  }

  @for $i from 0 through 8 {
    &.color-#{$i} {
      border: 2px solid var(--c_chip-bg-#{$i});
      color: var(--c_chip-bg-#{$i});
    }
  }
  input[type="text"] {
    border: none;
  }
}
