@import "../../../styles/variables";

.xw-label-value {
  margin-bottom: $unit * 2;

  &.full-width {
    width: 100% !important;
  }

  label {
    display: block;
    font-size: 12 * $ptToEm;
    line-height: 15 * $ptToEm;
    color: var(--c_textInput-label);
    text-transform: capitalize;
  }
  .value,
  .value span,
  .value div,
  .value a {
    font-size: 15 * $ptToEm;
    font-weight: 300;
    line-height: 18 * $ptToEm;
    color: var(--c_textInput-value);
    text-overflow: ellipsis;
    overflow: hidden;
  }
  a.link {
    color: var(--c_button-link-label);
    &:hover,
    &:focus {
      color: var(--c_button-link-label-hover);
      transition: color 0.15s ease;
    }
  }
}
