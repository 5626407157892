@import "../../styles/variables";

.time-ticks.dynamic {
  text {
    fill: var(--c_text-title);
    font-size: 12 * $ptToEm;
    user-select: none;
    &.secondary {
      fill: var(--c_text);
    }
  }
  rect {
    fill: url(#chartTickX);
    shape-rendering: crispEdges;
  }
}
