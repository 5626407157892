@import "../../../styles/variables";

.xw-feedback {
  padding: $unit * 2;

  .xw-textarea {
    margin: $unit 0 0 0;
    min-width: 300px;
    width: 100%;
    max-width: 750px;

    textarea {
      width: 100%;
    }
  }
  .xw-button {
    margin: $unit 0;
  }
}
