@import "../../styles/variables";

.xw-prefixes-page-ext-route {
  height: 100%;
  display: flex;
  flex-direction: column;

  .xw-tabs {
    margin: $unit 0 0 0;
    &:before {
      display: block;
      flex: 1 1 $unit;
      content: " ";
      display: block;
      border-bottom: 1px solid var(--c_tabs-bottom-border);
    }
  }

  .xw-prefix-traffic {
    margin: $unit 0 0 0;
  }

  > .xw-button {
    position: absolute;
    top: $unit * 2;
    left: $unit * 2;
  }

  #prefix-table {
    margin-top: $unit;
    flex-grow: 1;
    button[name="subscribeToPrefixes"] {
      min-width: auto;
      padding: 0 calc($unit / 2);
    }
  }
}

.xw-bulk-edit-prefixes {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;

  > .prefix-bulkEdit-review {
    position: relative;
    flex: 1 1 100%;
    min-height: 0;
    margin-bottom: $unit * 4;
    .xw-data-table {
      height: 100%;
    }
  }
}
