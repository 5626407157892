@import "../../../styles/variables";

.xw-policy-tag-container {
  display: inline-flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  align-content: center;
  position: relative;
  justify-content: space-between;
  width: 100%;

  .xw-select.auto-height .hint {
    position: relative;
    bottom: -5px;
  }

  .xw-select {
    width: 100%;
    margin-right: $unit;
    &:last-child {
      margin-right: 0;
    }
  }
}
