@import "../../../styles/variables";

.xw-api {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;

  .api-component {
    position: relative;
    flex: 1 1 100%;
    overflow: hidden;
  }

  .markdown {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--c_markdown-bg);
    color: var(--c_markdown-text);
    word-wrap: break-word;
    padding: 0 $unit * 2;
    overflow-y: auto;
    overflow-x: hidden;

    a {
      color: var(--c_markdown-link);
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    p,
    blockquote,
    ul,
    ol,
    dl,
    table,
    pre {
      margin: 15px 0;
      line-height: 1.5;
    }

    ul,
    ol {
      padding-left: 30px;
    }

    h1 {
      border-bottom: 1px solid var(--c_markdown-line);
      color: var(--c_markdown-h1);
      font-size: 2.5em;
    }

    h2 {
      border-bottom: 1px solid var(--c_markdown-line);
      color: var(--c_markdown-h2);
      font-size: 2em;
    }

    h3 {
      font-size: 1.5em;
    }

    h4 {
      font-size: 1.2em;
    }

    h5 {
      font-size: 1em;
    }

    h6 {
      color: var(--c_markdown-h6);
      font-size: 1em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
      line-height: 1.7;
      margin: 1em 0 15px 0;
    }

    h1 + p,
    h2 + p,
    h3 + p {
      margin-top: 10px;
    }

    ul,
    ol {
      li {
        line-height: 1.5;
        margin: 0 0 10px 0;
        list-style: disc;
      }
    }

    .pre {
      display: block;
      border-radius: $unit;
      content: "";
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 18px;
      border-radius: $unit $unit 0 0;
      color: var(--c_code-text);
      background-color: var(--c_code-head-bg);
      height: $unit * 3;
    }

    code {
      display: block;
      border-radius: $unit;
      background-color: var(--c_code-bg);
      padding: 18px;
      max-height: 600px;
      overflow: auto;
      color: var(--c_code-brackets);
      word-wrap: break-word;
      scrollbar-color: var(--c_scrollbar-thumb-large)
        var(--c_scrollbar-track-large);
      scrollbar-width: auto;
      &::-webkit-scrollbar {
        width: 12px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--c_scrollbar-thumb-large);
        border-radius: 8px;
        border: 3px solid transparent;
        background-clip: content-box;
      }
      &::-webkit-scrollbar-track {
        background: var(--c_scrollbar-track-large);
      }
      &::-webkit-scrollbar-thumb:vertical:hover {
        background-color: var(--c_scrollbar-thumb-large-hover);
        border: 3px solid transparent;
        background-clip: content-box;
      }
    }
  }
}
