@import "../../../styles/variables";

.xw-textarea {
  position: relative;
  display: inline-block;

  textarea {
    min-width: 300px;
    background-color: var(--c_textInput-bg);
    color: var(--c_textInput-value);
    padding: $unit;
    font-size: 15 * $ptToEm;
    margin-bottom: $unit * 2;
    overflow-x: hidden;
    border: var(--s_textInput-border) var(--c_textInput-line) solid;
    border-bottom-width: var(--s_textInput-line);
    border-radius: 2px;
    transition: border 0.2s ease;
    resize: none;

    &:disabled {
      color: var(--c_textInput-value-disabled);
      border-color: var(--c_textInput-line-disabled);
      background-color: var(--c_textInput-bg-disabled);
      & + label {
        color: var(--c_textInput-label-disabled);
      }
    }
  }

  &.label textarea {
    padding-top: $unit + 10;
  }

  label {
    position: absolute;
    font-size: 15 * $ptToEm;
    line-height: 18 * $ptToEm;
    top: $unit * 1.25;
    left: $unit;
    right: $unit;
    color: var(--c_textInput-label);
    pointer-events: none;
    transform-origin: left;
    transition: transform 0.15s ease;
  }

  &.value label,
  textarea:focus + label {
    transform: translateY(-10px) scale(0.8);
  }

  &.value .label-guard,
  textarea:focus ~ .label-guard {
    background: var(--c_textInput-label-bg);
  }

  textarea:focus {
    border-color: var(--c_textInput-line-active);
  }

  .hint,
  .error {
    position: absolute;
    left: 0;
    bottom: $unit * 0.25;
    font-size: 10 * $ptToEm;
    line-height: 15 * $ptToEm;
  }

  .error {
    color: var(--c_textInput-label-error);
    display: none;
  }

  &.showRequired {
    textarea {
      border-color: var(--c_textInput-line-required);
    }
    .error {
      color: var(--c_textInput-label-required);
    }
  }

  &.showError {
    textarea {
      border-color: var(--c_textInput-line-error);
    }
  }

  &.showError,
  &.showRequired {
    > .hint {
      display: none;
    }

    > .error {
      display: block;
    }
  }

  .label-guard {
    position: absolute;
    top: var(--s_textInput-border);
    left: var(--s_textInput-border);
    right: $unit;
    height: $unit * 2;
    pointer-events: none;
    background: transparent;
  }

  &.value.disabled .label-guard {
    background: var(--c_textInput-label-bg-disabled);
  }
}

.submitted .xw-textarea.invalid {
  .hint {
    display: none;
  }
  .error {
    display: block;
  }
}

.theme-high-contrast {
  textarea {
    &:hover:not(:disabled),
    &:focus {
      border-bottom-width: 2px;
    }
  }
}
