@import "../../../styles/variables";
.xw-trust-dashboard {
  padding: $unit;
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
  .xw-page-header {
    padding: 0;
  }

  .content {
    position: relative;
    display: flex;
    height: 60px;
    padding-bottom: 12px;
    .device-timeRange {
      position: absolute;
      right: 0;
    }
  }
  .container {
    position: relative;
    height: 100%;
    .xw-card {
      flex-basis: 100%;
      min-width: 0;
    }
    .widget {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: grid;
      box-sizing: border-box;
      grid-template-rows: 50% 50%;
      grid-template-columns: 25% 25% 25% 25%;
      place-items: stretch;
      place-content: stretch;

      .line-chart {
        grid-area: 1/1/1/5;
      }

      .adjusted-width-chart {
        grid-area: 1/1/1/4;
      }

      .full-width-chart {
        grid-area: 1/1/1/5;
      }

      .dossier-error-list {
        grid-area: 1/4/1/4;
        display: flex;
        .content {
          overflow-y: auto;
          ul li {
            display: flex;
            justify-content: space-between;
            padding-bottom: $unit;
            .device-name {
              display: flex;
              flex: 0 0 auto;
              color: var(--c_text-link);
              margin-right: calc($unit / 2);
              .legend {
                margin-top: calc($unit/6);
                margin-right: calc($unit / 2);
                min-width: 9px;
                height: 9px;
                background-color: var(--c_text-error);
              }
            }
            .dossier-ts {
              min-width: 0;
              flex: 0 1 160px;
              color: var(--c_chart-label);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .donut-1 {
        grid-area: 2/1/2/2;
        display: flex;
        flex-direction: column;
      }

      .donut-2 {
        grid-area: 2/2/2/2;
        display: flex;
        flex-direction: column;
      }

      .donut-3 {
        grid-area: 2/3/2/3;
        display: flex;
        flex-direction: column;
      }

      .donut-4 {
        grid-area: 2/4/2/4;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
