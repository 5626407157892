.xw-active-alarms-country-header {
  display: flex;
  align-items: center;

  .xw-active-alarms-country-header-toggle {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
  }
}

.xw-dashboard-page .xw-active-alarms-country .content .xw-geo-map {
  flex: 1 1 auto;
}

#active-alarm_table {
  .no-pointer-event {
    pointer-events: inherit;
  }
}
