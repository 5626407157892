@import "../../../styles/variables";

.xw-api-list {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  color: var(--c_code-text);

  .counter-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--c_api-header);
    margin: 1px 0 0 0;
    height: 33px;
    padding: 0 $unit * 2;
    font-size: $ptToEm * 14;
  }

  .filter-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--c_api-header);
    margin: 1px 0;
    height: 66px;
    padding: 0 $unit * 2;
    gap: $unit;

    .method-filter {
      flex: 0 0 auto;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $unit;

      .label {
        font-size: $ptToEm * 12;
      }

      .xw-checkbox {
        margin-bottom: 0;

        > label {
          padding-left: 18px;
        }

        label[for="1"] {
          color: var(--c_api-get);
        }

        label[for="2"] {
          color: var(--c_api-post);
        }

        label[for="3"] {
          color: var(--c_api-put);
        }

        label[for="4"] {
          color: var(--c_api-delete);
        }

        label[for="5"] {
          color: var(--c_api-patch);
        }

        .subText {
          display: none;
        }
      }
    }

    .other-filters {
      flex: 1 1 100%;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: $unit;

      > .xw-select {
        flex: 1 1 100%;
        max-width: 400px;
      }

      .version {
        flex: 0 0 auto;
        width: 110px;
        min-width: auto;
      }
    }
  }

  .method {
    font-size: $ptToEm * 24;
    font-weight: 700;
    &.get {
      color: var(--c_api-get);
    }

    &.post {
      color: var(--c_api-post);
    }

    &.put {
      color: var(--c_api-put);
    }

    &.patch {
      color: var(--c_api-patch);
    }

    &.delete {
      color: var(--c_api-delete);
    }
  }

  .line {
    position: relative;
    top: -1px;
    width: 2px;
    height: 18px;
    background-color: var(--c_code-text);
    margin: 0 9px;
  }

  .name {
    font-size: $ptToEm * 24;
    color: var(--c_code-name);
    // text-transform: capitalize;
  }

  .url {
    font-size: $ptToEm * 16;
  }

  .version-filter {
    font-size: $ptToEm * 12;
    font-weight: 700;
  }

  .apis {
    width: 100%;
    flex: 1 1 100%;
    overflow-y: auto;
    position: relative;
  }

  .block {
    display: flex;
    align-items: center;
  }
  .row {
    justify-content: space-between;
  }
}
