@import "../../styles/variables";

.xw-cdgs-page.choose {
  display: flex;
  flex-direction: column;
  height: 100%;

  .xw-cdgs-content {
    padding: 0 24px 24px 24px;
  }

  p {
    color: var(--c_text);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
  .xw-cdgs-desc {
    margin-bottom: 36px;
  }
  .info {
    display: flex;
    align-items: center;
    padding: 0 0 12px 0;
    gap: 8px;
    color: var(--c_caption_text);
    font-size: 15px;
    svg {
      width: 32px;
      height: 32px;
    }
    svg.arrow-full-right {
      fill: var(--c_cdg-arrow);
    }
  }

  .btn-action {
    margin-bottom: 36px;
  }

  .xw-page-section > header {
    margin-bottom: 6px;
  }

  .xw-page-section > header .title {
    color: var(--c_page-header-title);
  }
  .vm {
    margin-bottom: 18px;
  }
}
