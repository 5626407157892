@import "../../../styles/variables";

.xw-purchasing {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
}

.xw-aws-banner {
  .notification-info {
    flex-direction: row;
    .notification-title {
      display: unset;
      font-size: unset;
    }
    .notification-description {
      display: unset;
      font-size: unset;
    }
  }
}
