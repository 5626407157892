@import "../../styles/variables";

.xw-modification-value {
  .modification-label-DELETE {
    color: var(--c_modification_Delete);
  }
  .modification-label-CREATE {
    color: var(--c_modification_Create);
  }
  .modification-label-EDIT {
    color: var(--c_modification_Edit);
  }
}
