@import "../../styles/variables";

.xw-combo-line-chart {
  height: 76px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  svg {
    flex: 1 1;
    .line {
      fill: none;
      stroke-width: 2px;
    }

    .currentHover {
      line {
        stroke: var(--c_trend-hover);
        stroke-width: 1px;
      }
      path {
        fill: var(--c_trend-hover);
      }
    }
  }

  .legend {
    width: 126px;
    flex-basis: 126px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    .area-text-0 label {
      color: var(--c_area-area-0);
    }
    .area-text-1 label {
      color: var(--c_chat-area-1);
    }
    .xw-label-value {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      margin-bottom: 0;

      label {
        margin: 0 $unit;
      }
    }
  }

  .title {
    position: absolute;
    bottom: 0;
    left: calc($unit / 2);
    transform: rotate(270deg);
    transform-origin: left bottom;
    font-size: 12 * $ptToEm;
    font-weight: 300;
    width: 76px;
    text-align: center;
  }
}
