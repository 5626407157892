@import "../../styles/variables";

.xw-monitor-value {
  .monitorEnum-label-ALREADY_MONITORED {
    color: var(--c_text);
  }
  .monitorEnum-label-MONITORED {
    color: var(--c_text-status-enabled);
  }
  .monitorEnum-label-UNMONITORED {
    color: var(--c_text-error);
  }
}
