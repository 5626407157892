@import "../../styles/variables";

.xw-policies-page {
  display: flex;
  flex-flow: column;
  height: 100%;
  .xw-default-policies {
    display: flex;
    flex-flow: row nowrap;
    .xw-card {
      min-width: 47%;
      min-height: 250px;
      margin-right: $unit * 2;
      margin-bottom: $unit * 2;
      .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .connectivity{
          min-width: 0;
          display: flex;
          flex-flow: column;
          flex: 1;
        }
        .xw-content.title {
          color: var(--c_policy-default-title);
          font-weight: 400;
        }
        button {
          min-width: 35px;
          align-items: center;
          display: inline-flex;
          margin-left: $unit;
          padding: $unit;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          background-color: var(--c_policy-list-default-details);
          text-decoration: unset;
          color: var(--c_policy-list-default-details-text);
        }
        .sub-title-container {
          display: flex;
          flex-flow: row;
          align-items: center;
          .sub-title{
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .active-alarms {
            font-size: 12 * $ptToEm;
            padding-left: ($unit * 2);
            font-weight: 400;
            color: var(--c_policy-list-active-alarm-text);
            margin: auto;
            .xw-chip {
              display: inline;
              font-weight: 400;
              margin-right: calc($unit / 2);
              border: 1px solid var(--c_policy-list-active-alarm-border);
              background-color: var(--c_policy-list-active-alarm-background);
              color: var(--c_policy-list-active-alarm-count-text);
            }
          }
        }
      }
      .title {
        a {
          &.xw-button {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &.default-policy {
        .key-values {
          display: flex;
          .title-case {
            text-transform: capitalize;
          }
          .left-section {
            flex: 1;
            padding-right: $unit;
            .xw-level {
              width: auto;
            }
            .low-level-icon,
            .med-level-icon,
            .high-level-icon,
            .level-none-icon {
              display: none;
            }
          }
          .right-section {
            flex: 1;
          }
        }
      }
    }
  }
  .xw-data-table {
    height: 100%;
    .table-actions {
      margin: $unit $unit * 2 calc($unit / 2) $unit * 2;
    }
    .actions {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      > .xw-button {
        min-width: 120px;
        &.link {
          min-width: 120px;
        }
      }
    }
  }
}
