@import "../../styles/variables";
.marketing-page {
  .wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
  }

  .header {
    flex: 0 0 auto;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0 30px;

    > .xw-icon {
      flex: 0 0 auto;
      width: 69px;
      height: 36px;
      fill: #029fd9;
      margin-right: 20px;
    }

    .actions {
      display: flex;
      margin-left: 30px;
      .actions-tag {
        font-size: 15px;
        font-weight: 700;
        width: 400px;
        color: #212626;
        align-items: center;
        display: flex;
      }
    }

    .xw-button {
      white-space: nowrap;
    }

    .crosswork {
      font-size: 20px;
      color: #029fd9;
    }
  }

  .crosswork,
  .bgpmon {
    color: #ffffff;
    font-size: 26px;
    font-weight: 300;
    flex: 1 1 100%;
    > span {
      font-weight: 700;
    }
  }

  .content {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 60px 60px 0;
  }

  .app-info {
    flex: 0 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .description {
      font-size: 18px;
      font-weight: 300;
      margin: 10px 0 20px;
      line-height: 21px;
      max-width: 800px;
    }
  }

  .app-icon {
    flex: 0 0 auto;
    position: relative;
    width: 140px;
    height: 140px;
    overflow: hidden;
    margin: 0 0 20px 0;

    .icon-wrapper {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 3;

      &.traffic {
        top: 0;
        bottom: auto;
        left: 30px;
        z-index: 2;
      }

      &.trust {
        right: 0;
        left: auto;
        z-index: 1;
      }
    }
  }

  .icon-wrapper {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    background-color: #029fd9;
    border: 2px #7ed1f6 solid;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.1);

    &.small {
      width: 66px;
      height: 66px;
      .xw-icon {
        width: 28px;
        height: 28px;
      }
    }

    .xw-icon {
      fill: #ffffff;
      width: 36px;
      height: 36px;
    }

    &.traffic {
      background-color: #55bb88;
      border-color: #60e7a4;

      .title {
        color: #80ffc0;
      }
    }

    &.trust {
      background-color: #7ab84f;
      border-color: #9fff5c;

      .title {
        color: #baff8a;
      }
    }
  }

  .lines {
    flex: 0 0 auto;
    display: flex;
    width: 100%;
    max-width: 1398px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
    opacity: 0.15;

    .divide {
      display: flex;
      width: 100%;
      max-width: 1398px;
      justify-content: center;
      align-items: center;
    }

    .line {
      width: 100%;
      height: 30px;
    }

    .top {
      .line {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          border-bottom: 1px #ffffff solid;
        }
        &:nth-child(3) {
          border-right: 1px #ffffff solid;
        }
      }
    }

    .bottom {
      .line {
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5) {
          border-right: 1px #ffffff solid;
        }
      }
    }
  }

  .cards {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    .app-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      justify-content: space-around;
      padding: 30px;
      text-align: center;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 20px;
      margin-left: 6px;

      &:first-child {
        margin-left: 0;
      }

      .title {
        font-size: 24px;
        font-weight: 300;
        margin: 18px 0;
        color: #85d8ff;
      }

      .text {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        max-width: 400px;
      }

      .actions {
        display: flex;
      }

      &.traffic {
        .title {
          color: #80ffc0;
        }

        .btn {
          .xw-icon {
            fill: #80ffc0;
          }
          &:hover {
            color: #80ffc0;
          }
        }
      }

      &.trust {
        .title {
          color: #baff8a;
        }

        .btn {
          .xw-icon {
            fill: #baff8a;
          }
          &:hover {
            color: #baff8a;
          }
        }
      }
    }

    .btn {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      padding: 10px;
      margin: 10px 0 0 0;
      text-align: left;
      line-height: 18px;

      &:last-child {
        margin-right: 0;
      }

      .xw-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      &:hover {
        color: #85d8ff;
      }
    }
  }

  .bgpmon-wrapper {
    width: calc(100% + 120px);
    padding: 0 30px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    background-color: rgba(255, 255, 255, 0.1);
    margin-block: auto;

    .content-wrapper {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 1200px;
      padding: 0 30px;
    }

    .bgpmon-btn {
      flex: 0 0 auto;
      height: 50px;
      margin: 0 0 0 20px;
      padding: 0 24px;
      white-space: nowrap;
      line-height: 50px;
      background-color: #398961;
      border: 1px solid #60e7a4;

      &:hover {
        background-color: #44a675;
      }
    }
    .text {
      font-size: $ptToEm * 18;
      line-height: 25px;
    }
  }

  .footer {
    flex: 0 0 auto;
    background: linear-gradient(90deg, #029fd9 0%, #bfdd20 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    padding: 0 18px 0 30px;

    .copyright-text {
      font-size: 11px;
      line-height: 36px;
    }

    .footer-nav {
      display: flex;
      color: #373f40;
      font-weight: bold;
      font-size: 11px;
      align-items: center;

      > a {
        display: flex;
        align-items: center;
        padding: 0 12px;
        line-height: 36px;

        &::before {
          pointer-events: none;
          position: relative;
          left: -12px;
          content: "";
          display: block;
          width: 1px;
          height: 12px;
          background-color: rgba(16, 97, 141, 0.5);
        }
        &:first-child {
          &::before {
            display: none;
          }
        }
        &:hover {
          color: #10618d;
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .marketing-page {
  }
}

// Below class is used by error.html, and can be re-used where we have pattern like login / error page
.common-background-page {
  font-family: CiscoSans, arial, sans-serif;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: #191e20;
  color: #fff;
  font-size: 13px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  .color-bar {
    background: linear-gradient(180deg, #029fd9 0%, #bfdd20 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 24px;
  }
  .main-content {
    background: unset;
    position: relative;
    z-index: 2;
    padding-left: 60px;
    width: 70%;
  }
  .logo {
    display: block;
    margin-bottom: 24px;
  }
  .title {
    display: block;
  }
  .message {
    margin: 9px 0;
    padding: 0;
  }
  .important-message {
    max-width: 70%;
    margin-top: 12px;
    font-size: 14px;
    line-height: 18px;
  }
  .important-message .email-link {
    color: #bfdd20;
  }
  .copyright {
    z-index: 2;
    position: absolute;
    bottom: 12px;
    left: 60px;
    font-size: 11px;
  }
  .lines {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
  }
  .background {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(305.42deg, #191e20 0%, rgba(25, 30, 32, 0) 100%),
      linear-gradient(180deg, #029fd9 0%, #bfdd20 100%);
    opacity: 0.8;
  }
}

.bgpmon {
  .header {
    height: 73 * $ptToEm;
  }
  .bgpmon-tag {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-right: 12 * $ptToEm;
    .tag1 {
      color: #212626;
      font-size: 17px;
      font-weight: 300;
    }
    .tag2 {
      color: #029FD9;
      font-size: 23px;
      font-weight: 300;
    }
  }

  .bgpmon-content {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 60px 60px 0;
    font-weight: 400;
    .bgpmon-title {
      h3 {
        font-size: 32px;
        font-weight: 300;
      }
      h1 {
        font-size: 56px;
        font-weight: 300;
      }
    }
    .bgpmon-desc {
      padding-top: 40 * $ptToEm;
      font-size: 13px;
    }
    .create-account {
      padding-top: 48 * $ptToEm;
      a {
        color: #DDF94B;
      }
    }
    .ask-assistance {
      padding-top: 12 * $ptToEm;
      a {
        color: #DDF94B;
      }
    }

  }
  
  .cards {
    padding-top: 48 * $ptToEm;
    justify-content: left;
  }

}

.ccniPromo {
  .ccni-header {
    display: flex;
    gap: 20px;
    margin: 20px 0px 0px 20px;
    align-items: center;
    .xw-icon{
      width: 53px;
      height: 27px;
      fill: #FFFFFF;
    }
  }
  .tag2 {
    color: #FFFFFF;
    font-size: 23px;
    font-weight: 300;
  }

  .ccniPromo-content {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 60px 60px 0;
    font-weight: 400;
    .ccniPromo-title {
      h3 {
        font-size: 32px;
        font-weight: 300;
      }
      h1 {
        font-size: 56px;
        font-weight: 300;
      }
    }
  }
  
  .cards {
    padding-top: 48 * $ptToEm;
    justify-content: center;
    gap: 5px;

    .app-card {
      width: 45%;
      align-items: baseline;
      .ccni-text {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
        margin-top: 15px;
      }

      .ccni-description {
        margin-top: 24px;
      }

      .ccni-title {
        font-size: 24px;
        font-weight: 300;
        margin: 18px 0 10px;
        align-self: center;
      }

      .feature-item {
        margin-bottom: 10px;
      }
  
      .ccni-actions {
        display: flex;
        align-self: flex-end;
        .ccniPromoBtn-container {
          font-size: 16px;
          font-weight: 700;
          padding: 10px;
          margin: 10px 0 0 0 ;
      }
      .ccniPromoBtn {
        background-color: #0F876A;
        border: 1px solid #0C8F4E;
      }
  
      }
      .ccniPromo-desc {
        padding-top: 40 * $ptToEm;
        font-size: 13px;
      }
  
      .subtitle {
        font-size: 20px;
        font-weight: 700;
        color: #FFF673;
        align-self: center;
      }
    }
  }
}