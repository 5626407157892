@import "../../styles/variables";

.peer-import {
  .field {
    padding-top: 12px;
  }
  #importPeer {
    .await-confirm {
      .sub-title {
        color: var(--c_notification-yellow);
      }
    }
    .content {
      padding-bottom: 24px;
      line-height: 19px;
      .sub-content {
        padding-top: 18px;
        line-height: 19px;
      }
    }
    .peer-help {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .modal-body {
      .xw-button {
        margin-bottom: calc($unit / 2);
      }
    }
    .modal-footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      .spacer {
        flex-grow: 1;
      }
      .import-help-ok {
        display: flex;
      }
    }
  }
}
