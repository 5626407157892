@import "../../styles/variables";

.xw-rov-value {
  color: var(--c_text-unknown);
  .status-Invalid {
    color: var(--c_modification_Delete);
  }
  .status-Valid {
    color: var(--c_modification_Create);
  }
}
