@import "../../../styles/variables";

.xw-calendar {
  padding: 0 $unit * 2 $unit * 2 $unit * 2;
  border-bottom: 1px var(--c_timeRange-line) solid;
  height: 318px;

  .month-picker {
    display: flex;
    width: 100%;
    align-items: center;

    .month-picker-btn {
      flex: 0 0 auto;
      background-color: var(--c_cal-btn-bg);
      border-radius: 100px;
      padding: 12px;
      border: 1px var(--c_cal-btn-border) solid;
      transition: background-color 0.2s ease;

      .xw-icon {
        fill: var(--c_cal-btn-icon);
      }

      &.prev {
        .xw-icon {
          transform: translateX(-1px) rotate(90deg);
        }
      }

      &.next {
        .xw-icon {
          transform: translateX(1px) rotate(-90deg);
        }
      }

      &:hover {
        background-color: var(--c_cal-btn-bg-hover);

        .xw-icon {
          fill: var(--c_cal-btn-icon-hover);
        }
      }
    }

    .month-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 100%;
      color: var(--c_timeRange-text-month);
      font-size: 18px;

      .picker-text-btn {
        display: flex;
        align-items: center;
        padding: calc($unit / 2);

        .xw-icon {
          margin-left: calc($unit / 2);
          fill: var(--c_timeRange-incrementer-icon);
        }

        &:hover {
          .xw-icon {
            fill: var(--c_timeRange-incrementer-icon-hover);
          }
        }
      }
    }
  }

  .dropdown {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: $unit * 4;
    left: $unit * 2;
    right: $unit * 2;
    z-index: 2;
    border: 1px solid var(--c_cal-select-border);
    background-color: var(--c_cal-select-bg);

    .select-btn {
      flex: 1 1 33%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: $unit * 4;
      color: var(--c_text-link);

      &:hover {
        color: var(--c_cal-select-text-hover);
        background-color: var(--c_cal-select-btn-hover);
      }
    }
  }

  .table {
    position: relative;
    left: -18px;
    width: calc(100% + 36px);

    .day-btn {
      display: block;
      position: relative;
      width: 100%;
      text-align: center;
      width: 100%;
      padding: 6px 0;
      border-radius: 100px;
      border: 1px transparent solid;
      color: var(--c_cal-text);

      &:hover {
        background-color: var(--c_cal-num-bg-hover);
        color: var(--c_cal-num-text-hover);
      }

      &.today {
        border-color: var(--c_cal-today-border);
        color: var(--c_cal-today-text);
      }
      &.disabled {
        pointer-events: none;
        color: var(--c_cal-today-text-disabled);
      }
      &.start {
        border-radius: 100px 0 0 100px;
        background-color: var(--c_cal-num-bg-selected);
        color: var(--c_cal-num-text-selected);
      }
      &.middle {
        border-radius: 0;
        background-color: var(--c_cal-num-bg-selected);
        color: var(--c_cal-num-text-selected);
      }
      &.end {
        border-radius: 0 100px 100px 0;
        background-color: var(--c_cal-num-bg-selected);
        color: var(--c_cal-num-text-selected);
      }
      &.selected {
        background-color: var(--c_cal-num-bg-selected);
        color: var(--c_cal-num-text-selected);
      }
      &.doubled {
        border-radius: 6px;
        background-color: var(--c_cal-num-bg-selected);
        color: var(--c_cal-num-text-selected);

        &:after {
          opacity: 0;
        }
      }
      &.highlighted {
        background-color: var(--c_cal-num-bg-highlighted);
        color: var(--c_cal-text);

        &.h-start {
          border-radius: 100px 0 0 100px;
          background-color: var(--c_cal-num-bg-selected);
          color: var(--c_cal-num-text-selected);
        }

        &.h-middle {
          border-radius: 0;
        }

        &.h-end {
          border-radius: 0 100px 100px 0;
        }
      }
    }

    .row {
      display: flex;
      justify-content: flex-start;
    }

    .col {
      flex: 0 1 100%;
      text-align: center;
      user-select: none;

      &.header-col {
        padding: 12px 0;
        font-weight: 700;
        color: var(--c_cal-header-text);
        user-select: auto;
      }

      &:first-child {
        .day-btn.middle,
        .day-btn.end {
          &:after {
            left: -7px;
            content: "";
            position: absolute;
            display: block;
            top: -1px;
            bottom: -1px;
            width: 7px;
            background-color: var(--c_cal-num-bg-selected);
          }
        }
      }

      &:last-child {
        .day-btn.middle,
        .day-btn.start {
          &:after {
            content: "";
            position: absolute;
            display: block;
            top: -1px;
            bottom: -1px;
            right: -7px;
            width: 7px;
            background-color: var(--c_cal-num-bg-selected);
          }
        }
      }
    }
  }
}
