@import "../../../styles/variables";
.xw-endpoint-view {
  $fieldWidth: 300px;
  > .content {
    display: flex;
    flex-flow: row wrap;
    width: $fieldWidth * 2.5;
    .xw-label-value {
      width: $fieldWidth;
    }
  }

  .xw-page-header {
    padding: 0;
  }

  > .xw-data-table {
    flex-grow: 1;
    position: relative;
    left: $unit * -2;
    width: calc(100% + #{$unit * 4});
  }
  .endpoint-sub-title {
    display: flex;
    align-items: baseline;

    .xw-label-value {
      margin-bottom: 0;
      .value > span {
        font-size: 16 * $ptToEm;
        font-weight: 700;
        line-height: 30 * $ptToEm;
      }
    }

    .status-enabled {
      color: var(--c_text-status-enabled);
      font-weight: 700;
    }

    .status-disabled {
      color: var(--c_text-status-disabled);
      font-weight: 700;
    }

    .status-error {
      color: var(--c_text-error);
      font-weight: 700;
    }
  }
}
