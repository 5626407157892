@import "../../../styles/variables";
.xw-utilization-range-selector {
  padding-bottom: 2 * $unit;

  .slider-handles {
    .range-handle {
      background-color: var(--c_range-utilization-handle);
      &.handle-0,
      &.handle-1 {
        .range-handle-label {
          background-color: var(--c_content-bg);
          width: 40px;
          left: calc(50% - 20px);
          display: inline-block;
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          opacity: 1;
          border-width: 0;
          border-style: solid;
          border-radius: 10px;
        }
      }
    }
  }
  .slider-handles .handle-10 {
    .range-handle-label {
      color: var(--c_range-handle-label);
    }

    &::after {
      width: 10px;
      height: 10px;
      right: 3px;
      top: 3px;
      border-radius: 50%;
      background-color: var(--c_range-utilization-error);
      opacity: 1;
    }

    &.disabled {
      background: var(--c_content-bg);
    }
  }
  .slider-handles .handle-0 {
    border-color: var(--c_range-utilization-error);
    &::after {
      width: 6px;
      height: 6px;
      right: 5px;
      top: 5px;
      border-radius: 50%;
      background-color: var(--c_range-utilization-error);
      opacity: 1;
    }

    &.disabled {
      border: 2px solid var(--c_content-bg);
      &::after {
        background: var(--c_range-handle-disabled-bg);
      }
    }
  }
  .slider-handles .handle-1 {
    border-color: var(--c_range-utilization-error);
    z-index: 3;

    .range-handle-label {
      width: 88px;
    }

    &.disabled {
      background: var(--c_content-bg);
      border: 2px solid transparent;
    }

    &::after {
      width: 6px;
      height: 6px;
      right: 5px;
      top: 5px;
      border-radius: 50%;
      background-color: var(--c_modification_Delete);
      opacity: 1;
    }
  }

  .slider-handles .handle-2 {
    .range-handle-label {
      color: var(--c_range-handle-label);
    }
    &::after {
      width: 10px;
      height: 10px;
      right: 3px;
      top: 3px;
      border-radius: 50%;
      background-color: var(--c_range-utilization-error);
      opacity: 1;
    }

    &.disabled {
      background: var(--c_content-bg);
    }
  }

  .slider-tracks {
    font-weight: 800;
    text-align: center;
    letter-spacing: 0.5em;

    .range-track-label {
      font-size: 8px;
      padding: calc($unit / 2);
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: -$unit * 2;
    }
  }

  .range-rail {
    background-color: var(--c_range-utilization-error);
    &:hover,
    &:active {
      background-color: var(--c_range-utilization-error);
    }
  }

  .slider-tracks .track-0,
  .slider-tracks .track-2 {
    cursor: default;
    background-color: var(--c_range-utilization-error);
    color: var(--c_range-handle-label);
    .range-track-label::before {
      content: "ALARM";
      margin-left: calc($unit / 2);
    }
  }
  .slider-tracks .track-1 {
    cursor: default;
    color: var(--c_range-handle-label);
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H8V4H0V0Z' fill='%239FA5A6'/%3E%3Cpath d='M4 0H8L4 4H0L4 0Z' fill='%23676B6C'/%3E%3C/svg%3E%0A");
    background-repeat: repeat;
    background-size: auto 100%;
    .range-track-label::before {
      content: "IGNORE";
      margin-left: calc($unit / 2);
    }
  }

  &:hover,
  &:active {
    .range-handle {
      border: 2px solid var(--c_range-utilization-error);
      &.disabled {
        border: 2px solid var(--c_content-bg);
      }
    }
  }
}
