.dot-loader {
  div {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    float: left;
    margin: 0 2px;
    background: var(--c_processing_modal_loader);
    transform: scale(0);
  }
  .dot-1 {
    animation: grow 3.4s infinite ease-in-out;
  }
  .dot-2 {
    animation: grow 3.4s infinite ease-in-out;
    animation-delay: 0.3s;
  }
  .dot-3 {
    animation: grow 3.4s infinite ease-in-out;
    animation-delay: 0.6s;
  }
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  75% {
    transform: scale(1);
    background: var(--c_processing_modal_loader);
  }
  100% {
    transform: scale(0);
    background: var(--c_processing_modal_loader);
  }
}
