.insightsPackageSearchBox {
  display: grid;
  box-sizing: border-box;
  grid-template-rows: 100%;
  grid-template-columns: 50% 50%;
  place-items: stretch;
  place-content: stretch;

  .insightsPackageSearchInput {
    grid-area: 1/1/1/1;
  }
  .insightsPackageSearchModeToggle {
    grid-area: 1/2/1/2;
  }

  .rail {
    height: 48px;
    margin-left: 12px;

    .options {
      top: 5px;
    }
  }
}
