@import "../../styles/variables";

//.policy-alarm-history-tab {
//  .custom-filter-controls-section {
//    position: absolute;
//    padding-top: $unit * 1.5;
//  }
//
//  .xw-dateTime {
//    padding-top: $unit * 1.5;
//    padding-bottom: $unit * 1.5;
//  }
//
//  #policy-alarmHistory-table {
//    padding-top: $unit;
//  }
//}

.policy-alarms {
  .xw-data-table {
    display: flex;
    height: 100%;
  }

  .custom-filter-controls-section-new-policy {
    position: absolute;
    padding-top: $unit * 1.5;
    padding-left: $unit * 2;
  }

  .policy-alarms-table {
    position: relative;
    flex-flow: column nowrap;
    overflow: hidden;

    padding-top: 60px;

    .xw-dateTime {
      padding-top: $unit * 1.5;
      padding-bottom: $unit * 1.5;
    }
  }

  #policy-alarmHistory-table {
    margin-top: 0;
  }
  position: relative;
  left: -24px;
  width: calc(100% + 48px);
  flex: 1 1 100%;
}
