@import "../../styles/variables";
.xw-flow-filter-controls {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: $unit;
  max-width: 100%;

  .xw-device-or-device-group-select {
    // flex: 1 0 50%;
    padding-right: $unit;
  }

  .timeframe-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    flex: 1 0 50%;
  }
  .xw-toggle {
    margin: $unit 0;
    flex: 1 1 auto;
    max-width: 391px;
  }
  .xw-trafficDatePicker {
    margin-left: $unit;
  }
}
