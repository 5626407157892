@import "../../../styles/variables";

.xw-user-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $unit $unit 0;
  .xw-page-header {
    padding: 0;
  }
  .xw-input-field {
    max-width: 64em;
  }

  .view-details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 1px solid var(--c_page_header_border_color);
    .xw-label-value {
      width: 50%;
    }
  }
  .current-user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .xw-label-value {
      flex: 1 1 100%;
    }
  }

  #apikey-table_table,
  #bearerToken-table_table {
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
  }
  &.mode-edit {
    .tracking {
      .opt-out-label {
        display: flex;
        align-items: center;
      }
      .analytics-tooltip {
        padding-left: calc($unit/2);
      }
      .xw-switch-input {
        padding-top: calc($unit/2);
        label {
          min-width: unset;
          padding-left: $unit;
        }
      }
    }
  }
  &.mode-create {
    .xw-textarea {
      display: flex;
      > textarea {
        width: 100%;
      }
      &.text-error {
        textarea {
          color: var(--c_text-error);
        }
      }
    }
  }
}

#createApiModal,
#createBearerTokenModal {
  .createApiForm,
  .createBearerTokenForm {
    .xw-textarea,
    textarea {
      width: 100%;
    }

    .xw-dateTime {
      width: 100%;

      .field-wrapper {
        width: 100%;
      }
    }
  }
  .modal-description {
    line-height: 20 * $ptToEm;
  }
}
.xw-api-library,
.xw-bearer-library {
  .xw-data-table {
    height: 100%;
    margin-left: -12px;
    margin-right: -12px;
  }
}
.api-result-display {
  color: var(--c_api_key_text_color);
  font-size: 16 * $ptToEm;
  text-align: center;
  padding: ($unit * 3) 0;
}

.token-result-display {
  color: var(--c_api_key_text_color);
  padding: ($unit * 3) 0;
  word-break: break-word;
  overflow-x: hidden;
  .token {
    font-size: 16 * $ptToEm;
    line-height: 22 * $ptToEm;
    font-weight: 300;
    max-height: 225px;
  }
}
