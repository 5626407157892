@import "../../styles/variables";

.xw-validation-summary {
  > h3 {
    font-size: 16 * $ptToEm;
    font-weight: 300;
    color: #858b8c;
  }
  > p {
    margin: 0;
    font-size: 12 * $ptToEm;
    color: #d4d8d9;
  }
  .validation-results {
    padding: $unit 0;
    list-style: disc outside;
    li {
      height: 22px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .field-name,
      .field-desc {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .spacer {
        margin: 0 $unit;
        border-bottom: 1px dashed #505859;
        height: 1px;
        flex: 1 1;
        min-width: $unit;
      }

      .field-desc {
        color: #ff4c52;

        &.required {
          color: var(--c_textInput-label-required);
        }
      }
    }
  }
}
