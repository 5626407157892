@import "../../styles/variables";

.xw-upsell {
  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    display: block;
    font-size: $ptToEm * 24;
    margin-bottom: calc($unit / 2);
    color: var(--c_text-warning);
  }

  .message {
    margin-bottom: $unit;
  }

  .actions {
    display: flex;
  }

  .link-btn.subscriptions {
    display: block;
    position: relative;
    margin: calc($unit / 2) 4px;
    width: 154px;
    min-width: 125px;
    padding: 0 24px;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: var(--c_button-label);
    border-radius: 2px;
    user-select: none;
    color: var(--c_button-secondary-label);
    background: var(--c_button-secondary);
    border: 1px solid var(--c_button-secondary-border);
    line-height: 32px;

    &:hover {
      background-color: var(--c_button-secondary-hover);
      color: var(--c_button-secondary-hover-label);
      .xw-icon {
        fill: var(--c_button-secondary-hover-label);
      }
    }
    &:active {
      background-color: var(--c_button-secondary-pressed);
      border: 1px solid var(--c_button-secondary-pressed-border);
    }

    &.primary {
      color: var(--c_button-primary-label);
      background: var(--c_button-primary);
      border: 1px solid var(--c_button-primary-border);
      &:hover {
        background-color: var(--c_button-primary-hover);
      }
      &:disabled {
        border: 1px solid var(--c_button-primary-disabled-border);
        pointer-events: none;
        opacity: 0.5;
      }
      &:active {
        background-color: var(--c_button-primary-pressed);
        border: 1px solid var(--c_button-primary-pressed-border);
      }
    }
  }
}
