@import "../../../styles/variables";
.xw-tolerance-range-selector {
  .slider-handles .handle-2 {
    display: none;
  }
  .slider-handles .handle-0 {
    border-color: var(--c_modification_Edit);
  }
  .slider-handles .handle-1 {
    border-color: var(--c_modification_Delete);
  }
  .slider-tracks .track-0 {
    background-color: var(--c_modification_Edit);
  }
  .slider-tracks .track-1 {
    background-color: var(--c_modification_Delete);
  }
}
