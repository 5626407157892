@import "../../styles/variables";

.xw-bgp-state {
  .state-Idle,
  .state-Connect,
  .state-Active,
  .state-Open-Sent,
  .state-Open-Confirm {
    color: var(--c_card-arrow-inprogress);
  }
  .state-Established {
    color: var(--c_card-arrow-active);
  }
  .state-unknown {
    color: var(--c_card-arrow-inactive);
  }
}
