@import "../../styles/variables";

.xw-overlay-apply-filter {
  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--c_content-bg);
    opacity: 0.8;
  }

  .content {
    z-index: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    .text {
      font-size: 28 * $ptToEm;
      font-weight: 200;
      margin-bottom: $unit;
    }
    .subText {
      margin-bottom: $unit;
    }
    .buttons {
      display: flex;
      flex-flow: row nowrap;
    }
  }
}
