@import "../../styles/variables";

.xw-stepwizard {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  .xw-page-header {
    border-bottom: 1px solid var(--c_section-header-separator);
    .separator {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
  .page-content {
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
    .page-content-left {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: auto;
      padding: $unit * 2 $unit * 2 58px;
      background: var(--c_stepwizard_left);
      border-right: 1px solid var(--c_stepwizard-border);
    }
    .page-content-right {
      flex: 0 0 auto;
      width: 310px;
      background: var(--c_stepwizard_right);

      .number-panel {
        padding: $unit * 2;
        height: 100px;
        display: flex;
        align-items: center;

        &.selected {
          background: var(--c_stepwizard_right_selected);
          border-top: 1px solid var(--c_stepwizard-border);
          border-bottom: 1px solid var(--c_stepwizard-border);
          border-left: 1px solid var(--c_stepwizard_right);
        }

        .select-indicator {
          padding-left: 12 * $ptToEm;
          font-size: 15 * $ptToEm;
          color: var(--c_stepwizard-section-text-color);
        }
      }
    }
    .number-circle {
      flex-shrink: 0;
      border-radius: 50%;
      width: 34px;
      height: 34px;
      padding: 5px;
      font-size: 18 * $ptToEm;
      line-height: 25 * $ptToEm;
      text-align: center;
      background: var(--c_circle);
      color: var(--c_circle-text);
      border: 1px solid var(--c_circle_border);

      &.selected {
        background: var(--c_circle-active);
        color: var(--c_circle-active-text);
        border: 1px solid var(--c_circle-active-border);
      }
    }
  }
}
.right {
  order: 1;
}
