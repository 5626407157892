@import "../../../styles/variables";

.xw-api-usage {
  background-color: var(--c_api-param-bg);
  border-radius: calc($unit / 2);
  margin-top: calc($unit / 2);
  padding: $unit;

  .description {
    line-height: 18px;
    color: var(--c_api-param-sub);
  }

  .url {
    font-size: $ptToEm * 14;
    color: var(--c_api-param-code);
    word-wrap: break-word;
  }
}
