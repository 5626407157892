@import "../../styles/variables";

$drawer-opacity: 0.95;

.xw-drawer {
  position: fixed;
  top: $header-height;
  bottom: 0;
  right: 0;
  background-color: var(--c_drawer-bg);
  width: 440px;
  z-index: $zDrawer;
  opacity: $drawer-opacity;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: -8px 0px 8px rgba(0, 0, 0, 0.15);

  > header,
  > .content,
  > footer {
    padding: 0 $unit * 2;
  }
  > header {
    height: 72px;
    line-height: 72px;
    font-size: 20 * $ptToEm;
    background-color: var(--c_drawer-header-bg);
    margin-bottom: $unit;
  }

  footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    background-color: var(--c_drawer-footer-bg);
  }

  > .content {
    flex-grow: 1;
    overflow-y: auto;
  }

  // drawer sliding animation
  &.enter {
    transform: translateX(110%);
    opacity: 0.1;
  }

  &.enter-active {
    transform: translateX(0);
    opacity: $drawer-opacity;
    transition: transform 200ms ease-in, opacity 200ms ease-in;
  }

  &.enter,
  &.enter-done,
  &.exit {
    display: flex;
  }

  &.exit {
    transform: translateX(0);
    opacity: $drawer-opacity;
  }

  &.exit-active {
    transform: translateX(110%);
    opacity: 0.1;
    transition: transform 150ms ease-out, opacity 160ms ease-out;
  }

  &.exit-done {
    display: none;
  }
}

.theme-high-contrast .xw-drawer {
  > header {
    border-bottom: 1px solid var(--c_drawer-border);
  }

  > footer {
    border-top: 1px solid var(--c_drawer-border);
  }
}
