@import "../styles/variables";
.xw-valuesWithTooltip {
  .value {
    color: var(--c_text-title);
    cursor: default;
  }

  .separator {
    color: var(--c_text-title);
    padding-right: 5px;
  }
}
