@import "../../styles/variables";

.xw-customize-columns-modal {
  width: 600px;
  .xw-reorder-list {
    margin: calc($unit * 2) $unit;
    ul li {
      padding-right: $unit;
      .xw-checkbox {
        flex-grow: 1;
        margin-bottom: 0;
        margin-top: calc($unit * 2 / 3);
        &:hover label {
          color: var(--c_text-hover);
        }
        > .checkbox,
        > input {
          left: unset;
          right: 0;
        }
        label {
          display: block;
          width: 100%;
          font-size: 18 * $ptToEm;
          transition: color 0.15s ease;
          padding-left: 0;
          transition: color 0.15s ease;
        }

        &.hidden-column label {
          color: var(--c_text-status-disabled);
        }
        &.not-hideable {
          label {
            cursor: default;
          }
          > input,
          > .checkbox {
            display: none;
          }
        }
        > .subText {
          padding-left: 0;
        }
      }
    }
  }

  .modal-dialog .modal-footer {
    padding: $unit ($unit * 2);
  }
}
