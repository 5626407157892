@import "../../styles/variables";
@import "../../styles/scrollbar";

.xw-main-header {
  position: relative;
  flex: 0 0 auto;
  // width: 100%;
  height: $header-height;
  display: flex;
  flex-flow: row nowrap;
  background-color: var(--c_header-bg);
  font-size: 15 * $ptToEm;
  z-index: 1;
  border-bottom: 1px var(--c_header-border) solid;
  box-sizing: border-box;
  align-items: center;

  .xw-help-link {
    height: auto;
    line-height: auto;
  }

  .logo-wrapper {
    display: flex;
  }

  .env-name {
    width: 100%;
    text-align: left;
    margin-left: $unit;
    user-select: none;
    text-transform: uppercase;
    font-size: 16 * $ptToEm;
    font-weight: 700;
    color: var(--c_header-env-text);
    transform: translate(0, 4px);
  }

  .title {
    z-index: 1;

    .platform-logo {
      flex: 0 0 auto;
      display: block;
      height: 20px;
      width: 136px;
      fill: var(--c_text-title);
    }

    .xw-breadcrumbs {
      display: inline-flex;
      a,
      span {
        height: 18px;
        line-height: 18px;
      }
    }
  }

  .user-info {
    flex-grow: 1;
    padding: 0 ($unit * 2);
    text-align: right;
    z-index: 1;

    .user-name {
      line-height: 20 * $ptToEm;
      color: var(--c_user-name);
      margin-top: calc($unit / 2);
    }

    .user-org {
      line-height: 18px;
      .separator {
        color: var(--c_header-link-separator);
      }
    }
  }

  .user-icon {
    float: right;
    width: $unit * 4;
    height: $unit * 4;
    border-radius: $unit * 2;
    font-size: 16 * $ptToEm;
    font-weight: 700;
    color: var(--c_user-initials);
    background-color: var(--c_user-icon-bg);
    text-align: center;
    line-height: $unit * 4;
    margin-left: $unit;
    transition: background-color 0.15s ease;

    &:hover {
      background-color: var(--c_header-link-hover);
    }
  }

  .dropdown-open .user-icon {
    background-color: var(--c_header-active);
  }

  .user-org {
    font-size: 12 * $ptToEm;

    a,
    button {
      transition: color 0.15s ease;
      color: var(--c_header-link);
      &:hover {
        color: var(--c_header-link-hover);
      }
    }
  }

  .logo {
    flex: 0 0 auto;
    fill: var(--c_header-logo);
    height: 36px;
    width: 64px;
    margin: 0 22px;
  }
  .xw-dropdown-menu-button[data-qa="header-menu"] .xw-dropdown-menu {
    @extend .scrollbar-small;
    overflow-y: auto;
    max-height: calc(100% - 12px);
    .xw-checkbox {
      overflow-wrap: anywhere;
    }
    button, a, .xw-checkbox {
      .theme-high-contrast &:hover {
        text-decoration: underline;
      }
    }
  }
}
