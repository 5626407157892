@import "../../../styles/variables";
.xw-flow-dashboard {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex-grow: 1;
  display: grid;
  grid-template-rows: 64px 296px 1fr 1fr;
  grid-template-columns: 50% 50%;
  padding: 0 $unit;
  > .xw-flow-filter-controls,
  > .overview {
    grid-column: span 2;
  }
  > .xw-flow-filter-controls {
    //   display: flex;
    //   flex-flow: row-reverse nowrap;
    padding: 0 $unit;
  }

  > .xw-overlay {
    margin-top: $unit * 6;
  }

  .xw-row-chart foreignObject .xw-tooltip {
    width: 228px;
  }

  > .overview {
    > .content {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: $unit $unit * 2;
      .xw-line-chart {
        flex-grow: 1;
      }
      ul {
        flex-basis: 316px;
        li {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          padding: calc($unit / 4) 0;
          cursor: pointer;
          .ellipsize-tooltip {
            flex-basis: 136px;
            a.link {
              font-size: 14 * $ptToEm;
              transition: color 0.15s ease;
              color: var(--c_text-link);
            }
          }

          .chart-color-bar {
            width: calc($unit / 2);
            height: $unit * 1.5;
            border-radius: 2px;
            margin: 0 $unit;
          }
          .separator {
            margin: 0 calc($unit/2);
            font-weight: bold;
            font-size: 14 * $ptToEm;
            color: var(--c_chart-data);
          }
          .xw-bytes-text {
            flex-basis: 74px;
          }
        }
      }
    }
  }

  .xw-card {
    &.top-asn .content {
      overflow: visible;
    }
    > .title {
      flex-flow: row nowrap;
      justify-content: space-between;
      .xw-button {
        position: relative;
        margin: 0;
        top: calc(-#{$unit} / 2);
      }
    }

    > .content {
      overflow: hidden;

      .xw-rx-tx-chart {
        align-self: stretch;
        width: auto;
        height: auto;
        flex-grow: 1;
        margin-right: $unit;
      }

      .xw-page-loader {
        // background-color: var(--c_page-footer-bg);
      }
    }
  }
}
