@import "../../../styles/variables";

.xw-toggle {
  $buttonHeight: 34px;
  position: relative;
  display: inline-block;
  min-width: 192px;

  label {
    font-size: 12 * $ptToEm;
    color: var(--c_textInput-label);
    margin-bottom: calc($unit / 3);
  }
  .rail {
    display: block;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--c_toggle-bg);
    border-radius: 2px;
    border: 1px solid var(--c_toggle-border);
    position: relative;

    .options {
      position: relative;
      display: flex;
      flex-flow: row nowrap;

      button {
        color: var(--c_toggle-label);
        height: $buttonHeight;
        font-size: 15 * $ptToEm;
        transition: color 0.1s ease 0.05s;
        text-align: center;
        text-transform: capitalize;
        flex: 1 1 100%;
        position: relative;
        min-width: 96px;
        overflow: hidden;
        box-sizing: border-box;

        > span {
          position: absolute;
          top: 0;
          right: $unit;
          bottom: 0;
          left: $unit;
          line-height: $buttonHeight;
          display: block;
          z-index: 2;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          user-select: none;
        }

        &.selected {
          &:focus span {
            text-decoration: underline;
          }
        }

        &.selected,
        &:not(.selected):active,
        &:not(.selected):hover:active {
          color: var(--c_toggle-label-selected);
          cursor: default;
          .value .xw-icon {
            fill: var(--c_toggle-label-selected);
          }
        }

        &:not(.selected) {
          transition: color 0.1s ease 0;
          &:hover {
            color: var(--c_toggle-label-hover);
            .xw-icon {
              fill: var(--c_toggle-label-hover);
            }
          }
        }

        &:not(:last-child) {
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            right: 0;
            top: $unit;
            bottom: $unit;
            background-color: var(--c_toggle-border);
          }
        }

        &:disabled {
          pointer-events: none;
        }
      }
    }

    .marker {
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      background-color: var(--c_toggle-marker);
      border-radius: 2px;
      border: 1px solid var(--c_toggle-border-outer);
      z-index: 1;
      &:before {
        content: " ";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid var(--c_toggle-border-selected);
        border-radius: 2px;
      }
    }
  }

  &.icon {
    .rail .options button > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.theme-high-contrast .xw-toggle {
  .rail .marker:before {
    border-width: 2px;
  }
}
