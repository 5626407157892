// .ellipsize {
//   display: block;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }

.ellipsize-tooltip.xw-tooltip-trigger {
  display: block;
  overflow: hidden;
  white-space: nowrap;

  &.overflow {
    text-overflow: ellipsis;
  }

  & + .xw-tooltip {
    max-width: unset;

    .tooltip-copy-paste-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
