@import "../../../../styles/variables";

.alarm-details-additional-info-table {
    height: 100%;
    flex: 1 1 auto;

    .table-actions {
        justify-content: flex-start;

        .xw-button.link {
            svg.xw-icon {
                fill: var(--c_button-link-label);
            }
        }

        .selected-files {
            display: flex;
            flex-flow: row nowrap;
            font-size: 16 * $ptToEm;
            font-weight: 300;
            line-height: 28 * $ptToEm;
            padding-left: $unit;
            flex: 1 1 100%;

            .text {
                color: var(--c_page-header-label);
                padding-right: $unit;
            }

            .total {
                color: var(--c_textInput-label-required);
                font-weight: 700;
            }

            .selected {
                color: var(--c_text-emphasis);
                font-weight: 700;
            }
        }
    }

    .tip {
        width: 240px;
        white-space: normal;
    }

    .yes {
        svg {
            fill: var(--c_table_column_update_text);
        }
    }

    .no {
        svg {
            fill: var(--c_text-emphasis)
        }
        color: var(--c_text-emphasis)
    }
}