@import "../../styles/variables";

.xw-select-policy-rules-modal {
  .xw-modal {
    .modal-header {
      background-color: var(--c_policy-create-modal-header-background);
    }
    .modal-footer {
      background-color: var(--c_policy-create-modal-footer-background);
    }
  }
  .modal-body {
    padding: $unit * 2;
    .xw-page-caption {
      padding-top: 0;
      font-size: 18 * $ptToEm;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
