@import "../../styles/variables";
.xw-sankey-chart {
  display: flex;
  flex-flow: row nowrap;

  > .left-gutter {
    width: 44px;
    padding: $unit 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    .title {
      color: var(--c_sankey-title);
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      flex-grow: 1;
      width: $unit * 2;
      line-height: $unit * 2;
    }
    .xw-button {
      width: 32px;
      height: 32px;
      min-width: unset;
    }
  }

  > svg {
    flex-grow: 1;
    height: 100%;

    .sankey-link {
      fill: transparent;
      opacity: 0.15;
    }

    .sankey-node {
      rect {
        cursor: pointer;
        opacity: 0.75;
        &:hover {
          transition: opacity 0.15s ease;
          opacity: 1;
        }
      }
      text {
        fill: var(--c_chart-label);
        font-size: 12 * $ptToEm;
        font-weight: 700;
        transform: rotate(90deg);
      }
    }
  }

  .sankey-node-tooltip {
    .label {
      font-weight: bold;
      line-height: 16 * $ptToEm;
      font-size: 12 * $ptToEm;
    }

    .value {
      font-size: 16 * $ptToEm;
      line-height: 22 * $ptToEm;
      font-weight: 300;
    }

    .unit {
      display: inline;
      position: static;
      color: var(--c_chart-unit);
      font-weight: 700;
      font-size: 10 * $ptToEm;
      fill: var(--c_chart-unit);
    }
  }
}
