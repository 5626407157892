@import "../../styles/variables";

.xw-content {
  display: flex;
  flex-flow: row nowrap;
  &.title {
    color: var(--c_modal-highlight);
    font-size: 20 * $ptToEm;
    font-weight: 350;
    line-height: 30px;
  }
  &.sub-title {
    color: var(--c_card-subtitle-text);
    font-size: 14 * $ptToEm;
    font-weight: 350;
    line-height: 18px;
  }
  &.section-title {
    font-size: 18 * $ptToEm;
    color: var(--c_content-section-header);
    font-weight: 350;
    line-height: 18px;
  }
  &.error {
    color: var(--c_text-error);
    font-size: 14 * $ptToEm;
    font-weight: 350;
    line-height: 18px;
  }
  &.warning {
    color: var(--c_text-warning);
    font-size: 14 * $ptToEm;
    font-weight: 350;
    line-height: 18px;
  }
  &.caption {
    font-size: 24 * $ptToEm;
    font-weight: bold;
    align-items: center;
  }
}
