@import "../../styles/variables";

.xw-data-table {
  .table-actions {
    margin: 24px;

    .xw-icon {
      margin-left: 6px;
    }
  }
  .actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    .xw-button {
      white-space: nowrap;
      padding: 0 24px;
    }
    .xw-button.link {
      padding: 0;
      margin: 0 0 0 24px;
    }
    > .xw-button:first-child {
      min-width: 170px;
    }
  }

  .emphasize {
    color: var(--c_text-title);
  }
}
