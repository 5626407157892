@import "../../../styles/variables";

.xw-select {
  $inputClasses: ".xw-select-input, .filter-input, .xw-chip-list";

  display: inline-block;
  min-width: 180px;
  height: $input-height;

  .xw-input-field {
    width: 100%;
    cursor: pointer;
    &.disabled {
      cursor: default;
    }
    margin-bottom: 0;

    &.dropdown-open {
      #{$inputClasses} {
        // border-bottom: 1px solid var(--c_select-dropdown-divider) !important;
        border-bottom-color: var(--c_select-dropdown-divider) !important;
      }
    }
    &:focus:not(.dropdown-open) {
      #{$inputClasses} {
        border-bottom-color: var(--c_textInput-line-active);
      }
    }

    .filter-input,
    .filter-input-multi {
      transition: none;
      color: var(--c_select-match);
    }

    .filter-input-multi {
      margin-left: calc($unit / 2);
    }

    .xw-icon {
      width: $unit;
      height: $unit;
      right: $unit * 1.5;
      top: calc(50% - calc($unit / 2));
      fill: var(--c_icon-input-action);
    }

    &.multi-select {
      .filter-input {
        display: inline-block;
        border: none;
      }
    }

    .xw-processing-loader {
      position: absolute;
      right: calc($unit / 2);
      height: $unit;
      width: $unit * 3;
      top: calc(50% - calc($unit / 2));
    }
  }

  &:hover .xw-icon,
  .dropdown-open .xw-icon {
    fill: var(--c_icon-hover);
  }

  .select-value.dropdown-open {
    display: none;
  }

  #{$inputClasses} {
    line-height: 28px;
    width: 100%;
    padding-right: $unit * 2.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .xw-dropdown-menu {
    padding: 0;
    padding-bottom: 2px;
    background-color: var(--c_textInput-bg);
    max-height: $unit * 25 + 2;
    border: none;

    button,
    .empty {
      display: block;
      width: 100%;
      margin: 0;
      color: var(--c_text);
      padding: calc($unit / 2) $unit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    button {
      position: relative;
      height: $unit * 2.5;
      color: var(--c_select-option);
      font-size: 15 * $ptToEm;

      &.focus {
        background-color: var(--c_select-option-bg-focus);
      }

      &.selected {
        color: var(--c_select-selected);
      }

      .secondary-label {
        position: absolute;
        right: $unit * 1.5;
        top: calc($unit / 2);
        font-size: 12 * $ptToEm;
        line-height: $unit * 1.5;
        color: var(--c_select-match);
        text-align: right;
        display: inline-block;
      }
    }

    nav {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .match {
    color: var(--c_select-match);
  }

  .footer-line {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    border: none;
    background-color: var(--c_textInput-line-active);
    margin: 0;
  }

  .xw-chip-list {
    padding: ($unit * 1.75) ($unit * 2.5) 3px calc($unit / 2);
    .xw-chip {
      margin-top: 0;
    }

    &.disabled .xw-chip {
      opacity: 0.5;
    }
  }

  input.xw-auto-size-input,
  .xw-auto-sizer {
    font-size: 12 * $ptToEm;
    line-height: 18 * $ptToEm;
    height: 18 * $ptToEm;
  }

  &.auto-height {
    height: auto;
    margin-bottom: $unit;
    .xw-chip-list {
      height: auto;
      margin-bottom: 0;
    }
    .error,
    .hint {
      top: unset;
      bottom: calc(-#{$unit} * (4 / 3));
    }
  }
}

body.theme-high-contrast .xw-select {
  .footer-line {
    background-color: var(--c_textInput-line);
  }
  .xw-input-field.dropdown-open .filter-input {
    border-color: var(--c_textInput-line);
  }
  .xw-dropdown-menu {
    border: var(--s_textInput-border) solid var(--c_textInput-line);
    border-top: none;
    button.focus {
      color: var(--c_select-option-focus);
      .match {
        color: var(--c_select-match-focus);
      }
    }
  }
}
