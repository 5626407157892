@import "../../styles/variables";

$height: 14px;

.xw-path-list {
  display: flex;
  > div {
    display: flex;
    align-items: center;
    // margin-right: 6px;
    // padding: 3px 6px;
  }
  .xw-tooltip.open {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
  }
  .tooltip-content {
    display: flex;
    align-items: center;
  }
  .path-value {
    color: var(--c_path-value);
    font-size: 12 * $ptToEm;
    line-height: 16 * $ptToEm;
    margin-top: 2px;
    .nested {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  .path-value.highlight {
    color: var(--c_path-hightlight-value);
  }
  .xw-icon {
    fill: var(--c_path-separator);
    width: 16px;
    height: 10px;
    transform: rotate(90deg);
  }
  .single-path {
    background: transparent;
    border: none;
  }
  .multi-path {
    display: inline;
    align-items: center;
  }
  .circle-container {
    display: inline-flex;
    align-items: baseline;
    padding: 0 3px;
    font-size: 9 * $ptToEm;
    line-height: 12 * $ptToEm;
    .content {
      position: relative;
      top: -1px;
      border-radius: 50%;
      width: $height;
      height: $height;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 1px 0 0;
      background: var(--c_circle-aspath-background);
      color: var(--c_hop-count-text);
      border: 1px solid var(--c_circle-aspath-background);
      justify-content: center;
    }
    &:before,
    &:after {
      display: block;
      content: "";
      width: 0;
      height: 0;
    }
  }
  .diamond-container {
    display: flex;
    align-items: center;
    padding: 0 3px;
    font-size: 9 * $ptToEm;
    line-height: 12 * $ptToEm;

    .content {
      background-color: var(--c_hop-count-diamond);
      height: $height;
      color: var(--c_hop-count-text);
      display: flex;
      align-items: center;
      padding: 0 2px;
    }
    &:before {
      display: block;
      content: "";
      width: 0;
      height: 0;
      border-top: calc($height / 2) solid transparent;
      border-right: calc($height / 2) solid var(--c_hop-count-diamond);
      border-bottom: calc($height / 2) solid transparent;
    }
    &:after {
      display: block;
      content: "";
      width: 0;
      height: 0;
      border-top: calc($height / 2) solid transparent;
      border-left: calc($height / 2) solid var(--c_hop-count-diamond);
      border-bottom: calc($height / 2) solid transparent;
    }
  }
}
