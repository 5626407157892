@import "../../styles/variables";

.xw-icon {
  fill: var(--c_icon);
  transition: fill 0.15s ease;

  width: 16px;
  height: 16px;

  &.small {
    width: $unit;
    height: $unit;
  }
  &.medium-large {
    width: 18px;
    height: 18px;
  }

  &.large {
    width: 20px;
    height: 20px;
  }

  &:hover {
    fill: var(--c_icon-hover);
  }
  &.timeline-ico-bar {
    fill: var(--c_tlbucketchart-tlpoint-dotline-error);
  }
  
  &.arrow-line {
    fill: var(--c_tlbucketchart-tlpoint-arrowline);
  }

  &.rectangular-dot-line {
    fill: var(--c_tlbucketchart-tlpoint-rectangularDotLine);
  }

  &.software-changes-indicator {
      fill: var(--c_tlbucketchart-data-top);
    }
  &.hardware-changes-indicator {
      fill: var(--c_tlbucketchart-data-bottom);
    }
}

.xw-icon-prefix {
  margin-right: calc($unit/2);
}
