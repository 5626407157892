@import "../../styles/variables";

.xw-trigger-rule-container {
  &.viewMode {
    .disabled-tag {
      display: flex;
      align-items: center;
      vertical-align: middle;
      height: 18px;
      background-color: var(--c_notification-orange);
      color: var(--c_chip-text);
      border-radius: 100px;
      padding: $unit;
      margin-left: 12px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
    }
    .alarm-tag {
      display: flex;
      align-items: center;
      vertical-align: middle;
      height: 18px;
      background-color: var(--c_policy-data-excluded);
      color: var(--c_chip-text);
      border-radius: 100px;
      padding: $unit;
      margin-left: 12px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
    }
    .rule-container {
      div.disabled {
        display: flex;
        opacity: 0.25;
        .xw-label-value {
          margin-right: $unit * 2;
        }
      }
    }
  }
  .title-container {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: $unit * 2;
    .title-status {
      display: flex;
      padding-bottom: calc($unit / 2);
      position: relative;
      align-items: center;
    }
    .sub-title {
      color: var(--c_modal_policy-rule-sub-title);
    }
  }
  .xw-policy-rule-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    padding-right: calc($unit / 2);
    .xw-icon {
      fill: var(--c_icon-close);
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    padding: $unit $unit $unit 0;
    .xw-input-field {
      width: 300px;
    }
  }
  .rule-container {
    display: flex;
    flex-flow: row wrap;
    align-self: center;
    > div {
      margin-right: $unit * 2;
    }
  }
}
