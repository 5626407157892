@import "../styles/variables";

.xw-list-data {
  display: flex;
  flex-wrap: wrap;
}

// more specific
.xw-label-value .value .xw-list-data a span {
  color: var(--c_card-link);
}
