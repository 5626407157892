@import "../../../styles/variables";

.xw-api-param {
  background-color: var(--c_api-param-bg);
  border-radius: calc($unit / 2);
  margin-top: calc($unit / 2);
  border: var(--c_api-param-border);
  padding: $unit;

  .tags {
    margin-top: 8px;
  }

  .title {
    position: relative;
    top: -2px;
    font-size: $ptToEm * 18;
    margin: 0 calc($unit / 2) 0 0;
    color: var(--c_api-param-code);
  }

  .type {
    font-size: $ptToEm * 14;
    color: var(--c_api-param-sub);

    &.linked {
      color: var(--c_text-link);
      outline: none;

      &:active,
      &:hover,
      &:focus {
        color: var(--c_text-hover);
        text-decoration: underline;
      }
    }
  }

  .description {
    line-height: 18px;
    color: var(--c_api-param-description);
  }

  .repeat {
    color: var(--c_api-param-sub);
  }

  .xw-tooltip {
    max-width: 500px;
    .values {
      max-height: 250px;
      overflow-y: auto;
      padding: calc($unit / 2);

      .value-item {
        display: flex;
        padding: calc($unit / 6);
        align-items: center;
      }

      .value {
        display: block;
        margin: 2px 0;
        color: var(--c_api-param-code);
      }
    }
  }
}
