@import "../../styles/variables";

.xw-page-section {
  > header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: ($unit * 1.5) 0;
    .title {
      flex: 0 0 auto;
      font-size: 24 * $ptToEm;
      color: var(--c_section-header-text);
      font-weight: 300;
      margin-right: $unit;
      .count {
        font-weight: bold;
        color: var(--c_section-header-emphasis);
      }
    }
    .spacer {
      flex: 1 1 100%;
      height: 1px;
      border-bottom: 1px dashed var(--c_section-header-separator);
    }
    .actions {
      margin-left: $unit;
    }
  }

  &.table-section {
    > .content {
      padding-right: $unit * 2;
    }
  }
}
