@import "../../styles/variables";

.xw-bgpUpdates {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  // copied from old bgpUpdates.scss
  > h2 {
    display: block;
    flex: 0 0 auto;
    margin: ($unit * 2) ($unit * 2) 0 ($unit * 2);
    font-weight: 300;
  }
  // end

  .xw-page-tabs > div {
    display: flex;
  }
  .xw-filter {
    background-color: var(--c_bgp-updates-filter-bg);
    padding: $unit;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 0 auto;
    gap: 10px;

    .filter-boxes {
      padding-bottom: 0;
    }

    .add-button {
      border: 1px solid var(--c_bgp-updates-filter-button-border);
      min-width: 0;
      background: var(--c_bgp-updates-filter-button-bg);
      align-items: center;
      color: var(--c_bgp-updates-filter-button-text);
      height: 34px;
      min-height: 0;
      padding: 0 $unit * 2;
      .theme-high-contrast & {
        text-decoration: none;
      }

      &:hover {
        background: var(--c_bgp-updates-filter-button-bg-hover);
      }
    }

    .asPath-filter-warning {
      border: 1px solid var(--c_bgp-updates-filter-warning-border);
      .bar {
        width: 24px;
        background-color: var(--c_bgp-updates-filter-warning-icon-bg);
        border-right: 1px solid var(--c_bgp-updates-filter-warning-border);
      }
      .bar::before {
          content: " ! ";
          color: var(--c_bgp-updates-filter-warning-icon);
          font-weight: 700;
          font-size: 24px;
          line-height: 2.2;
          padding-left: 7px;
        }
      .content {
        padding-left: 2.125rem;
        .label .operator .include, .label .operator .exclude {
          color: var(--c_bgp-updates-filter-warning-text);
        }
      }
    }
  }

  .xw-data-table {
    flex: 1 1 100%;
  }
}
