@import "../../styles/variables";

.xw-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 392px;
  background-color: var(--c_card-bg);
  border-radius: 3px;
  margin: $unit;
  &.connector {
    &::before {
      content: "";
      position: absolute;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      border-left: 15px solid transparent;
      top: 40%;
      left: -30px;
      height: 0;
      width: 0;
    }
    &.active::before {
      border-right: 15px solid var(--c_card-arrow-active);
    }
    &.inactive::before {
      border-right: 15px solid var(--c_card-arrow-inactive);
    }
    &.inprogress::before {
      border-right: 15px solid var(--c_card-arrow-inprogress);
    }
    &::after {
      content: "";
      position: absolute;
      right: -30;
      top: 40%;
      height: 0;
      width: 0;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      border-right: 15px solid transparent;
    }
    &.active::after {
      border-left: 15px solid var(--c_card-arrow-active);
    }
    &.inactive::after {
      border-left: 15px solid var(--c_card-arrow-inactive);
    }
    &.inprogress::after {
      border-left: 15px solid var(--c_card-arrow-inprogress);
    }
  }
  .xw-card {
    background-color: var(--c_card-bg-nested);
  }
  .ribbon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
    width: 125px;
    height: 125px;
    text-align: right;
    span {
      color: var(--c_page-footer-bg);
      text-align: center;
      line-height: 20px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      width: 160px;
      display: block;
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      position: absolute;
      top: 40px;
      right: -30px;
      &.red {
        background-color: var(--c_text-error);
      }
    }
  }

  > .title {
    flex: 0 0 auto;
    background-color: var(--c_card-title-bg);
    color: var(--c_card-title-text);
    font-size: 20 * $ptToEm;
    font-weight: 300;
    padding: $unit ($unit * 2);
    height: 66px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    white-space: nowrap;

    .xw-button.close {
      margin: 0;
      padding-right: 0;
      .xw-icon {
        fill: var(--c_card-btn-close);
      }

      &:hover .xw-icon {
        fill: var(--c_card-btn-close-hover);
        transition: fill 0.15s ease;
      }
    }

    .subtitle {
      color: var(--c_card-subtitle-text);
      font-size: 14 * $ptToEm;
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    position: relative;
    padding: $unit * 2;
    box-sizing: border-box;

    .xw-label-value {
      margin-bottom: $unit;
      min-height: 33px;
    }
  }

  ul.extension {
    display: block;
    $itemHeight: 24px;
    margin: 0 $unit * 2 $unit * 2;

    > li {
      display: flex;
      align-items: baseline;
      flex-flow: row nowrap;
      padding: 2px 0;
      a {
        font-size: 15 * $ptToEm;
        color: var(--c_button-link-label);
        padding-right: 6px;

        &:hover {
          transition: color 0.15s ease;
          color: var(--c_button-link-label-hover);
        }
      }
    }
  }
  .field-label {
    margin-left: $unit * 2;
    margin-bottom: calc($unit / 4);
    font-size: 12 * $ptToEm;
  }

  &.dashboard {
    > .title {
      height: $unit * 4;
      background-color: var(--c_card-title-bg2);
    }
    > .content {
      background-color: var(--c_card-bg2);
    }
  }
}

.xw-card-list {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: stretch;
  position: relative;
  left: -$unit;
  width: 100%;
}

.theme-high-contrast .xw-card,
.theme-light .xw-card {
  border: 1px solid var(--c_card-border);
  > .title {
    border-bottom: 1px solid var(--c_card-border);
  }
}

.theme-high-contrast .xw-card {
  .xw-switch-input {
    label {
      color: var(--c_card-subtitle-text);
    }
  }
  &.xw-rule-card > .title {
    .active-alarms {
      text-decoration: underline;
      color: var(--c_card-link);
      &:hover {
        color: var(--c_card-subtitle-text);
      }
    }
  }
}
