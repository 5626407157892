@import "../../styles/variables";

.xw-cdg-summary-counter-section {
  .xw-summary-counter-value {
    color: var(--c_text-title);
  }
}

.xw-cdg-delete-summary-container {
  padding: 4px;

  .xw-cdg-devices {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 6px 0;
  
  
    .xw-application-type {
      display: flex;
      gap: 6px;
      
      .xw-icon {
        fill: var(--c_text-title);
        width: 18px;
      }
  
      .xw-application-type-label {
        font-size: 18px;
        color: var(--c_text-title);
      }
    }
  
    .xw-cdg-device {
      display: flex;
      flex-direction: column;
      gap: 2px;
  
      .xw-label {
        font-weight: 400;
        font-size: 12px;
        margin-right: 2px;
      }
  
      .xw-device-value {
        font-weight: 350;
        font-size: 15px;
      }
  
      .xw-policy-value {
        font-weight: 350;
        font-size: 15px;
        color: var(--c_text-title);
      }
    }
  }
}

.xw-cdgs-delete-message {
  margin-bottom: 24px;

  .xw-cdgs-delete-message-highlight {
    color: var(--c_text-title);
  }
}
