@import "../../styles/variables";

.xw-device-bulk-edit {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 0;
  .xw-page-header {
    border-bottom: 1px solid var(--c_section-header-separator);
    .title-section .sub-title-section .sub-title {
      font-size: 14 * $ptToEm;
      line-height: 33 * $ptToEm;
      font-weight: 700;
    }
    .separator {
      border: none;
      margin: 0;
      padding: 0;
    }
  }
  .device-bulk-edit-fields {
    margin-bottom: $unit * 10;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .xw-page-caption {
      margin-bottom: $unit;
    }
    .fields {
      width: 50%;
      .xw-check-input {
        margin-bottom: $unit * 2;
        .xw-select {
          width: 100%;
        }
        .xw-input-field {
          margin: 0;
        }
      }
    }
  }

  .device-bulk-edit-review-table {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    .xw-page-caption {
      margin-bottom: $unit;
    }
    .xw-data-table {
      top: 0;
      right: 0;
      bottom: 0;
      left: -24px;
      width: calc(100% + 48px);
      display: flex;
      flex: 1 1 auto;
      flex-flow: column nowrap;
      margin-bottom: 100px;
    }
  }
}
