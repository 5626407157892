@import "../../styles/variables";

.xw-peer-prospecting-page {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  .xw-page-tabs {
    height: 100%;

    .xw-tabs {
      margin-top: calc($unit / 2);

      > li {
        padding: calc($unit / 2) $unit * 2;
      }
    }
  }

  .prospecting-filters {
    flex-grow: 0;
    display: flex;
    flex-flow: row nowrap;
    > .group {
      flex: 0 0 25%;
      // min-width: 256px;
      height: 138px;
      margin: $unit * 2 0 0 $unit * 2;
      display: flex;
      flex-flow: column nowrap;

      &:last-child {
        margin-right: $unit * 2;
      }
      > label {
        margin-bottom: $unit;
        font-size: 12 * $ptToEm;
        color: var(--c_text);
      }

      .xw-toggle {
        margin-bottom: calc($unit / 2);
      }
    }
    .spacer {
      flex: 1 1 100%;
    }
  }
  .xw-data-table {
    flex-grow: 1;
  }

  .xw-page-tabs > .xw-overlay {
    position: relative;
    flex-grow: 1;
  }
}
