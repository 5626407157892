@import "../../../styles/variables";

.subscriptionModal {
  textarea,
  .xw-textarea {
    width: 100%;
    &.text-error {
      textarea {
        color: var(--c_text-error);
      }
    }
  }
  .error {
    padding: $unit * 2 $unit * 2 0 $unit * 2;
    line-height: 20 * $ptToEm;
    .email {
      color: var(--c_text-link);
      line-height: 22 * $ptToEm;
      padding-right: calc($unit / 2);
    }
  }
  .message {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    line-height: 20 * $ptToEm;
    .email {
      color: var(--c_text-link);
      line-height: 22 * $ptToEm;
      padding-right: calc($unit / 2);
    }
  }
}
