@import "../../styles/variables";

.xw-prefixes-page-traffic {
  height: 100%;
  display: flex;
  flex-direction: column;

  .xw-dual-axis-chart {
    flex: 0 0 100%;
  }
  .xw-prefix-traffic,
  .xw-prefix-tag-traffic {
    margin: $unit 0 0 0;
    flex: 1 1 100%;
    height: 100%;
  }

  .xw-page-tabs .xw-tabs {
    margin-top: calc($unit / 2);
    li {
      padding: calc($unit / 2) $unit * 2;
    }
  }
}
