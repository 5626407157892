@import "../../../styles/variables";
.xw-device-details {
  padding-top: $unit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  .xw-page-tabs .xw-tabs li {
    padding: calc($unit/2) $unit * 2;
  }
}
