@import "../../../xw/styles/variables.scss";

.xw-device-csv-import {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 32px;
  left: -$unit * 2;
  right: -$unit * 2;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 0;

  .page-content-right {
    width: 250px !important;
  }
  
  .device-csv-file-select {
    .attribute-list {
      margin-bottom: $unit * 2;
      list-style-type: disc; /* Bullet style */
      margin-left: $unit * 2;
      li {
        font-size: 14 * $ptToEm;
        line-height: 18 * $ptToEm;
        color: var(--c_caption_text);
        padding: calc($unit/6) 0;
      }
      .attribute-sub-list {
        margin-left: $unit * 2;
      }
    }
    .file-sample {
      padding: 0;
      margin: 0;
    }
  }
  .device-csv-import-review {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;

    .caption-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .xw-toggle {
        width: 300px;
      }
    }
  }
}
  

.xw-device-import-table-container {
  height: 100%;
  margin: calc($unit / 2) 0;

  .xw-data-table {
    height: 100%;
  }
}