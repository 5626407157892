@import "../../../styles/variables";

.xw-manage-credential {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .xw-credential-form {
    height: 100%;
    min-height: 0;
    flex: 1 1 auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .field-set-children {
      height: 100%;
      min-height: 0;
      flex: 1 1 auto;
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .field-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 $unit * 2;
      min-height: 0;
      flex: 1 1 auto;
      overflow-y: hidden;
    }

    .standard-fields {
      flex: 0 0 auto;
      .xw-input-field {
        width: 50%;
      }
      .xw-textarea,
      textarea {
        width: 100%;
      }
    }

    .xw-page-footer {
      position: relative;
      flex: 0 0 auto;
    }

    .xw-tabs {
      flex: 0 0 auto;
    }

    .ssh-fields .xw-button {
      padding: 0;
    }

    .ssh-fields,
    .bgp-fields {
      padding: $unit * 2 0;
      .xw-input-field {
        width: 50%;
      }
    }

    .snmpV3-fields {
      overflow-y: auto;
      padding: $unit * 2 0;
      .xw-toggle {
        width: 400px;
      }
      .xw-input-field {
        width: 50%;
        margin: $unit * 2 0;
      }
      .xw-button {
        padding: 0;
      }
      .xw-select {
        .xw-input-field {
          margin: 0;
          width: 100%;
        }
      }
    }

    .separator {
      height: 1px;
      border-bottom: 1px dashed var(--c_section-header-separator);
    }

    .xw-reorder-list {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: hidden;
      min-height: 0;
      > header {
        flex: 0 0 auto;
      }
      > .content {
        position: relative;
        flex: 1 1 auto;
        overflow-y: auto;
        padding-right: 1px;
        .community-input {
          flex-grow: 1;
        }
      }
    }
  }
}

.addCredentialInline-modal-wrapper {
  .modal-dialog {
    min-height: 653px;
  }
  .xw-credential-form {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .field-wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: hidden;
      padding: $unit * 2 0;
      max-height: 500px;
      .xw-tabs {
        padding: $unit $unit * 2;
      }
    }

    .standard-fields {
      padding: 0 $unit * 2;
      .xw-input-field {
        width: 50%;
      }
      .xw-textarea,
      textarea {
        width: 100%;
      }
    }
    .ssh-fields,
    .bgp-fields {
      padding: 0 $unit * 2;
      .xw-input-field {
        width: 50%;
      }
      .xw-button {
        padding: 0;
      }
    }

    .snmpV3-fields {
      overflow-y: auto;
      padding: $unit * 2;
      background-color: var(--c_content-bg);
      .xw-toggle {
        width: 380px;
      }
      .xw-input-field {
        width: 50%;
        margin: $unit * 2 0;
      }
      .xw-select {
        .xw-input-field {
          margin: 0;
          width: 100%;
        }
      }
    }

    .xw-reorder-list {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: hidden;
      min-height: 0;
      background-color: var(--c_content-bg);
      padding: 0 $unit * 2;
      > header {
        flex: 0 0 auto;
      }
      > .content {
        position: relative;
        flex: 1 1 auto;
        overflow-y: auto;
        padding-right: 1px;
        .community-input {
          flex-grow: 1;
        }
      }
    }
  }
}

.sshPublicKeyCopy-modal-wrapper {
  .sshPublicKeyCopy-modal-content {
    font-weight: normal;
    font-size: 15 * $ptToEm;
    line-height: 20 * $ptToEm;
    color: var(--c_value-text);
    .info-text {
      margin-bottom: $unit;
    }
    .public-key-copy-instructions {
      margin-bottom: $unit;
      color: var(--c_title-special-text);
    }
    .whereToCopy {
      margin-bottom: $unit;
    }
    .sshKeyCopyRunInstruction {
      & span {
        color: var(--c_title-special-text);
      }
      margin-bottom: $unit * 2;
    }
    .public-key-title {
      font-weight: bold;
      font-size: 16 * $ptToEm;
      margin-bottom: calc($unit / 2);
    }
    .public-key-value {
      color: var(--c_title-special-text);
      overflow-wrap: break-word;
      font-size: 16 * $ptToEm;
    }
  }
}

.xw-credential-details {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .content {
    flex: 0 0 auto;
    .ssh-public-key-title {
      display: flex;
      font-size: 12 * $ptToEm;
      line-height: 15 * $ptToEm;
      label {
        color: var(--c_textInput-label);
      }
      button {
        margin: 0 0 0 6px;
        padding: 0;
        font-weight: normal;
        height: auto;
        font-size: 12 * $ptToEm;
        line-height: 15 * $ptToEm;
      }
    }
    .ssh-public-key-value {
      overflow-wrap: break-word;
      font-size: 15 * $ptToEm;
      font-weight: 300;
      line-height: 18 * $ptToEm;
      color: var(--c_textInput-value);
    }
  }
  .xw-data-table {
    flex: 1;
  }
}

.linkDevicesModal-modal-wrapper {
  .modal-dialog {
    height: 86%;
  }
  .xw-data-table {
    flex: 1;
  }
}

.xw-credentials {
  height: 100%;
  .table-actions {
    padding-top: 12px;
  }
  .actions {
    display: flex;
  }
}
