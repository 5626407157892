@import "../../styles/variables";

.xw-sparkline {
  position: relative;
  width: 100%;
  height: 150px;
  svg {
    width: 100%;
    height: 100%;
    .line {
      fill: none;
    }

    .box line {
      stroke: var(--c_sidebar-hr);
      stroke-width: 2;
    }
  }
}
