@import "../../styles/variables";
.xw-bytes-text {
  .value {
    font-size: 14 * $ptToEm;
    color: var(--c_text-title);
  }
  .unit {
    color: var(--c-text);
    font-size: 10 * $ptToEm;
    font-weight: 700;
  }
}
