@import "../../styles/variables";

.xw-donut-chart {
  display: inline-flex;
  flex-flow: row nowrap;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;

  &.no-data > svg path {
    fill: var(--c_chart-data-placeholder);
  }

  > svg {
    flex: 0 0 auto;
  }

  .legend {
    flex: 1 1 auto;
    margin-left: $unit * 2;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    min-width: 0;
    min-height: 0;
    overflow: hidden;

    > .title {
      color: var(--c_chart-title);
      font-size: 18 * $ptToEm;
      font-weight: 300;
      line-height: 25 * $ptToEm;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > .message {
      color: var(--c_chart-legend);
    }

    ul li {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow: hidden;

      span {
        display: block;
        font-size: 12 * $ptToEm;
        line-height: 16 * $ptToEm;
      }

      .value {
        flex: 0 0 auto;
        color: var(--c_chart-data);
        font-weight: 700;
      }

      .label {
        flex: 1 1 auto;
        color: var(--c_chart-label);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .value,
      .label {
        margin-left: calc($unit / 2);
      }
      .color {
        flex: 0 0 auto;
        width: $unit * 0.75;
        height: $unit * 0.75;
        border-radius: 1px;
      }

      &.max-legend-item {
        .color {
          background-color: var(--c_chart-indicator);
          border-radius: 100px;
        }
      }
    }
  }

  .filter {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
  }

  &.legend-left {
    flex-flow: row-reverse nowrap;

    .legend {
      margin-left: 0;
      margin-right: $unit * 2;
    }
  }

  .graph {
    transform-origin: center;
  }

  .total {
    fill: var(--c_chart-data);
    text-anchor: middle;
    dominant-baseline: middle;
  }

  .sub-total {
    fill: var(--c_chart-data);
    text-anchor: middle;
  }

  .max-group {
    transform-origin: center;
    .fill {
      fill: transparent;
    }
    .indicator {
      fill: var(--c_chart-indicator);
    }
  }

  &.percentage {
    .graph {
      path:last-child {
        fill: var(--c_chart-data-placeholder);
      }
    }
  }
}
