@import "../../styles/variables";

.login-screen {
  .buttons {
    display: flex;
    max-width: 270px;
    justify-content: space-between;
    a {
      color: white;
    }
  }

  &.full-page {
    .content {
      .xw-button {
        margin: 0;
      }
    }
  }

  .forgot,
  .create-account {
    margin: $unit 0 0 0;
    > a {
      color: #ddfa4c;
      font-weight: 700;
    }
  }
  .ask-assistance {
    margin: $unit 0 0 0;
    > a {
      color: #ddfa4c;
      font-weight: 700;
    }
  }

  .browser-message {
    margin-top: 20px;
    .message {
      display: flex;
      align-items: center;
      .rn-link {
        padding-left: calc($unit/2);
        padding-right: calc($unit/2);
      }
    }
    a {
      display: block;
      color: #ddfa4c;
      font-weight: 700;
      line-height: 18px;
      margin: 3px 0;
    }
  }
}
