@import "../../styles/variables";

.xw-page-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--c_content-bg);
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .loading-text {
      margin: $unit 0 0 0;
      text-align: center;
      color: var(--c_text);
      animation: 1.1s ease-in-out 0s infinite glow;
    }
  }
}

@keyframes glow {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
