@import "../../../styles/variables";

.xw-insights-compare-punchlist {
  overflow-y: auto;

  .xw-insights-special-number {
    color: var(--c_section-header-emphasis);
    font-weight: 600;
  }

  .xw-button {
    padding: 0;
  }
}
