@import "../../styles/variables";
.xw-drilldown-example {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  > .filters {
    display: flex;
    flex-flow: row nowrap;
    height: $unit * 4;
    margin: calc($unit / 2);
    > .spacer {
      flex-grow: 1;
    }
    .xw-trafficDatePicker {
      width: 256px;
    }
  }
  > .xw-drilldown-table {
    flex-grow: 1;
  }
}
