@import "../../../styles/variables";

.xw-insights-select-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  .xw-device-comparison-select-table {
    height: 100%;
    .xw-data-table {
      height: 100%;
    }
  }

  .xw-data-table {
    height: 100%;
  }
}
