@import "../../../styles/variables";

.xw-find-files-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  .xw-page-header {
    .header {
      padding: 12px 0;
    }
    .separator {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
  .toggle {
    display: flex;
    flex: 0 0 auto;
    margin: $unit * 2 $unit;
    .xw-toggle {
      flex: 0 0 300px;
    }
  }
  .find-device {
    display: flex;
    flex-flow: row wrap;
    padding-left: $unit;
    .xw-select {
      padding-right: calc($unit / 2);
    }
    .xw-filter {
      display: contents;
    }
  }

  .find-hash {
    display: flex;
    flex-flow: row nowrap;
    padding-left: $unit;
    width: 60%;
    .field {
      padding-right: $unit;
      margin-bottom: 0;
      .xw-select {
        width: 100%;
      }
      &.filter-value {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        .xw-input-field {
          width: 100%;
        }
      }
    }
  }
  .xw-find-files-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    margin-top: $unit * 2;
  }
}
