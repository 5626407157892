@import "../../../styles/variables";

.xw-file-input-field {
  > label {
    display: block;
    padding: 0 calc($unit * 2);
    font-size: 15px;
    line-height: $unit * 3;
    text-align: center;
    border-radius: 2px;
    color: var(--c_fileInput-text);
    background: var(--c_fileInput-bg);
    border: 1px solid var(--c_fileInput-border);
    z-index: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
    cursor: pointer;

    .xw-icon {
      position: relative;
      top: 2px;
      fill: var(--c_fileInput-text);
      margin: 0 6px 0 0;
    }

    &:hover {
      background-color: var(--c_fileInput-bg-hover) !important;
      border: 1px solid var(--c_fileInput-border) !important;
    }
  }

  // &.value {
  //   > label {
  //     border-color: var(--c_fileInput-border-success);
  //     background-color: var(--c_fileInput-bg-success);
  //   }
  // }

  &.error {
    > label {
      border-color: var(--c_fileInput-border-error);
      background-color: var(--c_fileInput-bg-error);
    }
  }
  &.disabled {
    opacity: 0.5;
  }

  .error {
    display: block;
    color: var(--c_textInput-label-error);
    margin-top: calc($unit / 2);
  }

  > input {
    position: absolute;
    opacity: 0;
  }

  > .hint {
    margin-top: calc($unit / 2);
    font-size: var(--s_font-small);
    color: var(--c_textInput-label);
  }
}
