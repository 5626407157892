@import "../../styles/variables";

.xw-percentage-text {
  display: inline-flex;
  align-items: center;

  .donut {
    width: 18px;
    height: 18px;
    display: block;

    circle {
      fill: transparent;
    }
  }

  .donut-ring {
    stroke: var(--c_column-donut-bg);
    stroke-width: 8px;
  }

  .donut-segment {
    stroke: var(--c_column-donut-bg-active);
    stroke-width: 8px;
  }

  .value {
    margin-left: calc($unit / 2);
    font-size: 16 * $ptToEm;
    color: var(--c_column-donut-value);
    font-weight: 500;

    .percent {
      font-weight: 700;
      font-size: 12 * $ptToEm;
      color: var(--c_column-donut-secondary);
    }
  }
}
