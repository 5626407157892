@import "../../styles/variables";

.xw-notification-view-container {
  .xw-reorder-list {
    .content > ul > li {
      min-height: 60px;
      height: unset;
      border: 1px var(--c_policy-notification-border) solid;
    }
  }

  .xw-notification-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 100%;
    .xw-label-value {
      padding: $unit $unit * 2 $unit $unit;
      .value {
        overflow-wrap: anywhere;
      }
      &:first-child {
        width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
