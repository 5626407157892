@import "../../styles/variables";

.xw-page-manage-reports {
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  form {
    overflow-x: auto;
  }
  .row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .fields,
    .xw-label-value {
      padding-right: $unit * 2;
      width: 100%;
      .xw-select {
        width: 100%;
      }
    }
  }
  .name-field {
    display: flex;
    margin-top: $unit;
    .report {
      padding-right: $unit;
    }
  }
  .xw-endpoint-summary {
    padding-bottom: $unit * 2;
  }
  .xw-textarea,
  textarea {
    width: 100%;
    padding-bottom: $unit * 2;
  }

  .xw-page-section header {
    .actions {
      display: flex;
      flex-flow: row nowrap;
      > .xw-button {
        min-width: 146px;
        &.addEndpointToAllButton {
          min-width: 228px;
        }
      }
    }
  }

  > .scrollbar-container {
    overflow-y: auto;
    flex-grow: 1;
    padding: $page-padding;
    margin-bottom: $footerHeight;
  }
}
