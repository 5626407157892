@import "../../styles/variables";

#timelineBucketChart {
  .xw-bucket-chart.simple-bucket-chart {
    height: 146px;
    margin-bottom: $unit * 4;
  }

  .toggles {
    display: flex;
    margin-bottom: 24px;

    .xw-toggle {
      margin-right: 12px;
    }
  }
  .xw-toggle {
    width: 300px;
  }

  .xw-bucket-chart {
    position: relative;
    margin: 0 -23px 48px -23px;
    width: calc(100% + 48px);
  }
}
