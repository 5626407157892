@import "../../styles/variables";

.xw-bulk-edit-peers {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;

  > .peer-bulkEdit-review {
    position: relative;
    flex: 1 1 100%;
    min-height: 0;
    margin-bottom: $unit * 4;
    .xw-data-table {
      height: 100%;
    }
  }
}
