@import "../../../../styles/variables";

.xw-subscription-tier {
  .xw-icon {
    &.available,
    &.additional {
      fill: var(--c_tier-available);
    }
    &.na {
      fill: var(--c_tier-na);
    }
    &.partial {
      fill: var(--c_tier-partial);
    }
  }
  .current-plan-chart {
    flex-grow: 1;
    position: relative;

    > svg {
      position: absolute;
      display: inline-block;
      rect {
        opacity: 0.5;
        fill: var(--c_tier-current-selection);
        stroke-width: 1px;
        stroke: var(--c_tier-current-selection-border);
      }
    }
  }
  .current-tier {
    align-items: center;
    display: grid;
    grid-template-columns: 51.5% 48.5%;
    padding-bottom: $unit;
    header {
      margin-bottom: calc($unit / 2);
      .title {
        font-size: 20 * $ptToEm;
      }
    }
    .plan-value {
      display: flex;
      flex-direction: column;
      align-items: center;
      img,
      .xw-icon {
        background: var(--c_tier_plan-icon-bg);
        border: 2px solid var(--c_tier_plan-border);
        box-sizing: border-box;
        border-radius: 6px;
        padding: calc($unit / 3);
        width: 36px;
        height: 36px;
        &.selected {
          border: 2px solid var(--c_tier_plan-active);
        }
      }
      .tier-label {
        padding-top: $unit;
      }
    }
  }
  .tier-item {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    align-items: center;
    .plan-value {
      font-size: 18 * $ptToEm;
      color: var(--c_page-header-title);
      text-align: center;
      justify-content: center;
      &.current-plan {
        background-color: red;

        position: relative;
        display: block;
        height: 100%;
      }
      &.current-plan:before {
        content: "";
        opacity: 0.25;
        position: absolute;
        height: 100%;
      }
    }
    .xw-icon {
      justify-self: center;
    }
  }

  .xw-offerings-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: $unit * 2;
    .xw-product-offerings {
      flex: 0 1 50%;
    }
    .availability-legends {
      display: flex;
      padding-top: $unit * 2;
      margin-left: auto;
    }
    .checked-wrapper {
      padding: 0 $unit * 2;
      display: flex;
      align-items: center;
      margin-top: calc($unit / 2);
      .additional,
      .partial,
      .na,
      .available {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $unit;
      }
    }
  }
  .ni-plan {
    padding: 0;
  }
  .trust-plan {
    .current-tier {
      grid-template-columns: 51.5% 48.5%;
    }
    .tier-item {
      grid-template-columns: 1fr 1fr;
    }
    .plan-item {
      grid-template-columns: 1fr 1fr;
    }
    .xw-plan-sections {
      .xw-section-expander {
        .plan-item {
          grid-template-columns: 1fr 1fr;
        }
        .section-header {
          grid-template-columns: 40px 1fr 1fr;
        }
      }
    }
  }

  .traffic-plan {
    .current-tier {
      grid-template-columns: 51.5% 48.5%;
    }
    .tier-item {
      grid-template-columns: 1fr 1fr;
    }
    .plan-item {
      grid-template-columns: 1fr 1fr;
    }
    .xw-plan-sections {
      .xw-section-expander {
        .plan-item {
          grid-template-columns: 1fr 1fr;
        }
        .section-header {
          grid-template-columns: 40px 1fr 1fr;
        }
      }
    }
  }
  .xw-plan-sections {
    padding-bottom: $unit * 2;
    display: flex;
    .xw-section-expander {
      flex: 1;
      .header {
        font-size: 24 * $ptToEm;
      }
      .plan-item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: minmax(40px, auto);
        align-items: center;
        padding-bottom: calc($unit / 2);
        padding-left: 40px;
      }
    }
    .xw-page-section {
      > header {
        margin: $unit 0 calc($unit / 2) 0;
      }
      .title {
        color: var(--c_page-header-title);
        font-size: 14 * $ptToEm;
      }
      .content {
        font-size: 12 * $ptToEm;
        line-height: 18 * $ptToEm;
        padding-right: calc($unit / 2);
      }
    }
  }
}
