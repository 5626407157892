@import "../../../styles/variables";

.xw-dateTime {
  position: relative;

  &.has-hint {
    .xw-select {
      width: 100%;
      margin-bottom: $unit * 2;
    }
  }

  .field-wrapper {
    width: 400px;
    position: relative;

    .xw-select {
      width: 100%;

      .xw-select-input {
        padding-right: $unit * 4;
      }
    }

    .xw-input-field {
      width: 100%;

      input:disabled {
        color: var(--c_textInput-value);
        cursor: pointer;
      }

      .xw-icon {
        fill: var(--c_timeRange-cal-icon);
        transition: fill 0.2s ease;
      }
    }

    &:hover {
      .xw-input-field {
        input:disabled {
          border-color: var(--c_timeRange-cal-icon-hover);
        }

        .xw-icon {
          fill: var(--c_textInput-line-active);
        }
      }
    }
  }

  .hint,
  .error {
    position: absolute;
    top: $input-height + calc($unit / 2);
    left: 0;
    width: 100%;
    font-size: var(--s_font-small);
  }

  .hint {
    color: var(--c_textInput-label);
  }

  .error {
    color: var(--c_textInput-label-error);
    display: none;
  }

  &.value {
    label {
      transform: translateY(-10px) scale(0.8);
    }
  }

  &.showRequired {
    .xw-input-field input {
      border-color: var(--c_textInput-line-required) !important;
    }
    .error {
      color: var(--c_textInput-label-required);
    }
  }

  &.showError {
    .xw-input-field input {
      border-color: var(--c_textInput-line-error);
    }
  }

  &.showError,
  &.showRequired {
    .hint {
      display: none;
    }

    .error {
      display: block;
    }
  }
}

.custom-date-button {
  display: block;

  .children {
    display: block;
  }
}

.date-time-modal-wrapper {
  .xw-modal {
    .xw-icon.arrow-icon {
      flex: 0 0 auto;
      fill: var(--c_timeRange-arrow);
      width: 24px;
      height: 24px;
    }

    .modal-header {
      border-bottom: 1px var(--c_timeRange-line) solid;
      padding: $unit * 1.5;

      .dates {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .xw-icon {
          margin: $unit * 2;
        }

        .label {
          text-transform: uppercase;
          font-weight: 700;
          color: var(--c_timeRange-title);
          font-size: 12 * $ptToEm;
          padding-bottom: 3px;
        }

        .date-wrapper {
          flex: 1 1 100%;
          padding: $unit;
          border: 1px transparent solid;
          border-radius: 2px;

          &.active {
            border-color: var(--c_cal-date-selected-border);
          }
        }

        .date {
          display: flex;

          .day-num {
            flex: 0 0 auto;
            font-size: 32px;
            font-weight: 100;
            margin-right: 6px;
            color: var(--c_timeRange-text-num);
          }

          .month-year {
            font-size: 16px;
            font-weight: 500;
            color: var(--c_timeRange-subtitle);
          }

          .day-time {
            font-size: 12px;
            font-weight: 700;
            color: var(--c_timeRange-title);
          }
        }

        .placeholder {
          line-height: 32px;
          font-size: 18px;
          font-weight: 100;
          color: var(--c_timeRange-text-placeholder);

          &.active {
            color: var(--c_timeRange-text-placeholder-active);
          }
        }
      }
    }

    .modal-body {
      padding: $unit 0;
    }

    .xw-time-range-picker {
      padding: $unit ($unit * 2) 0;
    }
  }
}
