@import "../../../styles/variables";

.xw-tracked-devices {
  .actions {
    display: flex;
  }
  .xw-content-suppress {
    &.devices {
      .device {
        color: var(--c_value-text);
        text-transform: uppercase;
        font-weight: bold;
        padding-right: calc($unit/3);
      }
    }
    > div {
      display: flex;
      padding-right: calc($unit);
    }
    .link {
      min-width: 110px;
    }
  }
}

.do-not-show-hash {
  display: flex;
  width: 335px;
}
