@import "../../styles/variables";

.xw-reorder-list {
  .limit {
    font-size: 14 * $ptToEm;
  }
  .xw-page-section header {
    margin-bottom: 0;
  }
  > .content > ul {
    > li {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      height: 60px;
      background-color: var(--c_card-bg);
      position: relative;
      padding-right: 52px;
      border-radius: 3px;
      margin-bottom: 1px;
      transition: opacity 0.15s ease;

      &.dragged {
        border: 1px solid var(--c_text-hover);
        opacity: 0.6;
      }

      .drag-handle {
        width: 48px;
        height: 100%;
        cursor: ns-resize;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover .xw-icon {
          fill: var(--c_text-hover);
        }
      }

      .remove-action {
        position: absolute;
        top: 7px;
        right: 0;
        margin: calc($unit / 2) 4px;

        .xw-icon {
          fill: var(--c_icon-close);
          transition: fill 0.15s ease;
        }

        &:hover .xw-icon {
          fill: var(--c_icon-close-hover);
        }
      }

      .xw-input-field {
        margin: 0 calc($unit / 2);
      }
    }
  }
}
