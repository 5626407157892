@import "../../styles/variables";

.xw-object-status-value {
  .update-label-enabled {
    color: var(--c_modification_Create);
  }
  .update-label-removed {
    color: var(--c_modification_Delete);
  }
}
