*,
::before,
::after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

::before,
::after {
  vertical-align: inherit;
  text-decoration: inherit;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
input,
textarea {
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  color: inherit;
  font-style: normal;
  line-height: 1;
  border: 0;
}

label {
  display: block;
}

body {
  position: relative;
  overscroll-behavior: none;
  width: 100%;
  height: 100%;
}

ol,
ul {
  list-style: none;
}

table {
  border-spacing: 0;
  border-collapse: separate;
}

caption,
th,
td {
  font-weight: 500;
  text-align: left;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
}

blockquote,
q {
  quotes: "" "";
}

button {
  display: block;
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
  text-align: inherit;
  line-height: normal;
  border: 0;
  background: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;
}

input,
select,
textarea,
button {
  font-family: inherit;
}
