@import "../../styles/variables";

.xw-endpoint-select-card {
  min-height: 162px;
  max-width: calc(33% - #{$unit * 2});
  flex-basis: calc(33% - #{$unit * 2});

  .title .xw-select {
    flex-grow: 1;
  }
  > .content {
    .xw-select {
      width: 100%;
    }
    > .details {
      margin-top: $unit * 1.5;
    }
  }
}
