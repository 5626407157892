@import "../../../xw/styles/variables.scss";

.xw-device-import-details-modal {
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 2 * $unit;

  .detail-section {
    margin-bottom: $unit;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2 * $unit;

    .tags .value .xw-chip .label {
      font-size: 12 * $ptToEm;
      line-height: 12 * $ptToEm;
      color: var(--c_chip-text);
    }
  }
}

