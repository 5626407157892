@import "../../styles/variables";

.xw-request-tenancy {
  .tenancy-details {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
  .request {
    display: flex;
    flex-direction: column;
    .header {
      font-size: 20 * $ptToEm;
      line-height: 27 * $ptToEm;
      padding-bottom: calc($unit/2);
    }
    .multiple {
      display: flex;
      flex-direction: row;
      .xw-input-field {
        width: 258px;
        padding-right: calc($unit/2);
      }
    }
    .field {
      margin-bottom: $ptToEm;
    }
    .trial-description {
      line-height: 19 * $ptToEm;
      padding-bottom: $unit * 3;
    }
    .terms {
      display: flex;
      flex-direction: column;
      .xw-checkbox {
        margin-bottom: 12px;
        a {
          color: var(--c_text-link);
        }
      }
    }
  }

  &.full-page {
    position: unset;
    .xw-input-field {
      width: 512px;
    }
    .xw-input-field > input[type="text"]:disabled {
      background-color: var(--c_page-header-title);
    }
    .xw-select {
      .xw-dropdown-menu {
        button {
          &:focus,
          &.focus,
          &:hover {
            color: var(--c_text-title);
          }
        }
      }
      .match {
        color: var(--c_trial_select-dropdown);
      }
    }
  }
  .footer {
    padding-top: $unit * 2;
  }
}
