.root-page {
  flex: 1 1 100%;
  width: 100%;
  display: flex;
}

.page-content-wrapper {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}
