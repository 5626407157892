@import "../../../styles/variables";

.xw-feature-tier-notification {
  .xw-notification-banner {
    .notification-info {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 4 * $ptToEm;
    }
    .notification-title {
      justify-content: unset;
      font-size: unset;
      line-height: unset;
      span {
        padding-top: 0;
      }
    }
    .notification-description {
      .xw-checkbox {
        margin-bottom: 0;
        label {
          color: var(--c_notification-info-elements-text);
          font-size: 13 * $ptToEm;
        }
      }
    }
    a.link {
      color: var(--c_tier-notification-link);
    }
  }
}

.xw-feature-tier-notification + .xw-policy-edit,
.xw-feature-tier-notification + .xw-policy-details,
.xw-feature-tier-notification + .xw-asns-details > .xw-page-header,
.xw-feature-tier-notification + .xw-prefixes-details > .xw-page-header,
.xw-feature-tier-notification + .xw-alarm-details > .xw-page-header {
  top: 60px;
}
