@import "../../styles/variables";
.xw-prefix-search-form {
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;

  form[name="prefixTrafficSearchForm"] {
    margin: $unit $unit * 2;
    width: 500px;
    .xw-select,
    .xw-input,
    .xw-textarea,
    textarea {
      width: 100%;
    }

    > .xw-trafficDatePicker,
    > .xw-select,
    > .xw-input {
      margin-bottom: $unit * 2;
    }
  }
}
.prefix-traffic-search-table {
  margin-top: calc($unit / 2);
  .xw-button[name="editSearchButton"] {
    width: 256px;
  }
}
