@import "../../../styles/variables";

.xw-api-doc {
  margin-bottom: 10px;
  border: var(--c_api-border);

  .version {
    padding: 0 $unit * 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    .version-text {
      font-weight: 700;
      margin-top: 12px;
      color: var(--c_api-version);
    }
  }
  .section-header {
    background-color: var(--c_api-titlebar);
    color: var(--c_api-titlebar-text);
    display: flex;
    width: 100%;
    .xw-button {
      margin-left: 24px;
    }
  }
  .header-container {
    flex-grow: 1;
  }
  .content {
    display: flex;
    padding: $unit * 2;
    gap: $unit * 2;
    width: 100%;
    flex-grow: 1;
  }
  .url {
    font-size: $ptToEm * 24;
    line-height: 32.69px;
    color: var(--c_accordion-subtitle-text);
  }
  .column {
    flex: 1 1 100%;
    min-width: 0;
    // white-space: wrap;

    &.code {
      display: flex;
      flex-direction: column;
      gap: $unit * 2;
    }
  }
  .title-bar {
    padding: $unit 0;
    display: flex;
    justify-content: space-between;
  }
  .text-block {
    margin: 0 0 $unit * 2 0;

    .block-label {
      font-size: $ptToEm * 18;
      color: var(--c_api-label);
      margin: 0 0 3px 0;
    }

    .val {
      font-size: $ptToEm * 14;
      color: var(--c_api-val);
      line-height: 18px;

      &.chips {
        display: flex;
        flex-wrap: wrap;
        gap: $unit;

        .xw-chip {
          margin: 0;
        }
      }
    }
  }
}
