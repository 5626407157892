@import "../../styles/variables";

.xw-endpoint-summary {
  .xw-reorder-list {
    .content > ul > li {
      min-height: 60px;
      height: unset;
    }
  }

  .xw-policy-endpoint {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 100%;
    .xw-select {
      margin-right: $unit * 2;
    }
    .details {
      display: flex;
      position: relative;
      flex-direction: row;
      flex: 1 1 100%;
      .xw-label-value {
        margin-bottom: 0;
        padding: 6px 24px 0 0;
        .value {
          overflow-wrap: anywhere;
        }
      }
    }
  }
}
