@import "../../styles/variables";
.xw-prefix-traffic {
  display: flex;
  flex-flow: column nowrap;
  // height: 100%;
  > .filters {
    height: $unit * 4;
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: $unit;
    padding: 0 $unit;
    .xw-device-or-device-group-select {
      flex: 0 1 372px;
      padding-right: $unit;
      .select-specific {
        flex: 0 1 200px;
      }
    }
    > .separator {
      flex-grow: 1;
    }
    > .xw-trafficDatePicker {
      width: 400px;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-items: center;
    }

    > button {
      margin-right: $unit;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  > .xw-data-table {
    flex-grow: 1;
  }
}
