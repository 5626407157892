@import "../../styles/variables";

.traffic-tag-details-page {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  .xw-traffic-double-trend-chart {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 $unit * 2 $unit $unit * 2;

    .actions {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      .spacer {
        flex-grow: 1;
      }
      .xw-dateTimeRefresh {
        width: 377px;
        align-self: flex-end;
        margin-bottom: $unit;
      }
    }

    .xw-double-trend-options {
      margin-bottom: $unit * 2;
    }
    > .chart-area {
      position: relative;
      flex-grow: 1;
      .xw-double-trend-chart {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .right-section .xw-bytes-text .value {
          font-size: unset;
          color: unset;
        }
        .customY {
          .xw-bytes-text {
            .unit {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
