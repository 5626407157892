@import "../../../../styles/variables";

.xw-device-changes {
  position: relative;
  flex: 1 1 100%;
  padding-top: $unit * 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 0;

  .fields {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 5px;
    .xw-label-value {
      min-width: 1px;
      margin-right: $unit * 3;
      margin-bottom: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .device-common-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .dossier-timestamps {
      display: flex;
      flex: 0 0 auto;
      .xw-label-value {
        text-align: right;
        > label {
          color: var(--c_changes-modify);
        }
      }
    }
  }

  .xw-bucket-chart {
    flex: 0 0 auto;
    margin: 0 -23px;
    width: calc(100% + #{$unit} * 4);
  }

  .summary {
    display: flex;
    margin-bottom: $unit * 2;

    .widget {
      flex: 1 1 100%;
    }
  }

  .toggles {
    display: flex;
    flex: 0 0 auto;
    margin: $unit * 2 0;
    justify-content: space-between;

    .xw-toggle {
      flex: 0 0 300px;
      margin: 0;
    }
  }

  .xw-processing-loader {
    display: flex;
    align-self: center;
  }

  .xw-diff-tree {
    flex: 1 1 100%;
    margin: 0 -23px;
    width: calc(100% + #{$unit} * 4);
  }
}
