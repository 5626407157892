@import "../../styles/variables";

.xw-page-multitraffic {
  $stacked-colors: 5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  > .xw-traffic-double-trend-chart {
    flex-basis: 67%;
    margin: $unit * 1.5;

    @for $i from 0 through $stacked-colors - 1 {
      .chart-section > svg .top.series-#{$i},
      .chart-section > svg .bottom.series-#{$i} {
        fill: var(--c_chart-stacked-#{$i});
      }
      .chart-section > svg .row.hover {
        transition: filter 0.15s ease;
        filter: brightness(1.25);
      }
    }
  }
  > .xw-data-table {
    flex-basis: 33%;
    min-height: 186px;

    .table-actions {
      align-items: flex-end;
      height: $unit * 2;
      margin-left: $unit * 1.5;
      .viewing {
        font-size: 18 * $ptToEm;
        color: var(--c_text-warning);
        font-weight: 700;
        // flex-basis: 162px;
        margin-right: $unit;
      }
      .date-time {
        color: var(--c_text-title);
        font-size: 18 * $ptToEm;
        .date {
          font-weight: 700;
          margin-right: calc($unit / 2);
        }
      }
      .separator {
        flex-grow: 1;
      }
      .instructions {
        font-size: 12 * $ptToEm;
        color: var(--c_chart-legend);
      }
      .spacer {
        flex: 0 0 0px;
      }
      .xw-button {
        margin: 0;
        font-size: 12 * $ptToEm;
      }
    }
    > .header {
      .multi-traffic-add {
        margin: 0;
        padding: 0;
        font-size: 10 * $ptToEm;
      }
    }
    > .tbody .row.even,
    > .tbody .row.odd {
      @for $i from 0 through $stacked-colors - 1 {
        &.row-bg-#{$i} {
          background-color: var(--c_chart-stacked-bg-#{$i});
        }
        .pip-#{$i} {
          background-color: var(--c_chart-stacked-#{$i});
        }
      }
    }

    .xw-button.remove .xw-icon {
      fill: var(--c_icon-close-hover);
    }
  }
}
