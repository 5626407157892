@import "../../styles/variables";

.xw-geo-map {
  $legendWidth: 156px;
  $mapLeft: $legendWidth + ($unit * 3);

  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  overflow: visible;
  flex-grow: 1;

  svg.map {
    height: 100%;
    width: 100%;

    path {
      stroke: var(--c_geo-border);
      fill: var(--c_geo-fill-noval);
      stroke-width: 2px;

      @for $i from 1 through 5 {
        &.q#{$i} {
          fill: var(--c_geo-fill-#{$i});
          stroke: var(--c_geo-border);
        }
      }

      &.selected {
        fill: var(--c_geo-fill-selected);
      }

      &:hover {
        cursor: pointer;
        fill: var(--c_geo-fill-hover);
      }
    }
  }

  .legend {
    flex: 0 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden;
    width: $legendWidth;

    li {
      display: flex;
      flex-flow: row nowrap;
      height: 18 * $ptToEm;
      margin: calc($unit / 4) 0;
      .label {
        flex-grow: 1;
      }
      .xw-value span {
        color: var(--c_geo-value);
        font-size: 15 * $ptToEm;
        font-weight: 700;
      }
    }
  }

  > .xw-tooltip {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .label,
    .value {
      display: inline-block;
    }
    .value {
      margin-left: calc($unit / 2);
      color: var(--c_geo-value);
      font-weight: 700;
    }
  }
}
