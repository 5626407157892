body.theme-high-contrast {
  --c_content-bg: #ffffff;
  --c_icon: #087dbc;
  --c_icon-input-action: #555555;
  --c_icon-hover: #087dbc;
  --c_icon-hover-two: #000;
  --c_icon-close: #6b7273;
  --c_icon-close-hover: #bd2227;
  --c_text: #000000;
  --c_text-title: #000000;
  --c_text-link: #087dbc;
  --c_text-hover: #10618d;
  --c_text-emphasis: #fc9423;
  --c_hr: #d4d8d9;

  // Statuses
  --c_text-ok: #00620f;
  --c_text-warning: #aa5800;
  --c_text-error: #a31d21;
  --c_text-unknown: #373f40;
  --c_text-status-enabled: #219533;
  --c_text-status-disabled: #a31d21;
  --c_text-red: #a31d21;
  --c_text-status-notice: #9f971c;

  // UI Components Page
  --c_ui-title: #000000;
  --c_ui-divider: #09354d;
  --c_ui-bullet: #6b7273;

  // Status Flow Chart
  --c_statusflowchart-titles: #555;
  --c_statusflowchart-card-text: #000;
  --c_statusflowchart-card-text-secondary: #333;
  --c_statusflowchart-card-bg: #f2f6f7;
  --c_statusflowchart-card-border: #555;
  --c_statusflowchart-working: #00620f;
  --c_statusflowchart-disabled: #aa5800;
  --c_statusflowchart-unknown: #666;
  --c_statusflowchart-error: #a31d21;
  --c_statusflowchart-filled-text: #ffffff;
  --c_statusflowchart-icon-bg: #555;

  // Header
  --c_header-logo: #0d4f73;
  --c_header-bg: #ffffff;
  --c_header-border: #10618d;
  --c_header-active: #0d4f73;
  --c_header-env-text: #a31d21;
  --c_header-link: #0d4f73;
  --c_header-disabled: #373f40;
  --c_header-link-hover: #10618d;
  --c_header-link-separator: #9fa5a6;
  --c_user-icon-bg: #000000;
  --c_user-name: #080a0a;
  --c_user-initials: #ffffff;
  --c_page-header-title: #000000;
  --c_page-header-label: #000000;
  --c_section-header-text: #000000;
  --c_section-header-separator: #373f40;
  --c_section-header-emphasis: #087dbc;

  // Footer
  --c_page-footer-bg: #ffffff;
  --c_page-footer-border: #000000;
  // Text Input
  --c_textInput-bg: #ffffff;
  --c_textInput-bg-disabled: #edf1f2;
  --s_textInput-border: 1px;
  --s_textInput-line: 1px;
  --c_textInput-line: #6b7273;
  --c_textInput-line-active: #087dbc;
  --c_textInput-line-hover: #000000;
  --c_textInput-line-error: #d7262b;
  --c_textInput-line-disabled: #babebf;
  --c_textInput-label: #000000;
  --c_textInput-hint: #000000;
  --c_textInput-label-error: #a31d22;
  --c_textInput-label-disabled: #373f40;
  --c_textInput-value: #000000;
  --c_textInput-value-disabled: #373f40;
  --c_textInput-label-bg: linear-gradient(
    #ffffff 0%,
    #ffffff 75%,
    #{rgba(#ffffff, 0%)} 100%
  );
  --c_textInput-label-bg-disabled: linear-gradient(
    #edf1f2 0%,
    #edf1f2 75%,
    #{rgba(#edf1f2, 0%)} 100%
  );
  --c_textInput-line-required: #a31d22;
  --c_textInput-label-required: #a31d22;
  --c_textInput-search-icon: #babebf;

  // File Input
  --c_fileInput-text: #ffffff;
  --c_fileInput-bg: #087dbc;
  --c_fileInput-bg-hover: #029fd9;
  --c_fileInput-bg-error: #a31d22;
  --c_fileInput-bg-success: #1c802c;
  --c_fileInput-border: #029fd9;
  --c_fileInput-border-error: #ff4b51;
  --c_fileInput-border-success: #41d659;

  // Range Input
  --c_rangeInput-track-bg: #373f40;
  --c_rangeInput-track-bg-focus: #7ed1f6;
  --c_rangeInput-track-border: transparent;
  --c_rangeInput-thumb-bg: #212626;
  --c_rangeInput-thumb-border: #7ed1f6;

  // Time Range
  --c_timeRange-title: #ffffff;
  --c_timeRange-subtitle: #ffffff;
  --c_timeRange-text-label: #373f40;
  --c_timeRange-text-num: #a4e2fe;
  --c_timeRange-text-month: #000000;
  --c_timeRange-text-day: #373f40;
  --c_timeRange-text-time: #373f40;
  --c_timeRange-arrow: #373f40;
  --c_timeRange-line: #b9bebf;
  --c_timeRange-text-placeholder: #373f40;
  --c_timeRange-text-placeholder-active: #087dbc;
  --c_timeRange-incrementer-icon: #{rgba(#087dbc, 0.5)};
  --c_timeRange-incrementer-icon-hover: #087dbc;
  --c_timeRange-cal-icon: #{rgba(#087dbc, 0.5)};
  --c_timeRange-cal-hover: #087dbc;
  --c_timeRange-refresh-label: #373f40;
  --c_timeRange-refresh-time: #000000;
  --c_timeRange-refresh-bg: #eef1f2;

  // Calendar
  --c_cal-text: #000000;
  --c_cal-today-text: #087dbc;
  --c_cal-today-text-disabled: #9fa5a6;
  --c_cal-today-border: #{rgba(#087dbc, 1)};
  --c_cal-num-bg-hover: #373f40;
  --c_cal-num-bg-selected: #087dbc;
  --c_cal-num-bg-highlighted: #ddd;
  --c_cal-num-text-hover: #ffffff;
  --c_cal-num-text-selected: #ffffff;
  --c_cal-date-selected-border: #087dbc;
  --c_cal-header-text: #9fa5a6;
  --c_cal-btn-icon: #087dbc;
  --c_cal-btn-icon-hover: #ffffff;
  --c_cal-btn-bg: #ffffff;
  --c_cal-btn-bg-hover: #087dbc;
  --c_cal-btn-border: #d4d8d9;
  --c_cal-select-bg: #ffffff;
  --c_cal-select-border: #d4d8d9;
  --c_cal-select-text-hover: #ffffff;
  --c_cal-select-btn-hover: #087dbc;

  // Radio/Check (Selection Control) Input
  --c_checkbox-bg: #ffffff;
  --c_checkbox-border: #000000;
  --c_checkbox-active: #087dbc;
  --c_checkbox-error: #d7262c;
  --c_checkbox-disabled: #6b7273;
  --c_checkbox-text: #000000;
  --c_checkbox-disabled-label: #6b7273;
  --c_checkbox-subtext: #000000;

  // Switch
  --c_switch-track-bg: #505859;
  --c_switch-track-bg-disabled: #d4d8d9;
  --c_switch-track-bg-on: #087dbc;
  --c_switch-track-bg-on-disabled: #7ed1f6;
  --c_switch-thumb-bg: transparent;
  --c_switch-thumb-bg-disabled: #ffffff;
  --c_switch-thumb-border: #ffffff;
  --c_switch-thumb-border-active: #029fd9;
  --c_switch-thumb-border-disabled: #6b7273;
  --c_switch-label: #6b7273;
  --c_switch-label-disabled: #6b7273;
  --c_switch-label-selected: #000000;
  --c_switch-label-selected-disabled: #6b7273;

  // Dropdown menu
  --c_dropdown-menu-bg: #ffffff;
  --c_dropdown-menu-border: #d4d8d9;
  --c_dropdown-menu-shadow: #{rgba(0, 0, 0, 0.18)};
  --c_dropdown-menu-link: #000000;
  --c_dropdown-menu-link-hover: #087dbc;

  // Tooltip
  --c_tooltip-bg: #ffffff;
  --c_tooltip-border: #505859;
  --c_tooltip-text: #000000;

  // Group Filter
  --c_group-filter-close: #0b7aba;
  --c_group-filter-close-hover: #a31d22;

  // Button
  --c_button-label: #ffffff;
  --c_button-secondary: #ffffff;
  --c_button-secondary-label: #087dbc;
  --c_button-secondary-border: #087dbc;
  --c_button-secondary-hover: #edf1f2;
  --c_button-secondary-pressed: #7ed1f6;
  --c_button-secondary-pressed-border: #000000;
  --c_button-secondary-disabled-border: #000000;
  --c_button-primary: #0b7aba;
  --c_button-primary-border: #0b7aba;
  --c_button-primary-hover: #10618d;
  --c_button-primary-pressed: #10618d;
  --c_button-primary-pressed-border: #7ed1f6;
  --c_button-primary-disabled-border: #0b7aba;
  --c_button-primary-label: #ffffff;
  --c_button-secondary-label: #000000;
  --c_button-secondary-hover-label: #000000;
  --c_button-link-label: #0d4f73;
  --c_button-link-label-hover: #087dbc;
  --c_button-link-icon: #000000;
  --c_button-link-label-disabled: #373f40;
  --c_button-danger: #a31d22;
  --c_button-danger-border: #a31d22;
  --c_button-danger-hover: #bd2227;
  --c_button-danger-label: #ffffff;
  --c_button-outline-border: #505859;
  --c_button-outline-title: #373f40;
  --c_button-outline-text: #858b8c;
  --c_button-outline-border-hover: #505859;
  --c_button-outline-title-hover: #000000;
  --c_button-subtitle: #000000;
  --c_button-search-icon: #ffffff;

  // Scrollbar
  --c_scrollbar-thumb-large: #9fa5a6;
  --c_scrollbar-thumb-large-hover: #087dbc;
  --c_scrollbar-track-large: #edf1f2;
  --c_scrollbar-thumb-small: #9fa5a6;
  --c_scrollbar-thumb-small-hover: #087dbc;
  --c_scrollbar-track-small: #edf1f2;

  // table
  --c-table-header: #0d4f73;
  --c-table-header-text: #ffffff;
  --c-table-footer-text: #ffffff;
  --c-table-icons: #ffffff;
  --c-table-icons-disabled: #ffffff;
  --c_table-odd: #ffffff;
  --c_table-odd-gradient-cover: linear-gradient(
    270deg,
    #ffffff 50%,
    rgba(255, 255, 255, 0) 100%
  );
  --c_table-even: #edf1f2;
  --c_table-row-border: #10618d;
  --c_table-row-hover: #dcdfe0;
  --c-table-divider: #ffffff;
  --c-table-border: #10618d;
  --c-table-sort-active: #41d659;
  --c-table-sort-inactive: #ffffff;
  --c-table-switch-active-background: #087dbc;
  --c-table-switch-active-fill: #ffffff;
  --c-table-wedge-rowcount-color: #596263;
  --c-table-column-filter: #000000;
  --c-table-inactive-background: #f5f7f7;
  --c-table-background: #ffffff;
  --c-table-error-text: #bd2227;
  --c-table-nodata-text: #000000;
  --c-table-loading-text: #087dbc;
  --c-table-overlay-subtext: #000000;
  --g-table-row-selected: #bceaff;
  --c-table-secondary-action: #01aa96;
  --c-table-secondary-action-hover: darken(#01aa96, 5%);
  --c-table-parent-row: #f1f1f1;
  --c-table-sub-row: #f7f9fa;

  --c_data-table-row-border: #dadada;
  --c_data-table-text-edit: #ffffff;
  --c_data-table-text-add: #ffffff;
  --c_data-table-text-sub: #ffffff;
  --c_data-table-divider-hover: #4b5555;
  --c_data-table-icon-bg-hover: #087dbc;
  --c_data-table-row-number: #373f40;
  --c_data-table-checkbox-filter-divider: #000000;
  --c_data-table-filter-action-hover: #d4d8d9;
  --c_data-table-no-data: #ffffff;

  // Toast
  --c_toast-bg: #ffffff;
  --c_toast-message: #000000;
  --c_toast-custom: #505859;
  --c_toast-info: #087dbc;
  --c_toast-success: #219533;
  --c_toast-warning: #9f971c;
  --c_toast-error: #bd2227;

  // Modal
  --c_modal-dialog-bg: #ffffff;
  --c_modal-dialog-border: #373f40;
  --c_modal-header-bg: #0d4f73;
  --c_modal-header-title: #ffffff;
  --c_modal-header-subtitle: #ffffff;
  --c_modal-body-text: #000000;
  --c_modal-footer-bg: #ffffff;
  --c_modal-border: #000000;

  --c_modal-error-bg: #ffffff;
  --c_modal-error-border: #373f40;
  --c_modal-error-left-border: #d7262c;
  --c_modal-error-title: #d7262c;
  --c_modal-error-text: #080a0a;

  --c_modal-backdrop-bg: #505859;
  --c_modal-backdrop-dots: #6b7273;

  --c_modal-highlight: #087dbc;
  --c_modal-subtitle: #373f40;
  --c_modal-help: #222222;
  --c_modal-any: #000000;

  // drawer
  --c_drawer-title: #000000;
  --c_drawer-bg: #e9eeef;
  --c_drawer-header-bg: #ffffff;
  --c_drawer-footer-bg: #ffffff;
  --c_drawer-border: #373f40;

  // Tabs
  --c_tabs-bottom-border: #0d4f73;
  --c_tabs-text-default-color: #0d4f73;
  --c_tabs-text-state-color: #000000;
  --c_tabs-primary-active-border: #087dbc;
  --c_tabs-primary-pressed: #0d4f73;
  --c_tabs-secondary-active-border: #087dbc;
  --c_tabs-secondary-pressed: #0d4f73;
  --c_tabs-secondary-left-right-border: #6b7273;
  --c_tabs-secondary-bottom-border: #ffffff;

  // Select
  --c_select-option: #373f40;
  --c_select-option-bg-focus: #087dbc;
  --c_select-dropdown-divider: #6b7273;
  --c_select-match: #087dbc;
  --c_select-option-focus: #ffffff; // HC specific
  --c_select-match-focus: #7ed1f6; // HC specific
  --c_select-selected: #8926ec;

  // Chip
  --c_chip-text: #000000;
  --c_chip-action: #000000;
  --c_chip-action-add: #3ef15b;
  --c_chip-action-remove: #d7262c;
  --c_chip-action-bg: #ffffff;
  --c_chip-bg-0: #505859;
  --c_chip-bg-1: #10618d;
  --c_chip-bg-2: #01aa96;
  --c_chip-bg-3: #219533;
  --c_chip-bg-4: #7f9020;
  --c_chip-bg-5: #9f971c;
  --c_chip-bg-6: #a3631d;
  --c_chip-bg-7: #a31d22;
  --c_chip-bg-8: #8926ec;
  --c_page-header-chip-bg: #ffffff;
  --c_page-header-chip-border: #000000;

  // Notification-banner
  --c_notification-info-elements-text: #000000;
  --c_notification-blue: #ffffff;
  --c_notification-teal: #ffffff;
  --c_notification-green: #ffffff;
  --c_notification-olive: #ffffff;
  --c_notification-yellow: #ffffff;
  --c_notification-orange: #ffffff;
  --c_notification-red: #ffffff;
  --c_notification-purple: #ffffff;
  --c_notification-blue-border: #087dbc;
  --c_notification-teal-border: #02d9bf;
  --c_notification-green-border: #41d659;
  --c_notification-olive-border: #bfdd20;
  --c_notification-yellow-border: #e8d800;
  --c_notification-orange-border: #de7603;
  --c_notification-red-border: #bd2227;
  --c_notification-purple-border: #6d26b5;

  // Levels - severity
  --c_level-label: #000000;
  --c_level-icon-bg: #ffffff;
  --c_low-level-icon-border: #078b7b;
  --c_low-level-icon-border-bottom: #078b7b;
  --c_med-level-icon-border: #b9ae1d;
  --c_med-level-icon-border-bottom: #b9ae1d;
  --c_high-level-icon-bg: #bd2227;
  --c_level-high-border-all: #505859;
  --c_none-level-icon-bg: #ffffff;

  // Range Selector
  --c_range-rail-bg: #babebf;
  --c_range-rail-hover-bg: #babebf;
  --c_range-handle-bg: #ffffff;
  --c_range-handle-border: #000000;
  --c_range-handle-after-border: #087dbc;
  --c_range-handle-hover-border: #087dbc;
  --c_range-handle-active-border: #000000;
  --c_range-handle-active-after: #000000;
  --c_range-handle-disabled-bg: #9fa5a6;
  --c_range-handle-label: #000000;
  --c_range-handle-label-active: #087dbc;
  --c_range-handle-label-disabled: #9fa5a6;
  --c_range-track-bg: #000000;
  --c_range-hover-range-track: #029fd9;
  --c_range-hover-range-handle-border: #029fd9;
  --c_range-disabled-all: #9fa5a6;

  // Charting
  --c_chart-title: #000000;
  --c_chart-label: #000000;
  --c_chart-legend: #000000;
  --c_chart-data: #000000;
  --c_chart-data-0: #10618d;
  --c_chart-data-1: #a3631d;
  --c_chart-data-2: #078b7b;
  --c_chart-data-3: #7f9020;
  --c_chart-data-4: #a142ff;
  --c_chart-data-5: #b8bebf;
  --c_chart-area-0: #10618d;
  --c_chart-area-1: #078b7b;
  --c_chart-area-2: #a142ff;
  --c_chart-area-3: #029fd9;
  --c_chart-area-4: #478f53;
  --c_chart-area-5: #087dbc;
  --c_chart-row-0: #029fd9;
  --c_chart-row-1: #10618d;
  --c_chart-row-2: #41d659;
  --c_chart-row-3: #7ed1f6;
  --c_chart-row-4: #de7603;
  --c_chart-column-bg-0: #7f9020;
  --c_chart-column-bg-1: #078b7b;
  --c_chart-column-bg-2: #562586;
  --c_chart-column-bg-3: #10618d;
  --c_chart-column-bg-4: #a2631d;
  --c_chart-stacked-0: #029fd9;
  --c_chart-stacked-1: #01edd1;
  --c_chart-stacked-2: #de7603;
  --c_chart-stacked-3: #fff45d;
  --c_chart-stacked-4: #b163ff;
  --c_chart-stacked-bg-0: #{rgba(#029fd9, 0.1)};
  --c_chart-stacked-bg-1: #{rgba(#01edd1, 0.1)};
  --c_chart-stacked-bg-2: #{rgba(#de7603, 0.1)};
  --c_chart-stacked-bg-3: #{rgba(#fff45d, 0.1)};
  --c_chart-stacked-bg-4: #{rgba(#b163ff, 0.1)};
  --c_chart-tick: #313838;
  --c_chart-avg: #1c802c;
  --c_chart-p95: #b163ff;
  --c_chart-min: #de7603;
  --c_chart-max: #b9ae1d;
  --c_chart-marker-border: #000000;
  --c_chart-indicator: #000000;
  --c_chart-select-marker: #000000;
  --c_chart-select-fill: #{rgba(255, 255, 255, 0.35)};
  --c_chart-data-placeholder: #b8bebf;
  --c_chart-unit: #000000;
  --c_sankey-title: #000000;
  --c_trend-hover: #ffad53;
  --c_trend-hover-bg: #{rgba(255, 173, 83, 0.2)};
  --c_chart-double-top: #7f9020;
  --c_chart-double-bottom: #078b7b;
  --c_chart-series-0: #6d26b5;
  --c_chart-series-1: #de7603;
  --c_chart-dual-area-1: #2fb945;
  --c_chart-dual-area-2: #087dbc;
  --c_chart-dual-area-3: #b163ff;
  --c_chart-dual-area-4: #fff675;
  --c_chart-dual-brush-handle-hover: #007e13;
  --c_chart-dual-hover-default: #777777;
  --c_chart-brush-select-area: #{rgba(220, 220, 220, 0.5)};

  --c_gauge-low: #087dbc;
  --c_gauge-medium: #de7603;
  --c_gauge-high: #d7262c;
  --c_gauge-indicator-fill: #000000;
  --c_gauge-indicator-stroke: #ffffff;

  --c_geo-fill-1: #d7262c;
  --c_geo-fill-2: #a3631d;
  --c_geo-fill-3: #078b7b;
  --c_geo-fill-4: #7f9020;
  --c_geo-fill-5: #020202;
  --c_geo-fill-noval: #b8bebf;
  --c_geo-fill-hover: #087dbc;
  --c_geo-fill-selected: #087dbc;
  --c_geo-border: #ffffff;
  --c_geo-value: #000000;

  // Toggle
  --c_toggle-bg: #ffffff;
  --c_toggle-border-selected: #000000;
  --c_toggle-border-outer: #000000;
  --c_toggle-label: #444444;
  --c_toggle-label-hover: #0c59de;
  --c_toggle-label-selected: #000000;
  --c_toggle-marker: #ffffff;

  // Path list
  --c_path-value: #10618d;
  --c_path-hightlight-value: #05665a;
  --c_path-separator: #505859;
  --c_multi-path-bg: #ffffff;
  --c_multi-path-border: #505859;
  --c_hop-count-diamond: #05665a;
  --c_hop-count-text: #ffffff;

  // Value
  --c_value-text: #000000;
  --c_value-icon: #c4c4c4;

  // Login page
  --c_login_background: #ffffff;
  --c_login-logo-color: #087dbc;

  // Step Wizard
  --c_stepwizard_left: #ffffff;
  --c_stepwizard_right: #e1e4e5;
  --c_stepwizard_right_selected: #ffffff;
  --c_stepwizard_caption: #000000;
  --c_stepwizard_sub_caption: #6b7273;
  --c_stepwizard-border: #000000;
  --c_stepwizard-section-text-color: #000000;

  // Welcome Page
  --c_welcome_grey: #d4d8d9;

  // Express page
  --c_express_selected: #087dbc;

  // number circle
  --c_circle: #087dbc;
  --c_circle_border: #ffffff;
  --c_circle-text: #ffffff;
  --c_circle-active: #ffffff;
  --c_circle-active-border: #000000;
  --c_circle-active-text: #131717;
  --c_circle-aspath-background: #505859;

  // Card
  --c_card-title-text: #ffffff;
  --c_card-subtitle-text: #ffffff;
  --c_card-title-bg: #08354d;
  --c_card-title-bg2: #08354d;
  --c_card-bg: #ffffff;
  --c_card-bg2: #ffffff;
  --c_card-border: #6b7273;
  --c_card-bg-nested: #edf1f2;
  --c_card-link: #7ed1f6;
  --c_card-bg-active: #ffffff;
  --c_card-expander-bg: #313838;
  --c_card-expander-bg-hover: #000000;
  --c_card-btn-close: #bbbbbb;
  --c_card-btn-close-hover: #7ed1f6;

  // Accordion
  --c_accordion-header-background: #08354d;
  --c_accordion-content-background: #f6f6f6;
  --c_accordion-content-border: transparent;
  --c_accordion-title-text: #ffffff;
  --c_accordion-title-text-disabled: #bbbbbb;
  --c_accordion-subtitle-text: #000000;
  --c_accordion-icon-background: #ffffff;
  --c_accordion-icon-box-border: #000000;
  --c_accordion-icon-color: #087dbc;
  --c_accordion-icon-disabled: #9fa5a6;

  // Login page
  --c_login_background: #ffffff;
  --c_login-logo-color: #087dbc;

  // help
  --c_help_section_label: #000000;
  --c_help_section_description: #000000;
  --c_help_section_separator: #6b7273;
  --c_help_section_bullet-bg: #6b7273;
  --c_help_li_item: #087dbc;

  // external help Link Button
  --c_help_link_button-bg: #ffffff;
  --c_help_link_button-border: #000000;
  --c_help_icon_button-fill: #000000;

  // Import export configuration
  --c_importExport_caution_text: #000000;
  --c_fileImport_upload_icon-fill: #000000;

  // Modification column values
  --c_modification_Delete: #b30006;
  --c_modification_Create: #007e13;
  --c_modification_Edit: #998f00;

  // Processing Modal component
  --c_processing_modal_loader: #087dbc;
  --c_processing_modal_processing_title: #000000;
  --c_processing_modal_processing_message: #000000;
  --c_processing_modal_failure_title: #bd2227;
  --c_processing_modal_failure_message: #000000;
  --c_processing_modal_success_title: #000000;
  --c_processing_modal_success_message: #000000;

  // Well
  --c_well-bg: #edf1f2;
  --c_well-border: #2e3435;

  // page-section
  --c_title-special-text: #01aa96;

  // page-caption
  --c_caption_text: #000000;

  //table-columns
  --c_table_column_update_text: #219533;

  // column-donut
  --c_column-donut-bg: #cccccc;
  --c_column-donut-bg-active: #08354d;
  --c_column-donut-value: #000;
  --c_column-donut-secondary: #6b7273;

  // user-detailspage-header-border
  --c_page_header_border_color: #000000;
  --c_api_key_text_color: #087dbc;

  // Filter By
  --c_filterby-item-text: #ffffff;
  --c_filterby-item-bg: #0c4f73;
  --c_filterby-item-title: #000000;
  --c_filterby-label: #000000;

  --c_card-arrow-active: #219533;
  --c_card-arrow-inactive: #000000;
  --c_card-arrow-inprogress: #087dbc;
  --c_parent-menu-active: #ffffff;
  --c_parent-menu-background: #08354d;
  --c_parent-menu-border-top-bg: linear-gradient(
    180deg,
    #{rgba(255, 255, 255, 0.12)} 0%,
    #{rgba(255, 255, 255, 0)} 100%
  );
  --c_parent-menu-border-bottom-bg: linear-gradient(
    0deg,
    #{rgba(255, 255, 255, 0.12)} 0%,
    #{rgba(255, 255, 255, 0)} 100%
  );

  // Diff Tree
  --c_difftree-bg: #ffffff;
  --c_difftree-text: #000000;
  --c_difftree-text-secondary: #333333;
  --c_difftree-text-legend: #ffffff;
  --c_difftree-header-bg: #08354d;
  --c_difftree-header-text: #ffffff;
  --c_difftree-expander-bg: #edf1f2;
  --c_difftree-expander-bg-hover: #edf1f2;
  --c_difftree-row-bg: #edf1f2;
  --c_difftree-parent-change: #00550d;
  --c_difftree-add: #00550d;
  --c_difftree-change: #a3631d;
  --c_difftree-delete: #a31d21;
  --c_difftree-modified: #087dbc;
  --c_difftree-row-bg-hover: #ffffff;
  --c_difftree-footer-bg: #08354d;

  // Device Image
  --c_device-image-label: #000;
  --c_device-image-label-border: #0c4f73;
  --c_device-image-label-border-virtual: #562586;
  --c_device-image-label-icon: #ffffff;
  --c_device-image-box: #000000;
  --c_device-image-rack: #000;

  // Device Capabilities
  --c_device-capabilities-title: #2d3335;
  --c_device-capabilities-item-title: #000000;
  --c_device-capabilities-check-na: #858b8c;
  --c_device-capabilities-check-upgrade: #000000;
  --c_device-capabilities-check-active: #087dbc;

  // Filter
  --c_filter_box-bg: #ffffff;
  --c_filter_box-border: #373f40;
  --c_filter_operator-include: #0873dd;
  --c_filter_operator-exclude: #a31d21;
  --c_filter_operator-disabled: #161414;
  --c_filter_box-text: #000000;
  --c_filter_box-close: #ffffff;
  --c_filter_box-close-background: #08354d;
  --c_filter-btn-text: #000000;
  --c_filter-modal-header-background: #17344b;
  --c_filter-modal-body-background: #f6f6f6;
  --c_filter-modal-footer-background: #212626;

  // coming soon
  --c_coming-soon: #01aa96;
  --c_not-licensed: #de7603;

  // Timeline Bucket Chart
  --c_tlbucketchart-legend-bg: linear-gradient(
    180deg,
    #{rgba(49, 56, 56, 0.05)} 0%,
    #{rgba(49, 56, 56, 0)} 50%,
    #{rgba(49, 56, 56, 0.05)} 100%
  );
  --c_tlbucketchart-legend-dossierErrors-diagonalStripes: #FFFFFFBF;
  --c_tlbucketchart-y-legend-background: #FFFFFF;
  --c_tlbucketchart-legend-background: #EDF1F2;
  --c_tlbucketchart-border: #000000;
  --c_tlbucketchart-track-bg: #EDF1F2;
  --c_tlbucketchart-action: #087dbc;
  --c_tlbucketchart-action-active: #000000;
  --c_tlbucketchart-action-hover: #000000;
  --c_tlbucketchart-tlpoint-line: #9f971c;
  --c_tlbucketchart-tlpoint-arrowline: #212626;
  --c_tlbucketchart-tlpoint-rectangularDotLine: #562586;
  --c_tlbucketchart-tlpoint-dotline: #562586;
  --c_tlbucketchart-tlpoint-dotline-circle: #000000;
  --c_tlbucketchart-tlpoint-dotline-error: #711013;
  --c_tlbucketchart-axis-text-primary: #000000;
  --c_tlbucketchart-axis-text-secondary: #555555;
  --c_tlbucketchart-data-top: #219533;
  --c_tlbucketchart-data-bottom: #078B7B;
  --c_tlbucketchart-data-zero: #000000;
  --c_tlbucketchart-select-line: #9fa5a6;
  --c_tlbucketchart-select-line-hover: #0c4f73;
  --c_tlbucketchart-select-bg: #ffffff;
  --c_tlbucketchart-arrow-select: #000000;
  --c_tlbucketchart-overlay: #{rgba(25, 30, 32, 0.2)};
  --c_tlbucketchart-pager-icon-hover: #ffffff;

  --c_doublestackchart-card-bg: #e9eeef;
  --c_doublestackchart-date-title: #000000;

  --c_doubletrendchart-card-bg: #ffffff;

  --c_countdown-active: #01aa96;
  --c_countdown-expiring: #de7603;
  --c_countdown-expired: #bd2227;
  --c_countdown-recent: #ffffff;

  --c_trial_select-dropdown: #7ed0f6;

  --c_changes-modify: #6d3800;

  // Main Nav
  --c_mainNav-bg: #09354d;
  --c_mainNav-seperater-small: #{rgba(255, 255, 255, 0.5)};
  --c_mainNav-seperater-large: #10618d;
  --c_mainNav-border: #10618d;
  --c_mainNav-text: #a4e2fe;
  --c_mainNav-text-heading: #ffffff;
  --c_mainNav-text-active: #dddddd;
  --c_mainNav-text-hover: #ffffff;
  --c_mainNav-icon: #ffffff;
  --c_mainNav-icon-active: #ffffff;
  --c_mainNav-icon-hover-bg: #{rgba(255, 255, 255, 0.25)};
  --c_mainNav-icon-active-bg: #{rgba(#ffffff, 0.15)};
  --c_mainNav-pin: #bbbbbb;
  --c_mainNav-pin-hover: #63bbe3;
  --c_mainNav-pin-active: #ddf94b;
  --c_mainNav-gradient: #10618d;

  --c_circle-check-success: #087dbc;
  --c_drilldown-shadow: -6px 1px 5px 0px rgba(0, 0, 0, 0.25);
  --c_drilldown-text: #09354d;
  --c_drilldown-text-active: #ffffff;
  --c_drilldown-text-hover: #0873dd;
  --c_drilldown-text-1: #000000;
  --c_drilldown-text-2: #444444;

  --c_card-disabled: #edf1f2;
  --c_card-disabled-header: #505859;
  --c_card-disabled-text: #de7603;

  --c_expander-header: rgba(80, 88, 89, 1);

  // Peer ID
  --c_peer-id-text: #ffffff;
  --c_peer-id-background: #000000;

  --c-expander-plus: #0873dd;
  --c_tier-available: #0873dd;
  --c_tier-na: #000000;
  --c_tier-partial: #0873dd;
  --c_tier-partial-line: #000000;
  --c_tier-text: #a4e2fe;
  --c_tier-current-selection: rgba(196, 196, 196, 0.15);
  --c_tier-current-selection-border: rgba(8, 115, 221, 0.9);
  --c_tier_plan-icon-bg: #09354d;
  --c_tier_plan-active: #0873dd;
  --c_tier_plan-border: #c4c4c4;
  --c_tier-notification-link: #0873dd;
  --c_tier-section-header-text: #ffffff;
  --c_tier-text-link: #0153c0;

  // Reccomendations
  --c_recommendations-title: #000000;
  --c_recommendations-headers: #000000;

  // Telemetry
  --c_telemetry-picker-border-selected: #000000;

  // Policy
  --c_content-section-header: #000000;
  --c_modal_policy-title: #ffffff;
  --c_modal-policy-subtitle: #d4d8d9;
  --c_modal_policy-rule-title: #000000;
  --c_modal_policy-rule-sub-title: #212626;
  --c_policy-summary-count: #ffffff;
  --c_policy-data-included: #41d659;
  --c_policy-data-excluded: #a31d22;
  --c_policy-notification-border: #000000;
  --c_policy-notification-background: #ffffff;
  --c_range-utilization-error: #b30006;
  --c_range-utilization-handle: #f6f6f6;
  --c_policy-default-title: #ffffff;
  --c_policy-list-active-alarm-border: #a31d22;
  --c_policy-list-active-alarm-background: #ffffff;
  --c_policy-list-active-alarm-count-text: #a31d22;
  --c_policy-list-active-alarm-text: #ffffff;
  --c_policy-list-default-details: #ffffff;
  --c_policy-list-default-details-text: #0873dd;
  --c_policy-create-modal-header-background: #17344b;
  --c_policy-create-modal-sub-title-text: #000000;
  --c_policy-create-modal-sub-title-sub-text: #373f40;
  --c_policy-create-modal-policy-type-text: #000000;
  --c_policy-create-modal-object-border: #0873dd;
  --c_policy-create-modal-footer-background: #212626;
  --c_policy-select-modal-title: #d4d8d9;
  --c_policy-select-add-queue-icon: #ffffff;
  --c_policy-select-add-queue-text: #000000;
  --c_policy-select-added-queue-icon: #219533;
  --c_policy-select-added-queue-text: #219533;

  // BGP Updates
  --c_daul-axis-chart-header-bg: #ffffff;
  --c_daul-axis-chart-bg: #f2f4f7;
  --c_bgp-updates-legend-lbl: #000000;
  --c_bgp-updates-border: #ffffff;
  --c_bgp-updates-filter-bg: #ffffff;
  --c_bgp-updates-filter-button-bg: #007ec0;
  --c_bgp-updates-filter-button-text: #ffffff;
  --c_bgp-updates-filter-button-bg-hover: #00a1dd;
  --c_bgp-updates-filter-button-border: #00a1dd;
  --c_bgp-updates-filter-warning-border: #A3631D;
  --c_bgp-updates-filter-warning-icon: #000000;
  --c_bgp-updates-filter-warning-icon-bg: #EDF1F2;
  --c_bgp-updates-filter-warning-text: #373F40;

  // Topology
  --c_topology-header: #f2f4f7;
  --c_topology-footer: #f2f4f7;
  --c_topology-border: #e1e4e5;
  --c_topology-node: #777;
  --c_topology-node-removed: #6a0004;
  --c_topology-node-added: #027114;
  --c_topology-node-updated: #ad6800;
  --c_topology-node-stuffed-one: #bcd6bb;
  --c_topology-node-stuffed-two: #bcd6bb;
  --c_topology-node-text: #fff;
  --c_topology-node-hover: #000000;
  --c_topology-node-active: #000000;
  --c_topology-node-selected: #000000;
  --c_topology-node-text-active: #ffffff;
  --c_topology-node-border-active: #000000;
  --c_topology-node-icon: #ffffff;
  --c_topology-node-line: #cccccc;
  --c_topology-node-line-hover: #888;
  --c_topology-node-line-active: #ffffff;
  --c_topology-detail-bg: rgba(220, 220, 220, 1);
  --c_topology-detail-text: #777777;
  --c_topology-detail-asn: #666666;
  --c_topology-detail-text-bold: #000000;
  --c_topology-detail-icon: #6b7273;
  --c_topology-detail-path-header: #fff;
  --c_topology-peer-set-bg: #eee;
  --c_topology-peer-set-bg-hover: #ddd;
  --c_topology-detail-peer-set-bg: #eee;
  --c_topology-detail-peer-set-border: #fff;
  --c_topology-detail-peer-label: rgb(132, 154, 142);
  --c_topology-detail-peer-val: #555555;
  --c_topology-peer-num: #000;
  --c_topology-text-added: #027114;
  --c_topology-text-updated: #ad6800;
  --c_topology-text-removed: #6a0004;

  // API Docs
  --c_api-header: #eeeeee;
  --c_api-titlebar: #ffffff;
  --c_api-border: 1px solid #000000;
  --c_api-text: #000000;
  --c_api-label: #000000;
  --c_api-version: #858b8c;
  --c_api-val: #373f40;
  --c_api-get: #373f40;
  --c_api-post: #219533;
  --c_api-put: #9f971c;
  --c_api-patch: #A2631D;
  --c_api-delete: #a31d22;
  --c_api-param-bg: #ffffff;
  --c_api-param-border: 1px solid #000000;
  --c_api-param-code: #000000;
  --c_api-param-sub: #373f40;
  --c_api-param-description: #000000;

  // Code Display
  --c_code-bg: #ffffff;
  --c_code-border: 1px solid #000000;
  --c_code-head-bg: #edf1f2;
  --c_code-text: #000;
  --c_code-name: #333;
  --c_code-brackets: #000;
  --c_code-key: #087dbc;
  --c_code-number: #087dbc;
  --c_code-string: #555555;
  --c_code-true: #219533;
  --c_code-false: #a31d21;
  --c_code-null: #569ad5;

  // Markdown
  --c_markdown-bg: #fff;
  --c_markdown-h1: #000;
  --c_markdown-h2: #000;
  --c_markdown-h6: #000;
  --c_markdown-line: #ccc;
  --c_markdown-text: #555;
  --c_markdown-link: #087dbc;

  // Queue
  --c_queue-bg: #ffffff;
  --c_queue-head-bg: #17344b;
  --c_queue-title: #edf1f2;
  --c_queue-subtitle: #babebf;
  --c_queue-item: #edf1f2;
  --c_queue-item-icon: #131717;
  --c_queue-actual-num: #00620f;
  --c_queue-actual-num-error: #d7262c;
  --c_queue-item-border: rgba(0, 0, 0, 0.25);

  // Add CDG
  --c_cdg-arrow: #373f40;
  --c_cdg-gateway: #7ed1f6;
  --c_cdg-revoke: #a31d22;
  --c_cdg-allow: #219533;
  --c_cdg-table-highlight-text: #0873dd;
  --c_cdg-revoke-table-bg: #ffffff;
}
