@import "../../styles/variables";

.xw-row-chart {
  width: 100%;
  height: 100%;
  min-width: 128px;
  min-height: 64px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;

  > svg {
    width: 100%;
    flex-grow: 1;
    pointer-events: none;
    .group-labels > text {
      fill: var(--c_chart-data);
      font-size: 12;
      font-weight: 700;
      text-anchor: start;
    }

    foreignObject {
      pointer-events: all;
      overflow: visible;
      .groupLabel {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        height: 100%;
      }
      a.link {
        transition: color 0.15s ease;
        color: var(--c_text-link);
        font-weight: bold;
        font-size: 12 * $ptToEm;
        line-height: 18px;
        &:hover {
          color: var(--c_text-hover);
        }
      }
    }

    .bar-group > path {
      pointer-events: fill;
      cursor: pointer;
    }
    .tick {
      > text {
        fill: var(--c_chart-data);
        font-size: 10;
        font-weight: bold;
      }
    }
    @for $i from 0 through 4 {
      .row-bar-area-#{$i} {
        fill: url("#rowBarArea#{$i}");
        transition: fill 0.15s ease;

        &:hover {
          fill: var(--c_chart-row-#{$i});
        }
      }
    }
  }

  .unit {
    font-weight: 700;
    font-size: 10 * $ptToEm;
    fill: var(--c_chart-unit);
  }

  .row-chart-tooltip {
    // width: 96px;
    // padding: $unit / 4;
    .label {
      font-weight: bold;
      line-height: 16 * $ptToEm;
      font-size: 12 * $ptToEm;
    }

    .value {
      font-size: 16 * $ptToEm;
      line-height: 22 * $ptToEm;
      font-weight: 300;
    }

    .unit {
      display: inline;
      position: static;
      color: var(--c_chart-unit);
    }
  }

  .title {
    font-weight: 300;
    font-size: 18 * $ptToEm;
    line-height: 25 * $ptToEm;
    color: var(--c_text-title);
    margin-left: calc($unit / 4);
  }

  .legend {
    font-weight: 300;
    font-size: 12 * $ptToEm;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-top: $unit;

    .series {
      display: flex;
      flex-flow: row nowrap;
      flex: 0 1 auto;
      height: 12px;
      margin-right: $unit;
      font-weight: 700;
      font-size: 10 * $ptToEm;
      color: var(--c_chart-data);
      align-items: center;

      .color {
        display: inline-block;
        height: $unit;
        width: $unit;
        border-radius: 2px;
        margin-left: calc($unit / 2);
      }
    }
  }
}
