@import "../../../styles/variables";

.xw-subscribe-prefixes {
  .xw-page-header {
    padding: 0;
    > .header {
      padding: $unit $unit * 2;
    }
    .xw-tabs {
      padding-left: $unit * 2;
    }
  }

  .review-warning {
    font-size: 14 * $ptToEm;
    line-height: 18 * $ptToEm;
    color: var(--c_textInput-label);
    padding: $unit 0;
  }
  .xw-textarea,
  textarea {
    width: 100%;
  }
  .asn-lookup-input {
    input {
      width: 50%;
    }
  }
  .caution-text {
    color: var(--c_text-error);
    font-size: 24 * $ptToEm;
    line-height: 33 * $ptToEm;
    font-weight: 400;
  }
  .xw-policy-tag-container {
    padding-bottom: $unit * 2;
  }
  .xw-subscribe-prefixes-csvImport {
    .file-selection-page {
      width: 100%;
      .caution-text {
        color: var(--c_text-error);
        font-size: 24 * $ptToEm;
        line-height: 33 * $ptToEm;
      }
      .xw-file-input-field {
        width: 400px;
      }
      .file-sample {
        padding: 0;
        margin: $unit * 3 0;
      }
    }
    .xw-data-table {
      padding-bottom: $unit;
      display: flex;
      flex: 1 1 auto;
      flex-flow: column nowrap;
      height: 100%;
    }
  }
  .prefix-policy-review-table {
    flex: 1 1 100%;
    height: 100%;
    width: 100%;
    margin-top: calc($unit/2);

    .custom-filter-controls-section {
      display: none;
    }

    .xw-data-table {
      padding-bottom: $unit;
      display: flex;
      flex: 1 1 auto;
      flex-flow: column nowrap;
      height: 100%;
    }
  }
}
