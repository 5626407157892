@import "../../styles/variables";

@mixin toast-type-color($type, $color) {
  &.type-#{$type} {
    > .title {
      color: $color;
    }
    > .bar {
      background-color: $color;
    }
  }

  .theme-high-contrast &.type-#{$type} {
    border: 1px solid $color;
  }
}

.xw-toast {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: var(--s_toast-width);
  min-height: var(--s_toast-height);
  background-color: var(--c_toast-bg);
  padding: $unit * 1.5;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  > .title {
    font-size: 20 * $ptToEm;
    line-height: 23 * $ptToEm;
    margin-bottom: calc($unit / 3);
  }

  > .message {
    color: var(--c_toast-message);
  }

  > .bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc($unit / 2);
    background-color: var(--c_toast-custom);
    > .spent {
      animation: 0s slideDown linear;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  > .close-button {
    position: absolute;
    top: $unit;
    right: $unit;
    width: $unit;
    height: $unit;
    padding: 1px;
    .xw-icon {
      overflow: hidden;
      width: 100%;
      height: 100%;
      fill: var(--c_icon-close);
    }

    &:hover .xw-icon {
      fill: var(--c_icon-close-hover);
    }
  }

  @include toast-type-color("info", var(--c_toast-info));
  @include toast-type-color("success", var(--c_toast-success));
  @include toast-type-color("warning", var(--c_toast-warning));
  @include toast-type-color("error", var(--c_toast-error));
}
