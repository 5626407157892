@import "../../styles/variables";

.xw-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  .xw-button {
    margin: 0;
  }

  .xw-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    padding: 12px;
    .xw-icon {
      fill: var(--c_icon-close);
      cursor: pointer;
    }
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.94;
    transition: opacity ease 0.2s;
    &.show {
      opacity: 0;
    }

    .background {
      fill: var(--c_modal-backdrop-bg);
    }

    .dot {
      fill: var(--c_modal-backdrop-dots);
    }
  }

  .modal-dialog {
    position: relative;
    z-index: 2;
    max-height: 86%;
    min-height: 178px;
    width: 648px;
    background: var(--c_modal-dialog-bg);
    border-radius: 2px;
    border: 1px solid var(--c_modal-dialog-border);
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    transition: all ease 0.2s;
    opacity: 1;
    transform: scale(1);
    box-shadow: 0 20px 100px rgba(0, 0, 0, 0.25);
    &.show {
      opacity: 0;
      transform: scale(0.8);
    }

    .modal-header {
      position: relative;
      z-index: 2;
      width: 100%;
      padding: $unit * 2;
      background-color: var(--c_modal-header-bg);
      border-bottom: 1px solid var(--c_modal-border);

      .title {
        color: var(--c_modal-header-title);
        font-size: 24 * $ptToEm;
        line-height: 26 * $ptToEm;

        .xw-icon {
            fill: var(--c_table_column_update_text);
        }
      }

      .sub-title {
        color: var(--c_modal-header-subtitle);
        font-size: 15 * $ptToEm;
        line-height: 20 * $ptToEm;
      }
    }

    .mismatchKnownUnknown-banner {
      background-color:#FFAD53;
      color: black;
      padding: 10px 0px 10px 24px;
    }

    .modal-body {
      position: relative;
      display: flex;
      flex-direction: column;
      color: var(--c_modal-body-text);
      z-index: 1;
      line-height: 20px;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1 1 100%;
      height: 100%;

      .modal-padding {
        padding: $unit * 2;
        font-size: 14 * $ptToEm;
        line-height: 18 * $ptToEm;

        > p {
          line-height: 18 * $ptToEm;
        }

        &.no-bottom {
          padding-bottom: 0;
        }
      }

      .custom-filter-controls-section {
        flex: 0 0 auto;
        border-bottom: 1px solid var(--c_modal-border);
      }
    }

    .modal-footer {
      z-index: 3;
      height: 58px;
      width: 100%;
      border-top: 1px solid var(--c_modal-border);
      background: var(--c_modal-footer-bg);
      padding: $unit;
      text-align: right;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .xw-button {
        margin-left: $unit;

        &.left {
          margin-left: 0;
        }
      }
    }

    .modal-error {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 auto;
      height: 72px;
      padding: $unit $unit * 2 $unit $unit * 2 - 6;
      border: 1px solid var(--c_modal-error-border);
      border-left: 6px solid var(--c_modal-error-left-border);
      background: var(--c_modal-error-bg);
      border-radius: 2px 0px 0px 2px;
      .title {
        color: var(--c_modal-error-title);
      }
      .error {
        color: var(--c_modal-error-text);
      }
    }
  }

  .xw-page-footer {
    .xw-button {
      margin: calc($unit / 2) 0 calc($unit / 2) $unit;
    }
  }
}
