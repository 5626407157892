@import "../../../../styles/variables";
.xw-traffic-device-details {
  position: absolute;
  top: $unit * 3;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  .xw-page-header {
    padding: 0 $unit * 2;
  }

  .group-name {
    font-size: 14 * $ptToEm;
  }

  .xw-traffic-chart-tab {
    position: absolute;
    top: $unit * 5;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    .xw-traffic-double-trend-chart {
      padding-top: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
    }
  }
  .xw-interfaces-config-tab {
    position: absolute;
    top: $unit * 3;
    right: 0;
    bottom: 0;
    left: 0;
    .interface-count {
      display: flex;
      flex: 0 0 auto;
      justify-content: flex-end;
      text-align: right;
      margin-top: $unit;
      .xw-label-value {
        text-align: right;
        > label {
          padding-left: $unit;
        }
      }
    }
    .interface-config-table {
      .xw-data-table {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -24px;
        width: calc(100% + 48px);
        display: flex;
        flex-flow: column nowrap;
      }
      .table-actions {
        align-items: flex-end;
        margin-bottom: calc($unit / 2);
        .device-interface-actions {
          display: flex;
          width: 100%;
          .xw-button .xw-icon {
            margin: 0 calc($unit / 2);
            white-space: nowrap;
          }
        }
        .xw-tooltip-trigger {
          width: 100%;
        }
      }
    }
  }
}
