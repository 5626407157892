@import "../../styles/variables";

.xw-traffic-double-trend-chart {
  // position: relative;
  display: flex;
  flex-flow: column nowrap;
  .traffic-chart {
    position: relative;
    margin-top: $unit;
    flex: 1 0;
    .xw-double-trend-chart {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;

      g.top foreignObject .xw-bytes-text .unit {
        color: var(--c_chart-double-top);
      }
      g.bottom foreignObject .xw-bytes-text .unit {
        color: var(--c_chart-double-bottom);
      }
    }
  }
}
