@import "../../styles/variables";
@import "../../styles/base";
@import "../../components/charts/chart.scss";

.route-origin-validation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  .start-form-container {
    background-color: var(--c_content-bg);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .start-form {
      width: 300px;
    }
  }
  > .top-section {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: var(--c_daul-axis-chart-header-bg);
    height: $unit * 7;
    border-bottom: 1px solid var(--c_bgp-updates-border);

    .time-range {
      display: inline-flex;
      flex: 0 0 auto;
      flex-flow: row nowrap;
      padding: $unit;
    }

    > .time-summary-and-legend {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      min-width: 0;
      flex-flow: row;
      align-items: center;

      .xw-label-value {
        margin-bottom: 0;
        padding: 0 20px;

        > label {
          color: var(--c_bgp-updates-legend-lbl);
          font-size: 20 * $ptToEm;
          line-height: 20 * $ptToEm;
        }

        .value {
          color: var(--c_bgp-updates-legend-lbl);
        }
      }

      .separator {
        width: 1px;
        background-color: var(--c_ui-divider);
        margin: 10px 0;
        height: $unit * 5;
        padding: 0;
      }

      > .legend {
        display: flex;
        flex-flow: column;
        overflow: hidden;
        padding: 0 20px;
        margin-bottom: 0;

        .series {
          flex: 0 1 auto;
          height: 20px;
          border-radius: calc($unit / 2);
          display: flex;
          justify-content: space-between;
          align-items: center;
          @for $i from 0 through 5 {
            .legend-series-lbl-#{$i} {
              color: var(--c_chart-area-#{$i});
              font-size: 16 * $ptToEm;
              line-height: 14 * $ptToEm;
            }
          }

          .legend-series-val {
            font-size: 16 * $ptToEm;
            line-height: 14 * $ptToEm;
            color: var(--c_bgp-updates-legend-lbl);
            padding-left: 24px;
          }
        }
      }
    }
  }
  .xw-line-chart {
    max-height: 300px;
  }
  .xw-data-table {
    flex: 1 1 100%;
    .notes-highlight {
      font-weight: 600;
    }
    .obj-route-link {
      display: inline-block;
      padding-left: 5px;
      .xw-icon {
        fill: var(--c_text-title);
        width: 12px;
      }

      &:hover {
        .xw-icon {
          fill: var(--c_icon-hover);
        }
      }
      &:active {
        .xw-icon {
          fill: var(--c_text-link);
        }
      }
    }
  }
  .xw-filter {
    background-color: var(--c_bgp-updates-filter-bg);
    padding: 0 $unit $unit $unit;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 0 auto;
    gap: 10px;

    .filter-boxes {
      padding-bottom: 0;
    }

    .add-button {
      border: 1px solid var(--c_bgp-updates-filter-button-border);
      min-width: 0;
      background: var(--c_bgp-updates-filter-button-bg);
      align-items: center;
      color: var(--c_bgp-updates-filter-button-text);
      height: 34px;
      min-height: 0;
      padding: 0 $unit * 2;
      .theme-high-contrast & {
        text-decoration: none;
      }

      &:hover {
        background: var(--c_bgp-updates-filter-button-bg-hover);
      }
    }
  }
}
