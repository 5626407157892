.utilization-text,
.utilization-percent {
  font-weight: 700;
  &.utilization-critical {
    color: var(--c_text-error);
  }
  &.utilization-high {
    color: var(--c_text-status-notice);
  }
  .tooltip-content {
    white-space: normal;
    line-height: 18px;
  }
}
