@import "../../../styles/variables";

.xw-insights-compare-page {
  padding: 0;
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
  .baseline-section {
    width: 100%;
  }

  .baseline-fields-section {
    width: 100%;
    height: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 50% 50%;
    grid-template-columns: 33% 33% 33%;
    place-items: stretch;
    place-content: stretch;

    .xw-insights-compare-model {
      grid-area: 1/1/2/2;
    }
    .xw-insights-compare-location {
      grid-area: 1/2/2/2;
    }
    .xw-insights-compare-serial {
      grid-area: 1/3/2/3;
    }
    .xw-insights-compare-ipAddress {
      grid-area: 2/1/2/2;
    }
    .xw-insights-compare-osVersion {
      grid-area: 2/2/2/2;
    }
  }

  .xw-device-comparison-select-table {
    height: 100%;
    .xw-data-table {
      height: 100%;
    }
  }
}
