@import "../../../../styles/variables";

.xw-contact-sales {
  textarea,
  .xw-textarea {
    width: 100%;
    &.text-error {
      textarea {
        color: var(--c_text-error);
      }
    }
  }
  .xw-form {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  h6 {
    padding-bottom: $unit;
  }
  .xw-checkbox {
    padding-bottom: $unit;
  }
  .content {
    padding: $unit * 2 $unit * 2 0 $unit * 2;
  }
}
