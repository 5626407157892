@import "../../styles/variables";

.xw-bucket-chart {
  position: relative;
  $trackHeight: 33px;
  user-select: none;
  height: 260px; // Override to adjust chart height
  display: flex;
  width: 100%;

  &.selectible.single {
    .bar {
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .section {
    position: relative;
    flex: 0 0 200px;
    background: var(--c_tlbucketchart-legend-background);
    border: 1px solid var(--c_tlbucketchart-border);
    box-sizing: border-box;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  .chart-wrapper {
    position: relative;
    flex: 1 1 100%;
  }

  .timeframe-select {
    height: 30px;
    display: flex;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    align-items: center;
    margin: 0 6px;
    overflow: hidden;
    cursor: default;

    &.disabled {
      opacity: 0.25;
      filter: blur(1px);
      pointer-events: none;
    }

    .title {
      flex: 0 0 auto;
      padding: 0 12px 0 6px;
      margin: 0 6px 0 0;
      line-height: 16px;
      font-size: 13px;
      border-right: solid 1px var(--c_tlbucketchart-border);
    }

    .btn,
    .xw-dateTime .custom-date-button .children {
      flex: 0 1 auto;
      min-width: 0;
      padding: 3px 6px;
      line-height: 16px;
      font-size: 13px;
      color: var(--c_tlbucketchart-action);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        color: var(--c_tlbucketchart-action-hover);
      }

      &.active {
        pointer-events: none;
        color: var(--c_tlbucketchart-action-active);
      }
    }
  }

  .spacer {
    flex: 1 1 0;
    margin: 0 6px;
    width: 100%;
    min-width: 0;
    overflow: hidden;
  }

  .line-spacer {
    flex: 1 1 0;
    border-top: dashed 1px var(--c_tlbucketchart-border);
    margin: 0 6px;
    width: 100%;
    min-width: 0;
    overflow: hidden;
  }

  .chart {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .track {
      width: 100%;
      fill: var(--c_tlbucketchart-track-bg);
      stroke: var(--c_scrollbar-thumb-large-hover);
      stroke-width: 1px;
      shape-rendering: crispEdges;
    }

    .dot-line,
    .arrow-line,
    .dot-line-error {
      rect:hover {
        stroke-width: 3px;
      }
    }

    .dot-line {
      .circle {
        fill: var(--c_tlbucketchart-tlpoint-dotline-circle);
      }

      &.selected .line {
        fill: var(--c_tlbucketchart-tlpoint-dotline);
      }
      .line {
        width: 1px;
        fill: var(--c_tlbucketchart-tlpoint-dotline);
      }

      &.error {
        .circle {
          fill: var(--c_tlbucketchart-tlpoint-dotline-error);
        }

        .line {
          fill: var(--c_tlbucketchart-tlpoint-dotline-error);
        }
      }
    }

    .rectangular-dot-line {
      position: relative;
      fill: var(--c_tlbucketchart-tlpoint-rectangularDotLine);

      .rectangle.top {
        transform: translate(-5px, -3px);
      }

      .rectangle.bottom {
        transform: translate(6.5px, #{$trackHeight + 3}) rotate(180deg);
      }

      .line {
        width: 1px;
      }
    }

    .arrow-line {
      position: relative;
      fill: var(--c_tlbucketchart-tlpoint-arrowline);

      .line {
        width: 1px;
      }

      .wedge.top {
        transform: translate(-5px, -3px);
      }
      .wedge.bottom {
        transform: translate(6.5px, #{$trackHeight + 3}) rotate(180deg);
      }
    }

    .reg-line {
      fill: var(--c_tlbucketchart-tlpoint-line);
      width: 1px;
    }

    .arrow-select-line {
      stroke: var(--c_tlbucketchart-arrow-select);
      stroke-dasharray: 1 2;
      stroke-width: 1px;
    }

    .arrow-select {
      .wedge {
        fill: var(--c_tlbucketchart-arrow-select);
      }
      .highlight {
        fill: var(--c_tlbucketchart-arrow-select);
        stroke: var(--c_tlbucketchart-arrow-select);
        opacity: 0.07;
        shape-rendering: crispEdges;
      }
    }

    .red-background {
      fill: var(--c_tlbucketchart-tlpoint-dotline-error);
    }

    .black-lines {
      fill: var(--c_tlbucketchart-legend-dossierErrors-diagonalStripes);
    }

    .bar {
      opacity: 0.6;
      shape-rendering: crispEdges;
      &.top {
        fill: var(--c_tlbucketchart-data-top);
        stroke: var(--c_tlbucketchart-data-top);
      }
      &.bottom {
        fill: var(--c_tlbucketchart-data-bottom);
        stroke: var(--c_tlbucketchart-data-bottom);
      }
      &.error {
        fill: url(#diagonal-stripes);
        stroke: var(--c_text-error);
        pointer-events: none !important;
      }

      &.selected {
        opacity: 1;
        fill-opacity: 0.35;
        &.error {
          stroke: var(--c_text-error);
        }
      }
    }

    .select-line {
      stroke-width: 1px;
      stroke: var(--c_tlbucketchart-select-line);
      shape-rendering: crispEdges;
      &.hover {
        stroke-width: 1px;
      }
    }

    .select-overlay {
      height: calc(100% - 30px);
      fill: var(--c_tlbucketchart-overlay);
    }
  }

  .data-legends {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 20;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: $unit $unit * 2 $unit $unit * 3;

    .title {
      font-size: $ptToEm * 16;
      line-height: 18px;
      font-weight: 700;
      color: var(--c_tlbucketchart-axis-text-primary);
    }
    .tooltip-content {
      display: flex;
      flex-direction: column;
      width: 200px;
    }

    .subtitle {
      font-size: $ptToEm * 12;
      font-weight: 500;
      color: var(--c_tlbucketchart-axis-text-secondary);

      .numbers {
        padding-left: calc($unit / 4);
        font-weight: 700;
        color: var(--c_tlbucketchart-axis-text-primary);
      }
    }

    .change-breakdown-wrapper {
      margin: calc($unit / 2) $unit * 1.5 0 0;
      padding-top: calc($unit / 2);
      border-top: 1px var(--c_tlbucketchart-border) solid;
    }

    .change-breakdown {
      display: flex;
      font-size: $ptToEm * 12;
      font-weight: 500;
      align-items: center;
      padding: 1px 0;

      &:before {
        content: "";
        display: block;
        width: calc($unit/2);
        height: calc($unit/2);
        background-color: #ffffff;
        margin-right: calc($unit / 4);
        flex: 0 0 auto;
      }

      .label {
        margin-right: calc($unit / 2);
        white-space: nowrap;
        overflow: hidden;
        min-width: 1;
        text-overflow: ellipsis;
        flex: 1 1 auto;
      }
      .value {
        color: var(--c_tlbucketchart-axis-text-primary);
        font-weight: 700;
      }
    }

    .top-series {
      .title {
        color: var(--c_tlbucketchart-data-top);
      }

      .change-breakdown:before {
        background-color: var(--c_tlbucketchart-data-top);
      }
    }

    .bottom-series {
      .title {
        color: var(--c_tlbucketchart-data-bottom);
      }

      .change-breakdown:before {
        background-color: var(--c_tlbucketchart-data-bottom);
      }
    }
  }

  .section.start .y-axis {
    right: 0;
    background: var(--c_tlbucketchart-y-legend-background);
    border-left: 1px solid var(--c_tlbucketchart-border);
  }

  .section.end .y-axis {
    left: 0;
    background: var(--c_tlbucketchart-y-legend-background);
    border-right: 1px solid var(--c_tlbucketchart-border);
  }

  .y-axis {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 0;
    width: 45px;

    .spacer {
      flex: 0 0 auto;
    }

    .axis {
      position: relative;
      z-index: 1;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: center;

      &.top {
        color: var(--c_tlbucketchart-data-top);

        .axis-val {
          opacity: 0.75;
          &:first-child {
            opacity: 1;
          }
          &:last-child {
            opacity: 0.5;
          }
        }
      }

      &.bottom {
        color: var(--c_tlbucketchart-data-bottom);
        .axis-val {
          opacity: 0.75;
          &:first-child {
            opacity: 0.5;
          }
          &:last-child {
            opacity: 1;
          }
        }
      }
    }
  }

  .timeline-series-wrapper {
    position: relative;
    z-index: 1;
    position: absolute;
    top: $unit;
    right: $unit * 2;
    bottom: $unit;
    left: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .timeline-series {
      flex: 0 0 0;
      display: flex;
      align-items: center;

      .value {
        font-size: $ptToEm * 17;
        font-weight: 300;
      }

      .label {
        font-size: $ptToEm * 11;
        color: var(--c_tlbucketchart-axis-text-primary);
      }

      &.dot-line {
        color: var(--c_tlbucketchart-tlpoint-dotline);
      }

      &.dot-line-error {
        color: var(--c_tlbucketchart-tlpoint-dotline-error);
      }

      &.line {
        color: var(--c_tlbucketchart-tlpoint-line);
      }

      &.none {
        color: var(--c_tlbucketchart-data-top);

        .dossier-icons-container {
            display: flex;
            flex-direction: column;
        }
      }

      &.arrow-line {
        color: var(--c_tlbucketchart-tlpoint-arrowline);
      }

      &.rectangular-dot-line {
        color: var(--c_tlbucketchart-tlpoint-rectangularDotLine);
      }

      .xw-icon {
        width: 26px;
        height: 26px;
        // margin-right: $unit / 4;
      }
    }
  }

  .pager {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: 24px;
    background-color: var(--c_button-secondary);
    border: 1px var(--c_button-secondary-border) solid;

    .xw-icon {
      position: relative;
    }

    &.disabled {
      pointer-events: none;

      .xw-icon {
        fill: var(--c_button-secondary-border);
      }
    }

    &.prev {
      left: 0;
      .xw-icon {
        left: -1px;
        transform: rotate(90deg);
      }
    }

    &.next {
      right: 0;
      .xw-icon {
        left: 1px;
        transform: rotate(-90deg);
      }
    }

    &:hover {
      background-color: var(--c_button-secondary-hover);
      .xw-icon {
        fill: var(--c_tlbucketchart-pager-icon-hover);
        transition: all 0s;
      }
    }
  }

  .reset-selection-section {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    z-index: 1;

    button {
      display: block;
      overflow: visible;
    }
  }

  .selection-controls {
    position: absolute;
    top: calc(50% - #{$unit});
    margin: 0;
    z-index: 100;

    .btn {
      box-sizing: border-box;
      position: absolute;
      left: calc(50% - #{$unit});
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: $unit * 2;
      width: $unit * 2;
      border-radius: 100px;
      background-color: var(--c_tlbucketchart-select-bg);
      border: 1px var(--c_tlbucketchart-select-line) solid;
      cursor: col-resize;

      &.transparent {
        pointer-events: none;
      }

      .xw-icon {
        position: relative;
        left: -1px;
      }

      &:hover {
        background-color: var(--c_tlbucketchart-select-line-hover);
        border-color: var(--c_tlbucketchart-select-line-hover);
        .xw-icon {
          fill: var(--c_tlbucketchart-select-bg);
        }
      }
    }
  }
}
