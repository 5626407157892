@import "../../styles/variables";
.xw-asn-traffic-table {
  display: flex;
  flex-flow: column nowrap;
  height: 50%;
  > .filters {
    display: flex;
    flex-flow: row nowrap;
    margin: $unit $unit * 1.5;
    > .xw-device-or-device-group-select {
      margin-right: $unit * 1.5;
    }
    > .spacer {
      flex-grow: 1;
    }
    .xw-trafficDatePicker {
      width: 382px;
    }
    > .xw-button {
      margin-right: $unit * 1.5;
      &:last-child {
        margin-right: unset;
      }
    }
  }
  > .xw-data-table {
    flex-grow: 1;
  }
}
