@import "../../styles/variables";

.xw-policies-page {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .xw-table {
    flex: 1 1 100%;
  }
  .policy-table-container {
    height: 100%;
  }
}

.createPolicyModal-modal-wrapper {
  .types {
    display: flex;
    flex-direction: column;
    padding: $unit * 2;
    .type {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .create-policy {
        .title {
          color: var(--c_ui-title);
        }
        .xw-button {
          min-width: 140px;
        }
      }

      &:first-child {
        header {
          margin-top: 0;
        }
      }
    }
    .rules {
      .rule {
        line-height: 18px;

        &::before {
          content: "-";
          display: inline-block;
          padding: 0 calc($unit / 2);
        }
      }
    }
  }
}

#linkPrefixesModal,
#linkPeersModal,
#linkAsnsModal {
  height: 80%;

  .xw-data-table {
    height: 100%;
    padding-top: 12px;
  }
}
