@import "../../../../styles/variables";
.xw-observe-running-modal {
  .xw-modal {
    .modal-dialog {
      width: 1024px;
      overflow-x: hidden;
        .info-banner {
          background-color:#FFAD53;
          color: black;
          padding: 10px 0px 10px 24px;
        }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.xw-observed-running {
  .fields {
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: $unit ($unit * 2) 0 ($unit * 2);
    .xw-label-value {
      margin-bottom: 0;
    }
    &.hash {
      .xw-label-value {
        .value {
          .hash-mismatch {
            color: var(--c_text-emphasis);
          }
          .hash-unknown {
            display: flex;
            .xw-icon {
              fill: var(--c_text-emphasis);
              border: var(--c_text-emphasis);
              margin-right: 2px;
            }
          }
        }
      }
    }
  }
  .hash-mismatch {
    display: inline-flex;
    align-items: center;
    color: var(--c_text-emphasis);
    .value {
      color: var(--c_text-emphasis);
    }
    .compare-hash {
      flex: 0 1 50%;
    }
  }
  .hash-match {
    display: inline-flex;
    align-items: center;
    .compare-hash {
      flex: 0 1 50%;
    }
  }
  .checkbox-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px var(--c_device-capabilities-check-active) solid;
    border-radius: 100px;
    box-sizing: border-box;
    margin-right: calc($unit/3);
    &.large {
      width: 16px;
      height: 16px;
    }
    &.small {
      width: 12px;
      height: 12px;
    }
    .xw-icon {
      fill: var(--c_device-capabilities-check-active);
      transition: all 0.15s ease;
      &.large {
        width: 16px;
        height: 16px;
      }
      &.small {
        width: 12px;
        height: 12px;
      }
    }
    &.mismatch-icon,
    &.mismatch {
      border: 1px var(--c-table-switch-active-fill) solid;
      background-color: var(--c_text-emphasis);
      .xw-icon {
        fill: var(--c-table-switch-active-fill);
        padding: 2px;
        &.large {
          width: 16px;
          height: 16px;
        }
        &.small {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .xw-page-section {
    margin-bottom: 20px;
    .header {
      display: flex;
      color: var(--c_page-header-title);
      padding: $unit ($unit * 2) 0 ($unit * 2);
      margin-bottom: 0;
      .sub-title {
        padding-left: $unit;
        align-items: center;
        display: flex;
        font-size: 16 * $ptToEm;
        line-height: 22 * $ptToEm;
        font-weight: 300;
        .unknown-icon {
          border: 1px var(--c-table-switch-active-fill) solid;
          .xw-icon {
            fill: var(--c_text-emphasis);
            margin-right: 2px;
          }
        }
      }
    }
  }
  .stats {
    display: flex;
    flex-direction: row;
    padding: 0 ($unit * 2);
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    .xw-label-value {
      display: flex;
      flex-direction: row;
      margin-right: 12 * $ptToEm;
      margin-bottom: 12 * $ptToEm;
      align-items: center;
      label {
        color: var(--c_page-header-title);
        font-size: 16 * $ptToEm;
        line-height: 22 * $ptToEm;
        font-weight: 300;
        text-transform: none;
        &:after {
          content: "(";
        }
      }
      &:after {
        content: ")";
      }
      .value {
        font-weight: bold;
      }
      &.observed-value {
        .value {
          color: var(--c_section-header-emphasis);
        }
      }
      &.mismatch-count {
        font-size: 16 * $ptToEm;
        line-height: 22 * $ptToEm;
        font-weight: 300;
        label {
          color: var(--c_page-header-title);
          &:after {
            color: var(--c_page-header-title);
            content: "(";
          }
        }
        &:after {
          content: ")";
          color: var(--c_page-header-title);
        }
        .value {
          color: var(--c_text-emphasis);
        }
      }
    }
  }
  .mismatches {
    display: flex;
    flex-direction: row;
    color: var(--c_text-status-disabled);
    font-size: 16 * $ptToEm;
    line-height: 22 * $ptToEm;
    font-weight: 300;
    .value {
      color: var(--c_text-emphasis);
    }
  }
  .xw-data-table {
    height: 250px;
    .running-hash-container {
      display: flex;
    }
  }
}
