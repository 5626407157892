@import "variables";

@font-face {
  font-family: "CiscoSans";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/CiscoSansTTLight.woff2") format("woff2"),
    url("../assets/fonts/CiscoSansTTLight.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: "CiscoSans";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/CiscoSansTTRegular.woff2") format("woff2"),
    url("../assets/fonts/CiscoSansTTRegular.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: "CiscoSans";
  font-style: bold;
  font-weight: 700;
  src: url("../assets/fonts/CiscoSansTTBold.woff2") format("woff2"),
    url("../assets/fonts/CiscoSansTTBold.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
