@import "../../styles/variables";

.xw-cdg-container-image {
  .container-image-missing {
    .value {
      color: var(--c_text-error);
      font-weight: bold;
    }
  }
  .container-image-mismatch {
    .value {
      color: var(--c_text-status-notice);
      font-weight: bold;
    }
  }
}
