@import "../../../styles/variables";

.xw-system-settings {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .xw-page-header {
    position: relative;
    height: 100%;
  }
  .xw-entitlement {
    display: flex;
    flex-direction: column;
    .toggle {
      display: flex;
      flex: 0 0 auto;
    }
    .xw-toggle {
      flex: 0 0 600px;
      padding-top: 2 * $unit;
    }

    .xw-device-count {
      &.with-override {
        color: var(--c_text-link);
      }
    }

    .xw-tooltip-content {
      white-space: initial;
    }
  }
  .ta,
  .ti,
  .ni {
    padding-top: $unit * 2;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    .top {
      display: flex;
      flex-direction: row;
      flex: 1;
      .content {
        flex-basis: 40%;
      }
      .donut {
        justify-content: center;
        display: flex;
        flex-direction: row;
        .content {
          padding-right: 2 * $unit;
        }
      }
    }
    .table-content {
      flex: 1 1 100%;
      position: relative;
      left: -24px;
      width: calc(100% + 48px);
    }
    .xw-data-table {
      height: 100%;
    }
  }
  .ti {
    .advanced {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-left: $unit;
      .title {
        color: var(--c_modal-header-text);
        padding-bottom: $unit;
      }
      .content {
        line-height: 18px;
        font-size: 12 * $ptToEm;
        padding-bottom: calc($unit / 2);
      }
      .button {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .coming-soon {
    color: var(--c_coming-soon);
    font-size: 24 * $ptToEm;
    line-height: 36 * $ptToEm;
    padding-bottom: $unit;
  }
  .xw-purchase-tab {
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .actions {
      display: flex;
    }
    .instructions {
      display: block;
      line-height: 22px;
      align-items: center;
      .description {
        color: var(--c_text-title);
        padding-bottom: $unit * 2;
        line-height: 20px;
        max-width: 85%;
      }
      .opener-container {
        display: inline-block;
      }
      button {
        margin: unset;
        height: unset;
        padding: 0 0 0 2px;
      }
    }
    .table-content {
      flex: 1 1 100%;
      position: relative;
      left: -24px;
      width: calc(100% + 48px);
    }
    .xw-data-table {
      height: 100%;
    }
  }
  .not-licensed {
    display: flex;
    flex-direction: column;
    .email-link {
      color: var(--c_text-link);
    }
    .caption {
      color: var(--c_not-licensed);
      font-size: 24 * $ptToEm;
      line-height: 36 * $ptToEm;
      padding-bottom: $unit;
    }
    .trial-content {
      width: 70%;
      line-height: 19 * $ptToEm;
      padding-bottom: $unit;
    }
  }
  .ira {
    display: flex;
    flex-direction: column;
    padding-top: $unit * 2;
    width: 70%;
    .soon-content {
      line-height: 19 * $ptToEm;
    }
  }
}
.start-trial {
  display: flex;
  flex-direction: column;
  .xw-checkbox {
    margin-bottom: 12px;
    a {
      color: var(--c_text-link);
    }
  }
}

.xw-ta-unclaim {
  .header {
    line-height: 20 * $ptToEm;
    font-size: 16 * $ptToEm;
    padding-bottom: $unit * 2;
    .content {
      color: var(--c_text-title);
    }
  }
  .xw-textarea,
  textarea {
    width: 100%;
    &.content {
      textarea {
        padding-top: $unit * 2;
        color: var(--c_text-title);
        font-size: 16 * $ptToEm;
      }
    }
  }
}

.xw-purchase-confirm {
  .content {
    padding: $unit * 2;
    line-height: 20px;
  }
}

.xw-claim-banner {
  .notification-info {
    flex-direction: row;
    .notification-title {
      display: unset;
      font-size: unset;
    }
    .notification-description {
      display: unset;
      font-size: unset;
    }
  }
}
#unclaimSubscriptionsModal {
  .unclaim-content {
    p {
      line-height: 20 * $ptToEm;
    }
  }
}
