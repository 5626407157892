@import "../../styles/variables";

// This needs to be converted to use flex so we can get rid of height.
.xw-welcome {
  &.side-bar-margin {
    margin-left: 0;
    margin-top: 0;
  }
  margin-left: #{$sidebar-width};
  margin-top: $header-height;
  height: calc(100vh - #{$header-height});
  .xw-stepwizard {
    .xw-page-header {
      border-bottom: 1px solid var(--c_section-header-separator);
    }
    .label-content {
      color: var(--c_value-text);
      line-height: 18px;
      padding-bottom: 18px;
    }
    .grey {
      color: var(--c_welcome_grey);
    }
    .form-field {
      width: 40%;
      min-width: 40%;
    }
  }
}
