@import "../../../styles/variables";

.xw-alarm-overview {
  .xw-stale-alarm-banner {
    .notification-info {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 4 * $ptToEm;
    }
    .notification-title {
      justify-content: unset;
      font-size: 0.875rem;
      line-height: unset;
      span {
        padding-top: 0;
      }
    }
  }
}

.xw-alarm-overview-card {
  .title {
    display: flex;
    align-items: center;
    flex-flow: row;
    .left {
      flex-grow: 1;
    }
    .xw-label-value {
      margin-bottom: 0;
      text-align: end;
    }
  }
  .capacity-container {
    display: flex;
    margin: 14px 0px;
    gap: 4px;
  }
  .content {
    .entity-name > span{
      color: var(--c_text);
      font-weight: bold;
      margin-left: $unit * .5;
    }
  }

  .value-group {
    .xw-label-value {
      display: inline-block;
      padding-right: 2 * $unit;
    }
  }

  .sub-header {
    display: flex;
    color: var(--c_card-subtitle-text);
    font-size: 14 * $ptToEm;
    // line-height: 19 * $ptToEm;
    padding-top: calc($unit / 3);
    .xw-label-value {
      margin-bottom: 0;
    }
    .link-label {
      white-space: nowrap;
    }
    a,
    .link {
      padding-left: calc($unit / 2);
      color: var(--c_card-link);
      font-size: 14px;
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.xw-new-alarm-details {
  .xw-page-header {
    .title-section {
      .xw-alarm-status-value {
        display: flex;
        gap: 5px;
        align-items: baseline;
        .time {
          font-size: 0.75rem;
          color: var(--c_textInput-value);
          font-weight: 350;
        }
      }
      .sub-title-section {
        .sub-title {
          text-transform: none;
        }
      }
    }
  }

  .alarm-overview.xw-page-section {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;

    header {
      margin: 0 0 $unit * 2 0;
      flex: 0 0 auto;
    }

    > .content {
      flex: 1 1 100%;
      position: relative;
      display: flex;
    }
  }

  .xw-card-list {
    .xw-card {
      .title {
        > h3 {
          color: var(--c_card-subtitle-text);
          font-size: 14 * $ptToEm;
          line-height: 19 * $ptToEm;
        }
      }
    }
  }

  .xw-card {
    .title {
      .xw-label-value {
        label {
          color: var(--c_card-subtitle-text);
        }
        .value, span {
          color: var(--c_card-title-text);
        }
      }
    }
  }

  .xw-alarm-overview {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    left: -24px;
    width: calc(100% + 48px);

    .fields {
      display: flex;
      flex-flow: row wrap;
      .xw-label-value {
        flex-basis: 25%;
      }
    }
    .meta {
      flex: 0 0 auto;
      padding: $unit * 2 $unit * 2 0 $unit * 2;
      display: flex;
      justify-content: flex-start;

      .xw-label-value {
        margin-left: $unit * 3;

        &:first-child {
          margin-left: 0;
        }

        .xw-level {
          .high-level-icon {
            margin-top: calc($unit / 4);
          }
        }
      }
    }
    .alarm-cards {
      display: grid;
      flex-flow: row wrap;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      box-sizing: border-box;
      place-items: stretch;
      place-content: stretch;
      grid-gap: 12px;
      margin: 0;
      width: 100%;
      padding: 0 $unit * 2 $unit * 2;

      &.only-expected {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0;
      }
      .content {
        padding: 0;
        .custom-filter-controls-section {
          padding: 0;
        }
      }
      .xw-card {
        margin: 0;
      }

      .xw-valuesWithTooltip {
        .xw-tooltip-trigger {
          display: inline;
        }
      }

      .expected {
        grid-area: 1/1/1/1;

        .xw-data-table {
          height: 100%;
        }

        .padded-label-content {
          padding: 12px 24px;

          > div {
            padding-bottom: $unit;
          }
        }
      }

      .observed {
        grid-area: 1/2/1/2;

        .xw-data-table {
          height: 100%;
        }

        .padded-label-content {
          padding: 12px 24px;

          > div {
            padding-bottom: $unit;
          }
        }
      }
    }
  }

  .additional-info-title {
    padding: 0 0 12px 24px;
    .title {
      color: var(--c_section-header-emphasis);
      font-size: 1.25rem;
      line-height: 2.0625rem;
      font-weight: 300;
    }

    .entity-name > span{
      color: var(--c_text);
      font-weight: bold;
      margin-left: $unit * .5;
    }
  }

  .table-section {
    .table-title {
      background-color: var(--c_card-title-bg);
      color: var(--c_card-title-text);
      font-size: 1.25rem;
      font-weight: 300;
      padding: 12px 24px;
      height: 66px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }
    .custom-filter-controls-section {
      padding: 0;
    }
  }

  .xw-new-alarms-observed {
    color: var(--c_modification_Delete);
    .xw-bytes-text .value {
      color: var(--c_modification_Delete);
    }
  }

  .xw-alarm-history {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    left: -24px;
    width: calc(100% + 48px);
    .xw-data-table {
      display: flex;
      position: relative;
      flex-flow: column nowrap;
      overflow: hidden;
      height: 100%;
    }
  }
  .alarm-details-additional-info-table {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    .xw-data-table {
      display: flex;
      position: relative;
      flex-flow: column nowrap;
      overflow: hidden;
      height: 100%;
    }
  }
}
