@import "../../styles/variables";

.xw-peer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 0;

  .peer-fields {
    overflow-y: auto;
    flex-grow: 1;
    margin-bottom: $footerHeight;
    .name-field {
      margin-top: $unit;
    }

    .xw-page-section header .actions > .xw-button {
      min-width: 146px;
    }

    .xw-select {
      flex: 1 1 100%;
      margin: 0;
      width: 400px;
      height: auto;

      &:first-child {
        margin-right: $unit;
      }
    }

    .xw-page-header {
      z-index: 10;
    }

    .xw-input-field {
      position: relative;
      margin-right: $unit * 0.5;
      width: 400px;
    }

    .xw-textarea {
      display: flex;
      .label-guard {
        background: transparent;
      }
      > textarea {
        width: 400px;
      }
    }
  }
}
