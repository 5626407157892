@import "../../../../styles/variables";

.xw-aws-error {
  padding-top: 70 * $ptToEm;
  align-items: flex-start;
  .content {
    width: 60%;
  }
  .header {
    font-size: 18 * $ptToEm;
    line-height: 23 * $ptToEm;
    padding-bottom: calc($unit/2);
    &.error {
      padding-top: 32 * $ptToEm;
    }
  }
  .aws-landing-request {
    .description {
      line-height: 36 * $ptToEm;
      .aws-redirect {
        padding-top: 12 * $ptToEm;
      }
    }
  }
  .copyright {
    width: 70%;
  }
  .help {
    display: flex;
    flex-direction: column;
    width: 60%;
    .description {
      font-size: 12 * $ptToEm;
    }
    padding-bottom: $unit * 3;
  }
  .footer {
    padding-top: $unit;
  }
}
