@import "../../styles/variables";
.xw-data-table > .header {
  height: $unit * 4;
  background-color: var(--c-table-header);
  color: var(--c-table-header-text);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--c_data-table-row-border);
  border-bottom: 1px solid var(--c_data-table-row-border);

  .col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    height: 100%;
    min-width: 0;
    margin-right: $unit;

    &:nth-last-child(2) {
      .resizer {
        pointer-events: none;
        padding-right: 0;
      }
      margin-right: 0;
    }

    &.select {
      flex: 0 0 50px;
      display: flex;
      align-items: center;
    }

    &:first-child {
      padding-left: $unit * 1.5;
      &.select {
        padding: 0;
      }
    }

    &.expander {
      flex: 0 0 48px;
    }

    &.sortable {
      .col-wrapper-primary {
        &:hover {
          cursor: pointer;
          color: var(--c_text-hover);
        }
      }
    }
    .xw-dropdown-menu {
      transform: translate(-18px, 9px);
    }
    .subLabel {
      font-size: 12 * $ptToEm;
      color: var(--c_text);
      font-weight: 400;
    }
  }

  .col-wrapper-primary {
    position: relative;
    top: 2px;
    flex: 0 0 auto;
    display: flex;
    font-size: $ptToEm * 14;
    font-weight: 700;
    line-height: 17px;
    width: calc(100% - 37px);
    min-width: 0;

    .sort-icon {
      flex: 0 0 auto;
      margin-left: calc($unit / 2);
      width: 12px;
      height: 12px;
      fill: var(--c-table-sort-inactive);

      &.sorted {
        &.asc {
          path:nth-child(2) {
            fill: var(--c-table-sort-active);
          }
        }
        &.desc {
          path:nth-child(1) {
            fill: var(--c-table-sort-active);
          }
        }
      }
    }

    &:hover {
      .sort-icon {
        path:nth-child(1) {
          fill: var(--c_icon-hover);
        }

        &.sorted {
          path {
            fill: inherit;
          }
        }
      }
    }
  }

  .col-wrapper-secondary {
    flex: 0 1 auto;
    width: calc(100% - 37px);
    overflow: hidden;
    display: flex;
    font-size: $ptToEm * 11;
    font-weight: 500;
    line-height: 14px;
    min-width: 0;
    margin-top: 3px;
    white-space: nowrap;

    button:focus {
      color: var(--c_data-table-filter-action-hover);
      text-decoration: underline;
      outline: none;
    }

    .col-sub-label {
      flex: 0 1 auto;
      min-width: 0;
      margin-right: calc($unit / 2);
      color: var(--c_data-table-text-sub);
    }

    .filter-action {
      cursor: pointer;
      &:hover {
        color: var(--c_data-table-filter-action-hover);
      }
    }

    .btn-add-filter {
      color: var(--c_data-table-text-add);
    }

    .btn-edit-filter {
      color: var(--c_data-table-text-edit);
    }
  }

  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 calc($unit / 2);
    cursor: ew-resize;
    .separator {
      height: 100%;
      width: 1px;
      background-color: var(--c_data-table-row-border);
      padding: 0;
    }

    &:hover {
      .separator {
        background-color: var(--c_data-table-divider-hover);
      }
    }
  }

  .menu-button > .xw-dropdown-menu,
  .table-menu > .xw-dropdown-menu {
    z-index: 101;
    cursor: default;
    max-height: 300px;
    overflow: auto;
    overflow-wrap: anywhere;
    white-space: normal;

    > nav {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      > button {
        width: 100%;
        height: $unit * 2;
      }
      .filter {
        > label {
          display: block;
          margin-bottom: calc($unit / 2);
        }
        .xw-input-field {
          // NOTE: we should probably change abs positioning to flexbox everywhere eventually, but I didn't want
          //       to test every single input on the app for a minor bug fix. Let's start here
          display: flex;
          flex-flow: column nowrap;
          > .hint,
          > .error {
            position: static;
            margin-top: calc($unit / 2);
          }

          margin-bottom: 0;
          min-width: 64px;
        }

        .xw-range-selector {
          margin: $unit $unit calc($unit / 2) $unit;
          height: $unit * 2;
        }
        .xw-select {
          min-width: 96px;
          width: 100%;
        }
      }
      .reset {
        font-size: 12 * $ptToEm;
        float: right;
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
    }
  }

  .xw-dropdown-menu-button {
    .xw-icon {
      fill: var(--c-table-icons);
    }
  }
}
