@import "../../styles/variables";

.xw-chart-brush {
  pointer-events: all;
  cursor: col-resize;
  rect.selection-area {
    fill: none;
  }
  line.select-bound {
    stroke: var(--c_chart-select-marker);
    stroke-width: 2px;
    stroke-dasharray: 1 3;
  }
  line.select-hover {
    stroke: var(--c_chart-dual-brush-handle-hover);
    stroke-width: 1px;
  }
  rect.select-range {
    fill: var(--c_chart-brush-select-area);
    pointer-events: all;
    cursor: grab;
  }
  polygon.select-marker {
    fill: var(--c_chart-select-marker);
  }
  rect.select-rect {
    fill: none;
  }
}
