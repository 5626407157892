@import "../../../styles/variables";

.asn-looking-glass-section {
  position: relative;
  left: -2 * $unit;
  width: calc(100% + 48px);

  .xw-asnLookingGlassModeToggle {
    padding-right: 2 * $unit;
  }

  .xw-data-table {
    height: 100%;
  }
}
