@import "../../../styles/variables";

.xw-prefixes-details {
  .status-invalid {
    cursor: help;
  }
  .xw-prefix-overview {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px 0;
    position: relative;
    .fields {
      display: flex;
      flex-flow: row nowrap;
      .xw-label-value {
        min-width: 1px;
        margin-left: $unit * 3;
        margin-bottom: 0;

        &:first-child {
          margin-left: 0;
        }
        .last-active-alarm {
          display: flex;
          > a {
            padding-left: calc($unit / 2);
            margin-top: 1px;
            color: var(--c_text-link);
          }
        }
      }
    }
    .alarm-level-name {
      display: flex;
      > a {
        padding: 0 6px;
        font-size: 13 * $ptToEm;
        line-height: 100%;
        color: var(--c_text-link);
      }
      > div {
        width: 6px;
        height: 12px;
        align-items: flex-end;
        border-radius: 1px;
      }
    }
    .spacer {
      flex-grow: 1;
      height: 1px;
      margin: $unit * 2 0;
      border-bottom: 1px dashed var(--c_section-header-separator);
    }
    .xw-data-table {
      height: 100%;
    }
  }
  .xw-card-list {
    flex-wrap: nowrap;

    .xw-card {
      height: 300px;
      .title {
        > h3 {
          color: var(--c_card-subtitle-text);
          font-size: 14 * $ptToEm;
          line-height: 19 * $ptToEm;
        }
      }
    }
    .xw-whois-asData-card,
    .xw-whois-summary-card {
      display: flex;
      flex: 1 1 25%;
      .prefix-whois-loader {
        position: absolute;
        left: calc(50% - 50px); // processing loader is 100px in width
        top: calc(50% - 14px); // processing loader is approx 28px in height
      }
    }

    .xw-whois-summary-card {
      .geo-location-field {
        min-width: 1px;
        display: flex;
        label {
          display: block;
          font-size: 12 * $ptToEm;
          color: var(--c_textInput-label);
          text-transform: capitalize;
        }
        .label-value {
          font-size: 15 * $ptToEm;
          font-weight: 300;
          line-height: 18 * $ptToEm;
          color: var(--c_textInput-value);
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .xw-tooltip {
          text-transform: none;
          width: 285px;
          padding: 6px 6px;
        }
        .xw-icon.small {
          padding: 2px 0 0 2px;
        }
        .gauge-wrapper {
          display: flex;
          margin-left: 5px;
          .main-value {
            font-size: 10 * $ptToEm;
          }
          .xw-gauge {
            width: 36px;
            height: 36px;
            .main-value {
              font-size: 10 * $ptToEm;
              transform: translate(1px, 10px);
            }
            .indicator {
              path {
                transform: translate(-4px, -18px) scale(0.75);
              }
            }
          }
        }
      }
    }
    .xw-geo-map-card {
      display: flex;
      flex: 2 2 50%;
      .content {
        padding: 0;
      }
    }

    .xw-whois-asData-card {
      .rpsl-link .value {
        color: var(--c_text-link);
      }
    }
  }
  .table-section {
    > .content {
      height: 300px;
      display: flex;
      flex-direction: column;
    }

    .table-title {
      background-color: var(--c_card-title-bg);
      color: var(--c_card-title-text);
      font-size: 1.25rem;
      font-weight: 300;
      padding: 12px 20px;
      height: 66px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      > h3 {
        color: var(--c_card-subtitle-text);
        font-size: 14 * $ptToEm;
        line-height: 19 * $ptToEm;
      }
    }
    .custom-filter-controls-section {
      padding: 0;
    }
    .xw-data-table {
      height: 100%;
      border-right: 1px var(--c-table-header) solid;
      border-left: 1px var(--c-table-header) solid;
    }
  }

  .prefix-alarms,
  .xw-bgpUpdates,
  .roa-details {
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    flex: 1 1 auto;
    .xw-activeAlarms-new-table,
    .xw-alarmAcknowledged-new-table {
      min-height: 0;
      flex: 1 1 100%;
      .xw-data-table {
        height: 100%;
        .actions {
          display: flex;
          margin-left: 8px;
          min-width: fit-content;
        }
      }
    }
  }

  .prefix-looking-glass-section .xw-data-table {
    left: -24px;
    top: -2px;
    width: calc(100% + 48px);
    height: 100%;
  }

  .prefix-rpsl-details {
    position: absolute;
    top: $unit * 5;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    .last-scan-opener {
      .xw-button {
        padding: 0;
        margin: 0;
        height: $unit * 2;
      }
    }
    .rpsl-table .xw-data-table {
      position: absolute;
      top: $unit * 5;
      right: 0;
      bottom: 0;
      left: -24px;
      width: calc(100% + 48px);
      display: flex;
      flex-flow: column nowrap;
      overflow: visible;
      .info-rpsl-icon {
        margin-left: 4px;
      }
      .tooltip-rpsl {
        width: 300px;
        white-space: normal;
        word-wrap: break-word;
        padding: 5px;
        text-overflow: ellipsis;
        line-height: 1;
        overflow: auto;
        font-weight: 100;

        .feature-item {
          margin-bottom: 5px;
        }   
      }
    }
    .rpsl-tab-loader {
      .xw-processing-loader {
        position: absolute;
        left: calc(50% - 50px);
        top: calc(50% - 14px);
      }
    }
  }

  .xw-topology-page {
    position: absolute;
    top: 29px;
    right: -24px;
    bottom: 0;
    left: -24px;
  }
}

.xw-prefix-edit {
  .xw-textarea {
    display: flex;
    > textarea {
      width: 100%;
    }
  }
}

.lastScanModal-modal-wrapper {
  .xw-modal .modal-dialog {
    height: 86%;
    .custom-filter-controls-section {
      padding: 0;
    }
  }

  .last-scan-table .xw-data-table {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
