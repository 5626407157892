@import "../../../styles/variables";

.xw-device-groups .xw-data-table {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: $unit;

  .table-actions > .xw-button {
    flex: 0 0 auto;
    width: 170px;
  }
}

.xw-manage-deviceGroups {
  .xw-deviceGroup-form {
    .field-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 $unit * 2;
      min-height: 0;
      flex: 1 1 auto;
      overflow-y: hidden;
      .xw-input-field {
        width: 50%;
      }
    }

    .xw-textarea,
    textarea {
      width: 100%;
    }
  }
}

.xw-deviceGroup-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    .xw-label-value {
      padding: 0 $unit * 2;
    }
    .xw-data-table {
      flex: 1 1 auto;
      margin-top: 12px;
    }
    .separator {
      height: 1px;
      margin: 0 $unit * 2;
      border-bottom: 1px dashed var(--c_section-header-separator);
    }
  }
}

.deviceGroupLinkDevicesModal-modal-wrapper {
  .modal-dialog {
    height: 86%;
  }

  .xw-data-table {
    flex: 1 1 auto;
  }
}

.deviceGroupAddInline-modal-wrapper {
  .modal-dialog {
    min-height: 653px;
  }

  .xw-deviceGroup-form {
    .field-wrapper {
      display: flex;
      flex-direction: column;
      padding: $unit * 2;
      min-height: 0;
      flex: 1 1 auto;
      overflow-y: hidden;
      .xw-input-field {
        width: 50%;
      }
    }

    .xw-textarea,
    textarea {
      width: 100%;
    }
  }
}
