@import "../../../styles/variables";

.xw-asn-routing {
  .action-section {
    background: var(--c_filter_box-bg);
    padding: calc($unit / 2) $unit * 2 0 $unit * 2;
    width: 250px;
    height: 48px;
    .xw-label-value {
      margin-bottom: 0;
    }
  }
  .asn-routing-summary {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px 0;
    position: relative;
    section {
      .actions {
        width: 230px;
      }
      .content {
        display: flex;
        flex-direction: column;
        .rir {
          display: flex;
          .xw-label-value {
            padding-right: $unit * 2;
          }
        }
        .no-data {
          font-size: 16 * $ptToEm;
          line-height: 18 * $ptToEm;
          padding-bottom: $unit * 2;
        }
        .xw-card-list {
          display: flex;
          flex-flow: row wrap;
          align-content: center;
          align-items: stretch;
          position: relative;
          left: -12px;
          width: calc(100% + 24px);
          .xw-card {
            .content {
              flex-direction: row;
              justify-content: space-between;
            }
          }
        }
        .summary-section {
          display: flex;
          flex-direction: row;
          &.as-peer {
            flex: 1 1 auto;
            .xw-donut-chart {
              width: 33%;
            }
          }
          .xw-label-value {
            padding-right: $unit * 4;
            &.exclude-capitalize {
              label {
                text-transform: none;
              }
            }
          }
          .xw-donut-chart {
            padding-right: $unit * 3;
          }
        }
      }
    }
  }
  .xw-asn-report-peer,
  .xw-asn-report-ip {
    display: flex;
    flex: 1 1 100%;
    flex-flow: column nowrap;
    position: relative;
    .xw-data-table {
      flex: 1 1 100%;
      position: absolute;
      top: 10px;
      right: 0;
      bottom: 0;
      left: -24px;
      width: calc(100% + 48px);
    }
  }
}
