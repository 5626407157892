@import "../../../../styles/variables";

.xw-sales-purchase {
  .header {
    font-size: 24 * $ptToEm;
    line-height: 49 * $ptToEm;
    color: var(--c_page-header-title);
  }
  .sub-title {
    color: var(--c_textInput-value);
    font-size: 15 * $ptToEm;
    line-height: 20 * $ptToEm;
    width: 40%;
  }
}
