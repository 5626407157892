@import "../../styles/variables";

.xw-subscribe-value {
  .subscribeEnum-label-ALREADY_SUBSCRIBED {
    color: var(--c_text);
  }
  .subscribeEnum-label-YES {
    color: var(--c_text-status-enabled);
  }
  .subscribeEnum-label-NO {
    color: var(--c_text-error);
  }
}
