@import "../../styles/variables";

.xw-notification-banner {
  align-items: center;
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  .notification-info {
    display: flex;
    flex: 30 0 0;
    flex-direction: column;
    color: var(--c_notification-info-elements-text);
    .notification-title {
      display: block;
      justify-content: center;
      font-size: 20 * $ptToEm;
      line-height: 23 * $ptToEm;
      > span {
        padding: 4px 5px 0 0;

        svg {
          width: 12px;
          height: 12px;
          fill: var(--c_notification-info-elements-text);
          transition: fill 0.15s ease;
        }
      }
    }
    .notification-description {
      display: flex;
      justify-content: center;
      font-size: 14 * $ptToEm;
      line-height: 16 * $ptToEm;
    }
  }
  .notification-close {
    display: flex;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
    svg {
      width: 10px;
      height: 10px;
      fill: var(--c_notification-info-elements-text);
      transition: fill 0.15s ease;
    }
  }
  &.blue {
    background-color: var(--c_notification-blue);
    border: 2px solid var(--c_notification-blue-border);
  }
  &.teal {
    background-color: var(--c_notification-teal);
    border: 2px solid var(--c_notification-teal-border);
  }
  &.green {
    background-color: var(--c_notification-green);
    border: 2px solid var(--c_notification-green-border);
  }
  &.olive {
    background-color: var(--c_notification-olive);
    border: 2px solid var(--c_notification-olive-border);
  }
  &.yellow {
    background-color: var(--c_notification-yellow);
    border: 2px solid var(--c_notification-yellow-border);
  }
  &.orange {
    background-color: var(--c_notification-orange);
    border: 2px solid var(--c_notification-orange-border);
  }
  &.red {
    background-color: var(--c_notification-red);
    border: 2px solid var(--c_notification-red-border);
  }
  &.purple {
    background-color: var(--c_notification-purple);
    border: 2px solid var(--c_notification-purple-border);
  }
}
