@import "../../styles/variables";

.xw-page-reports {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  .xw-reports-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    .actions {
      display: flex;
    }
  }
}
