@import "../../../styles/variables";

.roa-details {
  display: flex;
  flex-direction: column;

  .last-scan {
    margin: 12px;
    .roa-last-scan-opener {
      position: relative;
      float: right;
      padding-right: 15px;
      font-size: 12 * $ptToEm;
      line-height: 15px;

      button {
        height: 18px;
        font-size: 15 * $ptToEm;
        line-height: 18px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .roa-table {
    flex: 1 1 100%;
    .xw-data-table {
      height: 100%;
      .xw-roa-timestamp {
        .error {
          color: var(--c_text-red);
        }
      }
    }
  }
}
