@import "../../styles/variables";

#policyNameInput {
  width: 336px;
}

#expectedAsPathMode {
  display: block;
}

#daysToTriggerBeforeExpiration_StringInput {
  width: 280px;
}

#prefixAddRulesModal {
  h3 {
    padding-bottom: calc($unit * 2 / 3);
  }

  .my-peers-rules {
    width: 50%;
  }
}

.xw-policy-edit {
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  .name-field {
    display: flex;
    margin-top: $unit;
    .policy-type {
      padding-left: $unit * 2;
    }
  }

  .xw-page-section header {
    .actions {
      display: flex;
      flex-flow: row nowrap;
      > .xw-button {
        min-width: 146px;
        &.addEndpointToAllButton {
          min-width: 228px;
        }
      }
    }
  }

  > .scrollbar-container {
    overflow-y: auto;
    flex-grow: 1;
    padding: $page-padding;
    margin-bottom: $footerHeight;
  }

  .asn-section .content {
    .asns {
      display: flex;
      flex-flow: row nowrap;
      height: 100px;

      .xw-select {
        flex: 1 1 100%;
        margin: 0;
        height: 100%;
        border: var(--s_textInput-border) var(--c_textInput-line) solid;

        &:first-child {
          margin-right: $unit;
        }

        .xw-input-field {
          position: relative;

          .xw-chip-list {
            position: relative;
            z-index: 0;
            padding: $unit * 2 $unit 0;
            justify-content: flex-start;
            align-content: flex-start;
            height: 100px;
            overflow-y: auto;

            .xw-auto-size-input {
              margin: 0 6px 6px 6px;
            }
          }
          &:after {
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            height: 20px;
            background-color: var(--c_textInput-bg);
          }
          .hint,
          .error {
            top: 108px;
          }
        }
      }
    }

    .as-path-pattern-message {
      color: var(--c_text-error);
      font-size: var(--s_font-small);
      min-height: 10px;
    }

    .as-path-pattern {
      display: flex;
      align-items: center;

      .xw-label-value {
        margin: 0 0 0 $unit;
      }
    }

    .asns {
      margin-bottom: $unit * 3;
    }

    .xw-input-field {
      margin-right: $unit * 0.5;
    }

    button[name="patternHelperModalOpener"] {
      flex: 0 0;
      min-width: $unit * 3;
      padding: 0 $unit * 0.5;
    }
  }

  .xw-rule-edit-card {
    margin: $unit 0;
  }

  .xw-textarea {
    display: flex;
    > textarea {
      width: 100%;
    }
  }
}
