@import "../../styles/variables";

.xw-main-nav {
  flex: 0 0 auto;
  width: 244px;
  border-right: 1px;
  border-color: var(--c_mainNav-border);
  border-style: solid;
  display: flex;
  background: var(--c_mainNav-bg);

  .nav-wrapper {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-nav {
    align-items: center;
    padding: 9px 0;
    flex: 0 0 auto;
    width: 40px;
    background-size: 100% 200%;
    background: var(--c_mainNav-gradient);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .separator {
      display: flex;
      flex-direction: column;
      align-items: center;

      &::after {
        display: block;
        content: "";
        background-color: var(--c_mainNav-seperater-small);
        height: 1px;
        width: $unit * 2;
        margin: $unit 0;
      }
    }
  }

  .link-wrapper {
    .label {
      font-size: $ptToEm * 12;
      color: var(--c_text-warning);
      padding: 0 0 3px;
    }

    .sub {
      font-size: $ptToEm * 10;
    }

    &:focus,
    &:focus-within {
      .main-link {
        .xw-icon {
          transform: scale(1.15);
        }
        &::after {
          transform: scale(1);
        }
        &.active {
          &:focus,
          &:focus-within {
            &::after {
              background-color: var(--c_mainNav-icon-hover-bg);
            }
          }
        }
      }
    }
  }

  .main-link {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .xw-icon {
      fill: var(--c_mainNav-icon);
      width: 18px;
      height: 18px;
      transform: scale(1);
      transition: transform 0.15s ease;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--c_mainNav-icon-hover-bg);
      transform: scale(0);
      transition: transform 0.25s ease;
    }

    &.active {
      .xw-icon {
        // fill: var(--c_mainNav-icon-active);
      }
      &::after {
        transform: scale(1);
        background-color: var(--c_mainNav-icon-active-bg);
      }

      &:hover,
      &:focus,
      &:focus-within {
        &::after {
          background-color: var(--c_mainNav-icon-hover-bg);
        }
      }
    }

    &:hover,
    &:focus,
    &:focus-within {
      .xw-icon {
        transform: scale(1.15);
      }
      &::after {
        transform: scale(1);
      }
    }
  }

  .menu-heading {
    color: var(--c_mainNav-text-heading);
    font-weight: 300;
    font-size: $ptToEm * 18;
    padding: 4px $unit * 2;
  }

  .contextual-nav {
    padding: 14px 0;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .separator {
      background-color: var(--c_mainNav-seperater-large);
      height: 1px;
      width: 100%;
      margin: 15px 0;
    }
  }

  .text-link {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 $unit * 2;
    color: var(--c_mainNav-text);
    line-height: 18px;
    height: 28px;

    .text {
      white-space: nowrap;
    }

    .pin-btn {
      transform: scale(0);
      padding: 8px 18px 8px 8px;
      transition: transform 0.15s ease;

      .xw-icon {
        width: 12px;
        height: 12px;
        fill: var(--c_mainNav-pin);
      }

      &:hover,
      &:focus {
        .xw-icon {
          fill: var(--c_mainNav-pin-hover);
        }
      }

      &.active {
        transform: scale(1);
        pointer-events: none;
        .xw-icon {
          fill: var(--c_mainNav-pin-active);
        }
      }
    }

    &:hover,
    &:focus,
    &:focus-within {
      color: var(--c_mainNav-text-hover);

      .pin-btn {
        transform: scale(1);
      }
    }

    &.active {
      color: var(--c_mainNav-text-active);
    }
    .theme-high-contrast &:hover {
      text-decoration: underline;
    }
  }
}
