@import "../../styles/variables";

.xw-chip-list {
  display: flex;
  overflow: visible;
  align-items: center;
  height: auto;
  flex-flow: row wrap;
  width: 100%;
  padding: calc($unit / 4) 0;
  margin-bottom: calc(-#{$unit} / 2);

  &.editable {
    min-height: $input-height;
    display: flex;
    padding: ($unit * 0.75) $unit;
    border-top: none;
    border-right: none;
    border-left: none;
  }

  .xw-chip {
    display: inline-block;
    margin: 0 calc($unit / 2) calc($unit / 2) 0;
    text-align: center;
    flex: 0 1 auto;
  }

  .start-add {
    display: inline-block;
    margin: 0 calc($unit / 2) 0 0;
    flex: 0 1 auto;
  }

  .rowColumn & {
    flex-wrap: nowrap;
    margin-bottom: 0;
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 95%, transparent);
    -webkit-mask-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 95%,
      transparent
    );

    .xw-chip,
    .start-add {
      min-width: 34px;
      margin-bottom: 0;
    }
  }
}
