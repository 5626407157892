@import "../../styles/variables";

.xw-double-trend-chart-info {
  $lineHeight: 25 * $ptToEm;
  flex: 0 0 106px;
  height: 106px;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .date-labels,
  .date-values,
  .time-values {
    display: flex;
    flex-flow: column nowrap;
  }

  .date-labels {
    text-transform: uppercase;
    width: 60px;
    margin-right: $unit * 2;
    font-size: 18 * $ptToEm;
    font-weight: 700;
    label {
      line-height: $lineHeight;
    }
  }

  .date-values,
  .time-values {
    margin-left: $unit * 2;
    font-size: 18 * $ptToEm;
    color: var(--c_chart-data);

    .date,
    .time {
      font-size: 18 * $ptToEm;
      color: var(--c_chart-data);
      line-height: $lineHeight;
    }

    .date {
      min-width: 268px;
      padding-right: $unit;
    }
  }

  .spacer {
    flex-grow: 1;
  }

  .vr {
    width: 1px;
    height: 50px;
    background: var(--c_chart-tick);
  }
  .avg-info {
    flex: 0 1 168px;
    &:not(:last-child) {
      margin-right: $unit * 4;
    }
    label {
      font-size: 18 * $ptToEm;
      .chart-label {
        font-weight: 700;
      }
    }
    .value {
      font-size: 34 * $ptToEm;
      .unit {
        font-size: 14 * $ptToEm;
        color: var(--c_text);
      }
    }

    &.multi-series {
      margin-left: $unit;
      flex: 0 1 118px;
      position: relative;
      top: -7px;
      > label {
        font-size: 14 * $ptToEm;

        color: var(--c_text-title);
      }
      .value {
        font-size: 18 * $ptToEm;
        line-height: $lineHeight;
      }
    }
  }

  .multi-series-labels {
    margin-right: $unit;
    > label {
      line-height: $lineHeight;
      font-size: 18 * $ptToEm;
      font-weight: 700;
    }
  }

  .series-text-0 {
    color: var(--c_chart-series-0);
  }
  .series-text-1 {
    color: var(--c_chart-series-1);
  }
}
