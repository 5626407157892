@import "../../styles/variables";

.xw-search-input {
  min-width: 180px;
  height: $input-height;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;

  .xw-input-field {
    width: 100%;
    cursor: pointer;

    &.disabled {
      cursor: default;
    }
    margin-bottom: 0;

    &.focused {
      label {
        transform: translateY(-10px) scale(0.8);
      }
    }
    &:focus:not(.dropdown-open) {
      border-bottom-color: var(--c_textInput-line-active);
    }
    &.dropdown-open {
      border-bottom-color: var(--c_select-dropdown-divider) !important;
    }
    .xw-icon {
      pointer-events: none;
      fill: var(--c_textInput-search-icon);
    }
    .xw-button {
      position: absolute;
      top: 0px;
      right: 0;
      margin: 0;
      padding: 0 $unit * 2;
      height: 100%;
      min-width: 0;
      border-radius: 0;
      .xw-icon {
        fill: var(--c_button-search-icon);
      }
      &:disabled {
        pointer-events: none;
      }
    }
  }

  &:hover,
  .dropdown-open {
    fill: var(--c_icon-hover);
  }

  .xw-dropdown-menu {
    padding: 0;
    padding-bottom: 2px;
    background-color: var(--c_textInput-bg);
    max-height: $unit * 25 + 2;
    border: none;

    button,
    .empty,
    .no-match-text {
      display: block;
      width: 100%;
      margin: 0;
      color: var(--c_text);
      padding: calc($unit / 2) $unit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .no-match-text {
      padding: $unit;
    }
    .xw-processing-loader {
      position: absolute;
      left: $unit;
      top: calc($unit / 2);
      width: 40px;
    }
    button {
      position: relative;
      height: $unit * 2.5;
      color: var(--c_select-option);
      font-size: 15 * $ptToEm;
      &.focus {
        background-color: var(--c_select-option-bg-focus);
      }
    }

    nav {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .match {
    color: var(--c_select-match);
  }

  .footer-line {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    border: none;
    background-color: var(--c_textInput-line-active);
    margin: 0;
  }

  .error {
    top: unset;
    bottom: calc(-#{$unit} * (4 / 3));
  }
  .showError > .hint {
    display: flex;
    margin-top: 12px;
    bottom: calc(-#{$unit} * (4 / 3));
  }
}

body.theme-high-contrast .xw-search-input {
  .footer-line {
    background-color: var(--c_textInput-line);
  }
  .xw-dropdown-menu {
    border: var(--s_textInput-border) solid var(--c_textInput-line);
    border-top: none;
    button.focus {
      color: var(--c_select-option-focus);
      .match {
        color: var(--c_select-match-focus);
      }
    }
  }
}
