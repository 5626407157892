@import "../../styles/variables";
// needs to match constants in drilldownTable.tsx
$max-levels: 10;
$primary-width: 192px;
$box-shadow: var(--c_drilldown-shadow);

.xw-drilldown-table {
  display: flex;
  flex-flow: column nowrap;
  > .drilldowns {
    flex-grow: 1;
    position: relative;
    > .drilldown {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-flow: column nowrap;
      background-color: var(--c_content-bg);
      @for $i from 0 through $max-levels - 1 {
        &.lvl-#{$i} {
          left: $primary-width * $i;
          box-shadow: $box-shadow;
        }
      }
      &.lvl-0 {
        box-shadow: none;
      }

      // disable scrolling/filtering unless top-lvl table
      .xw-data-table {
        pointer-events: none;
        .filter-action {
          display: none;
        }
        // but always allow primary column clicks, because why not
        .tbody .cell.key-primary {
          pointer-events: all;
          position: relative;
        }

        .row {
          &.selected {
            .drill-col {
              color: var(--c_drilldown-text-active);
            }
            .click-ico {
              opacity: 0;
            }
          }
        }
        .drill-col {
          position: unset;
          display: flex;
          align-items: center;
          color: var(--c_drilldown-text);

          .click-ico {
            position: absolute;
            right: 0;
            background: var(--c_table-odd-gradient-cover);
            padding: 11px 5px 11px 18px;
            transition: opacity 0.05 ease;

            .xw-icon {
              transition: transform 0.15s ease;
              transform: rotate(90deg);
            }
          }

          &:hover {
            color: var(--c_drilldown-text-hover);

            .click-ico {
              .xw-icon {
                width: 12px;
                height: 12px;
                transform: rotate(0);
                fill: var(--c_icon-hover-two);
              }
            }
          }
        }

        .xw-tooltip {
          .drill-col .click-ico {
            display: none;
          }
        }
      }
      &.top-lvl .xw-data-table {
        pointer-events: all;
        .filter-action {
          display: block;
        }
      }

      .xw-data-table {
        flex-grow: 1;
        .header {
          .cell.key-primary, {
            min-width: $primary-width;

            .xw-select {
              min-width: unset;
              width: 100%;
            }
          }
        }
        .row {
          .cell:first-child {
            flex: 0 0;
          }

          .cell.key-primary {
            font-weight: 700;
            min-width: $primary-width;
          }
          &.selected {
            background-color: var(--c_header-active);

            .cell.key-primary,
            .cell.key-primary .link {
              color: var(--c-table-header-text);
              cursor: default;
            }
          }
        }
      }
      .close-drilldown {
        background: var(--c-table-header);
        position: absolute;
        width: 34px;
        height: 44px;
        left: -22px;
        margin: 0;
        padding: 0;
        top: 1px;
        min-width: auto;
        border-radius: 22px 0 0 22px;
        z-index: 1;
        border: none;
        box-shadow: $box-shadow;
        padding-left: 8px;
        .xw-icon {
          fill: var(--c-table-icons);
        }
      }
    }
  }
}
