@import "../../styles/variables";

// change name to xw-policy-detail when ccni policy is migrated
.xw-new-policy-details {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  &.page-padding {
    padding-bottom: 0;
  }

  .xw-policy-overview {
    overflow-y: auto;
    padding: $unit 0 0 0;
    position: relative;
    .title {
      flex: 0 0 auto;
      font-size: 24 * $ptToEm;
      color: var(--c_modal_policy-title);
      font-weight: 300;
      margin-right: $unit;
      .title-with-count {
        color: var(--c_modal_policy-rule-title);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
      }
      .count {
        padding-left: $unit;
        font-weight: bold;
        color: var(--c_section-header-emphasis);
        &.error {
          color: var(--c_policy-data-excluded);
        }
      }
      .sub-title {
        color: var(--c_modal_policy-rule-sub-title);
        font-size: 15 * $ptToEm;
        line-height: 20 * $ptToEm;
      }
    }
    .xw-accordion {
      margin-bottom: $unit;
      .header-title {
        .subtitle {
          .xw-content {
            color: var(--c_modal-policy-subtitle);
          }
        }
      }
      .summary {
        display: flex;
        flex-flow: column;
        align-items: center;
        .sections {
          display: flex;
          align-self: flex-end;
        }
        .type {
          font-style: normal;
          font-weight: 700;
          font-size: 14 * $ptToEm;
          line-height: 19 * $ptToEm;
          text-transform: capitalize;
          align-self: flex-end;
        }
      }
      .section-summary {
        display: flex;
        .count {
          font-size: 24px;
          color: var(--c_policy-summary-count);
          font-weight: 300;
          padding-right: calc($unit / 4);
        }
        .included {
          fill: var(--c_policy-data-included);
        }
        .not-included {
          fill: var(--c_policy-data-excluded);
        }
      }
    }
    .fields {
      margin-top: 24px;
      display: flex;
      flex-flow: row wrap;

      .xw-label-value {
        margin-bottom: 0;
        margin-left: $unit * 3;

        &:first-child {
          margin-left: 0;
        }

        .last-active-alarm {
          display: flex;

          > a {
            padding-left: calc($unit / 2);
            margin-top: 1px;
            color: var(--c_text-link);
          }
        }
      }
    }
    .data-section {
      padding: calc($unit / 2) 0 0 0;
      flex: 0 1 auto;
      display: flex;
      flex-flow: column nowrap;
      .connected-gateway-section {
        .spacer {
          border-bottom: unset;
        }
      }
    }

    .action-section {
      padding: calc($unit / 2) 0 0 0;
      flex: 0 1 30%;
      display: flex;
      flex-flow: column nowrap;
      .xw-page-section {
        .title {
          color: var(--c_modal_policy-rule-title);
        }
      }
    }

    .trigger-section {
      padding: calc($unit / 2) 0 0 0;
      flex: 0 1 auto;
      display: flex;
      flex-flow: column nowrap;

      .xw-page-section {
        display: flex;
        flex-flow: column nowrap;
        overflow: auto;
        .content > * {
          padding-top: $unit;
        }
        .spacer {
          flex: 1 1 100%;
          height: 1px;
          border-bottom: 1px dashed var(--c_section-header-separator);
          margin-bottom: calc($unit / 2);
        }
      }

      .title-container {
        display: flex;
        flex-flow: column nowrap;
      }

      .connection-loss {
        .xw-input-field {
          width: 300px;
        }
      }
      .xw-prefix-utilization {
        .xw-utilization-range-selector {
          .slider-handles {
            .handle-0 {
              border-color: transparent;
              border-radius: 24px;
              .range-handle-label {
                top: 18px;
                left: -15px;
              }
              &.disabled {
                width: 10px;
                height: 10px;
                margin-top: -4px;
                margin-left: -7px;
                &::after {
                  background: none;
                }
              }
            }
            .handle-2,
            .handle-3 {
              border: none;
              background: none;
              margin-top: -7px;
            }
          }
          .slider-tracks {
            .track-2 {
              .range-track-label {
                &::before {
                  color: var(--c_policy-data-excluded);
                }
              }
            }
          }
        }
      }
      .xw-interface-utilization {
        .xw-utilization-range-selector {
          .slider-handles {
            .handle-10,
            .handle-2 {
              border-color: transparent;
              background-color: transparent;
            }
          }
          .slider-tracks {
            .track-0,
            .track-2 {
              .range-track-label {
                &::before {
                  color: var(--c_policy-data-excluded);
                }
              }
            }
          }
        }
      }

      .xw-prefix-utilization,
      .xw-interface-utilization,
      .connection-loss {
        .xw-utilization-range-selector {
          pointer-events: none;
          min-width: 450px;
          padding-right: $unit * 2;
          margin-left: $unit;
          margin-right: $unit * 3;
        }
      }
    }
  }
}
