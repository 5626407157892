@import "../../styles/variables";

.view-token-modal {
  .xw-textarea {
    width: 100%;
    padding: 42px 24px;
    textarea {
      width: 100%;
      color: var(--c_api_key_text_color);
    }
  }
}
