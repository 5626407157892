.xw-utilization-icon.xw-icon {
  margin-left: 0 !important;

  &.lv-critical {
    fill: var(--c_text-error);
  }
  &.lv-high {
    fill: var(--c_text-status-notice);
  }
  &.lv-normal {
    fill: var(--c_text-ok);
  }
}
