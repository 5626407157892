@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

// @keyframes verticalExpand {
//   0% {
//     transform: scaleY(0);
//   }
//   1.69492% {
//     transform: scaleY(0.06609);
//   }
//   3.38983% {
//     transform: scaleY(0.12885);
//   }
//   5.08475% {
//     transform: scaleY(0.1884);
//   }
//   6.77966% {
//     transform: scaleY(0.24483);
//   }
//   8.47458% {
//     transform: scaleY(0.29827);
//   }
//   10.16949% {
//     transform: scaleY(0.34883);
//   }
//   11.86441% {
//     transform: scaleY(0.3966);
//   }
//   13.55932% {
//     transform: scaleY(0.44169);
//   }
//   15.25424% {
//     transform: scaleY(0.48421);
//   }
//   16.94915% {
//     transform: scaleY(0.52425);
//   }
//   18.64407% {
//     transform: scaleY(0.56192);
//   }
//   20.33898% {
//     transform: scaleY(0.5973);
//   }
//   22.0339% {
//     transform: scaleY(0.63049);
//   }
//   23.72881% {
//     transform: scaleY(0.66159);
//   }
//   25.42373% {
//     transform: scaleY(0.69068);
//   }
//   27.11864% {
//     transform: scaleY(0.71786);
//   }
//   28.81356% {
//     transform: scaleY(0.7432);
//   }
//   30.50847% {
//     transform: scaleY(0.7668);
//   }
//   32.20339% {
//     transform: scaleY(0.78873);
//   }
//   33.89831% {
//     transform: scaleY(0.80908);
//   }
//   35.59322% {
//     transform: scaleY(0.82792);
//   }
//   37.28814% {
//     transform: scaleY(0.84533);
//   }
//   38.98305% {
//     transform: scaleY(0.86139);
//   }
//   40.67797% {
//     transform: scaleY(0.87616);
//   }
//   42.37288% {
//     transform: scaleY(0.88972);
//   }
//   44.0678% {
//     transform: scaleY(0.90213);
//   }
//   45.76271% {
//     transform: scaleY(0.91346);
//   }
//   47.45763% {
//     transform: scaleY(0.92379);
//   }
//   49.15254% {
//     transform: scaleY(0.93315);
//   }
//   50.84746% {
//     transform: scaleY(0.94163);
//   }
//   52.54237% {
//     transform: scaleY(0.94927);
//   }
//   54.23729% {
//     transform: scaleY(0.95614);
//   }
//   55.9322% {
//     transform: scaleY(0.96229);
//   }
//   57.62712% {
//     transform: scaleY(0.96776);
//   }
//   59.32203% {
//     transform: scaleY(0.97262);
//   }
//   61.01695% {
//     transform: scaleY(0.97691);
//   }
//   62.71186% {
//     transform: scaleY(0.98067);
//   }
//   64.40678% {
//     transform: scaleY(0.98395);
//   }
//   66.10169% {
//     transform: scaleY(0.9868);
//   }
//   67.79661% {
//     transform: scaleY(0.98925);
//   }
//   69.49153% {
//     transform: scaleY(0.99134);
//   }
//   71.18644% {
//     transform: scaleY(0.99311);
//   }
//   72.88136% {
//     transform: scaleY(0.99459);
//   }
//   74.57627% {
//     transform: scaleY(0.99582);
//   }
//   76.27119% {
//     transform: scaleY(0.99683);
//   }
//   77.9661% {
//     transform: scaleY(0.99764);
//   }
//   79.66102% {
//     transform: scaleY(0.99829);
//   }
//   81.35593% {
//     transform: scaleY(0.99879);
//   }
//   83.05085% {
//     transform: scaleY(0.99917);
//   }
//   84.74576% {
//     transform: scaleY(0.99946);
//   }
//   86.44068% {
//     transform: scaleY(0.99966);
//   }
//   88.13559% {
//     transform: scaleY(0.9998);
//   }
//   89.83051% {
//     transform: scaleY(0.99989);
//   }
//   91.52542% {
//     transform: scaleY(0.99995);
//   }
//   93.22034% {
//     transform: scaleY(0.99998);
//   }
//   94.91525% {
//     transform: scaleY(0.99999);
//   }
//   96.61017% {
//     transform: scaleY(1);
//   }
//   98.30508% {
//     transform: scaleY(1);
//   }
//   100% {
//     transform: scaleY(1);
//   }
// }

// @keyframes verticalCollapse {
//   0% {
//     transform: scaleY(1000);
//   }
//   1.69492% {
//     transform: scaleY(15.13088);
//   }
//   3.38983% {
//     transform: scaleY(7.76096);
//   }
//   5.08475% {
//     transform: scaleY(5.30786);
//   }
//   6.77966% {
//     transform: scaleY(4.08447);
//   }
//   8.47458% {
//     transform: scaleY(3.35267);
//   }
//   10.16949% {
//     transform: scaleY(2.86673);
//   }
//   11.86441% {
//     transform: scaleY(2.52143);
//   }
//   13.55932% {
//     transform: scaleY(2.26403);
//   }
//   15.25424% {
//     transform: scaleY(2.06522);
//   }
//   16.94915% {
//     transform: scaleY(1.90749);
//   }
//   18.64407% {
//     transform: scaleY(1.77961);
//   }
//   20.33898% {
//     transform: scaleY(1.6742);
//   }
//   22.0339% {
//     transform: scaleY(1.58607);
//   }
//   23.72881% {
//     transform: scaleY(1.51151);
//   }
//   25.42373% {
//     transform: scaleY(1.44785);
//   }
//   27.11864% {
//     transform: scaleY(1.39303);
//   }
//   28.81356% {
//     transform: scaleY(1.34553);
//   }
//   30.50847% {
//     transform: scaleY(1.30412);
//   }
//   32.20339% {
//     transform: scaleY(1.26786);
//   }
//   33.89831% {
//     transform: scaleY(1.23597);
//   }
//   35.59322% {
//     transform: scaleY(1.20785);
//   }
//   37.28814% {
//     transform: scaleY(1.18297);
//   }
//   38.98305% {
//     transform: scaleY(1.16091);
//   }
//   40.67797% {
//     transform: scaleY(1.14134);
//   }
//   42.37288% {
//     transform: scaleY(1.12395);
//   }
//   44.0678% {
//     transform: scaleY(1.10849);
//   }
//   45.76271% {
//     transform: scaleY(1.09474);
//   }
//   47.45763% {
//     transform: scaleY(1.0825);
//   }
//   49.15254% {
//     transform: scaleY(1.07164);
//   }
//   50.84746% {
//     transform: scaleY(1.06199);
//   }
//   52.54237% {
//     transform: scaleY(1.05344);
//   }
//   54.23729% {
//     transform: scaleY(1.04587);
//   }
//   55.9322% {
//     transform: scaleY(1.03919);
//   }
//   57.62712% {
//     transform: scaleY(1.03331);
//   }
//   59.32203% {
//     transform: scaleY(1.02815);
//   }
//   61.01695% {
//     transform: scaleY(1.02364);
//   }
//   62.71186% {
//     transform: scaleY(1.01971);
//   }
//   64.40678% {
//     transform: scaleY(1.01631);
//   }
//   66.10169% {
//     transform: scaleY(1.01338);
//   }
//   67.79661% {
//     transform: scaleY(1.01087);
//   }
//   69.49153% {
//     transform: scaleY(1.00874);
//   }
//   71.18644% {
//     transform: scaleY(1.00694);
//   }
//   72.88136% {
//     transform: scaleY(1.00544);
//   }
//   74.57627% {
//     transform: scaleY(1.0042);
//   }
//   76.27119% {
//     transform: scaleY(1.00318);
//   }
//   77.9661% {
//     transform: scaleY(1.00237);
//   }
//   79.66102% {
//     transform: scaleY(1.00171);
//   }
//   81.35593% {
//     transform: scaleY(1.00121);
//   }
//   83.05085% {
//     transform: scaleY(1.00083);
//   }
//   84.74576% {
//     transform: scaleY(1.00054);
//   }
//   86.44068% {
//     transform: scaleY(1.00034);
//   }
//   88.13559% {
//     transform: scaleY(1.0002);
//   }
//   89.83051% {
//     transform: scaleY(1.00011);
//   }
//   91.52542% {
//     transform: scaleY(1.00005);
//   }
//   93.22034% {
//     transform: scaleY(1.00002);
//   }
//   94.91525% {
//     transform: scaleY(1.00001);
//   }
//   96.61017% {
//     transform: scaleY(1);
//   }
//   98.30508% {
//     transform: scaleY(1);
//   }
//   100% {
//     transform: scaleY(1);
//   }
// }

.slideRight-enter {
  transform: translateX(-110%);
  opacity: 0;
}

.slideRight-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.15s ease-in, opacity 0.15s ease-in;
}

.slideRight-exit {
  transform: translateX(0);
  opacity: 1;
}

.slideRight-exit-active {
  transform: translateX(-110%);
  opacity: 0;
  transition: transform 0.15s ease-out, opacity 250ms ease-out;
}

.slideLeft-enter {
  transform: translateX(110%);
  opacity: 0;
}

.slideLeft-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 250ms ease-in, opacity 250ms ease-in;
}

.slideLeft-exit {
  transform: translateX(0);
  opacity: 1;
}

.slideLeft-exit-active {
  transform: translateX(110%);
  opacity: 0;
  transition: transform 250ms ease-out, opacity 250ms ease-out;
}

@keyframes SidebarGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% -200%;
  }
}

@keyframes SidebarLoadingGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 200%;
  }
}

.xfade-enter {
  opacity: 0;
}

.xfade-enter.xfade-enter-active {
  opacity: 1;
  transition: opacity 0ms ease-in;
}

.xfade-exit {
  opacity: 1;
}

.xfade-exit.xfade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.tablePage-enter {
  opacity: 0;
}

.tablePage-enter.tablePage-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

.tablePage-exit {
  opacity: 1;
}

.tablePage-exit.tablePage-exit-active {
  opacity: 0;
  transition: opacity 100ms ease-out;
}

.accordion-enter {
  opacity: 0;
}
.accordion-enter-active {
  opacity: 1;
  transition: opacity 0.75s ease-in;
}
.accordion-exit {
  opacity: 1;
}
.accordion-exit-active {
  opacity: 0;
  transition: opacity 0.75s ease-out;
}
