@import "../../../../styles/variables";

.xw-status-flowchart {
  color: var(--c_statusflowchart-card-text);

  .chart {
    display: flex;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 0;

    &.large {
      flex: 1 1 280px;
    }

    &.small {
      flex: 1 1 160px;
      padding: 42px 12px 0 12px;
    }

    > h3 {
      color: var(--c_statusflowchart-titles);
      font-size: 24 * $ptToEm;
      font-weight: 300;
      margin-bottom: $unit;
    }
  }

  .mode {
    margin-top: 3px;
    > label {
      font-size: 12 * $ptToEm;
      font-weight: 700;
      color: var(--c_statusflowchart-card-text-secondary);
      margin-bottom: 3px;
    }
    > .value {
      text-transform: capitalize;
      color: var(--c_statusflowchart-disabled);

      &.enabled {
        color: var(--c_statusflowchart-working);
      }
    }
  }

  .nodes {
    flex: 1 1 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .node {
    position: relative;
    flex: 1 1 100%;
    margin: 0 0 $unit * 2 0;
    height: 100%;

    &.card {
      display: flex;
      align-items: center;
      border: 1px solid var(--c_statusflowchart-card-border);
      background-color: var(--c_statusflowchart-card-bg);
      padding: 18px;
      border-radius: 3px;
      min-width: 0;
      cursor: default;
    }

    &.cdg {
      padding-right: $unit * 3;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.application {
      cursor: default;
      border-color: transparent;
      &:hover {
        border-color: transparent;
      }
    }

    .overview {
      display: flex;
      min-width: 0;
    }

    .hidden-content {
      position: absolute;
      left: -1px;
      right: -1px;
      top: calc(100% - 1px);
      z-index: 100;
      border: 1px solid var(--c_statusflowchart-card-border);
      border-top: none;
      background-color: var(--c_statusflowchart-card-bg);
      padding: 2px 18px 18px;
      border-radius: 0 0 3px 3px;
      transform: scaleY(0);
      opacity: 0;
      transform-origin: top;
      transition: all 0.15s ease 0s;

      .xw-label-value {
        margin-bottom: $unit;
      }
    }

    .icon-wrapper {
      flex: 0 0 auto;
      margin-right: $unit;
      width: 60px;
      display: flex;
      flex-direction: column;
    }

    .content-wrapper {
      flex: 1 1 100%;
      min-width: 0;
    }

    .xw-icon {
      flex: 0 0 auto;
      width: 60px;
      height: 32px;
      margin-bottom: 9px;
      fill: var(--c_statusflowchart-icon-bg);
      shape-rendering: geometricPrecision;
    }

    .app-title {
      display: block;
      font-size: 20 * $ptToEm;
      font-weight: 300;
      margin: 7px 0;
      height: 20px;
    }

    .link {
      &.title {
        display: block;
        font-size: 20 * $ptToEm;
        font-weight: 300;
        color: var(--c_text-link);
        margin-bottom: calc($unit / 4);
      }
    }

    .description {
      margin-top: calc($unit / 4);
      line-height: 16 * $ptToEm;
      color: var(--c_statusflowchart-card-text-secondary);
    }

    .subtitle {
      color: var(--c_statusflowchart-card-text);
    }

    &.enabled {
      &:hover {
        border-color: var(--c_statusflowchart-working);
        .hidden-content {
          border-color: var(--c_statusflowchart-working);
        }
      }
    }

    &.status-unknown {
      &:hover {
        border-color: var(--c_statusflowchart-unknown);
        .hidden-content {
          border-color: var(--c_statusflowchart-unknown);
        }
      }
    }

    &.status-working {
      &:hover {
        border-color: var(--c_statusflowchart-working);
        .hidden-content {
          border-color: var(--c_statusflowchart-working);
        }
      }
    }

    &:hover {
      border-color: var(--c_statusflowchart-disabled);
      .hidden-content {
        transform: scaleY(1);
        opacity: 1;
        border-color: var(--c_statusflowchart-disabled);
        transition: all 0.15s ease 0.4s;
      }
    }
  }

  .connections {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .connection {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    cursor: help;

    .content {
      flex: 1 1 100%;
      padding: 5px;
      color: var(--c_statusflowchart-filled-text);
      text-align: center;
      font-weight: 700;
      border-top: 1px solid #777777;
      border-bottom: 1px solid #777777;
    }

    &:before {
      content: "";
      flex: 0 0 auto;
      display: block;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #777777;
    }

    &:after {
      content: "";
      flex: 0 0 auto;
      display: block;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid #777777;
    }

    &.status-error {
      .content {
        background-color: var(--c_statusflowchart-error);
        border-top: 1px solid var(--c_statusflowchart-error);
        border-bottom: 1px solid var(--c_statusflowchart-error);
      }
      &:before {
        border-right-color: var(--c_statusflowchart-error);
      }
      &:after {
        border-left-color: var(--c_statusflowchart-error);
      }
    }

    &.status-disabled {
      .content {
        background-color: var(--c_statusflowchart-disabled);
        border-top: 1px solid var(--c_statusflowchart-disabled);
        border-bottom: 1px solid var(--c_statusflowchart-disabled);
      }
      &:before {
        border-right-color: var(--c_statusflowchart-disabled);
      }
      &:after {
        border-left-color: var(--c_statusflowchart-disabled);
      }
    }

    &.status-unknown {
      .content {
        background-color: var(--c_statusflowchart-unknown);
        border-top: 1px solid var(--c_statusflowchart-unknown);
        border-bottom: 1px solid var(--c_statusflowchart-unknown);
      }
      &:before {
        border-right-color: var(--c_statusflowchart-unknown);
      }
      &:after {
        border-left-color: var(--c_statusflowchart-unknown);
      }
    }

    &.status-working {
      .content {
        background-color: var(--c_statusflowchart-working);
        border-top: 1px solid var(--c_statusflowchart-working);
        border-bottom: 1px solid var(--c_statusflowchart-working);
      }
      &:before {
        border-right-color: var(--c_statusflowchart-working);
      }
      &:after {
        border-left-color: var(--c_statusflowchart-working);
      }
    }

    &:hover {
      .content {
        background-color: var(--c_statusflowchart-card-bg);
      }
      &.status-error {
        .content {
          color: var(--c_statusflowchart-error);
        }
      }

      &.status-disabled {
        .content {
          color: var(--c_statusflowchart-disabled);
        }
      }

      &.status-unknown {
        .content {
          color: var(--c_statusflowchart-unknown);
        }
      }

      &.status-working {
        .content {
          color: var(--c_statusflowchart-working);
        }
      }

      .xw-status-hover-box {
        opacity: 1;
        transform: scaleY(1);
        transition: all 0.15s ease 0.4s;
      }
    }
  }

  .credentials-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 3px;
  }

  .credential {
    display: flex;
    padding: 2px 0;
    align-items: center;
    position: relative;
    left: -42px;
    width: calc(100% + 42px);
    cursor: help;

    .content {
      border: 1px solid var(--c_statusflowchart-card-border);
      background-color: var(--c_statusflowchart-card-bg);
      padding: 3px 0 2px;
      border-radius: 3px;
      flex: 0 0 auto;
      width: 60px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      color: #000000;
    }

    .arrow {
      position: relative;
      height: 1px;
      flex: 1 1 100%;
      background-color: var(--c_statusflowchart-card-border);

      &:after {
        content: "";
        position: absolute;
        top: -6px;
        right: -1px;
        display: block;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid var(--c_statusflowchart-card-border);
      }
    }

    &.status-error {
      .content {
        color: var(--c_statusflowchart-error);
      }
      .arrow {
        background-color: var(--c_statusflowchart-error);
        &:after {
          border-left-color: var(--c_statusflowchart-error);
        }
      }
    }

    &.status-disabled {
      .content {
        color: var(--c_statusflowchart-disabled);
      }
      .arrow {
        background-color: var(--c_statusflowchart-disabled);
        &:after {
          border-left-color: var(--c_statusflowchart-disabled);
        }
      }
    }

    &.status-unknown {
      .content {
        color: var(--c_statusflowchart-unknown);
      }
      .arrow {
        background-color: var(--c_statusflowchart-unknown);
        &:after {
          border-left-color: var(--c_statusflowchart-unknown);
        }
      }
    }

    &.status-working {
      .content {
        color: var(--c_statusflowchart-working);
      }
      .arrow {
        background-color: var(--c_statusflowchart-working);
        &:after {
          border-left-color: var(--c_statusflowchart-working);
        }
      }
    }

    &:hover {
      .xw-status-hover-box {
        opacity: 1;
        transform: scaleY(1);
        transition: all 0.15s ease 0.4s;
      }

      .content {
        color: var(--c_statusflowchart-filled-text);
      }

      &.status-error {
        .content {
          background-color: var(--c_statusflowchart-error);
          border-color: var(--c_statusflowchart-error);
        }
      }

      &.status-disabled {
        .content {
          background-color: var(--c_statusflowchart-disabled);
          border-color: var(--c_statusflowchart-disabled);
        }
      }

      &.status-unknown {
        .content {
          background-color: var(--c_statusflowchart-unknown);
          border-color: var(--c_statusflowchart-unknown);
        }
      }

      &.status-working {
        .content {
          background-color: var(--c_statusflowchart-working);
          border-color: var(--c_statusflowchart-working);
        }
      }
    }
  }

  .data-flag {
    position: relative;
    display: flex;
    width: calc(100% + 12px);
    cursor: help;

    .content {
      flex: 1 1 100%;
      padding: 5px;
      color: var(--c_statusflowchart-filled-text);
      text-align: center;
      font-weight: 700;
      border: 1px solid #777777;
      border-left: none;
    }

    &:before {
      content: "";
      flex: 0 0 auto;
      display: block;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #777777;
    }

    &.status-error {
      .content {
        background-color: var(--c_statusflowchart-error);
        border-color: var(--c_statusflowchart-error);
      }
      &:before {
        border-right-color: var(--c_statusflowchart-error);
      }
    }

    &.status-disabled {
      .content {
        background-color: var(--c_statusflowchart-disabled);
        border-color: var(--c_statusflowchart-disabled);
      }
      &:before {
        border-right-color: var(--c_statusflowchart-disabled);
      }
    }

    &.status-unknown {
      .content {
        background-color: var(--c_statusflowchart-unknown);
        border-color: var(--c_statusflowchart-unknown);
      }
      &:before {
        border-right-color: var(--c_statusflowchart-unknown);
      }
    }

    &.status-working {
      .content {
        background-color: var(--c_statusflowchart-working);
        border-color: var(--c_statusflowchart-working);
      }
      &:before {
        border-right-color: var(--c_statusflowchart-working);
      }
    }

    &:hover {
      .xw-status-hover-box {
        opacity: 1;
        transform: scaleY(1);
        transition: all 0.15s ease 0.4s;
      }

      .content {
        background-color: var(--c_statusflowchart-card-bg);
      }

      &.status-error {
        .content {
          color: var(--c_statusflowchart-error);
        }
      }

      &.status-disabled {
        .content {
          color: var(--c_statusflowchart-disabled);
        }
      }

      &.status-unknown {
        .content {
          color: var(--c_statusflowchart-unknown);
        }
      }

      &.status-working {
        .content {
          color: var(--c_statusflowchart-working);
        }
      }
    }
  }

  .xw-status-hover-box {
    opacity: 0;
    transform-origin: top;
    transform: scaleY(0);
    transition: all 0.15s ease 0s;
  }

  .device-actions {
    .xw-button {
      margin: 0;
      padding: 0;
      height: auto;
      line-height: 16px;
    }
  }

  .credential-actions {
    .xw-button {
      margin: 0;
      height: auto;
      padding: calc($unit / 2) $unit * 2;
    }
  }

  .legend-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $unit 0;

    .legend {
      display: flex;
      align-items: center;
      text-transform: capitalize;

      .item {
        display: flex;
        margin-right: $unit;

        &:before {
          margin-right: calc($unit / 2);
          flex: 0 0 auto;
          content: "";
          display: block;
          width: $unit;
          height: $unit;
          border-radius: 2px;
        }
      }

      .label {
        font-weight: 700;
        color: var(--c_statusflowchart-card-text-secondary);

        &:before {
          display: none;
        }
      }

      .working {
        color: var(--c_statusflowchart-working);

        &:before {
          background-color: var(--c_statusflowchart-working);
        }
      }

      .disabled {
        color: var(--c_statusflowchart-disabled);

        &:before {
          background-color: var(--c_statusflowchart-disabled);
        }
      }

      .unknown {
        color: var(--c_statusflowchart-unknown);

        &:before {
          background-color: var(--c_statusflowchart-unknown);
        }
      }

      .error {
        color: var(--c_statusflowchart-error);

        &:before {
          background-color: var(--c_statusflowchart-error);
        }
      }
    }

    .help {
      color: var(--c_statusflowchart-card-text-secondary);
    }
  }
}
