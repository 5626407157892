@import "../../styles/variables";

.xw-topology-page {
  background-color: var(--c_content-bg);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .free-tier-notification {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
  }

  &.disabled {
    .topology-header,
    .topology-footer,
    .xw-topology-graph {
      pointer-events: none;
      opacity: 0.4;
      user-select: none;
    }
  }

  .start-form {
    width: 300px;
  }

  .topology-header {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
    background-color: var(--c_topology-header);
    padding: $unit 0;
    min-height: 0;
    margin: 1px 0;

    .col {
      min-height: 0;
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      padding-left: $unit;

      &.spacer {
        flex: 1 1 100%;
      }

      &:first-child {
        padding-left: $unit * 2;
      }

      &.right {
        padding-right: $unit * 2;
      }

      > .xw-select .xw-input-field {
        flex: 0 0 auto;
        width: 140px;
      }

      > .xw-input-field {
        width: 180px;
        margin: 0 3px 0 0;
      }

      > .xw-button {
        margin: 0 $unit 0 0;
        padding: 0;
        height: 48px;
        min-width: 80px;
      }

      .mode-picker {
        .xw-input-field {
          width: 180px;
        }
      }

      .xw-dateTime {
        margin-left: 6px;
        width: 240px;
        height: 48px;
        background-color: var(--c_textInput-bg);

        label {
          color: var(--c_textInput-label);
        }

        .field-wrapper {
          width: 100%;
        }

        .xw-icon {
          fill: var(--c_icon);
        }
      }
    }

    .timeline-btn {
      display: block;
      padding: 9px;

      &.start {
        transform: rotate(90deg);
      }
      &.end {
        transform: rotate(-90deg);
      }

      .xw-icon {
        fill: var(--c_icon);
        width: 12px;
      }

      &:hover {
        .xw-icon {
          fill: var(--c_icon-hover);
        }
      }

      &.disable {
        opacity: 0.25;
        pointer-events: none;
      }
    }
  }

  .comparison-header {
    height: 42px;
    display: flex;
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 0 0 auto;
    background-color: var(--c_topology-header);
    border-bottom: 1px solid var(--c_topology-border);
    padding: $unit $unit * 2;
    min-height: 0;
    margin: 0 0 1px 0;

    .col {
      min-height: 0;
      display: flex;
      align-items: center;
      flex: 1 1 auto;

      &.spacer {
        flex: 1 1 100%;
      }

      > .xw-button {
        margin: 0 0 0 $unit;
        padding: 0 $unit;
        height: 30px;
        white-space: nowrap;
        min-width: 80px;
      }
    }

    .show-filter {
      display: flex;
      align-items: center;
      > h6 {
        font-size: $ptToEm * 12;
        white-space: nowrap;
      }
      .xw-checkbox {
        margin: 0 0 0 18px;

        .subtext {
          display: none;
        }

        label[for="added"] {
          color: var(--c_topology-text-added);
        }

        label[for="updated"] {
          color: var(--c_topology-text-updated);
        }

        label[for="removed"] {
          color: var(--c_topology-text-removed);
        }
      }
    }
  }

  .topology-footer {
    position: relative;
    z-index: 3;
    width: 100%;
    display: flex;
    background-color: var(--c_topology-footer);
    padding: $unit 0 $unit * 2;
    min-height: 0;
    margin: 1px 0;
    flex: 0 0 auto;
    border-top: 1px solid var(--c_topology-border);
    align-items: flex-start;

    .col {
      position: relative;
      display: block;
      padding-left: $unit;

      &.large {
        flex: 1 1 100%;

        .xw-select {
          width: 100%;

          .xw-input-field {
            flex: 0 0 auto;
            width: 100%;
          }
        }
      }

      &:first-child {
        margin-left: $unit;
      }

      &:last-child {
        margin-right: $unit * 2;
      }

      .xw-switch-input {
        position: absolute;
        left: -1px;
        bottom: -18px;
      }
    }
  }

  .center {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .xw-page-loader,
  .xw-overlay-no-data {
    position: relative;
    flex: 1 1 100%;
    width: 100%;
  }
}

.xw-topology-graph {
  height: 100%;
  position: relative;
  flex: 1 1 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;

  .asn-node {
    position: relative;
    width: 100%;
    max-width: 200px;
    justify-self: center;
    align-items: center;
    display: flex;
    color: var(--c_topology-node-text);

    .node-wrapper {
      cursor: pointer;
      position: relative;
      z-index: 10;
      width: 100%;
      flex: 1 1 100%;
      background-color: var(--c_topology-node);
      border-radius: 100px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $ptToEm * 18;
      height: 50px;
      border: 2px solid transparent;

      &.updated {
        background-color: var(--c_topology-node-updated);
      }

      &.added {
        background-color: var(--c_topology-node-added);
      }

      &.removed {
        background-color: var(--c_topology-node-removed);
      }
    }

    .path-stuffing {
      z-index: 8;
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 50px;
      border: 2px solid var(--c_topology-node-stuffed-one);
      background: var(--c_content-bg);
      border-radius: 100px;
      transform: translate(4px, 4px);

      &.two {
        z-index: 6;
        transform: translate(8px, 8px);
        border: 2px solid var(--c_topology-node-stuffed-two);
      }
    }

    .peer-counts {
      flex: 1 1 100%;
      min-width: 0;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      row-gap: 3px;

      .path-set {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 10px;
        flex: 1 1 auto;
        height: 50px;
        background-color: var(--c_topology-peer-set-bg);
      }

      .peers {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.updated .val {
          color: var(--c_topology-text-updated);
        }

        &.added .val {
          color: var(--c_topology-text-added);
        }

        &.removed .val {
          color: var(--c_topology-text-removed);
        }
      }

      .label {
        font-size: $ptToEm * 12;
        line-height: 14px;
        color: var(--c_topology-peer-num);
      }

      .val {
        font-size: $ptToEm * 12;
        font-weight: 700;
        line-height: 14px;
        color: var(--c_topology-peer-num);
      }
    }

    &.hovered,
    &:focus {
      .node-wrapper {
        border-color: var(--c_topology-node-hover);
        color: var(--c_topology-node-text-active);
        outline: none;
      }

      .path-stuffing {
        border-color: var(--c_topology-node-hover);
      }

      .peer-counts {
        .path-set {
          background-color: var(--c_topology-peer-set-bg-hover);
        }
      }
    }

    &.active {
      .node-wrapper {
        border-color: var(--c_topology-node-active);
        color: var(--c_topology-node-text-active);
      }

      .path-stuffing {
        border-color: var(--c_topology-node-active);
      }

      .peer-counts {
        .path-set {
          background-color: var(--c_topology-peer-set-bg-hover);
        }
      }
    }

    &.selected {
      .node-wrapper {
        border-color: var(--c_topology-node-selected);
        color: var(--c_topology-node-text-active);
      }

      .path-stuffing {
        border-color: var(--c_topology-node-border-active);
      }

      .peer-counts {
        .path-set {
          background-color: var(--c_topology-peer-set-bg-hover);
        }
      }
    }

    .filtered {
      fill: var(--c_topology-node-text-active);
      width: 14px;
      height: 14px;
    }

    .actions {
      z-index: 12;
      position: absolute;
      right: calc(-#{$unit} / 2);
      top: -$unit;
      display: flex;

      .circle-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        width: $unit * 2;
        height: $unit * 2;
        background-color: var(--c_topology-node-border-active);
        margin-left: calc($unit / 4);

        .xw-icon {
          width: 12px;
          height: 12px;
          fill: var(--c_topology-node-icon);
        }
      }
    }

    &.destination {
      max-width: 400px;
      display: flex;

      .node-wrapper {
        position: relative;
        border-radius: 0 15px 15px 0;
        height: 100%;
      }
    }
  }

  .grid {
    width: 100%;
    display: grid;
    column-gap: 35px;
    row-gap: 20px;
    padding: 20px;
  }

  .lines {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: visible;
  }
}

.xw-topology-lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .asn-line {
    fill: transparent;
    stroke: var(--c_topology-node-line);
    stroke-width: 2px;
    opacity: 1;
    mix-blend-mode: exclusion;

    &.updated {
      stroke: var(--c_topology-node-updated);
    }

    &.added {
      stroke: var(--c_topology-node-added);
    }

    &.removed {
      stroke: var(--c_topology-node-removed);
    }

    &.hovered {
      stroke: var(--c_topology-node-hover);
      mix-blend-mode: normal;
    }

    &.active {
      stroke: var(--c_topology-node-active);
      mix-blend-mode: normal;
    }
  }
}

.xw-topology-detail {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 100px;
  right: $unit * 2;
  background-color: var(--c_topology-detail-bg);
  border-radius: 22px 22px 0 22px;
  z-index: 99;

  .path-sets {
    flex: 1 1 100%;
    min-height: 0;
    overflow-y: auto;
  }

  .title-wrapper {
    padding: $unit * 2 $unit * 2 $unit $unit * 2;
  }

  .title {
    color: var(--c_topology-detail-text);
    font-weight: 700;
    margin: 10px 0 0 0;
    font-size: $ptToEm * 14;
    line-height: 14px;
  }

  .number {
    font-size: $ptToEm * 18;
    line-height: 22px;
    color: var(--c_text-link);

    &:hover {
      color: var(--c_text-hover);
    }
  }

  .name {
    line-height: 16px;
    font-size: $ptToEm * 14;
    color: var(--c_topology-detail-text);
  }

  .actions {
    display: flex;
    padding: 0 $unit * 2;

    .xw-button {
      margin: 0 $unit $unit 0;
      .xw-icon {
        width: 12px;
        height: 12px;
        margin-left: 4px;
        fill: var(--c_topology-detail-text);
      }
    }
  }

  .path-detail {
    max-height: 450px;
    overflow-y: auto;
    padding: 0 $unit * 2;
    margin-bottom: 20px;
  }

  .paths {
    font-size: $ptToEm * 12;

    .path {
      display: flex;
      align-items: center;
      margin: 3px 0;
      .node {
        position: relative;
        flex: 0 0 auto;
        display: flex;
      }

      .asn {
        position: relative;
        flex: 0 0 auto;
        font-weight: 700;
        color: var(--c_topology-detail-asn);
        display: flex;
        align-items: center;

        .stuff-num {
          background-color: var(--c_topology-detail-icon);
          color: var(--c_topology-detail-text-bold);
          border-radius: 100px;
          font-size: $ptToEm * 10;
          padding: 3px;
          text-align: center;
          min-width: 16px;
          margin: 0 0 0 3px;
        }

        &.active {
          color: var(--c_topology-detail-text-bold) !important;
        }
      }

      .xw-icon {
        transform: rotate(90deg);
        fill: var(--c_topology-detail-icon);
        height: 10px;
        width: 10px;
        margin-right: 3px;
      }
    }
    .more {
    }
  }

  .path-set {
    margin: 0 24px 24px 24px;
    border: 1px solid var(--c_topology-detail-path-header);
    border-radius: 6px;
    background-color: var(--c_topology-detail-peer-set-bg);

    .path-wrapper {
      background-color: var(--c_topology-detail-path-header);
      padding: 9px 18px;
      border-radius: 4px 4px 0 0;

      &.updated .paths .path .asn {
        color: var(--c_topology-text-updated);
      }

      &.added .paths .path .asn {
        color: var(--c_topology-text-added);
      }

      &.removed .paths .path .asn {
        color: var(--c_topology-text-removed);
      }
    }

    .peers-wrapper {
      display: flex;
      padding: 14px 12px 12px 12px;
      gap: 24px;
      align-items: top;
    }

    .peers {
      .label {
        font-size: $ptToEm * 12;
        color: var(--c_topology-detail-peer-label);
        font-weight: 700;
        margin: 0 0 3px 0;
      }

      .vals {
        color: var(--c_topology-detail-peer-val);
      }

      .peer {
        font-size: $ptToEm * 13;
        line-height: 16px;

        &.updated {
          color: var(--c_topology-text-updated);
        }

        &.added {
          color: var(--c_topology-text-added);
        }

        &.removed {
          color: var(--c_topology-text-removed);
        }
      }
    }
  }
}
