@import "../../styles/variables";

.xw-breadcrumbs {
  font-size: 12 * $ptToEm;
  display: flex;
  flex-flow: row nowrap;

  > li:not(:last-child) {
    margin-right: calc($unit / 2);

    &:after {
      content: " ❯ ";
      color: var(--c_header-link-separator);
      font-weight: 700;
    }
  }

  a,
  span {
    line-height: 26px;
    height: 26px;
    display: inline-block;
    transition: color 0.15s ease;
  }

  a {
    color: var(--c_header-link);

    &:hover {
      color: var(--c_header-link-hover);
    }
  }

  span {
    color: var(--c_header-link-disabled);
    cursor: default;
  }
}
