@import "../../../styles/variables";

.xw-users-page .xw-data-table {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: 12px;
}
