@import "../styles/variables";

.xw-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .text {
    color: var(--c-table-nodata-text);
    font-size: 24 * $ptToEm;
    line-height: 33 * $ptToEm;
  }
  .sub-text {
    color: var(--c-table-overlay-subtext);
  }
}

.xw-overlay-error .text {
  color: var(--c-table-error-text);
}

.xw-overlay-warning .text {
  color: var(--c_text-warning);
}
