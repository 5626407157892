@import "../../../../styles/variables";
.xw-integrity {
  display: flex;
  align-items: center;

  .xw-tooltip {
    .tooltip-content {
      white-space: normal;
      line-height: 18px;
    }
  }
  .xw-button {
    margin: 0;
    overflow: hidden;
  }
  .checkbox-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px var(--c_table_column_update_text) solid;
    border-radius: 100px;
    box-sizing: border-box;
    margin-right: calc($unit/3);

    .xw-icon {
      fill: var(--c_table_column_update_text);
      width: 14px;
      height: 14px;
      transition: all 0.15s ease;
    }
  }
  .mismatch-icon {
    border: 1px var(--c-table-switch-active-fill) solid;
    background-color: var(--c_text-emphasis);
    .xw-icon {
      fill: var(--c-table-switch-active-fill);
      padding: 2px;
    }
  }
  .unknown-icon {
    border: 1px var(--c-table-switch-active-fill) solid;
    .xw-icon {
      fill: var(--c_text-emphasis);
      margin-right: 2px;
    }
  }
}
