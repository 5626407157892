@import "../../styles/variables";

.xw-purchasing-help {
  .help {
    padding: $unit $unit $unit $unit * 2;
    line-height: 25px;
    width: 65%;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    > div {
      padding: $unit $unit $unit $unit * 2;
      line-height: 20px;
      width: 60%;
      &.field-spacing {
        margin-bottom: 15px;
        .xw-textarea,
        textarea {
          width: 100%;
        }
      }
    }
  }
}
