@import "../../styles/variables";
.xw-loading-overlay {
  position: relative;
  display: inline-block;
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--c-table-inactive-background);
    opacity: 0.8;
    justify-content: center;
    align-items: center;
    z-index: 9;
    display: flex;
    .overlay-content {
      text-align: center;
      display: inline-block;
    }
  }
  .loading {
    .text {
      color: var(--c-table-loading-text);
      font-size: 24 * $ptToEm;
      line-height: 33 * $ptToEm;
    }
    .sub-text {
      color: var(--c-table-overlay-subtext);
    }
  }
  .no-data {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text {
      color: var(--c-table-nodata-text);
      font-size: 24 * $ptToEm;
      line-height: 33 * $ptToEm;
    }
    .sub-text {
      color: var(--c-table-overlay-subtext);
    }
  }
  .error {
    .text {
      color: var(--c-table-error-text);
      font-size: 24 * $ptToEm;
      line-height: 33 * $ptToEm;
    }
    .sub-text {
      color: var(--c-table-overlay-subtext);
    }
  }
}
