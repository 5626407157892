@import "../../styles/variables";

.xw-cdgs-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  & .xw-tabs:before {
    display: block;
    flex: 1 1 36px;
    content: " ";
    display: block;
    border-bottom: 1px solid var(--c_tabs-bottom-border);
  }
  .xw-cdgs-header {
    display: flex;
    flex-direction: column;
    padding: 24px;
    h2 {
      color: var(--c_page-header-title);
    }
    span {
      color: var(--c_page-header-label);
      text-transform: uppercase;
    }
  }
  .xw-data-table {
    height: 100%;
    .table-actions {
      margin: 24px;

      .xw-icon {
        margin-left: 6px;
      }
    }
    .actions {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      > .xw-button {
        white-space: nowrap;
      }
      > .xw-button:first-child {
        min-width: 170px;
      }
    }
  }
}
