@import "../../../styles/variables";

.xw-time-picker {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .hour,
  .min,
  .divider {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 34px;
    font-weight: 300;
    color: var(--c_timeRange-text-time);
  }

  .incrementer {
    padding: $unit;

    .xw-icon {
      fill: var(--c_timeRange-incrementer-icon);
    }

    &:hover {
      .xw-icon {
        fill: var(--c_timeRange-incrementer-icon-hover);
      }
    }
  }

  button.up {
    .xw-icon {
      transform: rotate(180deg);
    }
  }

  .xw-toggle {
    min-width: 0;
    margin-left: $unit;

    .rail .options button.toggle-option {
      min-width: 48px;
    }
  }

  &.showError {
    .value {
      color: var(--c_text-error);
    }
  }
}
