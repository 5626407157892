@import "../../styles/variables";

#patternTesterModal {
  .modal-body {
    display: flex;
    flex-direction: row;
  }

  .tester {
    flex: 1 1 100%;
    padding: $unit * 2;
    border-right: 1px var(--c_modal-dialog-border) solid;
    overflow-y: auto;
  }

  .help {
    flex: 1 1 100%;
    padding: $unit * 2;
    overflow-y: auto;
    color: var(--c_modal-help);

    p {
      line-height: 130%;
      margin-bottom: 0;
    }

    .highlight {
      color: var(--c_modal-highlight);
    }

    h3 {
      font-size: 18 * $ptToEm;
      font-weight: 300;
      color: var(--c_modal-subtitle);
      margin: ($unit * 2) 0 0 0;
    }

    .symbol {
      font-size: 24 * $ptToEm;
      margin: 0 calc($unit / 2);
    }

    .expression {
      display: flex;
      align-items: center;
      margin: calc($unit * 2) 0 0 0;
    }

    .any {
      font-weight: 700;
      color: var(--c_modal-any);
    }

    table {
      margin: $unit 0 0 0;
      font-size: 14 * $ptToEm;
      td {
        line-height: 130%;
        padding: calc($unit / 2) calc($unit / 3);

        &:first-child {
          white-space: nowrap;
        }
      }
    }
  }

  textarea,
  .xw-textarea {
    width: 100%;
  }

  .section-header {
    font-size: 18 * $ptToEm;
    color: var(--c_section-header-text);
    border-bottom: 1px solid var(--c_section-header-separator);
    margin-top: calc($unit * 2);
    padding-bottom: calc($unit * 0.75);
  }

  .results {
    display: grid;
    grid-template-columns: [path] auto [match] 60px [action] 120px;
    grid-auto-rows: auto;
    margin-top: $unit;
    margin-bottom: $unit;
    font-weight: 300;
    align-items: center;
    row-gap: calc($unit / 2);
    column-gap: calc($unit / 2);

    .path,
    .match,
    .action {
      font-size: 14 * $ptToEm;
    }

    .path {
      font-size: 12 * $ptToEm;
      line-height: 14 * $ptToEm;
      font-weight: 700;
      grid-column-start: path;
      color: var(--c_text-title);
    }

    .match {
      grid-column-start: match;
      font-size: 12 * $ptToEm;
      font-weight: 700;
    }

    .action {
      grid-column-start: action;
      text-align: right;
    }

    .pass {
      color: var(--c_text-ok);
    }

    .fail {
      color: var(--c_text-warning);
    }

    .error {
      color: var(--c_text-error);
      grid-column-start: path;
      grid-column-end: 4;
    }
  }
}
