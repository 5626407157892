@import "../../../../styles/variables";

.xw-status-hover-box {
  z-index: 100;
  position: absolute;
  top: 24px;
  left: -$unit * 2;
  right: -$unit * 2;
  border: 1px solid var(--c_statusflowchart-card-border);
  background-color: var(--c_statusflowchart-card-bg);
  padding: $unit;
  border-radius: 3px;
  overflow: hidden;
  color: var(--c_statusflowchart-card-text);
  cursor: default;

  .help-link {
    display: flex;
    align-items: center;
    position: absolute;
    top: $unit;
    right: $unit;

    .xw-icon {
      width: 14px;
      height: 14px;
      margin: 0 0 0 3px;
      fill: var(--c_text-link);
    }

    &:hover {
      color: var(--c_text-link);
    }
  }

  .xw-label-value {
    margin-bottom: $unit;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
