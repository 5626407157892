@import "../../styles/variables";

.xw-peers-page {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .actions > button {
    margin-right: 12px;
  }
  .actions {
    .xw-tooltip-trigger {
      margin-right: 12px;
    }
  }
  .peer-table-container {
    height: 100%;
  }
}

.xw-prefix-count-modal {
  position: relative;
  .ip {
    display: flex;
    .xw-input-field {
      padding: 4px;
      flex: 1 1 50%;
    }
  }
}
