@import "../../../styles/variables";

.xw-dashboard-page {
  margin: 1px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  box-sizing: border-box;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
  place-items: stretch;
  place-content: stretch;
  grid-gap: 3px;
  overflow: hidden;

  .widget {
    position: relative;

    &.alarm-table {
      grid-area: 1/1/10/9;
      border: 1px var(--c_card-border) solid;
      .table-title-container {
        background-color: var(--c_card-title-bg);
        color: var(--c_card-title-text);
        font-size: 1.25rem;
        font-weight: 300;
        padding: 12px 24px;
        // height: $table-title-height;
        display: flex;
        align-items: center;
        .table-title-action {
          display: flex;
          flex: 1 1 auto;
          justify-content: flex-end;
          align-items: center;

          .link-btn {
            display: flex;
            align-items: center;
          }
        }
        .table-title {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          > h3 {
            color: var(--c_card-subtitle-text);
            font-size: 14 * $ptToEm;
            line-height: 19 * $ptToEm;
          }
        }
      }
    }
    &.alarm-donut {
      grid-area: 10/1/13/5;

      .content {
        width: 100%;
        display: flex;
        align-items: left;
        justify-content: center;
      }
    }
    &.prefix-donut {
      grid-area: 10/5/13/9;

      .content {
        width: 100%;
        display: flex;
        align-items: left;
        justify-content: center;
      }
    }
    // &.bgp-updates {
    //   grid-area: 7/1/10/13;
    //   // border: 1px var(--c_card-border) solid;

    //     > .content {
    //       padding-bottom: 0;
    //     }

    //   .xw-bgp-updates-graph {
    //     height: 100%;
    //     min-height: 100px;
    //     .xw-column-chart{
    //       height: 100%;
    //       min-height: 100px;
    //     }
    //   }
    // }
    &.map {
      grid-area: 4/9/10/13;
    }
    &.links {
      grid-area: 10/9/13/13;
    }
    &.jump {
      grid-area: 1/9/4/13;

      .xw-form {
        display: flex;

        .xw-input-field {
          flex: 1 1 100%;
          margin-right: $unit;
        }

        .xw-button {
          flex: 0 0 auto;
          min-width: 0;
        }
      }
    }
  }

  .xw-activeAlarms-table {
    position: absolute;
    top: $table-title-height;
    right: 0;
    bottom: 0;
    left: 0;

    .custom-filter-controls-section {
      padding: 0;
    }
    .xw-data-table {
      height: 100%;
    }
  }

  .xw-active-alarms-country {
    .content {
      display: flex;
      flex-direction: column;
      padding: 0px;
      flex-wrap: nowrap;
      flex: 1 1 100%;

      .xw-geo-map {
        flex: 0 0 auto;
        height: 200px;
      }

      .xw-data-table {
        height: 100%;
      }
    }
  }

  .xw-card {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
    width: auto;
    margin: 0;
  }

  .donut {
    display: flex;
    align-items: center;

    .field {
      margin: 0;
    }
  }

  .link-card {
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      > a,
      span > a {
        display: block;
        font-size: 15 * $ptToEm;
        line-height: 18 * $ptToEm;
        color: var(--c_text-link);

        &:hover {
          color: var(--c_text-hover);
        }

        &::before {
          content: "";
          display: inline-block;
          margin: 0 9px 2px 0;
          border-radius: 6px;
          width: 6px;
          height: 6px;
          background-color: var(--c_ui-bullet);
        }
      }
    }
  }

  .coming-soon {
    position: absolute;
    text-align: center;
    top: calc(50% - 12px);
    left: 0;
    right: 0;
    font-size: 18 * $ptToEm;
    line-height: 24 * $ptToEm;
    font-weight: 300;
  }
}

.intro-modal {
  .xw-button {
    display: block;
    line-height: 34px;
    width: 181px;
  }

  .modal-body {
    padding: $unit * 2;

    p {
      line-height: 1.2em;
    }
  }

  .divider {
    height: 1px;
    background-color: var(--c_modal-border);
    width: 100%;
    margin: $unit * 2 0;
  }

  .actions {
    display: flex;
    gap: $unit;
  }

  .opt-out-checkbox {
    margin: 30px 0 0 0;
  }
}
