@import "../../styles/variables";

.xw-device-summary-counter-section {
  .xw-summary-counter-value {
    color: var(--c_text-title);
  }
}

.xw-device-delete-summary-container {
  padding: 6px 0;

  .xw-device-applications {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 12px 0;
  
    .xw-device-application-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
    
      .xw-application-type {
        display: flex;
        gap: 6px;
        
        .xw-icon {
          fill: var(--c_text-title);
          width: 18px;
        }
  
        .xw-application-type-label {
          font-size: 18px;
          color: var(--c_text-title);
        }
      }
  
      .xw-device-policy-rules-pair {
        display: flex;
        flex-direction: column;
        gap: 2px;
  
        .policy-rules-label {
          font-weight: 400;
          font-size: 12px;
          margin-right: 6px;
        }
  
        .policy-container {
          font-weight: 350;
          font-size: 15px;
          color: var(--c_header-link-hover);
        }
  
        .rules-container {
          font-weight: 350;
          font-size: 15px;
          color: var(--c_text-title);
        }
      }
    }
  }
}

.xw-devices-delete-message {
  margin-bottom: 24px;

  .xw-devices-delete-message-highlight {
    color: var(--c_text-title);
  }
}
