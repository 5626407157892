@import "../../styles/variables";

.xw-page-activity-log .xw-data-table {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: $unit;
  .change {
    &.added {
      color: var(--c_text-ok);
    }
    &.modified {
      color: var(--c_text-warning);
    }
    &.deleted {
      color: var(--c_text-error);
    }
  }
}
