body,
body.theme-dark {
  --c_content-bg: #1a1e20;
  --c_icon: #7ed1f6;
  --c_icon-input-action: #9fa5a6;
  --c_icon-close: #6b7273;
  --c_icon-close-hover: #ff4c52;
  --c_icon-hover: #7ed1f6;
  --c_icon-hover-two: #ffffff;
  --c_text: #9fa5a6;
  --c_text-link: #63bbe3;
  --c_text-hover: #a4e2fe;
  --c_text-title: #ffffff;
  --c_text-emphasis: #ffad53;
  --c_hr: #505859;

  // Statuses
  --c_text-ok: #7dff92;
  --c_text-warning: #ffad53;
  --c_text-error: #ff4c52;
  --c_text-unknown: #858b8c;
  --c_text-status-enabled: #7dff92;
  --c_text-status-disabled: #ff4c52;
  --c_text-red: #ff4c52;
  --c_text-status-notice: #fff45e;

  // UI Components Page
  --c_ui-title: #fff;
  --c_ui-divider: #373f40;
  --c_ui-bullet: #ffffff;

  // Status Flow Chart
  --c_statusflowchart-titles: #505859;
  --c_statusflowchart-card-text: #ffffff;
  --c_statusflowchart-card-text-secondary: #9fa5a6;
  --c_statusflowchart-card-bg: #2e3435;
  --c_statusflowchart-card-border: #505859;
  --c_statusflowchart-working: #7dff92;
  --c_statusflowchart-disabled: #ffad53;
  --c_statusflowchart-unknown: #858b8c;
  --c_statusflowchart-error: #ff4c52;
  --c_statusflowchart-filled-text: #191e20;
  --c_statusflowchart-icon-bg: #505859;

  // Header
  --c_header-logo: #ffffff;
  --c_header-bg: #282e2e;
  --c_header-border: #282e2e;
  --c_header-active: #087dbc;
  --c_header-env-text: #ff4c52;
  --c_header-link: #63bbe3;
  --c_header-disabled: #505859;
  --c_header-link-hover: #7ed1f6;
  --c_header-link-separator: #505859;
  --c_user-icon-bg: #505859;
  --c_user-name: #ffffff;
  --c_user-initials: #ffffff;
  --c_page-header-title: #edf1f2;
  --c_page-header-label: #858b8c;
  --c_section-header-text: #6b7273;
  --c_section-header-separator: #373f40;
  --c_section-header-emphasis: #bfdd20;

  // Footer
  --c_page-footer-bg: #212626;
  --c_page-footer-border: #373f40;

  // Text Input
  --c_textInput-bg: #212626;
  --c_textInput-bg-disabled: #1f2626;
  --s_textInput-border: 0;
  --s_textInput-line: 2px;
  --c_textInput-line: #373f40;
  --c_textInput-line-active: #66a5c2;
  --c_textInput-line-hover: #505859;
  --c_textInput-line-error: #ff4c52;
  --c_textInput-line-disabled: #373f40;
  --c_textInput-label: #9fa5a6;
  --c_textInput-hint: #9fa5a6;
  --c_textInput-label-disabled: #505859;
  --c_textInput-label-error: #ff4c52;
  --c_textInput-value: #ffffff;
  --c_textInput-value-disabled: #858b8c;
  --c_textInput-label-bg: linear-gradient(
    #212626 0%,
    #212626 75%,
    rgba(#212626, 0%) 100%
  );
  --c_textInput-label-bg-disabled: var(--c_textInput-label-bg);
  --c_textInput-line-required: #ddf94b;
  --c_textInput-label-required: #ddf94b;
  --c_textInput-search-icon: #373f40;

  // File Input
  --c_fileInput-text: #ffffff;
  --c_fileInput-bg: #087dbc;
  --c_fileInput-bg-hover: #029fd9;
  --c_fileInput-bg-error: #a31d22;
  --c_fileInput-bg-success: #1c802c;
  --c_fileInput-border: #029fd9;
  --c_fileInput-border-error: #ff4c52;
  --c_fileInput-border-success: #7dff92;

  // Range Input
  --c_rangeInput-track-bg: #373f40;
  --c_rangeInput-track-bg-focus: #7ed1f6;
  --c_rangeInput-track-border: transparent;
  --c_rangeInput-thumb-bg: #212626;
  --c_rangeInput-thumb-border: #7ed1f6;

  // Time Range
  --c_timeRange-title: #858b8c;
  --c_timeRange-subtitle: #ffffff;
  --c_timeRange-text-label: #9fa5a6;
  --c_timeRange-text-num: #ddf94b;
  --c_timeRange-text-month: #ffffff;
  --c_timeRange-text-day: #858b8c;
  --c_timeRange-text-time: #ffffff;
  --c_timeRange-arrow: #5f6566;
  --c_timeRange-line: #505859;
  --c_timeRange-text-placeholder: #ffffff;
  --c_timeRange-text-placeholder-active: #ddf94b;
  --c_timeRange-incrementer-icon: #{rgba(#7ed1f6, 0.35)};
  --c_timeRange-incrementer-icon-hover: #7ed1f6;
  --c_timeRange-cal-icon: #{rgba(#ddf94b, 0.35)};
  --c_timeRange-cal-hover: #ddf94b;
  --c_timeRange-refresh-label: #babebf;
  --c_timeRange-refresh-time: #ffffff;
  --c_timeRange-refresh-bg: #505859;

  // Calendar
  --c_cal-text: #d4d8d9;
  --c_cal-today-text: #ddf94b;
  --c_cal-today-text-disabled: #5f6566;
  --c_cal-today-border: #{rgba(#ddf94b, 0.35)};
  --c_cal-num-bg-hover: #212626;
  --c_cal-num-bg-selected: #ddf94b;
  --c_cal-num-bg-highlighted: #212626;
  --c_cal-num-text-hover: #7dd1f6;
  --c_cal-num-text-selected: #222727;
  --c_cal-date-selected-border: #5f6566;
  --c_cal-header-text: #5f6566;
  --c_cal-btn-icon: #7dd1f6;
  --c_cal-btn-icon-hover: #212626;
  --c_cal-btn-bg: #212626;
  --c_cal-btn-bg-hover: #7dd1f6;
  --c_cal-btn-border: #5f6566;
  --c_cal-select-bg: #212626;
  --c_cal-select-border: #5f6566;
  --c_cal-select-text-hover: #7dd1f6;
  --c_cal-select-btn-hover: #373f40;

  // Radio/Check (Selection Control) Input
  --c_checkbox-bg: #000000;
  --c_checkbox-border: #9fa5a6;
  --c_checkbox-active: #7dd0f6;
  --c_checkbox-error: #ff4c52;
  --c_checkbox-disabled: #4f5859;
  --c_checkbox-text: #ffffff;
  --c_checkbox-disabled-label: #6b7273;
  --c_checkbox-subtext: #9fa5a6;

  // Switch
  --c_switch-track-bg: #9fa5a6;
  --c_switch-track-bg-disabled: #505859;
  --c_switch-track-bg-on: #63bbe3;
  --c_switch-track-bg-on-disabled: #10618d;
  --c_switch-thumb-bg: #{rgba(255, 255, 255, 0.65)};
  --c_switch-thumb-bg-disabled: #6b7273;
  --c_switch-thumb-border: #ffffff;
  --c_switch-thumb-border-active: #029fd9;
  --c_switch-thumb-border-disabled: #858b8c;
  --c_switch-label: #9fa5a6;
  --c_switch-label-disabled: #505859;
  --c_switch-label-selected: #ffffff;
  --c_switch-label-selected-disabled: #9fa5a6;

  // Dropdown menu
  --c_dropdown-menu-bg: #373f40;
  --c_dropdown-menu-border: #282e2e;
  --c_dropdown-menu: #{rgba(0, 0, 0, 0.18)};
  --c_dropdown-menu-link: #edf1f2;
  --c_dropdown-menu-link-hover: #7ed1f6;

  // Tooltip
  --c_tooltip-bg: #{rgba(55, 63, 64, 0.94)};
  --c_tooltip-border: #505859;
  --c_tooltip-text: #ffffff;

  // Group Filter
  --c_group-filter-close: #505859;
  --c_group-filter-close-hover: #ff4c52;

  // Button
  --c_button-label: #ffffff;
  --c_button-secondary: #373f40;
  --c_button-secondary-label: #ffffff;
  --c_button-secondary-border: #505859;
  --c_button-secondary-hover: #505859;
  --c_button-secondary-pressed: #505859;
  --c_button-secondary-pressed-border: #7ed1f6;
  --c_button-secondary-disabled-border: #505859;
  --c_button-primary: #087dbc;
  --c_button-primary-border: #029fd9;
  --c_button-primary-hover: #029fd9;
  --c_button-primary-pressed: #029fd9;
  --c_button-primary-pressed-border: #7ed1f6;
  --c_button-primary-disabled-border: #029fd9;
  --c_button-primary-label: #ffffff;
  --c_button-secondary-label: #ffffff;
  --c_button-secondary-hover-label: #ffffff;
  --c_button-link-label: #63bbe3;
  --c_button-link-label-hover: #7ed1f6;
  --c_button-link-icon: #ffffff;
  --c_button-link-label-disabled: #babebf;
  --c_button-danger: #f02b31;
  --c_button-danger-border: #ff4c52;
  --c_button-danger-hover: #ff4c52;
  --c_button-danger-label: #ffffff;
  --c_button-outline-border: #505859;
  --c_button-outline-title: #babebf;
  --c_button-outline-text: #9fa5a6;
  --c_button-outline-border-hover: #848b8c;
  --c_button-outline-title-hover: #ffffff;
  --c_button-subtitle: #bfdd20;
  --c_button-search-icon: #7ed0f6;

  // Scrollbar
  --c_scrollbar-thumb-large: #6b7273;
  --c_scrollbar-thumb-large-hover: #858b8c;
  --c_scrollbar-track-large: #373f40;
  --c_scrollbar-thumb-small: #63bbe3;
  --c_scrollbar-thumb-small-hover: #7ed1f6;
  --c_scrollbar-track-small: #373f40;

  // table
  --c-table-header: #2c3233;
  --c-table-header-text: #d4d8d9;
  --c-table-footer-text: #d4d8d9;
  --c-table-icons: #7ed1f6;
  --c-table-icons-disabled: #505859;
  --c_table-odd: #202626;
  --c_table-odd-gradient-cover: linear-gradient(
    270deg,
    #202626 50%,
    rgba(32, 38, 38, 0) 100%
  );
  --c_table-even: #222829;
  --c_table-row-border: transparent;
  --c_table-row-hover: #303738;
  --c-table-divider: #505859;
  --c-table-border: transparent;
  --c-table-sort-active: #7dff92;
  --c-table-sort-inactive: #505859;
  --c-table-switch-active-background: #7ed1f6;
  --c-table-switch-active-fill: #373f40;
  --c-table-wedge-rowcount-color: #4b5555;
  --c-table-column-filter: #9fa5a6;
  --c-table-inactive-background: #1a1e20;
  --c-table-background: #1d2224;
  --c-table-error-text: #ff4c52;
  --c-table-nodata-text: #ffffff;
  --c-table-loading-text: #7ed1f6;
  --c-table-overlay-subtext: #babebf;
  --g-table-row-selected: #1e2f37;
  --c-table-secondary-action: #ddf94b;
  --c-table-secondary-action-hover: lighten(#ddf94b, 25%);
  --c-table-parent-row: #373f40;
  --c-table-sub-row: #2e3435;

  --c_data-table-row-border: #181e20;
  --c_data-table-text-edit: #ffad53;
  --c_data-table-text-add: #63bbe3;
  --c_data-table-text-sub: #9fa5a6;
  --c_data-table-divider-hover: #4b5555;
  --c_data-table-icon-bg-hover: #373f40;
  --c_data-table-row-number: #505859;
  --c_data-table-checkbox-filter-divider: #9fa5a6;
  --c_data-table-filter-action-hover: #a4e2fe;
  --c_data-table-no-data: #212626;

  // Toast
  --s_toast-width: 288px;
  --s_toast-height: 72px;
  --c_toast-bg: #{rgba(49, 55, 56, 0.9)};
  --c_toast-message: #edf1f2;
  --c_toast-custom: #858b8c;
  --c_toast-info: #7ed1f6;
  --c_toast-success: #7dff92;
  --c_toast-warning: #fff45e;
  --c_toast-error: #ff4c52;

  // Modal
  --c_modal-dialog-bg: #2e3435;
  --c_modal-dialog-border: #373f40;
  --c_modal-header-bg: #373f40;
  --c_modal-header-title: #d4d8d9;
  --c_modal-header-subtitle: #babebf;
  --c_modal-body-text: #9fa5a6;
  --c_modal-footer-bg: #212626;
  --c_modal-border: #373f40;

  --c_modal-error-bg: #313738;
  --c_modal-error-border: #373f40;
  --c_modal-error-left-border: #ff4c52;
  --c_modal-error-title: #ff4c52;
  --c_modal-error-text: #ffffff;

  --c_modal-backdrop-bg: #1a1e20;
  --c_modal-backdrop-dots: #202626;

  --c_modal-highlight: #ddf94b;
  --c_modal-subtitle: #9fa5a6;
  --c_modal-help: #edf1f2;
  --c_modal-any: #ffffff;

  //
  --c_drawer-title: #ffffff;
  --c_drawer-bg: #2e3435;
  --c_drawer-header-bg: #373f40;
  --c_drawer-footer-bg: #212626;

  // Tabs
  --c_tabs-bottom-border: #373f40;
  --c_tabs-text-default-color: #9fa5a6;
  --c_tabs-text-state-color: #ffffff;
  --c_tabs-primary-active-border: #ddf94b;
  --c_tabs-primary-pressed: #6b7273;
  --c_tabs-secondary-active-border: #ddf94b;
  --c_tabs-secondary-pressed: #6b7273;
  --c_tabs-secondary-bottom-border: #1a1e20;

  // Select
  --c_select-option: #ffffff;
  --c_select-option-bg-focus: #10618d;
  --c_select-dropdown-divider: #858b8b;
  --c_select-match: #ddf94b;
  --c_select-selected: #6b7273;

  // Chip
  --c_chip-text: #000000;
  --c_chip-action: #9fa5a6;
  --c_chip-action-add: #3ef15b;
  --c_chip-action-remove: #ff4c52;
  --c_chip-action-bg: #373f40;
  --c_chip-bg-0: #9fa5a6;
  --c_chip-bg-1: #7ed1f6;
  --c_chip-bg-2: #65fae8;
  --c_chip-bg-3: #7dff92;
  --c_chip-bg-4: #ddf94b;
  --c_chip-bg-5: #fff45e;
  --c_chip-bg-6: #ffad53;
  --c_chip-bg-7: #ff4c52;
  --c_chip-bg-8: #b163ff;
  --c_page-header-chip-bg: #9fa5a6;

  // Notification-banner
  --c_notification-info-elements-text: #000000;
  --c_notification-blue: #7ed1f6;
  --c_notification-teal: #65fae8;
  --c_notification-green: #7dff92;
  --c_notification-olive: #ddf94b;
  --c_notification-yellow: #fff45e;
  --c_notification-orange: #ffad53;
  --c_notification-red: #ff4c52;
  --c_notification-purple: #b163ff;

  // Levels - severity
  --c_level-label: #858b8c;
  --c_level-icon-bg: #505859;
  --c_low-level-icon-border: #505859;
  --c_low-level-icon-border-bottom: #65fae8;
  --c_med-level-icon-border: #505859;
  --c_med-level-icon-border-bottom: #fff45e;
  --c_high-level-icon-bg: #ff4c52;
  --c_none-level-icon-bg: #505859;

  // Range Selector
  --c_range-rail-bg: #373f40;
  --c_range-rail-hover-bg: #505859;
  --c_range-handle-bg: #212626;
  --c_range-handle-border: #9fa5a6;
  --c_range-handle-after-border: #7ed1f6;
  --c_range-handle-hover-border: #7ed1f6;
  --c_range-handle-active-border: #7ed1f6;
  --c_range-handle-active-after: #ffffff;
  --c_range-handle-disabled-bg: #505859;
  --c_range-handle-label: #ffffff;
  --c_range-handle-label-active: #7ed1f6;
  --c_range-handle-label-disabled: #858b8c;
  --c_range-track-bg: #9fa5a6;
  --c_range-hover-range-track: #7ed1f6;
  --c_range-hover-range-handle-border: #7ed1f6;
  --c_range-disabled-all: #373f40;

  // Charting
  --c_chart-title: #ffffff;
  --c_chart-label: #babebf;
  --c_chart-legend: #babebf;
  --c_chart-data: #ffffff;
  --c_chart-data-0: #029fd9;
  --c_chart-data-1: #fc9422;
  --c_chart-data-2: #ddf94b;
  --c_chart-data-3: #01edd1;
  --c_chart-data-4: #a142ff;
  --c_chart-data-5: #fff45e;
  --c_chart-area-0: #7ed1f6;
  --c_chart-area-1: #7dff92;
  --c_chart-area-2: #b163ff;
  --c_chart-area-3: #010202;
  --c_chart-area-4: #478f53;
  --c_chart-area-5: #fff45e;
  --c_chart-row-0: #ddf94b;
  --c_chart-row-1: #65fae8;
  --c_chart-row-2: #7dff92;
  --c_chart-row-3: #7ed1f6;
  --c_chart-row-4: #ffad53;
  --c_chart-column-0: #ddf94b;
  --c_chart-column-1: #65fae8;
  --c_chart-column-2: #7dff92;
  --c_chart-column-3: #7ed1f6;
  --c_chart-column-4: #ffad53;
  --c_chart-column-bg-0: #ddf94b;
  --c_chart-column-bg-1: #65fae8;
  --c_chart-column-bg-2: #b163ff;
  --c_chart-column-bg-3: #7ed1f6;
  --c_chart-column-bg-4: #ffad53;
  --c_chart-stacked-0: #029fd9;
  --c_chart-stacked-1: #01edd1;
  --c_chart-stacked-2: #ffad53;
  --c_chart-stacked-3: #fff45e;
  --c_chart-stacked-4: #b163ff;
  --c_chart-stacked-bg-0: #{rgba(#029fd9, 0.1)};
  --c_chart-stacked-bg-1: #{rgba(#01edd1, 0.1)};
  --c_chart-stacked-bg-2: #{rgba(#ffad53, 0.1)};
  --c_chart-stacked-bg-3: #{rgba(#fff45e, 0.1)};
  --c_chart-stacked-bg-4: #{rgba(#b163ff, 0.1)};
  --c_chart-tick: #505859;
  --c_chart-avg: #7dff92;
  --c_chart-p95: #b163ff;
  --c_chart-min: #ffad53;
  --c_chart-max: #fff45e;
  --c_chart-marker-border: #ffffff;
  --c_chart-indicator: #ffffff;
  --c_chart-select-marker: #ffffff;
  --c_chart-select-fill: #{rgba(255, 255, 255, 0.1)};
  --c_chart-data-placeholder: black;
  --c_chart-unit: #ddf94b;
  --c_sankey-title: #d4d8d9;
  --c_trend-hover: #ffad53;
  --c_trend-hover-bg: #{rgba(255, 173, 83, 0.2)};
  --c_chart-double-top: #ddf94b;
  --c_chart-double-bottom: #65fae8;
  --c_chart-series-0: #b163ff;
  --c_chart-series-1: #fc9422;
  --c_chart-dual-area-1: #7dff92;
  --c_chart-dual-area-2: #fff45e;
  --c_chart-dual-area-3: #b163ff;
  --c_chart-dual-area-4: #fff675;
  --c_chart-dual-brush-handle-hover: #ddf94b;
  --c_chart-dual-hover-default: #babebf;
  --c_chart-brush-select-area: #{rgba(255, 255, 255, 0.1)};

  --c_gauge-low: #ddf94b;
  --c_gauge-medium: #ffad53;
  --c_gauge-high: #ff4c52;
  --c_gauge-indicator-fill: #ffffff;
  --c_gauge-indicator-stroke: #191e20;

  --c_geo-fill-1: #c4ff00;
  --c_geo-fill-2: #98bf16;
  --c_geo-fill-3: #738f16;
  --c_geo-fill-4: #637627;
  --c_geo-fill-5: #5a6634;
  --c_geo-fill-noval: #505859;
  --c_geo-fill-hover: #7ed1f6;
  --c_geo-fill-selected: #7ed1f6;
  --c_geo-border: #2e3435;
  --c_geo-value: #ffffff;

  // Toggle
  --c_toggle-bg: #131717;
  --c_toggle-border: #373f40;
  --c_toggle-border-selected: #373f40;
  --c_toggle-border-outer: #000000;
  --c_toggle-label: #6b7273;
  --c_toggle-label-hover: #7ed1f6;
  --c_toggle-label-selected: #ffffff;
  --c_toggle-marker: #212626;

  // Path list
  --c_path-value: #7ed1f6;
  --c_path-hightlight-value: #ddf94b;
  --c_path-separator: #ffffff;
  --c_multi-path-bg: #373f40;
  --c_multi-path-border: #505859;
  --c_hop-count-diamond: #ddf94b;
  --c_hop-count-text: #202626;

  // Value
  --c_value-text: #d4d8d9;
  --c_value-icon: #c4c4c4;

  // Card
  --c_card-title-text: #edf1f2;
  --c_card-subtitle-text: #babebf;
  --c_card-title-bg: #373f40;
  --c_card-title-bg2: #2e3435;
  --c_card-bg: #2e3435;
  --c_card-bg2: #212626;
  --c_card-border: #2e3435;
  --c_card-bg-nested: #292f30;
  --c_card-link: #63bbe3;
  --c_card-bg-active: #22322b;
  --c_card-expander-bg: #505859;
  --c_card-expander-bg-hover: #586162;
  --c_card-btn-close: #ffffff;
  --c_card-btn-close-hover: #7ed1f6;

  // Accordion
  --c_accordion-header-background: #212626;
  --c_accordion-content-background: #131717;
  --c_accordion-content-border: transparent;
  --c_accordion-title-text: #ddf94b;
  --c_accordion-title-text-disabled: #6b7273;
  --c_accordion-subtitle-text: #d4d8d9;
  --c_accordion-icon-background: #373f40;
  --c_accordion-icon-box-border: #505859;
  --c_accordion-icon-color: #ddf94b;
  --c_accordion-icon-disabled: #9fa5a6;

  // Login page
  --c_login_background: #191e20;
  --c_login-logo-color: #ffffff;

  // Step Wizard
  --c_stepwizard_left: #131717;
  --c_stepwizard_right: #080a0a;
  --c_stepwizard_right_selected: #131717;
  --c_stepwizard_caption: #edf1f2;
  --c_stepwizard_sub_caption: #858b8c;
  --c_stepwizard-border: transparent;
  --c_stepwizard-section-text-color: #ffffff;

  // Welcome Page
  --c_welcome_grey: #d4d8d9;

  // Express page
  --c_express_selected: #ddf94b;

  // number circle
  --c_circle: #087dbc;
  --c_circle_border: #087dbc;
  --c_circle-text: #ffffff;
  --c_circle-active: #bfdd20;
  --c_circle-active-text: #131717;

  --c_circle-aspath-background: #ffffff;

  // help
  --c_help_section_label: #9fa5a6;
  --c_help_section_description: #ffffff;
  --c_help_section_separator: #505859;
  --c_help_section_bullet-bg: #ffffff;
  --c_help_li_item: #7ed1f6;

  // external help Link Button
  --c_help_link_button-bg: #373f40;
  --c_help_link_button-border: #505859;
  --c_help_icon_button-fill: #ffffff;

  // Import export configuration
  --c_importExport_caution_text: #d4d8d9;
  --c_fileImport_upload_icon-fill: #6b7273;

  // Modification column values
  --c_modification_Delete: #ff4c52;
  --c_modification_Create: #7dff92;
  --c_modification_Edit: #fff45e;

  // Processing Modal component
  --c_processing_modal_loader: #7ed1f6;
  --c_processing_modal_processing_title: #d4d8d9;
  --c_processing_modal_processing_message: #9fa5a6;
  --c_processing_modal_failure_title: #ff4c52;
  --c_processing_modal_failure_message: #9fa5a6;
  --c_processing_modal_success_title: #d4d8d9;
  --c_processing_modal_success_message: #9fa5a6;

  // Well
  --c_well-bg: #131717;
  --c_well-border: #212626;

  // page-section
  --c_title-special-text: #ddf94b;

  // page-caption
  --c_caption_text: #d4d8d9;

  //table-columns
  --c_table_column_update_text: #7dff92;

  // column-donut
  --c_column-donut-bg: #505859;
  --c_column-donut-bg-active: #ffffff;
  --c_column-donut-value: #d4d8d9;
  --c_column-donut-secondary: #9fa5a6;

  // user-detailspage-header-border
  --c_page_header_border_color: #373f40;
  --c_api_key_text_color: #ddf94b;

  // Filter By
  --c_filterby-item-text: #e1e4e5;
  --c_filterby-item-bg: #2c3233;
  --c_filterby-item-title: #ffffff;
  --c_filterby-label: #9fa5a6;

  --c_card-arrow-active: #7dff92;
  --c_card-arrow-inactive: #6b7273;
  --c_card-arrow-inprogress: #ddf94b;
  // Diff Tree
  --c_difftree-bg: #101313;
  --c_difftree-text: #ffffff;
  --c_difftree-text-secondary: #858b8c;
  --c_difftree-text-legend: #858b8c;
  --c_difftree-header-bg: #282e2e;
  --c_difftree-header-text: #ffffff;
  --c_difftree-expander-bg: #282e2e;
  --c_difftree-expander-bg-hover: #383f40;
  --c_difftree-row-bg: #202626;
  --c_difftree-parent-change: #4af15b;
  --c_difftree-add: #4af15b;
  --c_difftree-change: #fff45e;
  --c_difftree-delete: #ff4c52;
  --c_difftree-modified: #7ed1f6;
  --c_difftree-row-bg-hover: #282e2e;
  --c_difftree-footer-bg: #282e2e;
  --c_parent-menu-active: #bfdd20;
  --c_parent-menu-background: #2e3435;
  --c_parent-menu-border-top-bg: linear-gradient(
    180deg,
    #{rgba(0, 0, 0, 0.12)} 0%,
    #{rgba(0, 0, 0, 0)} 100%
  );
  --c_parent-menu-border-bottom-bg: linear-gradient(
    0deg,
    #{rgba(0, 0, 0, 0.12)} 0%,
    #{rgba(0, 0, 0, 0)} 100%
  );

  // Device Image
  --c_device-image-label: #ffffff;
  --c_device-image-label-border: #029fd9;
  --c_device-image-label-border-virtual: #b163ff;
  --c_device-image-label-icon: #ffffff;
  --c_device-image-box: #d4d8d9;
  --c_device-image-rack: #d4d8d9;

  // Filter
  --c_filter_box-bg: #212626;
  --c_filter_box-border: #2e3435;
  --c_filter_operator-include: #ddf94b;
  --c_filter_operator-exclude: #ff4c52;
  --c_filter_operator-disabled: #505859;
  --c_filter_box-text: #d4d8d9;
  --c_filter_box-close: #202626;
  --c_filter_box-close-background: #373f40;
  --c_filter-btn-text: #63bbe3;
  --c_filter-bg: #373f40;
  --c_filter-modal-header-background: #373f40;
  --c_filter-modal-body-background: #2e3435;
  --c_filter-modal-footer-background: #212626;

  // Device Capabilities
  --c_device-capabilities-title: #373f40;
  --c_device-capabilities-item-title: #ffffff;
  --c_device-capabilities-check-na: #858b8c;
  --c_device-capabilities-check-upgrade: #ffffff;
  --c_device-capabilities-check-active: #7dff92;

  // coming soon
  --c_coming-soon: #7ed1f6;
  --c_not-licensed: #ffad53;

  // Timeline Bucket Chart
  --c_tlbucketchart-legend-bg: linear-gradient(
    180deg,
    #{rgba(49, 56, 56, 0.35)} 0%,
    #{rgba(49, 56, 56, 0)} 50%,
    #{rgba(49, 56, 56, 0.35)} 100%
  );
  --c_tlbucketchart-legend-dossierErrors-diagonalStripes: #131717;
  --c_tlbucketchart-y-legend-background: linear-gradient(180deg, #31383859 0%, #31383800 51.04%, #31383840 100%);
  --c_tlbucketchart-legend-background: #131717;
  --c_tlbucketchart-border: #313838;
  --c_tlbucketchart-track-bg: #131717;
  --c_tlbucketchart-action: #63bbe3;
  --c_tlbucketchart-action-active: #ffffff;
  --c_tlbucketchart-action-hover: #ffffff;
  --c_tlbucketchart-tlpoint-line: #ffad53;
  --c_tlbucketchart-tlpoint-arrowline: #fff45e;
  --c_tlbucketchart-tlpoint-rectangularDotLine: #B163FF;
  --c_tlbucketchart-tlpoint-dotline: #b163ff;
  --c_tlbucketchart-tlpoint-dotline-circle: #ffffff;
  --c_tlbucketchart-tlpoint-dotline-error: #FF4C52;
  --c_tlbucketchart-axis-text-primary: #ffffff;
  --c_tlbucketchart-axis-text-secondary: #9fa5a6;
  --c_tlbucketchart-data-top: #7dff92;
  --c_tlbucketchart-data-bottom: #65fae8;
  --c_tlbucketchart-data-zero: #9fa5a6;
  --c_tlbucketchart-select-line: #505859;
  --c_tlbucketchart-select-line-hover: #7ed1f6;
  --c_tlbucketchart-select-bg: #2e3435;
  --c_tlbucketchart-arrow-select: #ffffff;
  --c_tlbucketchart-overlay: #{rgba(25, 30, 32, 0.85)};
  --c_tlbucketchart-pager-icon-hover: #ffffff;
  --c_doublestackchart-card-bg: #2e3435;
  --c_doublestackchart-date-title: #ffffff;
  --c_doubletrendchart-card-bg: #232829;

  --c_countdown-active: #ddf94b;
  --c_countdown-expiring: #ffad53;
  --c_countdown-expired: #ff4c52;
  --c_countdown-recent: #505859;

  --c_trial_select-dropdown: #7ed0f6;

  --c_changes-modify: #ffad53;

  // Main Nav
  --c_mainNav-bg: #373f40;
  --c_mainNav-seperater-small: #{rgba(255, 255, 255, 0.5)};
  --c_mainNav-seperater-large: #505859;
  --c_mainNav-border: #1a1e20;
  --c_mainNav-text: #d4d8d9;
  --c_mainNav-text-heading: #6b7273;
  --c_mainNav-text-active: #ddf94b;
  --c_mainNav-text-hover: #ffffff;
  --c_mainNav-icon: #ffffff;
  --c_mainNav-icon-active: #ddf94b;
  --c_mainNav-icon-hover-bg: #{rgba(255, 255, 255, 0.2)};
  --c_mainNav-icon-active-bg: #{rgba(#ddf94b, 0.2)};
  --c_mainNav-pin: #505859;
  --c_mainNav-pin-hover: #63bbe3;
  --c_mainNav-pin-active: #ddf94b;
  --c_mainNav-gradient: linear-gradient(180deg, #087dbc 0%, #93a920 100%);

  --c_circle-check-success: #bfdd20;
  --c_drilldown-shadow: -6px 1px 5px 0px rgba(0, 0, 0, 0.25);
  --c_drilldown-text: #a4e2fe;
  --c_drilldown-text-active: #ffffff;
  --c_drilldown-text-hover: #ffffff;
  --c_drilldown-text-1: #ffffff;
  --c_drilldown-text-2: #9fa5a6;

  --c_card-disabled: rgba(163, 99, 29, 0.1);
  --c_card-disabled-header: #edf1f2;
  --c_card-disabled-text: #ffad53;

  --c_expander-header: rgba(55, 63, 64, 1);
  // Peer ID
  --c_peer-id-text: #191e20;
  --c_peer-id-background: #6b7273;

  --c-expander-plus: #bfdd20;
  --c_tier-available: #ddf94b;
  --c_tier-na: #858b8c;
  --c_tier-partial: rgba(191, 221, 32, 0.5);
  --c_tier-partial-line: #c4c4c4;
  --c_tier-text: #d4d8d9;
  --c_tier-current-selection: rgba(255, 255, 255, 0.1);
  --c_tier-current-selection-border: rgba(255, 255, 255, 0.4);
  --c_tier_plan-icon-bg: #000000;
  --c_tier_plan-active: #ddf94b;
  --c_tier_plan-border: #6b7273;
  --c_tier-notification-link: #0873dd;
  --c_tier-section-header-text: #6b7273;
  --c_tier-text-link: #7ed1f6;

  // Reccomendations
  --c_recommendations-title: #edf1f2;
  --c_recommendations-headers: #858b8c;

  // Telemetry
  --c_telemetry-picker-border-selected: #edf1f2;

  // Policy
  --c_content-section-header: #ffffff;
  --c_modal_policy-title: #d4d8d9;
  --c_modal-policy-subtitle: #babebf;
  --c_modal_policy-rule-title: #d4d8d9;
  --c_modal_policy-rule-sub-title: #babebf;
  --c_policy-summary-count: #ffffff;
  --c_policy-data-included: #7dff92;
  --c_policy-data-excluded: #ff4c52;
  --c_policy-notification-border: #2e3435;
  --c_policy-notification-background: #2e3435;
  --c_range-utilization-error: #ff4c52;
  --c_range-utilization-handle: #2e3435;
  --c_policy-default-title: #ddf94b;
  --c_policy-list-active-alarm-border: #ff4c52;
  --c_policy-list-active-alarm-background: #ff4c52;
  --c_policy-list-active-alarm-count-text: #000000;
  --c_policy-list-active-alarm-text: #d4d8d9;
  --c_policy-list-default-details: transparent;
  --c_policy-list-default-details-text: #7ed1f6;
  --c_policy-create-modal-header-background: #373f40;
  --c_policy-create-modal-sub-title-text: #ffffff;
  --c_policy-create-modal-sub-title-sub-text: #858b8c;
  --c_policy-create-modal-policy-type-text: #ffffff;
  --c_policy-create-modal-object-border: #ddf94b;
  --c_policy-create-modal-footer-background: #212626;
  --c_policy-select-modal-title: #edf1f2;
  --c_policy-select-add-queue-icon: #505859;
  --c_policy-select-add-queue-text: #ffffff;
  --c_policy-select-added-queue-icon: #7dff92;
  --c_policy-select-added-queue-text: #6b7273;

  // BGP Updates
  --c_daul-axis-chart-header-bg: #181e20;
  --c_daul-axis-chart-bg: #121717;
  --c_bgp-updates-legend-lbl: #edf1f2;
  --c_bgp-updates-border: #212626;
  --c_bgp-updates-filter-bg: #181e20;
  --c_bgp-updates-filter-button-bg: #007ec0;
  --c_bgp-updates-filter-button-text: #ffffff;
  --c_bgp-updates-filter-button-bg-hover: #00a1dd;
  --c_bgp-updates-filter-button-border: #00a1dd;
  --c_bgp-updates-filter-warning-border: #FFAD53;
  --c_bgp-updates-filter-warning-icon: #1A1F1F;
  --c_bgp-updates-filter-warning-icon-bg: #FFAD53;
  --c_bgp-updates-filter-warning-text: #D4D8D9;

  // Topology
  --c_topology-header: #282e2e;
  --c_topology-footer: #282e2e;
  --c_topology-border: #373f40;
  --c_topology-node: #303d36;
  --c_topology-node-removed: #705a5b;
  --c_topology-node-added: #58845f;
  --c_topology-node-updated: #a18458;
  --c_topology-node-stuffed-one: #28332e;
  --c_topology-node-stuffed-two: #222b26;
  --c_topology-node-text: #ffffff;
  --c_topology-node-hover: #aaaaaa;
  --c_topology-node-active: #cccccc;
  --c_topology-node-selected: #ffffff;
  --c_topology-node-text-active: #ffffff;
  --c_topology-node-border-active: #ffffff;
  --c_topology-node-icon: #282e2e;
  --c_topology-node-line: #333;
  --c_topology-node-line-hover: #ffffff;
  --c_topology-node-line-active: #ffffff;
  --c_topology-detail-bg: rgba(55, 63, 64, 0.9);
  --c_topology-detail-text: #babebf;
  --c_topology-detail-asn: #849a8e;
  --c_topology-detail-text-bold: #ffffff;
  --c_topology-detail-icon: #6b7273;
  --c_topology-detail-path-header: #1f1f1f;
  --c_topology-peer-set-bg: #212626;
  --c_topology-peer-set-bg-hover: #373f40;
  --c_topology-detail-peer-set-bg: #282e2e;
  --c_topology-detail-peer-set-border: #383f40;
  --c_topology-detail-peer-label: rgb(132, 154, 142);
  --c_topology-detail-peer-val: #ffffff;
  --c_topology-peer-num: #ffffff;
  --c_topology-text-added: #7dff92;
  --c_topology-text-updated: #ffad53;
  --c_topology-text-removed: #ff4c52;

  // API Docs
  --c_api-header: #282e2e;
  --c_api-titlebar: #212626;
  --c_api-text: #212626;
  --c_api-label: #6b7273;
  --c_api-val: #babebf;
  --c_api-get: #fff;
  --c_api-post: #85cd91;
  --c_api-put: #e7e082;
  --c_api-patch: #CC956D;
  --c_api-delete: #cc6d70;
  --c_api-param-bg: #131717;
  --c_api-param-code: #ffffff;
  --c_api-param-sub: #858b8c;

  // Code Display
  --c_code-bg: #080a0a;
  --c_code-head-bg: #131717;
  --c_code-text: #858b8c;
  --c_code-name: #d4d8d9;
  --c_code-brackets: #fff;
  --c_code-key: #9cdcfe;
  --c_code-number: #b5cea8;
  --c_code-string: #cc9077;
  --c_code-true: #7dff92;
  --c_code-false: #ff4c52;
  --c_code-null: #569ad5;

  // Markdown
  --c_markdown-bg: #1a1e20;
  --c_markdown-h1: #ffffff;
  --c_markdown-h2: #ffffff;
  --c_markdown-h6: #ffffff;
  --c_markdown-line: #505859;
  --c_markdown-text: #ccc;
  --c_markdown-link: #63bbe3;

  // Queue
  --c_queue-bg: #2e3435;
  --c_queue-head-bg: #373f40;
  --c_queue-title: #edf1f2;
  --c_queue-subtitle: #babebf;
  --c_queue-item: #212626;
  --c_queue-item-icon: #ffffff;
  --c_queue-actual-num: #7dff92;
  --c_queue-actual-num-error: #ff4c52;
  --c_queue-item-border: #2e3435;

  // Add CDG
  --c_cdg-arrow: #6b7273;
  --c_cdg-gateway: #7ed1f6;
  --c_cdg-revoke: #ff4c52;
  --c_cdg-allow: #7dff92;
  --c_cdg-revoke-table-bg: #282d2e;
  --c_cdg-table-highlight-text: #7ed1f6;
}
