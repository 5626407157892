@import "../../styles/variables";
.xw-animated-lines {
  rect {
    transform-origin: top;

    &:nth-child(1) {
      animation: 20s linear 0s infinite running down;
    }

    &:nth-child(2) {
      animation: 9s linear 0s infinite running up;
    }

    &:nth-child(3) {
      animation: 14s linear 0s infinite running down;
    }

    &:nth-child(4) {
      animation: 7s linear 0s infinite running up;
    }

    &:nth-child(5) {
      animation: 10s linear 0s infinite running down;
    }

    &:nth-child(6) {
      animation: 16s linear 0s infinite running up;
    }

    &:nth-child(7) {
      animation: 13s linear 0s infinite running down;
    }

    &:nth-child(8) {
      animation: 11s linear 0s infinite running up;
    }

    &:nth-child(9) {
      animation: 20s linear 0s infinite running up;
    }

    &:nth-child(10) {
      animation: 25s linear 0s infinite running down;
    }

    &:nth-child(11) {
      animation: 13s linear 0s infinite running up;
    }

    &:nth-child(12) {
      animation: 18s linear 0s infinite running down;
    }

    &:nth-child(13) {
      animation: 30s linear 0s infinite running up;
    }

    &:nth-child(14) {
      animation: 35s linear 0s infinite running down;
    }

    &:nth-child(15) {
      animation: 40s linear 0s infinite running up;
    }

    &:nth-child(16) {
      animation: 20s linear 0s infinite running down;
    }
  }
}

@keyframes down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(120%);
  }
  50.001% {
    transform: translateY(-120%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes up {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-120%);
  }
  50.0001% {
    transform: translateY(120%);
  }
  100% {
    transform: translateY(0);
  }
}
