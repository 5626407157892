@import "../../../styles/variables";

.xw-dossier-file-select {
  height: 100%;
  display: flex;
  flex-direction: column;
  .device-common-details {
    .fields {
      display: flex;
      flex: 1 1 auto;
      flex-flow: row nowrap;
      justify-content: space-between;
      padding: $unit $unit 0;
      .xw-label-value {
        margin-bottom: $unit;
        padding-right: $unit;
        min-width: 100px;
      }
    }
  }
  .xw-data-table {
    flex: 1 1 auto;
    height: 100%;
    .selected-files {
      display: flex;
      flex-flow: row nowrap;
      flex: 1 0 100%;
      padding-left: $unit;
      font-size: 16 * $ptToEm;
      font-weight: 300;
      line-height: 28 * $ptToEm;
      .text {
        padding-right: $unit;
        color: var(--c_page-header-label);
      }
      .total {
        color: var(--c_textInput-label-required);
        font-weight: 700;
      }
      .selected {
        color: var(--c_text-emphasis);
        font-weight: 700;
      }
    }
  }
}
