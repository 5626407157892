@import "../../../styles/variables";

.xw-input {
}

// A way of defining query selectors as a variable that's just stupid enough to work
$inputs: "input[type = " text "],input[type = " password "],input[type = "
  number "],input[type = " email "],.xw-select-input,.xw-chip-list.editable";

// Base styling for input elements
#{$inputs} {
  z-index: 1;
  background-color: var(--c_textInput-bg);
  display: block;
  width: 100%;
  height: $input-height;
  color: var(--c_textInput-value);
  padding: ($unit * 1.5) $unit 0;
  box-sizing: border-box;
  font-size: 15 * $ptToEm;
  border: var(--s_textInput-border) var(--c_textInput-line) solid;
  border-bottom-width: var(--s_textInput-line);
  border-radius: 2px;
  transition: border 0.25s ease;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    color: var(--c_textInput-value-disabled);
    border-color: var(--c_textInput-line-disabled);
    background-color: var(--c_textInput-bg-disabled);

    & + label {
      color: var(--c_textInput-label-disabled);
    }
  }

  &:hover:not(:disabled):not(:focus):not(.disabled) {
    border-color: var(--c_textInput-line-hover);
  }

  &:focus {
    border-color: var(--c_textInput-line-active);
  }

  &:focus {
    outline: none;

    & + label {
      transform: translateY(-10px) scale(0.8);
    }
  }
}

// Fields
.field,
.xw-input-field {
  position: relative;
  margin-bottom: $unit * 2;

  > label {
    display: block;
    z-index: 2;
    position: absolute;
    top: 15px;
    left: $unit;
    transform-origin: left;
    transition: transform 0.15s ease;
    pointer-events: none;
  }

  > .hint,
  > .error {
    position: absolute;
    top: $input-height + calc($unit / 2);
    font-size: var(--s_font-small);
  }

  > .hint {
    color: var(--c_textInput-hint);
  }

  > .error {
    color: var(--c_textInput-label-error);
    display: none;
  }

  &.value {
    label {
      transform: translateY(-10px) scale(0.8);
    }
  }

  &.showRequired {
    #{$inputs} {
      border-color: var(--c_textInput-line-required);
    }
    .error {
      color: var(--c_textInput-label-required);
    }
  }

  &.showError {
    #{$inputs} {
      border-color: var(--c_textInput-line-error);
    }
  }

  &.showError,
  &.showRequired {
    > .hint {
      display: none;
    }

    > .error {
      display: block;
    }
  }

  > .xw-icon {
    position: absolute;
    right: $unit * 1.5;
    top: 16px;
  }
}

.submitted .field.invalid,
.submitted .xw-input-field.invalid {
  > .hint {
    display: none;
  }
  > .error {
    display: block;
  }

  #{$inputs} {
    border-color: var(--c_textInput-line-error);
  }
}

.theme-high-contrast {
  #{$inputs} {
    &:hover:not(:disabled):not(.disabled),
    &:focus {
      border-bottom-width: 2px;
    }
  }
}
