@import "../../styles/variables";

.xw-value {
  font-size: 14 * $ptToEm;
  line-height: 14 * $ptToEm;
  > span {
    padding: 0 2px 0 3px;
    color: var(--c_value-text);
  }
  .value-unit {
    display: inline-block;
    font-size: 10px;
    line-height: 14px;
  }
  .xw-icon {
    width: 10px;
    height: 10px;
    fill: var(--c_value-icon);
  }
}
