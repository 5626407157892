@import "../../../styles/variables";

.xw-insights-findpkg-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 12px;

  .xw-insights-findpkg-filter-container {
    display: flex;
    justify-content: space-between;
  }

  .xw-insights-findpkg-search {
    flex: 0 0 auto;
    display: flex;
    width: 75%;
  }

  .xw-insights-findpkg-view {
    min-height: 72px; // to prevent bounce
  }

  .xw-insights-name-search {
    display: inline-block;
    padding-left: $unit;
    width: 75%;
  }

  .xw-insights-findpkg-selected-time {
    flex: 0 0 auto;
    padding-top: $unit;
    padding-right: $unit;
  }
  .xw-data-table {
    height: 100%;
  }
}
