@import "../../styles/variables";

.xw-rule-edit-card,
.xw-endpoint-card {
  position: relative;

  > .rule-card-title.title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .spacer {
      flex-grow: 1;
    }
  }

  > .content {
    // height: 84px;
    padding: 0 ($unit * 2);
    overflow: visible;

    .main-inputs {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin: ($unit * 1.5) 0;

      > :not(:first-child) {
        margin-left: $unit;
        &.xw-label-value {
          margin-left: $unit * 2;
        }
      }
      input[type="number"] {
        width: 128px;
      }

      .xw-label-value {
        margin-bottom: 0;
      }

      .prohibited-ip-options {
        flex-basis: 100%;
        margin: 0;
        .xw-checkbox {
          margin-top: $unit * 2;
        }
      }
    }
  }

  .xw-switch-input {
    .off,
    .on {
      min-width: auto;
    }
  }

  .xw-input-field {
    margin-bottom: 0;
    height: $input-height;
  }
}

.xw-rule-edit-card {
  > .content {
    padding-bottom: $unit;

    .xw-switch-field {
      width: 128px;
    }
  }

  .xw-dns-root-server-card {
    display: flex;
    flex-flow: column wrap;
    .dns-root-server-info {
      margin-top: $unit * 2;
      font-weight: bold;
    }
    .column-titles {
      display: flex;
      flex-flow: row wrap;
      margin-top: $unit * 2;
      .prefix-title {
        display: flex;
        flex-direction: row;
        flex: 0 0 20%;
      }
      .toggle-title {
        flex: 0 0 40%;
        .toggle-text {
          color: var(--c_text-title);
          font-weight: normal;
        }
      }
    }
    .dns-root-server-section {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      .row {
        display: flex;
        flex-direction: row;
        flex: 1 1 100%;
        .item {
          display: flex;
          flex-direction: row;
          flex: 0 0 20%;
          .empty {
            margin-top: calc($unit / 2);
          }
          .xw-check-input .xw-checkbox {
            margin: 6px 36px 0 0;
          }
        }
        .description {
          flex: 0 0 40%;
          margin: 0;
          padding: 0;
          align-items: flex-start;
        }
      }
    }
    .actions {
      display: flex;
      .xw-button {
        margin: $unit calc($unit / 2) 0 0;
      }
    }
  }
}

.xw-rule-edit-card-subprefix-mask-selector-break {
  flex-basis: 100%;
}

.xw-rule-edit-card-subprefix-mask-selector-container {
  display: flex;
}

.xw-rule-edit-card-subprefix-mask-selector {
  width: 100%;
  padding: 2 * $unit 6 * $unit 2 * $unit 0;
}

.xw-rule-edit-card-subprefix-mask-section {
  width: 100%;
  margin-left: 0 !important; // undo indentation caused by section
  h5 {
    color: var(--c_section-header-text);
    font-weight: 300;
  }
  .slider-handles .handle-0,
  .handle-2 {
    &.disabled {
      background: var(
        --c_card-bg
      ) !important; // needs to match background container so that it breaks the line
    }
  }
  .handle-1 {
    background: var(--c_card-bg) !important;
  }
}

.xw-endpoint-select-card {
  > .rule-card-title.title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .spacer {
      flex-grow: 1;
    }

    .xw-button {
      margin: 0;
    }
  }
}
