@import "../../../styles/variables";

.xw-trafficDatePicker {
  position: relative;
  width: 100%;

  &.has-hint {
    .xw-select {
      width: 100%;
      margin-bottom: $unit * 2;
    }
  }

  .field-wrapper {
    width: 100%;
    position: relative;

    .xw-select {
      width: 100%;

      .xw-select-input {
        padding-right: $unit * 4;
      }
    }

    &:hover {
      .xw-input-field {
        input:disabled {
          border-color: var(--c_timeRange-cal-icon-hover);
        }

        .xw-icon {
          fill: var(--c_textInput-line-active);
        }
      }
    }
  }

  .hint,
  .error {
    position: absolute;
    top: $input-height + calc($unit / 2);
    left: 0;
    width: 100%;
    font-size: var(--s_font-small);
  }

  .hint {
    color: var(--c_textInput-label);
  }

  .error {
    color: var(--c_textInput-label-error);
    display: none;
  }

  &.value {
    label {
      transform: translateY(-10px) scale(0.8);
    }
  }

  &.showRequired {
    .xw-input-field input {
      border-color: var(--c_textInput-line-required) !important;
    }
    .error {
      color: var(--c_textInput-label-required);
    }
  }

  &.showError {
    .xw-input-field input {
      border-color: var(--c_textInput-line-error);
    }
  }

  &.showError,
  &.showRequired {
    .hint {
      display: none;
    }

    .error {
      display: block;
    }
  }
}

.traffic-date-picker-modal-wrapper {
  .modal-body {
    padding: $unit * 2 0;
  }

  .xw-calendar {
    border-bottom: none;
    padding-bottom: 0;
    width: 100%;
    flex: 0 0 auto;
    margin-bottom: -$unit * 3;
  }

  .cal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px var(--c_timeRange-line) solid;
  }

  .bottom-content {
    padding: $unit * 2 $unit * 2 0;

    .xw-select {
      margin: 0 0 $unit * 2;
      width: 100%;
    }

    .dates {
      display: flex;
      justify-content: space-between;

      .date {
        flex: 1 1 100%;

        &.error {
          .value {
            color: var(--c_text-error);
          }
        }
      }

      .label {
        font-weight: 700;
        color: var(--c_timeRange-refresh-label);
        margin: 0 0 calc($unit / 3);
      }

      .value {
        color: var(--c_timeRange-text-time);
      }
    }
  }
}
