@import "../../../styles/variables";

.xw-endpoints {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  .xw-page-tabs {
    height: 100%;
    .xw-data-table{
      .endpoint-actions-wrapper {
        display: flex;
        min-width: fit-content;
        .endpoint-create-btn {
          padding: 0px 10px;
        }
      }
    }
  }
  .xw-tabs {
    margin: ($unit * 2) ($unit * 1) 0 calc($unit * 4 / 3);
  }


}
