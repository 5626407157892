@import "../../styles/variables";

.xw-traffic-devices-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding-top: 12px;
  height: 100%;

  .xw-dual-axis-chart {
    flex: 0 0 100%;
  }

  .xw-page-tabs {
    height: 100%;

    .xw-tabs {
      margin-top: calc($unit / 2);

      > li {
        padding: calc($unit/2) $unit * 2;
      }
    }
  }

  .filters {
    height: $unit * 4;
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    margin: 10px 10px 0 10px;

    .xw-filter {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
     
      .filter-boxes {
        padding-bottom: 0;
      }
  
      .add-button {
        align-items: center;
        height: 34px;
        min-height: 0;
        padding: 0 $unit * 2;
        min-width: 125px;
        .theme-high-contrast & {
          text-decoration: none;
        }
      }
    }

  }

  .xw-device-traffic-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .xw-tooltip-trigger .device-bulk-edit {
      margin-left: 20px;
    }

  }
}
