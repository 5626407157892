@import "../../styles/variables";
.xw-page-interfaces {
  position: absolute;
  margin-top: 12px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  .xw-dual-axis-chart {
    flex: 0 0 100%;
  }

  .xw-page-tabs {
    height: 100%;

    .xw-tabs {
      margin-top: calc($unit / 2);

      > li {
        padding: calc($unit/2) $unit * 2;
      }
    }
  }

  .filters {
    height: $unit * 4;
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    margin: 10px 10px 0 10px;

    .xw-filter {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .filter-boxes {
        padding-bottom: 0;
      }
  
      .add-button {
        align-items: center;
        min-height: 0;
        padding: 0 $unit * 2;
        .theme-high-contrast & {
          text-decoration: none;
        }
      }
    }
  }

  .xw-data-table {
    margin-top: 10px;
    height: 100%;
  }

  .table-actions > .xw-toggle {
    min-width: 240px;
  }
}
