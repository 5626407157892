@import "../../styles/variables";

.xw-data-table-page-controls {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-right: $unit;

  .xw-button {
    padding: 0;
    min-width: $unit * 2;
    margin: 0;
  }

  .bold-text {
    font-weight: 700;
  }

  .page-number {
    margin: 0 calc($unit / 2);
    line-height: 34px;
  }

  .xw-button .xw-icon {
    fill: var(--c-table-icons);
  }
}
