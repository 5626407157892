@import "../../styles/variables";

.xw-help {
  .help-section {
    padding: $unit 0;
    margin-bottom: $unit;

    .app-section {
      margin: $unit * 2 0 0 0;
      h2 {
        font-weight: $ptToEm * 18;
        font-weight: 300;
        color: var(--c_help_section_bullet-bg);
      }
      a {
        line-height: 18px;
        display: block;
        color: var(--c_text-link);
        &:hover {
          color: var(--c_text-hover);
        }
      }
    }

    label {
      color: var(--c_help_section_label);
      font-size: 24 * $ptToEm;
      line-height: 33 * $ptToEm;
    }
    .description {
      color: var(--c_help_section_description);
      font-size: 15 * $ptToEm;
      line-height: 20 * $ptToEm;
    }
    .seperator {
      border-bottom: 1px solid var(--c_help_section_separator);
      padding: 10px 0;
    }
  }
  ul {
    margin-top: $unit;
    li {
      list-style: disc;
      font-size: 24 * $ptToEm;
      line-height: $unit * 2;
      color: var(--c_help_section_bullet-bg);
      margin: 0 0 0 $unit * 2;
      a,
      button,
      .opener-container {
        display: block;
        color: var(--c_help_li_item);
        font-size: 15 * $ptToEm;
        line-height: $unit * 2;
        height: $unit * 2;
      }
      > div,
      > a,
      > button {
        position: relative;
        top: -3px;
      }
    }
  }
  .opener-container {
    display: flex;
    align-items: center;

    .xw-button {
      margin: 0;
      padding: 0;
      color: var(--c_help_li_item);
    }
  }
}

.xw-modal {
  textarea[name="feedbackDescription"] {
    width: 600px;
  }
  .field-spacing {
    margin-bottom: 15px;
    p {
      font-size: 15 * $ptToEm;
      line-height: 20 * $ptToEm;
    }
  }
}
