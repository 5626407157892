@import "../../../styles/variables";

.xw-device-select {
  height: 100%;
  display: flex;
  flex-direction: column;
  .xw-filter {
    padding: 0 0 12px 0px;
  }
  .xw-device-select-table {
    flex: 1 1 auto;
  }
}
