@import "../../../styles/variables";

.xw-time-range-picker {
  .times {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .xw-icon.arrow-icon {
      flex: 0 0 auto;
      fill: var(--c_timeRange-arrow);
      width: 24px;
      height: 24px;
    }
  }

  .increment-picker {
    display: flex;
    align-items: center;

    .label {
      flex: 0 0 auto;
      text-transform: uppercase;
      font-weight: 300;
      color: var(--c_timeRange-text-label);
    }

    .spacer {
      flex: 1 1 100%;
      height: 1px;
      margin: 0 9px 0 12px;
      background-color: var(--c_timeRange-line);
    }

    .xw-radio {
      position: relative;
      top: 2px;
      flex: 0 0 auto;
      display: flex;
      padding: 9px;
      &:last-child {
        padding-right: 0;
      }
      > input {
        display: block;
        position: absolute;
        z-index: 3;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      > label {
        flex: 0 0 auto;
        display: block;
        padding: 0 6px 0 0;
      }
      .checkbox {
        position: relative;
        top: 1px;
        flex: 0 0 auto;
        pointer-events: none;
      }
      .subtext {
        display: none;
      }
    }
  }
}
