@import "../../styles/variables";

.xw-filter {
  display: flex;
  .filter-boxes {
    margin-right: 6px;
    padding-bottom: calc($unit / 2);
  }
  .add-button {
    border: 1px solid var(--c_filter_box-border);
    box-sizing: border-box;
    border-radius: 2px;
    min-width: 290 * $ptToEm;
    text-align: center;
    background: var(--c_filter_box-bg);
    align-items: center;
    color: var(--c_filter-btn-text);
    height: $unit * 4;
    min-height: 48 * $ptToEm;
    .theme-high-contrast & {
      text-decoration: underline;
    }
  }
}

.fieldFilter-modal-wrapper {
  .xw-modal {
    .modal-header {
      background-color: var(--c_filter-modal-header-background);
    }
    .modal-body {
      background-color: var(--c_filter-modal-body-background);
    }
    .modal-footer {
      background-color: var(--c_filter-modal-footer-background);
    }
  }
  .modal-body.scrollbar-small {
    height: unset;
    overflow: unset;
    z-index: 100;
  }
  .xw-page-caption {
    padding: 0 0 $unit * 2 0;
  }
  .field {
    .xw-select {
      width: 70%;
    }
  }
  .single-operator,
  .include-operator {
    height: 34px;
    background-color: var(--c_textInput-bg);
    border-radius: 2px;
    border: 1px solid var(--c_toggle-border);
    text-align: center;
    color: var(--c_toggle-label-selected);
    display: inline-flex;
    min-width: 120px;
    align-items: center;
    justify-content: center;
  }
  .component-toggle {
    display: flex;
    background-color: var(--c_textInput-bg);
    max-width: 480px;
    .xw-input-field {
      margin-bottom: unset;
      flex: 1;
    }
    input {
      padding: $unit 0 0 $unit;
    }
    .single-operator,
    .include-operator {
      border: 0;
      align-self: center;
      h6 {
        border: 0;
        font-size: 12px;
        color: var(--c_modification_Delete);
      }
    }
    .include-operator {
      h6 {
        color: var(--c_textInput-label-required);
      }
    }
    .xw-select {
      width: 300px;
    }
    .xw-select.auto-height {
      margin-bottom: unset;
    }
    .xw-toggle {
      align-self: flex-start;
      position: relative;
      min-width: unset;
      flex: 0 0 35%;
      .rail {
        position: absolute;
        top: 10px;
        border: 0;
      }
      .marker {
        border-radius: 0;
        border: none;
        background-color: transparent;
        &:before {
          border: 0;
        }
      }
      .options {
        border: 0 transparent;
        button {
          background: var(--c_textInput-bg);
          min-width: 80px;
          &:first-child {
            &.selected {
              span {
                color: var(--c_textInput-label-required);
              }
            }
          }
          &:last-child {
            &.selected {
              span {
                color: var(--c_modification_Delete);
              }
            }
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  .bytes-input {
    max-width: 550px;
  }
}

.xw-filter-box {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-width: 290 * $ptToEm;
  background: var(--c_filter_box-bg);
  border: 1px solid var(--c_filter_box-border);
  height: $input-height;
  padding-right: 5px;
  .content {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 18 * $ptToEm;
    cursor: pointer;
    overflow-y: auto;
    .label {
      display: flex;
      flex-direction: row;
      font-size: 10 * $ptToEm;
      padding-top: 3 * $ptToEm;
      align-items: center;
      .header {
        font-weight: bold;
        padding-right: 8 * $ptToEm;
        line-height: 16 * $ptToEm;
        color: var(--c_value-text);
      }
      .operator {
        font-size: 12 * $ptToEm;
        line-height: 16 * $ptToEm;
        color: var(--c_filter_operator-disabled);
        .include {
          color: var(--c_filter_operator-include);
        }
        .exclude {
          color: var(--c_filter_operator-exclude);
        }
      }
    }
    .value {
      color: var(--c_filter_box-text);
      padding-top: 4 * $ptToEm;
      .chip-container {
        display: flex;
        overflow-y: hidden;
        white-space: nowrap;
        .chip {
          display: inline-block;
          margin: 0 6px 0 0;
          text-align: center;
          flex: 0 1 auto;
          background-color: var(--c_page-header-chip-bg);
          border: 1px solid var(--c_page-header-chip-border);
          height: 18px;
          position: relative;
          border-radius: 100px;
          color: var(--c_chip-text);
          padding: 3px 12px;
          font-size: 0.75rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
        }
        .xw-button {
          margin: 0;
          padding: 0;
          height: 20px;
          font-size: 12 * $ptToEm;
          text-decoration: none;
        }
      }
    }
  }

  > .bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc($unit / 2);
    background-color: var(--c_filter_box-border);
    &.include {
      background-color: var(--c_filter_operator-include);
    }
    &.exclude {
      background-color: var(--c_filter_operator-exclude);
    }
  }

  > .close-button {
    position: absolute;
    right: $unit;
    width: 2 * $unit;
    height: 2 * $unit;
    background: var(--c_filter_box-close-background);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    padding: 5px;
    .xw-icon {
      overflow: hidden;
      width: 12px;
      height: 12px;
      fill: var(--c_filter_box-close);
    }

    &:hover {
      .theme-high-contrast & {
        background-color: var(--c_icon-close-hover);
        .xw-icon {
          fill: var(--c_filter_box-bg);
        }
      }
      .xw-icon {
        fill: var(--c_icon-close-hover);
      }
    }
  }
}
