@import "../../styles/variables";

.xw-removed-devices {
  padding-top: 1px;
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .status-enabled {
    text-align: center;
    width: 100px;
    margin-left: 9px;
  }
  .restore {
    width: 100px;
    height: 26px;
  }
}
