@import "../../../styles/variables";

input.xw-auto-size-input {
  border: none;
  display: inline-block;
  width: auto;
  min-width: $unit;
  padding: 0;
}

.xw-auto-sizer {
  visibility: hidden;
  display: block;
}

input.xw-auto-size-input,
.xw-auto-sizer {
  font-size: 15 * $ptToEm;
  height: 28 * $ptToEm;
  line-height: 28 * $ptToEm;
}

.xw-auto-sizer {
  // add a bit of fudge because sizing math doesn't seem to be perfect
  padding: 0 calc($unit / 4);
}
