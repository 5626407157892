@import "../../styles/variables";
.xw-table-wrapper {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  .table-sizer {
    flex: 1 1 100%;
    height: 100%;
  }
}

.virtual-table {
  flex: 1 1 100%;
  height: 100%;

  .xw-loading-overlay {
    display: flex;
    flex: 1 1 100%;
    height: 100%;
    flex-direction: column;

    .xw-table {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      .innerScrollContainer {
        flex: 1 1 100%;
        height: auto !important;
        max-height: 100% !important;
      }
    }
  }
}
@import "./tableColumns";

.xw-table {
  min-height: 121px;
  .cell,
  .headerCell {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0.5em;
  }
  .xw-icon {
    // this screws overwrites icon sizes.
    //width: 12px;
    //height: 12px;
    fill: var(--c-table-icons);

    &.icon-disabled {
      fill: var(--c-table-icons-disabled);
    }
  }

  .xw-table-headerRow,
  .headerRow {
    flex: 0 0 auto;
    border-radius: 2px 0;
    display: flex;
    align-items: center;
    height: $unit * 4;
    border-top: 1px var(--c-table-border) solid;
    border-bottom: 1px var(--c-table-border) solid;
    background-color: var(--c-table-header);
    #table-header-checkbox {
      width: 100%;
      height: 100%;
    }
    .xw-dropdown-menu-button {
      flex: 0 0 auto;
      background-color: var(--c-table-header);
      width: $unit * 2;
      display: flex;
      align-items: center;
      justify-content: center;
      .headerColumn {
        padding-left: 0;
        width: 100%;
      }
      .xw-dropdown-menu {
        .range-slider {
          margin: 7%;
          padding-top: 10px;
          width: 85%;
          height: 40px;
        }
        .xw-range-selector {
          .slider-handles {
            button {
              margin-top: -9px;
            }
          }
        }
        &.open {
          text-transform: capitalize;
          .filter-header {
            height: 24px;
          }
          .select-controls {
            height: 22px;
          }
          .xw-checkbox {
            height: 100%;
          }
          .xw-input-field > label {
            left: 10px;
          }
          .nav-menu-content {
            display: flex;
            button {
              color: var(--c-table-overlay-subtext);
              &.active {
                color: var(--c-table-nodata-text);
                pointer-events: none;
              }
            }
          }
        }
      }
    }
    .xw-dropdown-menu-button.table-menu {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: auto;

      > button {
        width: $unit * 4;
        height: $unit * 4;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        min-width: auto;

        .xw-icon {
          box-sizing: content-box;
          padding: 6px;
          border-radius: 3px;
        }
      }

      .headerColumn {
        padding-left: 0;
        width: 100%;
      }
      .xw-dropdown-menu {
        .range-slider {
          margin: 7%;
          padding-top: 10px;
          width: 85%;
          height: 40px;
        }
        .xw-range-selector {
          .slider-handles {
            button {
              margin-top: -9px;
            }
          }
        }
      }
    }
    .dropdown-open {
      .xw-icon {
        fill: var(--c-table-switch-active-fill);
        background-color: var(--c-table-switch-active-background);
        padding: 2px;
      }
    }
    .open {
      z-index: 1;
    }
    .configColumn {
      width: 5%;
    }
    .headerColumn {
      min-width: 0;
      padding-left: 18px;
      height: $unit * 4;
      display: flex;
      align-items: center;
      position: relative;
    }
    .sortableHeaderIcon {
      flex: 0 0 12px;
      height: 12px;
      width: 12px;
      fill: currentColor;
      .sort-active {
        fill: var(--c-table-sort-active);
      }
      .sort-inactive {
        fill: var(--c-table-sort-inactive);
      }
    }
    .table_header_ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .DragHandle {
      flex: 0 0 0px;
      z-index: 2;
      cursor: col-resize;
      align-items: center;
    }
    .DragHandleActive,
    .DragHandleActive:hover {
      z-index: 3;
    }
    .DragHandleIcon {
      flex: 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: right;
      align-items: center;
      font-size: var(--s_font-normal);
      width: 1px;
      height: var(--s_font-normal);
      background: var(--c-table-divider);
    }
  }
  .no-pointer-event {
    pointer-events: none;
  }
  .xw-table-grid {
    flex: 1 1 100%;
    background-color: var(--c-table-background);
    border-right: 1px var(--c-table-header) solid;
    border-left: 1px var(--c-table-header) solid;

    .innerScrollContainer,
    .infinite-scroll {
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 1px var(--c_table-row-border) solid;
        &.oddRow {
          background-color: var(--c_table-odd);
        }
        &.evenRow {
          background-color: var(--c_table-even);
        }
        &.oddRow:hover {
          background-color: var(--c_table-row-hover);
        }
        &.evenRow:hover {
          background-color: var(--c_table-row-hover);
        }
        &.selected-row {
          background: var(--g-table-row-selected);
        }
      }
      .table_header_ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
      .rowColumn {
        min-width: 0;
        padding-left: 18px;
        overflow: hidden;
        white-space: nowrap;
        .xw-tooltip {
          max-width: 100%;
        }
        .xw-tooltip-trigger {
          display: flex;
        }
        .triggerData-asn {
          // make sure old tables display tooltips in the correct position
          .xw-tooltip-trigger {
            display: inherit;
          }
        }

        .xw-checkbox {
          margin-bottom: 0px;
          height: 20px;
        }
        .xw-checkbox > input {
          position: relative;
          width: 100%;
          height: 100%;
        }

        a.link {
          color: var(--c_button-link-label);
          &:hover {
            color: var(--c_button-link-label-hover);
            transition: color 0.15s ease;
          }
        }
        .xw-button {
          padding-left: 0;
          margin-left: 0;
        }
      }
      .wedge {
        display: flex;
        justify-content: center;
        flex: 0 0 $unit * 4;
        padding-right: $unit * 1.5;
        text-align: center;
        color: var(--c-table-wedge-rowcount-color);
      }
      .xw-dropdown-menu.open {
        z-index: 1000;
      }
    }
  }

  .header-text-capitalize {
    text-transform: capitalize;
    .select-controls {
      display: flex;
      padding-bottom: 4px;
      flex-direction: row;
      justify-content: space-between;
      height: 20px;
      color: var(--c_dropdown-menu-link);
      .asn {
        text-transform: uppercase;
      }
      .xw-checkbox > input {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }
    .filter-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 20px;
      color: var(--c-table-column-filter);
      padding-bottom: 8px;
      margin-bottom: 8px;
      .xw-checkbox > input {
        position: relative;
        height: 100%;
        width: 100%;
      }
      label {
        color: var(--c-table-column-filter);
      }
    }
  }

  .table-header-label {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1 1 100%;
    font-weight: 700;
    overflow: hidden;
    user-select: none;
    color: var(--c-table-header-text);
  }

  .sortableHeaderColumn {
    .table-header-label {
      cursor: ns-resize;
    }
  }

  .table-header-actions {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    user-select: none;

    > div {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 $unit 0 0;
      height: 100%;
    }

    .sort-icon {
      pointer-events: none;
    }

    &:after {
      content: "";
      display: block;
      height: $unit;
      width: 1px;
      background-color: var(--c-table-divider);
    }
  }

  .table-header-checkbox-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    height: 16px;
    width: 16px;

    .xw-checkbox {
      height: 16px;
      margin: 0;
    }
  }

  .footer {
    flex: 0 0 auto;
    border-radius: 0 0 2px 2px;
    background-color: var(--c-table-header);
    height: 48px;
    padding-top: 1px;
    box-sizing: border-box;
    color: var(--c-table-footer-text);
    .page-scroll-summary,
    .infinite-scroll-summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      .left {
        flex: 0 0 auto;
        text-align: left;
        margin-left: $unit * 2;
      }
      .right {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        margin-right: $unit * 2;
        padding-bottom: 1px;
        .link {
          display: inline-flex;
          padding: 0;
          .xw-icon {
            fill: var(--c-table-icons);
          }
        }
        .link:disabled {
          .xw-icon {
            opacity: 0.25;
          }
        }
      }
      .bold-text {
        font-weight: bold;
      }
      .page-number {
        padding: 2px 6px 0 6px;
      }
    }
  }
}

.resize-triggers {
  animation: 1ms resizeanim;
  visibility: hidden;
  opacity: 0;
}
.resize-triggers,
.resize-triggers > div,
.contract-trigger:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}
.resize-triggers > div {
  background: var(--c-table-header);
  overflow: auto;
}
.contract-trigger:before {
  width: 200%;
  height: 200%;
}

.headerWedge {
  flex: 0 0 36px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

input.check-header-indeterminate {
  width: 20px;
  margin-top: 6px;
}

.noCells {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
}

.noRows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
}

.GridRow {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}
.GridColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.LeftSideGridContainer {
  flex: 0 0 50px;
}

.centeredCell {
  align-items: center;
  text-align: center;
}

.letterCell {
  font-size: 1.5em;
  color: #fff;
  text-align: center;
}

.Table {
  width: 100%;
  margin-top: 15px;
}

.custom-filter-controls-section {
  flex: 0 0 auto;
  min-height: 0;
  padding: $unit ($unit * 2);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .xw-input-field {
    margin-bottom: 0;
    width: 300px;
  }

  .global-search-input-div {
    margin-left: auto;
  }

  .xw-button {
    margin: 0;
  }

  .xw-button.primary {
    margin: 0 $unit 0 0;
  }

  .actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    button.primary:not(:last-child) {
      margin-right: $unit * 1.5;
    }
    button.link {
      padding: 0 $unit;
    }
  }
}

.custom-table-title {
  margin-top: 18px;
}

.record-count-text {
  padding-top: 40px;
  margin-right: 15px;
}

.global-search-input {
  margin-right: 5px;
  outline: none;
}

.global-search,
.record-count-text,
.filter-search {
  display: inline-block;
}

.table-right {
  order: 1;
  width: 300px;
}

.table-left,
.table-right {
  padding: 0px;
}

.maxWidth {
  width: 100%;
}

.table-custom-legend {
  margin-top: 15px;
  float: right;
}
