@import "../../styles/variables";

.xw-card.xw-geo-map-card {
  // width: 598px;
  // height: 294px;
  .title-container {
    display: flex;
    .geo-card-actions {
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-end;
    }
  }
  .title .value {
    font-size: 14 * $ptToEm;
    color: var(--c_card-subtitle-text);
  }
  .content {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .xw-geo-map {
      height: 100%;
      width: 100%;

      .legend {
        padding-left: $unit * 2;
      }
    }

    .geo-table {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      .custom-filter-controls-section {
        display: none;
      }
    }
  }
}
