@import "../../../styles/variables";
.xw-byte-range-filter {
  > label {
    margin-bottom: $unit;
  }
  .xw-bytes-input {
    display: flex;
    flex-flow: row nowrap;
    .xw-numeric-input {
      width: 14ch;
      margin-right: $unit;
    }
    .xw-select {
      width: 96px;
      min-width: 96px;
    }
  }
}
