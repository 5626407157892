body.theme-light {
  --c_content-bg: #f5f7f7;
  --c_icon: #087dbc;
  --c_icon-hover: #087dbc;
  --c_icon-hover-two: #000;
  --c_icon-close: #babebf;
  --c_icon-close-hover: #d7262c;
  --c_text: #373f40;
  --c_text-title: #505859;
  --c_text-link: #087dbc;
  --c_text-emphasis: #fc9422;
  --c_hr: #babebf;
  --c_icon-input-action: #9fa5a6;
  --c_text-hover: #029fd9;

  // Statuses
  --c_text-ok: #01aa96;
  --c_text-warning: #fc9423;
  --c_text-error: #d7262c;
  --c_text-unknown: #858b8c;
  --c_text-status-enabled: #01aa96;
  --c_text-status-disabled: #d7262c;
  --c_text-red: #d7262c;
  --c_text-status-notice: #b9ae1d;

  // UI Components Page
  --c_ui-title: #000000;
  --c_ui-divider: #373f40;
  --c_ui-bullet: #b9bebf;

  // Status Flow Chart
  --c_statusflowchart-titles: #999;
  --c_statusflowchart-card-text: #000;
  --c_statusflowchart-card-text-secondary: #555;
  --c_statusflowchart-card-bg: #f2f6f7;
  --c_statusflowchart-card-border: #ddd;
  --c_statusflowchart-working: #01aa96;
  --c_statusflowchart-disabled: #fc9423;
  --c_statusflowchart-unknown: #858b8c;
  --c_statusflowchart-error: #d7262c;
  --c_statusflowchart-filled-text: #ffffff;
  --c_statusflowchart-icon-bg: #bbb;

  // Header
  --c_header-logo: #029fd9;
  --c_header-bg: #ffffff;
  --c_header-border: #e1e4e5;
  --c_header-active: #029fd9;
  --c_header-env-text: #d7262c;
  --c_header-link: #10618d;
  --c_header-link-disabled: #373f40;
  --c_header-link-hover: #029fd9;
  --c_header-link-separator: #babebf;
  --c_user-icon-bg: #9fa5a6;
  --c_user-name: #505859;
  --c_user-initials: #ffffff;
  --c_page-header-title: #505859;
  --c_page-header-label: #babebf;
  --c_section-header-text: #6b7273;
  --c_section-header-separator: #d4d8d9;
  --c_section-header-emphasis: #01aa96;

  // Footer
  --c_page-footer-bg: #f2f4f7;
  --c_page-footer-border: #f2f4f7;

  // Text Input
  --c_textInput-bg: #ffffff;
  --c_textInput-bg-disabled: #ffffff;
  --s_textInput-border: 1px;
  --s_textInput-line: 1px;
  --c_textInput-line: #e1e4e5;
  --c_textInput-line-active: #63bbe3;
  --c_textInput-line-hover: #babebf;
  --c_textInput-line-error: #d7262c;
  --c_textInput-line-disabled: #e1e4e5;
  --c_textInput-label: #505859;
  --c_textInput-hint: #848b8c;
  --c_textInput-label-disabled: #9fa5a6;
  --c_textInput-label-error: #d7262c;
  --c_textInput-value: #373f40;
  --c_textInput-value-disabled: #9fa5a6;
  --c_textInput-label-bg: linear-gradient(
    #ffffff 0%,
    #ffffff 75%,
    #{rgba(#ffffff, 0%)} 100%
  );
  --c_textInput-label-bg-disabled: var(--c_textInput-label-bg);
  --c_textInput-line-required: #63bbe3;
  --c_textInput-label-required: #63bbe3;
  --c_textInput-search-icon: #e1e4e5;

  // File Input
  --c_fileInput-text: #ffffff;
  --c_fileInput-bg: #087dbc;
  --c_fileInput-bg-hover: #029fd9;
  --c_fileInput-bg-error: #a31d22;
  --c_fileInput-bg-success: #1c802c;
  --c_fileInput-border: #029fd9;
  --c_fileInput-border-error: #d7262c;
  --c_fileInput-border-success: #41d659;

  // Range Input
  --c_rangeInput-track-bg: #373f40;
  --c_rangeInput-track-bg-focus: #7ed1f6;
  --c_rangeInput-track-border: transparent;
  --c_rangeInput-thumb-bg: #212626;
  --c_rangeInput-thumb-border: #7ed1f6;

  // Time Range
  --c_timeRange-title: #858b8c;
  --c_timeRange-subtitle: #505859;
  --c_timeRange-text-label: #373f40;
  --c_timeRange-text-num: #63bbe3;
  --c_timeRange-text-month: #505859;
  --c_timeRange-text-day: #9fa5a6;
  --c_timeRange-text-time: #858b8c;
  --c_timeRange-arrow: #d4d8d9;
  --c_timeRange-line: #d4d8d9;
  --c_timeRange-text-placeholder: #858b8c;
  --c_timeRange-text-placeholder-active: #63bbe3;
  --c_timeRange-incrementer-icon: #{rgba(#029fd9, 0.5)};
  --c_timeRange-incrementer-icon-hover: #029fd9;
  --c_timeRange-cal-icon: #{rgba(#63bbe3, 0.35)};
  --c_timeRange-cal-hover: #63bbe3;
  --c_timeRange-refresh-label: #505859;
  --c_timeRange-refresh-time: #373f40;
  --c_timeRange-refresh-bg: #edf1f2;

  // Calendar
  --c_cal-text: #373f40;
  --c_cal-today-text: #63bbe3;
  --c_cal-today-text-disabled: #9fa5a6;
  --c_cal-today-border: #{rgba(#63bbe3, 0.35)};
  --c_cal-num-bg-hover: #edf1f2;
  --c_cal-num-bg-selected: #63bbe3;
  --c_cal-num-bg-highlighted: #e7edee;
  --c_cal-num-text-hover: #029fd9;
  --c_cal-num-text-selected: #ffffff;
  --c_cal-date-selected-border: #d4d8d9;
  --c_cal-header-text: #9fa5a6;
  --c_cal-btn-icon: #029fd9;
  --c_cal-btn-icon-hover: #ffffff;
  --c_cal-btn-bg: #ffffff;
  --c_cal-btn-bg-hover: #029fd9;
  --c_cal-btn-border: #d4d8d9;
  --c_cal-select-bg: #ffffff;
  --c_cal-select-border: #d4d8d9;
  --c_cal-select-text-hover: #029fd9;
  --c_cal-select-btn-hover: #f7f9fa;

  // Radio/Check (Selection Control) Input
  --c_checkbox-bg: #ffffff;
  --c_checkbox-border: #858b8c;
  --c_checkbox-active: #029fd9;
  --c_checkbox-error: #d7262c;
  --c_checkbox-disabled: #b9bebf;
  --c_checkbox-text: #6b7273;
  --c_checkbox-disabled-label: #6b7273;
  --c_checkbox-subtext: #858b8c;

  // Switch
  --c_switch-track-bg: #858b8c;
  --c_switch-track-bg-disabled: #babebf;
  --c_switch-track-bg-on: #029fd9;
  --c_switch-track-bg-on-disabled: #7ed1f6;
  --c_switch-thumb-bg: #{rgba(255, 255, 255, 0.65)};
  --c_switch-thumb-bg-disabled: #{rgba(255, 255, 255, 0.65)};
  --c_switch-thumb-border: #ffffff;
  --c_switch-thumb-border-active: #63bbe3;
  --c_switch-thumb-border-disabled: #{rgba(255, 255, 255, 0.6)};
  --c_switch-label: #858b8c;
  --c_switch-label-disabled: #9fa5a6;
  --c_switch-label-selected: #000000;
  --c_switch-label-selected-disabled: #6b7273;

  // Dropdown menu
  --c_dropdown-menu-bg: #ffffff;
  --c_dropdown-menu-border: #ffffff;
  --c_dropdown-menu-shadow: #{rgba(0, 0, 0, 0.08)};
  --c_dropdown-menu-link: #373f40;
  --c_dropdown-menu-link-hover: #029fd9;

  // Tooltip
  --c_tooltip-bg: #{rgba(255, 255, 255, 0.94)};
  --c_tooltip-border: #e1e5e6;
  --c_tooltip-text: #6b7273;

  // Group Filter
  --c_group-filter-close: #babebf;
  --c_group-filter-close-hover: #ff4c52;

  // Button
  --c_button-label: #6b7273;
  --c_button-secondary: #ffffff;
  --c_button-secondary-label: #6b7273;
  --c_button-secondary-border: #d4d8d9;
  --c_button-secondary-hover: #edf1f2;
  --c_button-secondary-pressed: #edf1f2;
  --c_button-secondary-pressed-border: #7ed1f6;
  --c_button-secondary-disabled-border: #d4d8d9;
  --c_button-primary: #087dbc;
  --c_button-primary-border: #029fd9;
  --c_button-primary-hover: #029fd9;
  --c_button-primary-pressed: #029fd9;
  --c_button-primary-pressed-border: #7ed1f6;
  --c_button-primary-disabled-border: #029fd9;
  --c_button-primary-label: #ffffff;
  --c_button-secondary-label: #6b7273;
  --c_button-secondary-hover-label: #6b7273;
  --c_button-link-label: #087dbc;
  --c_button-link-label-hover: #029fd9;
  --c_button-link-icon: #087dbc;
  --c_button-link-label-disabled: #babebf;
  --c_button-danger: #bd2227;
  --c_button-danger-border: #ff4c52;
  --c_button-danger-hover: #ff4c52;
  --c_button-danger-label: #ffffff;
  --c_button-outline-border: #505859;
  --c_button-outline-title: #373f40;
  --c_button-outline-text: #858b8c;
  --c_button-outline-border-hover: #505859;
  --c_button-outline-title-hover: #000000;
  --c_button-subtitle: #858b8c;
  --c_button-search-icon: #ffffff;

  // Scrollbar
  --c_scrollbar-thumb-large: #d4d8d9;
  --c_scrollbar-thumb-large-hover: #babebf;
  --c_scrollbar-track-large: #edf1f2;
  --c_scrollbar-thumb-small: #7ed1f6;
  --c_scrollbar-thumb-small-hover: #63bbe3;
  --c_scrollbar-track-small: #edf1f2;

  // table
  --c-table-header: #edf1f2;
  --c-table-header-text: #687173;
  --c-table-footer-text: #373f40;
  --c-table-icons: #087dbc;
  --c-table-icons-disabled: #babebf;
  --c_table-odd: #ffffff;
  --c_table-odd-gradient-cover: linear-gradient(
    270deg,
    #ffffff 50%,
    rgba(255, 255, 255, 0) 100%
  );
  --c_table-even: #f9f9f9;
  --c_table-row-border: #e1e4e5;
  --c_table-row-hover: #f5f7f9;
  --c-table-divider: #687173;
  --c-table-border: #e1e4e5;
  --c-table-sort-active: #63bbe3;
  --c-table-sort-inactive: #babebf;
  --c-table-switch-active-background: #0873dd;
  --c-table-switch-active-fill: #ffffff;
  --c-table-wedge-rowcount-color: #bcc2c3;
  --c-table-column-filter: #6b7273;
  --c-table-inactive-background: #f5f7f7;
  --c-table-background: #ffffff;
  --c-table-error-text: #d7262c;
  --c-table-nodata-text: #9fa5a6;
  --c-table-loading-text: #029fd9;
  --c-table-overlay-subtext: #373f40;
  --g-table-row-selected: #bceaff;
  --c-table-secondary-action: #63bbe3;
  --c-table-secondary-action-hover: darken(#63bbe3, 5%);
  --c-table-parent-row: #f1f1f1;
  --c-table-sub-row: #f7f9fa;

  --c_data-table-row-border: #dadada;
  --c_data-table-text-edit: #fc9423;
  --c_data-table-text-add: #029fd9;
  --c_data-table-text-sub: #555555;
  --c_data-table-divider-hover: #029fd9;
  --c_data-table-icon-bg-hover: #ffffff;
  --c_data-table-row-number: #9fa5a6;
  --c_data-table-checkbox-filter-divider: #555555;
  --c_data-table-filter-action-hover: #63bbe3;
  --c_data-table-no-data: #ffffff;

  // Toast
  --c_toast-bg: #ffffff;
  --c_toast-message: #6b7273;
  --c_toast-custom: #858b8c;
  --c_toast-info: #7ed0f6;
  --c_toast-success: #63bbe3;
  --c_toast-warning: #fc9423;
  --c_toast-error: #f02b31;

  // Modal
  --c_modal-dialog-bg: #f7f9fa;
  --c_modal-dialog-border: #babebf;
  --c_modal-header-bg: #ffffff;
  --c_modal-header-title: #6b7273;
  --c_modal-header-subtitle: #858b8c;
  --c_modal-body-text: #858b8c;
  --c_modal-footer-bg: #ffffff;
  --c_modal-border: #d4d8d9;

  --c_modal-error-bg: #ffffff;
  --c_modal-error-border: #d4d8d9;
  --c_modal-error-left-border: #d7262c;
  --c_modal-error-title: #d7262c;
  --c_modal-error-text: #6b7273;

  --c_modal-backdrop-bg: #edf1f2;
  --c_modal-backdrop-dots: #d4d8d9;

  --c_modal-highlight: #63bbe3;
  --c_modal-subtitle: #6b7273;
  --c_modal-help: #373f40;
  --c_modal-any: #000000;

  //
  --c_drawer-title: #6b7273;
  --c_drawer-bg: #f4f8f9;
  --c_drawer-header-bg: #ffffff;
  --c_drawer-footer-bg: #212626;

  // Tabs
  --c_tabs-bottom-border: #d4d8d9;
  --c_tabs-text-default-color: #858b8c;
  --c_tabs-text-state-color: #373f40;
  --c_tabs-primary-active-border: #93a920;
  --c_tabs-primary-pressed: #9fa5a6;
  --c_tabs-secondary-active-border: #93a920;
  --c_tabs-secondary-pressed: #9fa5a6;
  --c_tabs-secondary-bottom-border: #f5f7f7;

  // Select
  --c_select-option: #373f40;
  --c_select-option-bg-focus: #7ed1f6;
  --c_select-dropdown-divider: #9fa5a6;
  --c_select-match: #63bbe3;
  --c_select-selected: #9fa5a6;

  // Chip
  --c_chip-text: #ffffff;
  --c_chip-action: #858b8c;
  --c_chip-action-add: #41d659;
  --c_chip-action-remove: #d7262c;
  --c_chip-action-bg: #ffffff;
  --c_chip-bg-0: #9fa5a6;
  --c_chip-bg-1: #029fd9;
  --c_chip-bg-2: #01aa96;
  --c_chip-bg-3: #219533;
  --c_chip-bg-4: #7f9020;
  --c_chip-bg-5: #b9ae1d;
  --c_chip-bg-6: #be6f18;
  --c_chip-bg-7: #a31d22;
  --c_chip-bg-8: #8926ec;
  --c_page-header-chip-bg: #cccccc;

  // Notification-banner
  --c_notification-info-elements-text: #000000;
  --c_notification-blue: #7ed1f6;
  --c_notification-teal: #02d9bf;
  --c_notification-green: #41d659;
  --c_notification-olive: #bfdd20;
  --c_notification-yellow: #f8e91a;
  --c_notification-orange: #fc9423;
  --c_notification-red: #ff4c52;
  --c_notification-purple: #b163ff;

  // Levels - severity
  --c_level-label: #858b8c;
  --c_level-icon-bg: #edf1f2;
  --c_low-level-icon-border: #edf1f2;
  --c_low-level-icon-border-bottom: #01edd1;
  --c_med-level-icon-border: #edf1f2;
  --c_med-level-icon-border-bottom: #e8d800;
  --c_high-level-icon-bg: #ff4c52;
  --c_none-level-icon-bg: #edf1f2;

  // Range Selector
  --c_range-rail-bg: #d4d8d9;
  --c_range-rail-hover-bg: #d4d8d9;
  --c_range-handle-bg: #d4d8d9;
  --c_range-handle-border: #9ca4a6;
  --c_range-handle-after-border: #029fd9;
  --c_range-handle-hover-border: #029fd9;
  --c_range-handle-active-border: #029fd9;
  --c_range-handle-active-after: #02d9bf;
  --c_range-handle-disabled-bg: #d4d8d9;
  --c_range-handle-label: #505859;
  --c_range-handle-label-active: #63bbe3;
  --c_range-handle-label-disabled: #9ca4a6;
  --c_range-track-bg: #d4d8d9;
  --c_range-hover-range-track: #029fd9;
  --c_range-hover-range-handle-border: #029fd9;
  --c_range-disabled-all: #babebf;

  // Charting
  --c_chart-title: #373f40;
  --c_chart-label: #848b8c;
  --c_chart-legend: #687173;
  --c_chart-data: #373f40;
  --c_chart-data-0: #029fd9;
  --c_chart-data-1: #fc9422;
  --c_chart-data-2: #39d9bf;
  --c_chart-data-3: #bfdd1f;
  --c_chart-data-4: #a142ff;
  --c_chart-data-5: #d4d8d9;
  --c_chart-area-0: #029fd9;
  --c_chart-area-1: #39d9bf;
  --c_chart-area-2: #a142ff;
  --c_chart-area-3: #63bbe3;
  --c_chart-area-4: #478f53;
  --c_chart-area-5: #fc9422;
  --c_chart-row-0: #39d9bf;
  --c_chart-row-1: #029fd9;
  --c_chart-row-2: #41d659;
  --c_chart-row-3: #a142ff;
  --c_chart-row-4: #fc9423;
  --c_chart-column-bg-0: #bfdd20;
  --c_chart-column-bg-1: #02d9bf;
  --c_chart-column-bg-2: #8826eb;
  --c_chart-column-bg-3: #029fd9;
  --c_chart-column-bg-4: #de7603;
  --c_chart-stacked-0: #029fd9;
  --c_chart-stacked-1: #01edd1;
  --c_chart-stacked-2: #fc9423;
  --c_chart-stacked-3: #f8e91a;
  --c_chart-stacked-4: #b163ff;
  --c_chart-stacked-bg-0: #{rgba(#029fd9, 0.1)};
  --c_chart-stacked-bg-1: #{rgba(#01edd1, 0.1)};
  --c_chart-stacked-bg-2: #{rgba(#fc9423, 0.1)};
  --c_chart-stacked-bg-3: #{rgba(#f8e91a, 0.1)};
  --c_chart-stacked-bg-4: #{rgba(#b163ff, 0.1)};
  --c_chart-tick: #dddddd;
  --c_chart-avg: #1c802c;
  --c_chart-p95: #b163ff;
  --c_chart-min: #de7603;
  --c_chart-max: #b9ae1d;
  --c_chart-marker-border: #000000;
  --c_chart-indicator: #222222;
  --c_chart-select-marker: #000000;
  --c_chart-select-fill: #{rgba(255, 255, 255, 0.35)};
  --c_chart-data-placeholder: #edf1f2;
  --c_chart-unit: #777777;
  --c_sankey-title: #505859;
  --c_trend-hover: #ffad53;
  --c_trend-hover-bg: #{rgba(255, 173, 83, 0.2)};
  --c_chart-double-top: #bfdd20;
  --c_chart-double-bottom: #02d9bf;
  --c_chart-series-0: #a142ff;
  --c_chart-series-1: #fc9422;
  --c_chart-dual-area-1: #087dbc;
  --c_chart-dual-area-2: #fc9422;
  --c_chart-dual-area-3: #b163ff;
  --c_chart-dual-area-4: #fff675;
  --c_chart-dual-brush-handle-hover: #63bbe3;
  --c_chart-dual-hover-default: #777777;
  --c_chart-brush-select-area: #{rgba(220, 220, 220, 0.5)};

  --c_gauge-low: #bfdd20;
  --c_gauge-medium: #fc9422;
  --c_gauge-high: #f02b31;
  --c_gauge-indicator-fill: #6b7273;
  --c_gauge-indicator-stroke: #ffffff;

  --c_geo-fill-1: #01aa96;
  --c_geo-fill-2: #31c3b1;
  --c_geo-fill-3: #63d2c5;
  --c_geo-fill-4: #8ad4cc;
  --c_geo-fill-5: #aac8c5;
  --c_geo-fill-noval: #d4d8d9;
  --c_geo-fill-hover: #029fd9;
  --c_geo-fill-selected: #029fd9;
  --c_geo-border: #ffffff;
  --c_geo-value: #373f40;

  // Toggle
  --c_toggle-bg: #ffffff;
  --c_toggle-border: #d4d8d9;
  --c_toggle-border-selected: #d4d8d9;
  --c_toggle-border-outer: #ffffff;
  --c_toggle-label: #858b8c;
  --c_toggle-label-hover: #087dbc;
  --c_toggle-label-selected: #212626;
  --c_toggle-marker: #edf1f2;

  // Path list
  --c_path-value: #087dbc;
  --c_path-hightlight-value: #078b7b;
  --c_path-separator: #9ca4a6;
  --c_multi-path-bg: #edf1f2;
  --c_multi-path-border: #d4d8d9;
  --c_hop-count-diamond: #078b7b;
  --c_hop-count-text: #ffffff;

  // Value
  --c_value-text: #6b7273;
  --c_value-icon: #c4c4c4;

  // Card
  --c_card-title-text: #373f40;
  --c_card-subtitle-text: #505859;
  --c_card-title-bg: #edf1f2;
  --c_card-title-bg2: #f7f8fa;
  --c_card-bg: #ffffff;
  --c_card-bg2: #ffffff;
  --c_card-border: #babebf;
  --c_card-bg-nested: #edf1f2;
  --c_card-link: #0e9fd9;
  --c_card-bg-active: #eaf5f1;
  --c_card-expander-bg: #d4d8d9;
  --c_card-expander-bg-hover: #9ca4a6;
  --c_card-btn-close: #9ca4a6;
  --c_card-btn-close-hover: #029fd9;

  // Accordion
  --c_accordion-header-background: #edf1f2;
  --c_accordion-content-background: #ffffff;
  --c_accordion-content-border: #babebf;
  --c_accordion-title-text: #93a920;
  --c_accordion-title-text-disabled: #9fa5a6;
  --c_accordion-subtitle-text: #505859;
  --c_accordion-icon-background: #ffffff;
  --c_accordion-icon-box-border: #9fa5a6;
  --c_accordion-icon-color: #fc9422;
  --c_accordion-icon-disabled: #6b7273;

  // Login page
  --c_login_background: #f5f7f7;
  --c_login-logo-color: #029fd9;

  // Step wizard
  --c_stepwizard_left: #ffffff;
  --c_stepwizard_right: #edf1f2;
  --c_stepwizard_right_selected: #d4d8d9;
  --c_stepwizard_caption: #6b7273;
  --c_stepwizard_sub_caption: #9fa5a6;
  --c_stepwizard-border: transparent;
  --c_stepwizard-section-text-color: #212626;

  // Welcome Page
  --c_welcome_grey: #d4d8d9;

  // Express page
  --c_express_selected: #93a920;

  // number circle
  --c_circle: #087dbc;
  --c_circle_border: #029fd9;
  --c_circle-text: #ffffff;
  --c_circle-active: #ffffff;
  --c_circle-active-text: #131717;
  --c_circle-aspath-background: #9ca4a6;

  // help
  --c_help_section_label: #6b7273;
  --c_help_section_description: #505859;
  --c_help_section_separator: #d4d8d9;
  --c_help_section_bullet-bg: #d4d8d9;
  --c_help_li_item: #029fd9;

  // external help Link Button
  --c_help_link_button-bg: #ffffff;
  --c_help_link_button-border: #d4d8d9;
  --c_help_icon_button-fill: #9fa5a6;

  // Import export configuration
  --c_importExport_caution_text: #6b7273;
  --c_fileImport_upload_icon-fill: #9fa5a6;

  // Modification column values
  --c_modification_Delete: #d7262c;
  --c_modification_Create: #63bbe3;
  --c_modification_Edit: #b9ae1d;

  // Processing Modal component
  --c_processing_modal_loader: #029fd9;
  --c_processing_modal_processing_title: #373f40;
  --c_processing_modal_processing_message: #858b8c;
  --c_processing_modal_failure_title: #d7262c;
  --c_processing_modal_failure_message: #858b8c;
  --c_processing_modal_success_title: #373f40;
  --c_processing_modal_success_message: #858b8c;

  // Well
  --c_well-bg: #ffffff;
  --c_well-border: #edf1f2;

  // page-section
  --c_title-special-text: #63bbe3;

  // page-caption
  --c_caption_text: #505859;

  //table-columns
  --c_table_column_update_text: #41d659;

  // column-donut
  --c_column-donut-bg: #d4d8d9;
  --c_column-donut-bg-active: #6b7273;
  --c_column-donut-value: #6b7273;
  --c_column-donut-secondary: #9fa5a6;

  // user-detailspage-header-border
  --c_page_header_border_color: #e1e4e5;
  --c_api_key_text_color: #93a920;

  // Filter By
  --c_filterby-item-text: #373f40;
  --c_filterby-item-bg: #e1e4e5;
  --c_filterby-item-title: #687173;
  --c_filterby-label: #687173;
  --c_card-arrow-active: #41d659;
  --c_card-arrow-inactive: #9fa5a6;
  --c_card-arrow-inprogress: #93a920;
  --c_parent-menu-active: #bfdd20;
  --c_parent-menu-background: #2e3435;
  --c_parent-menu-border-top-bg: linear-gradient(
    180deg,
    #{rgba(0, 0, 0, 0.12)} 0%,
    #{rgba(0, 0, 0, 0)} 100%
  );
  --c_parent-menu-border-bottom-bg: linear-gradient(
    0deg,
    #{rgba(0, 0, 0, 0.12)} 0%,
    #{rgba(0, 0, 0, 0)} 100%
  );

  // Diff Tree
  --c_difftree-bg: #ffffff;
  --c_difftree-text: #373f40;
  --c_difftree-text-secondary: #505859;
  --c_difftree-text-legend: #858b8c;
  --c_difftree-header-bg: #d4d8d9;
  --c_difftree-header-text: #373f40;
  --c_difftree-expander-bg: #d4d8d9;
  --c_difftree-expander-bg-hover: #babebf;
  --c_difftree-row-bg: #edf1f2;
  --c_difftree-parent-change: #2fb945;
  --c_difftree-add: #2fb945;
  --c_difftree-change: #fc9423;
  --c_difftree-delete: #d7262c;
  --c_difftree-modified: #029fd9;
  --c_difftree-row-bg-hover: #020202;
  --c_difftree-footer-bg: #d4d8d9;

  // Device Image
  --c_device-image-label: #373f40;
  --c_device-image-label-border: #029fd9;
  --c_device-image-label-border-virtual: #b163ff;
  --c_device-image-label-icon: #ffffff;
  --c_device-image-box: #687173;
  --c_device-image-rack: #687173;

  // Filter
  --c_filter_box-bg: #ffffff;
  --c_filter_box-border: #687173;
  --c_filter_operator-include: #63bbe3;
  --c_filter_operator-exclude: #d7262c;
  --c_filter_operator-disabled: #d4d8d9;
  --c_filter_box-text: #373f40;
  --c_filter_box-close: #6b7273;
  --c_filter_box-close-background: #d4d8d9;
  --c_filter-btn-text: #087dbc;
  --c_filter-modal-header-background: #d4d8d9;
  --c_filter-modal-body-background: #ffffff;
  --c_filter-modal-footer-background: #d4d8d9;

  // Device Capabilities
  --c_device-capabilities-title: #d4d8d9;
  --c_device-capabilities-item-title: #131717;
  --c_device-capabilities-check-na: #858b8c;
  --c_device-capabilities-check-upgrade: #000000;
  --c_device-capabilities-check-active: #63bbe3;

  // coming soon
  --c_coming-soon: #63bbe3;
  --c_not-licensed: #fc9423;

  // Timeline Bucket Chart
  --c_tlbucketchart-legend-bg: linear-gradient(
    180deg,
    #{rgba(49, 56, 56, 0.05)} 0%,
    #{rgba(49, 56, 56, 0)} 50%,
    #{rgba(49, 56, 56, 0.05)} 100%
  );
  --c_tlbucketchart-legend-dossierErrors-diagonalStripes: #00000059;
  --c_tlbucketchart-y-legend-background: #F5F7F7;
  --c_tlbucketchart-legend-background: #EDF1F2;
  --c_tlbucketchart-border: #babebf;
  --c_tlbucketchart-track-bg: #eeeeee;
  --c_tlbucketchart-action: #029fd9;
  --c_tlbucketchart-action-active: #373f40;
  --c_tlbucketchart-action-hover: #373f40;
  --c_tlbucketchart-tlpoint-line: #b9ae1d;
  --c_tlbucketchart-tlpoint-arrowline: #B9AE1D;
  --c_tlbucketchart-tlpoint-rectangularDotLine: #8926EC;
  --c_tlbucketchart-tlpoint-dotline: #b163ff;
  --c_tlbucketchart-tlpoint-dotline-circle: #9fa5a6;
  --c_tlbucketchart-tlpoint-dotline-error: #D7262C;
  --c_tlbucketchart-axis-text-primary: #212626;
  --c_tlbucketchart-axis-text-secondary: #687173;
  --c_tlbucketchart-data-top: #41D659;
  --c_tlbucketchart-data-bottom: #02D9BF;
  --c_tlbucketchart-data-zero: #687173;
  --c_tlbucketchart-select-line: #9fa5a6;
  --c_tlbucketchart-select-line-hover: #7ed1f6;
  --c_tlbucketchart-select-bg: #ffffff;
  --c_tlbucketchart-arrow-select: #000000;
  --c_tlbucketchart-overlay: #{rgba(255, 255, 255, 0.85)};
  --c_tlbucketchart-pager-icon-hover: #7ed1f6;

  --c_doublestackchart-card-bg: #f2f7f8;
  --c_doublestackchart-date-title: #373f40;

  --c_doubletrendchart-card-bg: #ffffff;

  --c_countdown-active: #01aa96;
  --c_countdown-expiring: #fc9423;
  --c_countdown-expired: #d7262c;
  --c_countdown-recent: #d4d8d9;

  --c_trial_select-dropdown: #7ed0f6;

  --c_changes-modify: #01aa96;

  // Main Nav
  --c_mainNav-bg: linear-gradient(180deg, #f2f4f7 0%, #dde1e2 100%);
  // --c_mainNav-bg: linear-gradient(180deg, #ccedff 0%, #f5fccf 100%);
  --c_mainNav-seperater-small: #{rgba(255, 255, 255, 0.5)};
  --c_mainNav-seperater-large: #e1e4e5;
  --c_mainNav-border: #e1e4e5;
  --c_mainNav-text: #505859;
  --c_mainNav-text-heading: #869192;
  --c_mainNav-text-active: #000000;
  --c_mainNav-text-hover: #029fd9;
  --c_mainNav-icon: #ffffff;
  --c_mainNav-icon-active: #ddf94b;
  --c_mainNav-icon-hover-bg: #{rgba(255, 255, 255, 0.2)};
  --c_mainNav-icon-active-bg: #{rgba(#ddf94b, 0.2)};
  --c_mainNav-pin: #babebf;
  --c_mainNav-pin-hover: #029fd9;
  --c_mainNav-pin-active: #01aa96;
  --c_mainNav-gradient: linear-gradient(180deg, #0992dc 0%, #a8c125 100%);

  --c_circle-check-success: #01aa96;
  --c_drilldown-shadow: -6px 1px 5px 0px rgba(0, 0, 0, 0.25);
  --c_drilldown-text: #505859;
  --c_drilldown-text-active: #ffffff;
  --c_drilldown-text-hover: #029fd9;
  --c_drilldown-text-1: #000000;
  --c_drilldown-text-2: #666666;

  --c_card-disabled: rgba(163, 99, 29, 0.25);
  --c_card-disabled-header: #505859;
  --c_card-disabled-text: #de7603;

  --c_expander-header: rgba(186, 190, 191, 1);

  // Peer ID
  --c_peer-id-text: #f5f7f7;
  --c_peer-id-background: #858b8c;

  --c-expander-plus: #01aa96;
  --c_tier-available: #01aa96;
  --c_tier-na: #babebf;
  --c_tier-partial: #01aa96;
  --c_tier-partial-line: #c4c4c4;
  --c_tier-text: #505859;
  --c_tier-current-selection: rgba(107, 114, 115, 0.15);
  --c_tier-current-selection-border: rgba(107, 114, 115, 0.4);
  --c_tier_plan-icon-bg: #f2f4f7;
  --c_tier_plan-active: #01aa96;
  --c_tier_plan-border: #6b7273;
  --c_tier-notification-link: #0873dd;
  --c_tier-section-header-text: #6b7273;
  --c_tier-text-link: #0873dd;

  // Reccomendations
  --c_recommendations-title: #373f40;
  --c_recommendations-headers: #505859;

  // Telemetry
  --c_telemetry-picker-border-selected: #029fd9;

  // Policy
  --c_content-section-header: #505859;
  --c_modal_policy-title: #63bbe3;
  --c_modal-policy-subtitle: #6b7273;
  --c_modal_policy-rule-title: #505859;
  --c_modal_policy-rule-sub-title: #858b8c;
  --c_policy-summary-count: #505859;
  --c_policy-data-included: #01aa96;
  --c_policy-data-excluded: #f02b31;
  --c_policy-notification-border: #babebf;
  --c_policy-notification-background: #edf1f2;
  --c_range-utilization-error: #f02b31;
  --c_range-utilization-handle: #ffffff;
  --c_policy-default-title: #63bbe3;
  --c_policy-list-active-alarm-border: #a31d22;
  --c_policy-list-active-alarm-background: #a31d22;
  --c_policy-list-active-alarm-count-text: #ffffff;
  --c_policy-list-active-alarm-text: #505859;
  --c_policy-list-default-details: transparent;
  --c_policy-list-default-details-text: #087dbc;
  --c_policy-create-modal-header-background: #d4d8d9;
  --c_policy-create-modal-sub-title-text: #000000;
  --c_policy-create-modal-sub-title-sub-text: #131717;
  --c_policy-create-modal-policy-type-text: #505859;
  --c_policy-create-modal-object-border: #63bbe3;
  --c_policy-create-modal-footer-background: #d4d8d9;
  --c_policy-select-modal-title: #029fd9;
  --c_policy-select-add-queue-icon: #d4d8d9;
  --c_policy-select-add-queue-text: #505859;
  --c_policy-select-added-queue-icon: #41d659;
  --c_policy-select-added-queue-text: #858b8c;

  // BGP Updates
  --c_daul-axis-chart-header-bg: #dadada;
  --c_daul-axis-chart-bg: #f2f4f7;
  --c_bgp-updates-legend-lbl: #000000;
  --c_bgp-updates-border: #ffffff;
  --c_bgp-updates-filter-bg: #dadada;
  --c_bgp-updates-filter-button-bg: #007ec0;
  --c_bgp-updates-filter-button-text: #ffffff;
  --c_bgp-updates-filter-button-bg-hover: #00a1dd;
  --c_bgp-updates-filter-button-border: #00a1dd;
  --c_bgp-updates-filter-warning-border: #DE7603;
  --c_bgp-updates-filter-warning-icon: #F5F7F7;
  --c_bgp-updates-filter-warning-icon-bg: #DE7603;
  --c_bgp-updates-filter-warning-text: #505859;

  // Topology
  --c_topology-header: #f2f4f7;
  --c_topology-footer: #f2f4f7;
  --c_topology-border: #e1e4e5;
  --c_topology-node: #999;
  --c_topology-node-removed: #ac4e51;
  --c_topology-node-added: #49b45a;
  --c_topology-node-updated: #ce9f27;
  --c_topology-node-stuffed-one: #bcd6bb;
  --c_topology-node-stuffed-two: #bcd6bb;
  --c_topology-node-text: #fff;
  --c_topology-node-hover: #555;
  --c_topology-node-active: #555;
  --c_topology-node-selected: #555;
  --c_topology-node-text-active: #ffffff;
  --c_topology-node-border-active: #10618d;
  --c_topology-node-icon: #ffffff;
  --c_topology-node-line: #cccccc;
  --c_topology-node-line-hover: #5d7e6d;
  --c_topology-node-line-active: #ffffff;
  --c_topology-detail-bg: rgba(220, 220, 220, 0.9);
  --c_topology-detail-text: #777777;
  --c_topology-detail-asn: #555;
  --c_topology-detail-text-bold: #000000;
  --c_topology-detail-icon: #6b7273;
  --c_topology-detail-path-header: #fff;
  --c_topology-peer-set-bg: #eee;
  --c_topology-peer-set-bg-hover: #ddd;
  --c_topology-detail-peer-set-bg: #eee;
  --c_topology-detail-peer-set-border: #fff;
  --c_topology-detail-peer-label: rgb(132, 154, 142);
  --c_topology-detail-peer-val: #555555;
  --c_topology-peer-num: #444;
  --c_topology-text-added: #49b45a;
  --c_topology-text-updated: #ce9f27;
  --c_topology-text-removed: #ac4e51;

  // API Docs
  --c_api-header: #e9ebed;
  --c_api-titlebar: #ffffff;
  --c_api-titlebar-text: #858b8c;
  --c_api-text: #000000;
  --c_api-label: #505859;
  --c_api-val: #858b8c;
  --c_api-get: #373f40;
  --c_api-post: #41d659;
  --c_api-put: #b9ae1d;
  --c_api-patch: #DE7603;
  --c_api-delete: #d7262c;
  --c_api-param-bg: #ffffff;
  --c_api-param-code: #505859;
  --c_api-param-sub: #9fa5a6;
  --c_api-param-description: #858b8c;

  // Code Display
  --c_code-bg: #ffffff;
  --c_code-head-bg: #d4d8d9;
  --c_code-text: #505859;
  --c_code-name: #333;
  --c_code-brackets: #505859;
  --c_code-key: #087dbc;
  --c_code-number: #087dbc;
  --c_code-string: #555555;
  --c_code-true: #219533;
  --c_code-false: #a31d21;
  --c_code-null: #569ad5;

  // Markdown
  --c_markdown-bg: #fff;
  --c_markdown-h1: #000;
  --c_markdown-h2: #000;
  --c_markdown-h6: #000;
  --c_markdown-line: #ccc;
  --c_markdown-text: #555;
  --c_markdown-link: #087dbc;

  // Queue
  --c_queue-bg: #f2f4f7;
  --c_queue-head-bg: #d4d8d9;
  --c_queue-title: #029fd9;
  --c_queue-subtitle: #505859;
  --c_queue-item: #ffffff;
  --c_queue-item-icon: #babebf;
  --c_queue-actual-num: #63bbe3;
  --c_queue-actual-num-error: #d7262c;
  --c_queue-item-border: #babebf;

  // Add CDG
  --c_cdg-arrow: #babebf;
  --c_cdg-gateway: #7ed1f6;
  --c_cdg-revoke: #d7262c;
  --c_cdg-allow: #41d659;
  --c_cdg-table-highlight-text: #029fd9;
  --c_cdg-revoke-table-bg: #ffffff;
}
