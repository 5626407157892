#endpointLinkRuleModal {
  min-height: 530px;

  .xw-select {
    width: 100%;
  }

  .xw-select .xw-dropdown-menu {
    max-height: 240px;
  }
}
