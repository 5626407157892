@import "../../../styles/variables";

.xw-punchlist {
  .xw-punchlist-item {
    margin-bottom: 12px;
  }
  .xw-item-header {
    color: var(--c_text-title);
    font-weight: 300;
    font-size: 24px;
  }
  .xw-item-subheader {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
  }

  .xw-insights-punchlist-pkglist {
    li {
      padding: 6px;
    }

    .insights-table-icon {
      padding-right: 4px;
    }

    .xw-insights-pkgname {
      padding-left: 4px;
      color: var(--c_modal-header-title);
    }
  }
}
