@import "../../styles/variables";
.xw-device-or-device-group-select {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  .select-general {
    margin-right: $unit;
    flex: 0 1 auto;
    min-width: 150px;
  }
  .select-specific {
    flex: 1 1 100%;
  }
}
