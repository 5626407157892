@import "../../../styles/variables";

.monitor-ignore-policy-device-modal {
  .xw-modal {
    .modal-header {
      background-color: var(--c_filter-modal-header-background);
    }
    .modal-body {
      background-color: var(--c_filter-modal-body-background);
    }
    .modal-footer {
      background-color: var(--c_filter-modal-footer-background);
    }
  }
  .modal-dialog {
    width: 70%;
    height: 70%;
    .xw-data-table {
      height: 100%;
    }
  }
}
.xw-data-connected-ignored-device {
  .device {
    align-items: center;
    font-size: 14 * $ptToEm;
    > div {
      padding-right: $unit * 2;
      font-weight: 700;
    }
    .link {
      font-size: 14 * $ptToEm;
    }
    .warning {
      color: var(--c_text-warning);
    }
    .error {
      color: var(--c_text-error);
    }
  }
  .xw-content-suppress {
    &.device {
      color: var(--c_value-text);
      font-weight: bold;
      padding-right: calc($unit/3);
      .xw-button {
        font-weight: lighter;
      }
    }
    > div {
      padding-right: calc($unit);
    }
    .link {
      min-width: 110px;
    }
  }
  .rule-title-with-count {
    color: var(--c_modal_policy-rule-title);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 18 * $ptToEm;
    margin-bottom: 5px;
  }
  .rule-sub-title {
    color: #858B8C;
    font-size: 14 * $ptToEm;
  }
}
