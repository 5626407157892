@import "../../styles/variables";

.xw-page-tabs {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  .xw-tabs {
    display: flex;
    flex: 0 0 auto;
    li {
      flex: 0 0 auto;
      position: relative;
      display: block;
      padding: 5px $unit;
      text-align: center;
      border-bottom: 1px solid var(--c_tabs-bottom-border);
      transition: border-color 0.25s ease;
      button {
        font-size: 15px;
        line-height: 20px;
        color: var(--c_tabs-text-default-color);
        &:hover {
          color: var(--c_tabs-text-state-color);
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          left: 0;
          height: 2px;
          background-color: transparent;
          transition: transform 0.5s ease;
          transform: scaleY(0);
        }
      }
    }
    &:after {
      flex: 1 1 100%;
      content: "";
      display: block;
      border-bottom: 1px solid var(--c_tabs-bottom-border);
    }
    &.primary {
      li.active {
        border-bottom-color: transparent;
        button {
          color: var(--c_tabs-text-state-color);

          &:after {
            bottom: 0;
            background-color: var(--c_tabs-primary-active-border);
            transform: scaleY(1);
            transform-origin: bottom;
          }
        }
      }
      li.last-item {
        position: fixed;
        right: 10px;
      }
    }
    &.secondary {
      li.active {
        border-bottom-color: transparent;
        button {
          color: var(--c_tabs-text-state-color);

          &:after {
            top: 0;
            background-color: var(--c_tabs-secondary-active-border);
            transform: scaleY(1);
            transform-origin: top;
          }
        }
      }
      li.last-item {
        position: fixed;
        right: 10px;
      }
    }

    // hide "hidden" tabs unless they are active
    li.hidden {
      display: none;
    }
    li.active {
      display: block;
    }
  }
  > div {
    flex: 1 1 auto;
  }
  .no-permission {
    top: 60px;
  }
}


.xw-feature-info-notification {
  .xw-notification-banner {
    .notification-info {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 4 * $ptToEm;
    }
    .notification-title {
      justify-content: unset;
      font-size: unset;
      line-height: unset;
      span {
        padding-top: 0;
      }
    }
    a.link {
      color: var(--c_tier-notification-link);
    }
  }
}
