@import "../../styles/variables";

.port-disclaimer {
  margin: 12px 0 6px 0;
  color: var(--c_text-warning);
}

.xw-add-single-device {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: $unit * 3;
  left: -$unit * 2;
  right: -$unit * 2;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 0;
  .xw-page-section {
    margin: $unit * 2 0 calc($unit/2);
  }
}

.emphasis {
  color: #FFFFFF;
}

.xw-edit-single-device {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 0;
  .xw-page-section {
    margin: $unit * 2 0 calc($unit/2);
  }
  .xw-page-header .separator {
    margin-bottom: 0;
  }
}

.xw-manage-device-fields {
  overflow-y: auto;
  flex-grow: 1;
  margin-bottom: $footerHeight;
  .turn-on-flowTrust-msg {
    color: var(--c_title-special-text);
    font-weight: normal;
    font-size: 12 * $ptToEm;
    line-height: 14 * $ptToEm;
    margin-top: $unit * 2;
  }

  .advanced-settings {
    margin-top: $unit * 1;
    .header {
      display: inline-flex;
      align-items: center;
      flex-direction: row;
      gap: $unit * .5;
      margin-bottom: $unit * 2;
      .title {
        font-size: 18 * $ptToEm;
        text-transform: unset;
      }
      .xw-switch-input {
        margin-bottom: 0;
      }
    }
    .xw-numeric-input {
      .steppers {
        display: none;
      }
    }
    .netstream-field {
      padding: $unit 0 0 0;
      .netstream-switch {
        display: flex;
        .netstream-switch-label {
          font-size: 12 * $ptToEm;
          margin-right: $unit;
          line-height: 15 * $ptToEm;
        }
      }
      .netstream-note {
        margin-top: -15 * $ptToEm;
        font-size: 10 * $ptToEm;
        color: #9fa5a6
      }
    }
  }

  .device-fields {
    width: 75%;
    .fields {
      .xw-input-field,
      .xw-select,
      .xw-textarea {
        width: 50%;
      }

      .country-city-combo {
        display: flex;
        width: 75%;
        .xw-input-field {
          margin-right: 8px;
        }
      }

      .xw-select {
        display: flex;
        margin-bottom: $unit * 2;
        .xw-input-field {
          width: 100%;
        }
      }
    }
  }

  .select-application-msg {
    color: var(--c_text-emphasis);
    margin: $unit * 2 0;
  }

  .trustInsights-fields {
    width: 75%;
    display: flex;
    flex-direction: column;
    .xw-switch-input {
      label {
        min-width: auto;
      }
      margin-bottom: $unit * 2;
    }
    .xw-select {
      width: 50%;
      margin-bottom: $unit * 2;
    }
  }

  .flowTraffic-fields {
    width: 75%;
    .xw-switch-input {
      label {
        min-width: auto;
      }
      margin-bottom: $unit * 2;
    }
    .xw-input-field,
    .xw-select {
      width: 50%;
    }

    .xw-select {
      margin-bottom: $unit * 2;
      .xw-input-field {
        width: 100%;
      }
    }
  }

  .xw-textarea {
    display: flex;
    > textarea {
      width: 100%;
    }
  }
}