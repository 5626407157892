@import "../../styles/variables";

#multiTrafficAddModal {
  height: 80%;
  width: 80%;
  .xw-prefix-traffic,
  .xw-asn-traffic-table {
    margin-top: $unit;
    flex: 1 1 100%;
  }
  .xw-device-traffic-table {
    flex-grow: 1;
  }
}
