@import "../../../styles/variables";

.xw-switch-input {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: auto;
  > label,
  > .track {
    display: inline-block;
  }

  > .track {
    height: 14px;
    width: $unit * 2;
    background-color: var(--c_switch-track-bg);
    margin: auto calc($unit / 2);
    border-radius: 3px;
    cursor: pointer;

    .thumb {
      display: block;
      height: $unit;
      width: 16px;
      margin: 1px;
      border-radius: 3px;
      background-color: var(--c_switch-thumb-bg);

      border: 1px solid var(--c_switch-thumb-border);
      transition: transform 0.15s ease, background-color 0.15s ease;
    }

    &:active .thumb {
      border-color: var(--c_switch-thumb-border-active);
    }

    &:hover .thumb {
      background-color: var(--c_switch-thumb-border);
    }
  }

  &.on {
    > .track {
      background-color: var(--c_switch-track-bg-on);

      .thumb {
        transform: translateX(6px);
      }
    }

    > label.on {
      color: var(--c_switch-label-selected);
    }
  }

  &.off label.off,
  &.on label.on {
    color: var(--c_switch-label-selected);
  }

  label {
    text-transform: uppercase;
    font-size: 10px;
    position: relative;
    top: 1px;
    color: var(--c_switch-label);
    transition: color 0.15s ease;
    min-width: 60px;
    cursor: pointer;
    &.off {
      text-align: right;
    }
  }

  &.disabled {
    > .track {
      cursor: default;
      background-color: var(--c_switch-track-bg-disabled);

      .thumb {
        background-color: var(--c_switch-thumb-bg-disabled);
        border-color: var(--c_switch-thumb-border-disabled);
      }
    }

    label {
      color: var(--c_switch-label-disabled);
    }

    &.off label.off,
    &.on label.on {
      color: var(--c_switch-label-selected-disabled);
    }

    &.on > .track {
      background-color: var(--c_switch-track-bg-on-disabled);
    }
  }
}

.field > .switch-input {
  float: right;
}

.xw-switch-field {
  > label {
    font-size: var(--s_font-normal);
    transform-origin: left;
    transform: scale(0.8);
    top: 4px;
    left: 0;
  }
  .xw-switch-input {
    margin-top: 24px;
  }
}
