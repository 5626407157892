@import "../../../styles/variables";

.xw-alarm-overview-card {
  .title {
    display: flex;
    align-items: center;
    flex-flow: row;
    .left {
      flex-grow: 1;
    }
    .xw-label-value {
      margin-bottom: 0;
      text-align: end;
    }
  }

  .value-group {
    .xw-label-value {
      display: inline-block;
      padding-right: 2 * $unit;
    }
  }

  .sub-header {
    display: flex;
    color: var(--c_card-subtitle-text);
    font-size: 14 * $ptToEm;
    // line-height: 19 * $ptToEm;
    padding-top: calc($unit / 3);
    .xw-label-value {
      margin-bottom: 0;
    }
    .link-label {
      white-space: nowrap;
    }
    a,
    .link {
      padding-left: calc($unit / 2);
      color: var(--c_card-link);
      font-size: 14px;
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
