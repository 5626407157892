@import "../../styles/variables";
@import "chart.scss";

.xw-line-chart {
  width: 100%;
  height: 100%;
  min-width: 128px;
  min-height: 64px;
  position: relative;

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    path.line,
    path.line-hover-area {
      fill: none;
    }

    path.line {
      stroke-width: 2px;
    }

    path.line-hover-area {
      pointer-events: stroke;
      stroke-width: $unit;
      cursor: pointer;
    }

    .line-marker {
      pointer-events: painted;
      cursor: pointer;
    }

    text {
      fill: var(--c_text-title);
      font-size: 12 * $ptToEm;
    }

    .axis rect {
      fill: url(#chartTickX);
    }
  }

  > .legend {
    display: flex;
    flex-flow: row wrap;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    overflow: hidden;

    .series {
      flex: 0 1 128px;
      height: 12px;

      .color {
        display: inline-block;
        height: $unit;
        width: $unit;
        border-radius: calc($unit / 2);
        margin-right: calc($unit / 2);
      }
    }
  }

  .xw-marker-tooltip {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 15px auto;
    width: 128px;
    // padding: $unit / 4;
    .label,
    .day {
      font-weight: bold;
    }

    .day {
      color: var(--c_text-emphasis);
    }

    .day,
    .time {
      text-align: right;
    }

    .label,
    .day {
      line-height: 16 * $ptToEm;
      font-size: 12 * $ptToEm;
    }

    .value {
      font-size: 16 * $ptToEm;
      line-height: 22 * $ptToEm;
      font-weight: 300;
    }

    .time {
      font-size: 12 * $ptToEm;
      line-height: 22 * $ptToEm;
    }
  }

  .actions-left {
    position: absolute;
    bottom: $unit;
    left: $unit;
  }

  .actions-right {
    position: absolute;
    bottom: $unit;
    right: $unit;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }

  &.selectable-range > svg {
    cursor: col-resize;
  }
  &.selectable > svg {
    pointer-events: all;

    line.select-bound,
    line.select-hover {
      stroke: var(--c_chart-select-marker);
      stroke-width: 2px;
      stroke-dasharray: 1 3;
    }

    rect.select-range {
      fill: var(--c_chart-select-fill);
      pointer-events: none;
    }
    polygon.select-marker {
      fill: var(--c_chart-select-marker);
    }
  }
}
