@import "../../styles/variables";

.xw-toaster {
  position: fixed;
  top: $header-height;
  bottom: $unit * 5;
  right: $unit * 2;
  width: var(--s_toast-width);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  pointer-events: none;
  user-select: none;
  z-index: $zToasterRoot;

  .xw-toast {
    pointer-events: all;
    user-select: auto;
    white-space: pre-line;
  }
  .xw-toast:not(:first-child) {
    margin-top: $unit;
  }

  .toast-item-enter {
    transform: translateX(110%);
    opacity: 0.1;
  }
  .toast-item-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 200ms ease-in, opacity 200ms ease-in;
  }

  .toast-item-exit {
    transform: translateX(0);
    opacity: 1;
  }
  .toast-item-exit-active {
    transform: translateX(110%);
    opacity: 0.1;
    transition: transform 150ms ease-out, opacity 160ms ease-out;
  }
}
