@import "../../styles/variables";

.xw-expander-card {
  background-color: var(--c_card-bg);
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  border-radius: 3px;
  transition: background-color 0.15s ease;

  &.expanded {
    background-color: var(--c_card-bg-active);
  }

  .pip {
    width: calc($unit / 2);
    background-color: var(--c_hr);
    border-radius: 3px;
    transition: background-color 0.15s ease;
  }
  &.expanded .pip {
    background-color: var(--c_toast-success);
  }

  .content {
    flex-grow: 1;
    padding: $unit * 2 $unit * 2 $unit * 2 $unit * 1.5;
  }

  &.expanded .expanded-content,
  .expanded-content.exit {
    margin-top: $unit * 2;
  }
  .expanded-content {
    transform-origin: top;
    &.enter {
      transform: scaleY(0);
      opacity: 0.1;
    }

    &.enter-active {
      transform: scaleY(1);
      opacity: 1;
      transition: transform 200ms ease-in, opacity 200ms ease-in;
    }

    &.exit {
      transform: scaleY(1);
      opacity: 1;
    }

    &.exit-active {
      transform: scaleY(0);
      opacity: 0.1;
      transition: transform 150ms ease-out, opacity 160ms ease-out;
    }

    &.exit-done {
      display: none;
    }
  }

  .expander {
    position: absolute;
    bottom: 0;
    right: 0;
    border-style: solid;
    border-width: 0 0 36px 36px;
    border-color: transparent transparent var(--c_card-expander-bg) transparent;
    width: 0;
    height: 0;
    transition: border-color 0.15s ease;
    &:hover {
      border-color: transparent transparent var(--c_card-expander-bg-hover)
        transparent;
    }
    .xw-button {
      position: absolute;
      bottom: -33px;
      right: 0;
      margin: 0;
      padding: calc($unit / 4);

      .xw-icon {
        fill: var(--c_card-bg);
      }
    }
  }
}
