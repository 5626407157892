@import "../../styles/variables";

.xw-item-delete-summary {
  .xw-item-delete-summary-header {
    display: flex;
    align-items: center;

    .xw-item-delete-name-container {
        display: flex;
        gap: 10px;
        font-size: 24 * $ptToEm;

        .xw-item-delete-label {
            color: var(--c_section-header-text);
        }

        .xw-item-delete-name {
            color: var(--c_modal-highlight);
        }
    }

    .spacer {
        height: 1px;
        border-bottom: 1px dashed var(--c_section-header-separator);
        margin: 0 10px;
    }

    .xw-item-delete-icon-button {
      min-width: 0;
      padding: 0;
      margin-left: $unit;
    }
  }
}
