@import "../../styles/variables";

.xw-code-block {
  border-radius: $unit;
  background-color: var(--c_code-bg);
  border: var(--c_code-border);

  .code-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    border-radius: $unit $unit 0 0;
    color: var(--c_code-text);
    background-color: var(--c_code-head-bg);
    height: $unit * 3;

    .actions {
      display: flex;

      .xw-button {
        padding: 0;
        margin: 0;
        background-color: transparent;
        width: auto;
        min-width: 0;
        border: none;
        color: var(--c_code-text);
        padding-left: calc($unit / 2);
        text-transform: uppercase;
        font-size: $ptToEm * 10;
        color: var(--c_text-link);
        font-weight: 700;

        &:hover {
          color: var(--c_text-hover);
        }
      }
    }
  }

  pre {
    color: var(--c_code-brackets);
    padding: 18px;
    max-height: 600px;
    overflow: auto;
  }
}
