@import "../../styles/variables";

.xw-activate-deactivate-value {
  .update-label-Enabled {
    color: var(--c_modification_Create);
  }
  .update-label-Disabled {
    color: var(--c_checkbox-disabled-label);
  }
}
