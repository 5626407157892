@import "../../styles/variables";

.xw-data-table {
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  overflow: hidden;

  .table-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: $unit * 4;
    margin: 0 $unit * 2 $unit $unit * 2;
    .spacer {
      flex: 1 1 100%;
    }
    .xw-dateTimeRefresh .xw-select {
      width: 144px;
    }
    .xw-trafficDatePicker {
      flex: 0 0 auto;
      width: 400px;
    }
    > .xw-button {
      white-space: nowrap;
    }
  }

  .xw-button {
    padding: 0;
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }

    .xw-icon {
      margin: 0 calc($unit / 4);
    }
  }

  .xw-button:disabled .xw-icon {
    opacity: 0.25;
    fill: var(--c_button-link-label-disabled);
  }

  .right-gutter {
    position: relative;
    height: 100%;
    flex: 0 0 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    &.row-gutter {
      margin-left: -12px;
    }

    .table-menu,
    .xw-button {
      // position: absolute;
      // top: 0;
      // right: 0;
      // bottom: 0;
      // left: 0;
      min-width: auto;
      height: 100%;
      width: 100%;
      margin: 0;
    }

    .xw-button {
      &:hover {
        background-color: var(--c_data-table-icon-bg-hover);
      }
    }

    .row-number {
      font-size: 10 * $ptToEm;
      color: var(--c_data-table-row-number);
    }
  }

  .cell.select {
    display: flex;
    justify-content: center;
    flex: 0 0 50px;
    .xw-checkbox.single {
      .checkbox,
      .checkbox .fill {
        border-radius: 8px;
      }
    }
  }

  .cell.expander {
    flex: 0 0 48px;
    .xw-processing-loader {
      max-width: 24px;
      height: 12px;
      position: relative;
      left: -6px;
    }
    .xw-icon {
      fill: var(--c-table-secondary-action);
    }
    button:hover .xw-icon {
      fill: var(--c-table-secondary-action-hover);
      transition: fill 0.15s ease;
    }
  }

  .cell.select .xw-checkbox,
  .col.select .xw-checkbox {
    position: relative;
    height: 16px;
    width: 16px;
    margin: 0;
    input {
      height: 16px;
      width: 16px;
    }
  }

  > .tbody {
    position: relative;
    min-height: 36px;

    .xw-page-loader {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    .infinite {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      overflow-y: auto;
      .row {
        position: absolute;
        width: 100%;
      }
      .scroll-sizer {
        position: absolute;
        background: transparent;
        pointer-events: none;
        width: 1px;
      }
    }
  }

  &.pending-filter > .tbody .row {
    transition: filter 0.2s ease;

    filter: blur(calc($unit / 3));
  }

  > .tbody .cell {
    overflow: hidden;
    white-space: nowrap;
    margin-right: $unit;
    min-width: 0;

    .cell-button {
      padding: 0 $unit;
      min-width: unset;
      margin: 0;
      height: $unit * 2;
      font-size: 12 * $ptToEm;
      text-transform: uppercase;
      font-weight: 700;
    }

    &.select {
      padding: 0;
    }

    a.link {
      transition: color 0.15s ease;
      color: var(--c_text-link);
      &:hover,
      &:focus {
        color: var(--c_text-hover);
      }
    }

    &:first-child {
      padding-left: $unit * 1.5;
      &.select {
        padding: 0;
      }
    }

    // overrides to fit chiplist neatly in a column with ellipsize
    .xw-chip-list {
      flex-flow: row nowrap;
      .xw-chip {
        min-width: 48px;
      }
    }
  }

  > .tbody {
    flex-grow: 1;
    overflow: hidden;

    display: flex;
    flex-flow: column nowrap;
    .row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      background-color: var(--c_table-odd);
      border-bottom: 1px solid var(--c_data-table-row-border);
      &.warn {
        border-bottom: 1px solid var(--c_text-warning);
        margin-bottom: 1px;
      }
      box-sizing: border-box;
      transition: background-color 0.15s ease;
      &.even {
        background-color: var(--c_table-even);
      }
      &.selected {
        background-color: var(--c_table-row-hover);
      }
      &.parent {
        background-color: var(--c-table-parent-row);
      }
      &.sub {
        background-color: var(--c-table-sub-row);
      }
    }
  }
  &.has-scrollbar > .header {
    padding-right: $unit;
  }
  > .footer {
    height: $unit * 4;
    background-color: var(--c-table-header);
    color: var(--c-table-footer-text);
    padding: 0 $unit * 1.5;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    &.hide-footer {
      height: 3px;
    }

    .separator {
      height: 20px;
      width: 1px;
      background-color: var(--c-table-divider);
      margin: 0 $unit;
    }
    .selected {
      margin-left: $unit;
      padding-left: $unit;
      height: 20px;
      border-left: 1px solid var(--c-table-divider);
      line-height: 20px;
    }
    .spacer {
      flex-grow: 1;
    }
    .xw-processing-loader {
      width: 64px;
    }
  }

  .pip,
  .pip-spacer {
    width: calc($unit / 2);
    height: 100%;
    margin-right: $unit;
  }
  .pip {
    background-color: var(--c_data-table-icon-bg-hover);
  }
  .xw-overlay-no-data {
    background-color: var(--c_data-table-no-data);
  }
}
