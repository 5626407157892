@import "../../styles/variables";

.xw-asns-page {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .xw-dual-axis-chart {
    flex: 0 0 100%;
  }

  .xw-page-tabs {
    height: 100%;

    .xw-tabs {
      margin-top: calc($unit / 2);

      > li {
        padding: calc($unit/2) $unit * 2;
      }
    }
  }

  .xw-asn-table {
    margin: $unit 0 0 0;
  }
  .asn-traffic-table {
    margin-top: $unit;
  }

  > .xw-button {
    position: absolute;
    top: $unit * 2;
    left: $unit * 2;
  }

  > .xw-asn-table {
    flex: 1 1 100%;
  }

  > .xw-donuts {
    flex: 0 0 auto;
  }
}
