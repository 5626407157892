.xw-insights-device-package-chart {
  .xw-bucket-chart {
    .timeline-series-wrapper {
      .dot-line-error {
        display: none;
      }

      .none {
        .value {
          display: none;
        }
      }
    }
  }
}
