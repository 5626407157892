@import "../styles/variables";

.xw-package-deviation-data {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  .insights-table-icon {
    padding-right: 4px;
  }
  .xw-insights-seperator {
    color: var(--c_help_section_separator);
    padding: 4px;
    font-size: large;
  }

  .link {
    color: var(--c_text-link);

    &:hover {
      transition: color 0.15s ease;
      color: var(--c_text-hover);
    }
  }

  .xw-opener-container {
    display: inline;
  }
}

.xw-package-deviation-data-modal {
  li {
    padding: 6px;
  }

  .insights-table-icon {
    padding-right: 4px;
  }

  .xw-insights-pkgname {
    padding-left: 4px;
    color: var(--c_modal-header-title);
  }
}

.xw-package-deviation-modal {
  .modal-dialog {
    width: 800px;
  }
}
