@import "../../styles/variables";
.page.xw-balancing {
  .xw-page-tabs {
    height: 100%;

    .xw-tabs {
      margin-top: calc($unit / 2);

      > li {
        padding: calc($unit/2) $unit * 2;
      }
    }
  }

  .xw-balancing-recommendations {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    overflow-y: hidden;

    .xw-select {
      width: 250px;
    }

    .filters {
      display: flex;
      flex-flow: row nowrap;
      margin: $unit * 2;
      .spacer {
        padding: 0 $unit * 2;
        align-self: center;
        font-size: 12 * $ptToEm;
      }
      .xw-trafficDatePicker {
        width: 250px;
      }
    }
    .recommendations-list {
      position: relative;
      flex-grow: 1;
      overflow-y: auto;
      padding: 0 $unit * 2;
    }
  }
}
