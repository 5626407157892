@import "../../../styles/variables";

.monitor-ignore-policy-gateway-modal {
  .xw-modal {
    .modal-header {
      background-color: var(--c_filter-modal-header-background);
    }
    .modal-body {
      background-color: var(--c_filter-modal-body-background);
    }
    .modal-footer {
      background-color: var(--c_filter-modal-footer-background);
    }
  }
  .modal-header {
    .title {
      text-transform: capitalize;
    }
  }
  .modal-dialog {
    width: 70%;
    height: 70%;
    .xw-data-table {
      height: 100%;
    }
  }
}
.xw-data-connected-ignored-gateway {
  .gateway {
    align-items: center;
    font-size: 14 * $ptToEm;

    > div {
      padding-right: $unit * 2;
      font-weight: 700;
    }

    .link {
      font-size: 14 * $ptToEm;
    }

    .warning {
      color: var(--c_text-warning);
    }

    .error {
      color: var(--c_text-error);
    }
  }

  .xw-content-suppress {
    &.gateway {
      color: var(--c_value-text);
      font-weight: bold;
      padding-right: calc($unit/3);
      .xw-button {
        font-weight: lighter;
      }
    }
    > div {
      padding-right: calc($unit);
    }

    .link {
      min-width: 110px;
    }
  }
}
