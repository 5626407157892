@import "../../../styles/variables";

.xw-peer-details {
  .xw-page-header {
    .title-section {
      .sub-title-section {
        .sub-title {
          text-transform: none;
        }
      }
    }
  }

  .xw-peer-overview {
    overflow: hidden;
    padding: $unit * 2 0 0;
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;
    .fields {
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: 12px;
      flex: 0 1;
      .xw-label-value {
        margin: 0 0 0 $unit * 3;
        min-width: 1px;

        &:first-child {
          margin-left: 0;
        }
        .last-active-alarm {
          display: flex;
          > a {
            padding-left: calc($unit / 2);
            margin-top: 1px;
            color: var(--c_text-link);
          }
        }
      }
    }
    .alarm-level-name {
      display: flex;
      > a {
        padding: 0 6px;
        font-size: 13 * $ptToEm;
        line-height: 100%;
        color: var(--c_text-link);
      }
      > div {
        width: 6px;
        height: 12px;
        align-items: flex-end;
        border-radius: 1px;
      }
    }

    > .chart-section {
      display: flex;
      flex: 1;
      flex-direction: column;
      .content {
        display: flex;
        position: relative;
        flex: 1 1 100%;
        height: 100%;
        padding: 10px;
      }
      .xw-line-chart {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-height: 400px;
        min-height: 150px;
      }
    }
  }
  .xw-card-list {
    flex-wrap: nowrap;

    .xw-card {
      .title {
        > h3 {
          color: var(--c_card-subtitle-text);
          font-size: 14 * $ptToEm;
          line-height: 19 * $ptToEm;
        }
      }
      .content {
        flex-flow: row wrap;
        flex: none;
        padding-bottom: $unit;
        .xw-label-value {
          flex: 1 1 50%;
        }
      }
    }
    .xw-peer-bgp-status-card,
    .xw-cisco-peer-card {
      display: flex;
      flex: 1 1 25%;
      .peer-bgp-status {
        display: flex;
        white-space: nowrap;
        .up-time {
          padding-left: 5 * $ptToEm;
        }
        .xw-label-value {
          margin-bottom: 0;
          .value,
          .value div,
          .value span,
          .xw-bgp-state {
            color: var(--c_card-subtitle-text);
          }
        }
      }
    }
    .xw-cisco-peer-card {
      .content {
        flex-direction: column;
        .xw-label-value {
          flex: 1;
        }
      }
    }
    .xw-current-peer-config-card {
      display: flex;
      flex: 2 2 50%;
      .content {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(3, 1fr);
        place-items: stretch;
        place-content: stretch;
        grid-gap: 3px;
        .xw-label-value {
          padding-right: $unit;
        }
      }
    }
  }

  .peer-alarms,
  .xw-bgpUpdates {
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    flex: 1 1 auto;
    .xw-activeAlarms-new-table,
    .xw-alarmAcknowledged-new-table {
      min-height: 0;
      flex: 1 1 100%;
      .xw-data-table {
        height: 100%;
        .actions {
          display: flex;
          margin-left: 8px;
          min-width: fit-content;
        }
      }
    }
  }
}

.xw-peer-edit {
  .xw-textarea {
    display: flex;
    > textarea {
      width: 100%;
    }
  }
}
