@import "../styles/variables";

.xw-yes-no-icon {
  display: flex;
  align-items: center;
  .checkbox-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px var(--c_device-capabilities-check-active) solid;
    border-radius: 100px;
    box-sizing: border-box;
    margin-right: calc($unit/3);

    .xw-icon {
      fill: var(--c_device-capabilities-check-active);
      width: 14px;
      height: 14px;
      transition: all 0.15s ease;
    }
  }
  .mismatch-icon {
    border: 1px var(--c-table-switch-active-fill) solid;
    background-color: var(--c_text-emphasis);
    .xw-icon {
      fill: var(--c-table-switch-active-fill);
      padding: 2px;
    }
  }
}
