@import "../../styles/variables";

.xw-card.xw-balancing-recommendation-card {
  margin: 0 0 1px 0;
  position: relative;

  > .content {
    display: flex;
    flex-flow: row nowrap;
    .pip {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: calc($unit / 2);
      background-color: var(--c_hr);
      border-radius: 3px 0 0 3px;
    }
    > .left {
      flex-basis: 33%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .xw-button {
        width: 138px;
        margin: 0;
      }

      .title {
        font-size: 20 * $ptToEm;
        line-height: 28 * $ptToEm;
        color: var(--c_recommendations-title);
        .xw-icon.move {
          margin-right: calc($unit / 2);
          fill: var(--c_text-ok);
        }
      }
      .traffic {
        display: flex;
        flex-flow: row nowrap;
        font-weight: 700;
        font-size: 14 * $ptToEm;
        .tx {
          margin-right: $unit * 0.75;
        }
        .prefix-utilization {
          font-size: 12 * $ptToEm;
          line-height: 15 * $ptToEm;
          .value {
            color: var(--c_text-title);
          }
        }
      }
    }
    > .right {
      flex-basis: 500px;
      display: grid;
      grid-template-columns: [header] 1fr [from] 1.5fr [to] 1.5fr;
      grid-template-rows: [header] 32px [device] 24px [interface] 24px [utilization] 24px;
      // grid-template-rows: 1fr 1fr 1fr;
      .col-header {
        grid-row-start: header;
        grid-row-end: header;
        font-size: 20 * $ptToEm;
        line-height: 28 * $ptToEm;
        font-weight: 400;
        color: var(--c_recommendations-headers);
        align-self: flex-end;
        margin-bottom: calc($unit / 2);
      }
      .row-header {
        grid-column-start: header;
        grid-column-end: header;
        font-size: 14 * $ptToEm;
        font-weight: 700;
        color: var(--c_recommendations-headers);
      }
      .item-from {
        grid-column-start: from;
      }
      .item-to {
        grid-column-start: to;
      }
      .value-device {
        grid-row-start: device;
      }
      .value-interface {
        grid-row-start: interface;
      }
      .value-utilization {
        grid-row-start: utilization;
        color: var(--c_text-title);
        font-size: 12 * $ptToEm;
        line-height: 15 * $ptToEm;
      }
      // special green color for normal utilization on this c
      .utilization-percent.utilization-normal {
        color: var(--c_text-ok);
      }
    }
  }
}
