@import "../../../../styles/variables";

.xw-aws-purchase {
  height: 100%;
  display: flex;
  flex-direction: column;
  .launchaws {
    display: inline-flex;
    align-items: center;
  }
  .aws-cards {
    display: flex;
    flex-flow: row wrap;
    .xw-card {
      min-width: 430px;
      min-height: 250px;
      margin-right: $unit * 2;
      margin-bottom: $unit * 2;
      .title-container {
        display: flex;
      }
      .title {
        .logo {
          display: flex;
          flex-flow: row nowrap;
          flex: 1 1 100%;
          height: 100%;
          align-items: center;
          svg {
            width: 36px;
            height: 36px;
          }
          .product-logo {
            display: flex;
            flex-flow: column nowrap;
            flex: 1 1 100%;
            padding-left: $unit;
            svg {
              width: 155px;
              height: 18px;
              fill: var(--c_mainNav-icon);
            }
            .tier-container {
              display: flex;
            }
            .tier {
              padding-top: 6px;
              text-transform: uppercase;
              font-size: 14 * $ptToEm;
              font-style: normal;
              color: var(--c_tier-section-header-text);
            }
            .tier-pending-status {
              color: var(--c_text-emphasis);
              font-size: 12 * $ptToEm;
              font-weight: bold;
              align-self: center;
              padding-left: 12px;
              padding-top: 5px;
            }
          }
        }
        a {
          &.xw-button {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &.new-purchase {
        .logo {
          svg {
            width: 24px;
            height: 24px;
            fill: var(--c_card-title-text);
          }
        }
      }
      &.existing-purchases {
        .logo {
          .xw-feature-logo-text {
            svg {
              fill: var(--c_card-title-text);
              width: 18px;
              height: 18px;
            }
            .part1 {
              font-size: 18 * $ptToEm;
            }
            .part2 {
              font-size: 18 * $ptToEm;
            }
          }
        }
      }
      .key-values {
        display: flex;
        .left-section {
          flex: 1;
          padding-right: $unit;
        }
        .right-section {
          flex: 1;
        }
      }
    }
  }
  h3 {
    color: var(--c_textInput-value);
    padding-top: $unit;
    padding-left: $unit;
    padding-bottom: $unit;
  }
  .header {
    a {
      padding-left: 4px;
      padding-right: 2px;
    }
    &.description {
      max-width: 70%;
      line-height: 24px;
      padding-left: $unit;
    }
  }
  .description {
    padding-bottom: calc($unit * 2);
    line-height: 18px;
    color: var(--c_textInput-value);
  }
  .tier-selection {
    color: var(--c_textInput-value);
    h6 {
      padding-bottom: $unit;
    }
    .footer {
      padding-top: $unit;
      display: flex;
      a {
        padding-left: calc($unit/3);
        color: var(--c_tier-text-link);
      }
    }
  }
}
.xw-feature-logo-text {
  display: flex;
  .part1 {
    padding-left: calc($unit/2);
    font-weight: 800;
    font-size: 24 * $ptToEm;
  }
  .part2 {
    font-weight: lighter;
    font-size: 24 * $ptToEm;
  }
}
