@import "../../styles/variables";

.xw-flow-replication-config {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 0;
  .xw-page-header {
    .separator {
      margin-bottom: 0;
    }
    > .header .title-section .sub-title-section .sub-title {
      white-space: normal;
      line-height: 18 * $ptToEm;
    }
  }
  .flow-replication-details {
    overflow-y: auto;
    flex-grow: 1;
    margin-bottom: 60px;
    .global-config {
      display: flex;
      flex-flow: row nowrap;
      > .xw-input-field > .xw-textarea {
        display: flex;
        flex-flow: column nowrap;
      }
      > .xw-input-field {
        flex: 1 1 30%;
        margin-left: 6px;
      }
      > .xw-textarea {
        flex: 1 1 59%;
      }
    }
    .gateway-config,
    .device-config {
      display: flex;
      flex-flow: column nowrap;
      .xw-reorder-list {
        > header {
          flex: 0 0 auto;
          > .actions {
            > .xw-button {
              width: 235px;
              padding: 0;
            }
          }
        }

        > .content {
          height: 100%;
          > ul > li {
            display: flex;
            flex: 0 0 auto;
            flex-flow: row nowrap;
            align-content: center;
            position: relative;
            justify-content: space-between;
            height: 80px;
            padding-bottom: $unit;
            > .xw-select,
            > .xw-input-field,
            > .xw-textarea {
              flex: 1 1 100%;
              display: flex;
              flex-flow: column nowrap;
            }
            > .xw-textarea {
              margin: $unit * 2 0 0 $unit;
            }
            .remove-action {
              top: 12px;
              right: 5px;
            }
          }
        }
      }
    }
    .xw-textarea textarea {
      width: 100%;
      padding: 24px 12px 7px;
    }
    .xw-textarea .hint {
      bottom: 5px;
    }
  }
}

.xw-view-flow-replication-config {
  margin: $unit * 2;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  overflow-y: auto;
  height: 100%;
  .xw-page-header {
    padding: 0;
    > .header .title-section .sub-title-section .sub-title {
      white-space: normal;
      line-height: 18 * $ptToEm;
    }
  }
  .data-fields {
    overflow-y: auto;
    height: 100%;
    margin-bottom: $unit * 3;
    .gateway-config-details,
    .device-config-details,
    .global-config-details {
      display: flex;
      flex-flow: row wrap;
      .xw-card {
        width: 460px;
        height: 200px;
        margin-left: 0;
        > .content {
          display: flex;
          flex-flow: row nowrap;
          overflow: auto;
          > div {
            justify-content: left;
          }
          .col1 {
            flex: 1 1 30%;
            .ip-label {
              display: block;
              font-size: 12 * $ptToEm;
              line-height: 15 * $ptToEm;
              color: var(--c_textInput-label);
              text-transform: capitalize;
            }
            .ip-value {
              font-size: 15 * $ptToEm;
              font-weight: 300;
              line-height: 18 * $ptToEm;
              color: var(--c_textInput-value);
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .col2 {
            flex: 1 1 25%;
          }
          .col3 {
            flex: 1 1 20%;
          }
          .col4 {
            flex: 1 1 25%;
          }
          .pps-highlight {
            .value {
              color: var(--c_section-header-emphasis);
            }
          }
        }
      }
    }
    .global-config-details .xw-card {
      flex-basis: auto;
      > .content {
        .col1 {
          flex: 1 1 30%;
        }
        .col2 {
          flex: 1 1 70%;
        }
      }
    }
  }
}
