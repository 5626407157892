@import "../../styles/variables";

.dossierSettingsModal-modal-wrapper {
  .modal-dialog {
    width: 1024px;
    min-height: 653px;
    .modal-body {
      padding: 0 $unit * 2;
      .xw-page-section {
        .title label {
          display: inline;
          font-size: 14 * $ptToEm;
        }
      }
      .content {
        .xw-switch-input {
          margin: $unit 0;
        }
        .xw-data-table {
          overflow-y: hidden;
          display: flex;
          flex-direction: column;
          top: $unit * 13;
          bottom: 0;
          right: 0;
          left: 0;
          position: absolute;
        }
      }
    }
  }
}
