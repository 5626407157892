@import "../../../styles/variables";

.xw-dossier-select {
  position: relative;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .device-common-details {
    .fields {
      display: flex;
      flex: 1 1 auto;
      flex-flow: row nowrap;
      justify-content: space-between;
      padding: $unit $unit 0;
      .xw-label-value {
        margin-bottom: calc($unit / 2);
        padding-right: $unit;
        min-width: 100px;
      }
    }
  }
  .xw-dossier-select-table {
    flex: 1 1 auto;
    .tbody {
      .change {
        border-bottom: 1px solid var(--c_text-emphasis);
      }
    }
  }
  .dossier-collection-ts {
    display: flex;
    .xw-tooltip-trigger {
      padding-left: calc($unit / 2);
      .warning-signing-change {
        fill: var(--c_text-emphasis);
      }
    }
  }
}
