@import "../../styles/variables";

.full-page {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  background: linear-gradient(305.42deg, #191e20 0%, rgba(25, 30, 32, 0) 100%),
    linear-gradient(180deg, rgba(#029fd9, 0.7) 0%, rgba(#bfdd20, 0.7) 100%),
    linear-gradient(305.42deg, #191e20 0%, #191e20 100%);
  color: #ffffff;
  font-size: 14 * $ptToEm;

  .content {
    padding: $unit $unit * 5;

    .logo > .xw-icon {
      width: 140px;
      height: 74px;
      fill: #ffffff;
      margin-bottom: $unit * 2;
    }

    h1.title {
      font-size: 26 * $ptToEm;
      font-weight: 100;
      margin-bottom: $unit;

      .crosswork {
        font-weight: 900;
      }
    }

    .large-text {
      font-size: 18 * $ptToEm;
    }

    .xw-checkbox {
      .checkbox {
        top: -1px;
        background-color: #ffffff;
        border-color: #ffffff;

        .fill {
          background-color: #087dbc;
        }
      }
    }
    .xw-select {
      margin-bottom: $unit * 2;
    }
    .xw-dropdown-menu {
      background-color: #ffffff;
      button {
        color: #373f40;
      }
    }
    .xw-input-field {
      width: 400px;
      > .xw-select-input,
      > input,
      > input:hover {
        background-color: #ffffff;
        color: #373f40;
        border-color: #d4d8d9 !important;
      }

      > label {
        color: #505859;
      }

      .error,
      .hint {
        color: #ffffff;
      }

      &.showError {
        > input {
          color: #d7262c;
          border-color: #d7262c !important;
        }
      }

      &.showRequired {
        > input {
          border-color: #d4d8d9;
        }
      }

      &:hover {
        > input {
          border-color: #ffffff;
        }
      }
    }

    .xw-button {
      margin: $unit * 2 0 0 0;
    }
  }

  .gradient-bar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: $unit * 2;
    background: linear-gradient(180deg, #029fd9 0%, #bfdd20 100%);
  }

  .copyright {
    position: absolute;
    bottom: $unit;
    left: $unit * 5;
  }

  .xw-animated-lines {
    position: absolute;
    z-index: 2;
    right: $unit * 4;
    bottom: 0;
  }
}
