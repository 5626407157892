@import "../../styles/variables";

.xw-device-inventory-page {
  .xw-device-inventory-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    padding-top: $unit;
    .xw-tooltip-trigger .device-bulk-edit {
      margin-left: 20px;
    }
  }
}
