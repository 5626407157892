@import "../../styles/variables";

.xw-tooltip {
  $arrowWidth: calc($unit / 2);
  color: var(--c_tooltip-text);
  background-color: var(--c_tooltip-bg);
  border-radius: 2px;
  min-height: $unit * 2;
  max-width: 340px;
  border: 1px solid var(--c_tooltip-border);
  font-size: 12 * $ptToEm;
  line-height: 16 * $ptToEm;
  padding: 4px $unit;
  position: fixed;
  top: 0; // overridden by popper, but necessary to take elem out of normal layout flow
  opacity: 0;
  transition: opacity 0.2s ease;
  visibility: hidden;
  z-index: $zTooltip;

  &.open {
    visibility: visible;
    opacity: 1;
  }

  .xw-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $arrowWidth;
    pointer-events: none;
  }

  .xw-tooltip-arrow:before {
    display: block;
    position: absolute;
    content: " ";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $arrowWidth - 2;
  }

  &[x-placement^="top"] {
    margin-bottom: $arrowWidth;
    > .xw-tooltip-arrow {
      border-color: var(--c_tooltip-border) transparent transparent transparent;
      bottom: -$arrowWidth * 2;
    }
    > .xw-tooltip-arrow:before {
      border-color: var(--c_tooltip-bg) transparent transparent transparent;
      top: -$arrowWidth;
      left: -$arrowWidth + 2;
    }
  }

  &[x-placement^="right"] {
    margin-left: $arrowWidth;
    > .xw-tooltip-arrow {
      border-color: transparent var(--c_tooltip-border) transparent transparent;
      left: -$arrowWidth * 2;
    }
    > .xw-tooltip-arrow:before {
      border-color: transparent var(--c_tooltip-bg) transparent transparent;
      right: -$arrowWidth;
      top: -$arrowWidth + 2;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: $arrowWidth;
    > .xw-tooltip-arrow {
      border-color: transparent transparent var(--c_tooltip-border) transparent;
      top: -$arrowWidth * 2;
    }
    > .xw-tooltip-arrow:before {
      border-color: transparent transparent var(--c_tooltip-bg) transparent;
      bottom: -$arrowWidth;
      left: -$arrowWidth + 2;
    }
  }

  &[x-placement^="left"] {
    margin-right: $arrowWidth;
    > .xw-tooltip-arrow {
      border-color: transparent transparent transparent var(--c_tooltip-border);
      right: -$arrowWidth * 2;
    }
    > .xw-tooltip-arrow:before {
      border-color: transparent transparent transparent var(--c_tooltip-bg);
      right: -2px;
      top: -$arrowWidth + 2;
    }
  }

  > .xw-tooltip-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px;
    svg {
      width: 10px;
      height: 10px;
      fill: var(--c_icon-close);
      transition: fill 0.15s ease;
    }

    &:hover svg,
    &:focus svg {
      fill: var(--c_icon-close-hover);
    }
  }
}

.theme-high-contrast .xw-tooltip {
  box-shadow: 0 0 $unit rgba(0, 0, 0, 0.25);
}
