#diffTree {
  .toggles {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .xw-toggle {
    width: 300px;
  }
  .xw-diff-tree {
    height: 400px;
  }
}
