@import "../../../styles/variables";
@import "../../../styles/base";

.asn-roa-details {
  display: flex;
  flex-direction: column;
  .validity-filter {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .xw-donut-chart,
    .xw-toggle {
      flex: 0 0;
      padding-top: 20px;
      width: 50%;
    }
    .xw-donut-chart {
      flex-basis: 550px;
    }
    .xw-toggle {
      flex-basis: content;
    }

  }

  .roa-last-scan-opener {
    position: relative;
    float: right;
    padding-right: 15px;
    font-size: 12 * $ptToEm;
    margin-top: 35px;
    margin-left: 60px;
    button {
      height: 20px;
      font-size: 15 * $ptToEm;
      line-height: 18px;
      margin: 0;
      padding: 0;
    }
  }

  .roa-table {
    margin-top: 10px;
    flex: 1 1 100%;
    .xw-data-table {
      height: 100%;
      .xw-roa-timestamp {
        .error {
          color: var(--c_text-red);
        }
      }
    }
  }
}
