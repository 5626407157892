@import "../../styles/variables";

.xw-validity-status {
  .expired {
    color: var(--c_modification_Delete);
  }
  .active {
    color: var(--c_modification_Create);
  }
}
