@import "../../styles/variables";

.xw-gauge {
  width: 156px;
  height: 156px - 24px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: visible;

    .main-value {
      font-size: 36 * $ptToEm;
      text-align: center;
      .unit {
        font-size: 18 * $ptToEm;
      }
      &.low {
        fill: var(--c_gauge-low);
      }
      &.medium {
        fill: var(--c_gauge-medium);
      }
      &.high {
        fill: var(--c_gauge-high);
      }
    }
    .arc-bg {
      fill: var(--c_text-link);
      transition: fill 0.15s ease;
      box-shadow: inset 0px 2.37037px 2.37037px rgba(255, 255, 255, 0.4);

      &.low {
        fill: var(--c_gauge-low);
      }
      &.medium {
        fill: var(--c_gauge-medium);
      }
      &.high {
        fill: var(--c_gauge-high);
      }
    }
    .labels .value {
      font-size: 12 * $ptToEm;
      fill: var(--c_chart-data);
      .unit {
        font-size: 8 * $ptToEm;
      }
    }
    .indicator {
      transition: transform 0.15s ease;
      fill: var(--c_gauge-indicator-fill);
      stroke: var(--c_gauge-indicator-stroke);
    }
  }
}
