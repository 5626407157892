@import "../../styles/variables";

.xw-traffic-analysis-setup {
  display: flex;
  height: 100%;
  flex: 1 1 100%;
  flex-direction: column;
  .setup-section {
    padding: $unit ($unit * 2) ($unit * 3) ($unit * 2);

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    li {
      padding-top: calc($unit / 3);
      padding-bottom: calc($unit / 3);
      display: flex;
      align-items: center;
      .x {
        fill: var(--c_text-error);
      }
      .checkmark {
        fill: var(--c_text-ok);
      }
      .label {
        padding-left: calc($unit / 2);
        color: var(--c_text);
      }
      .dot-loader {
        margin-top: 6px;
        div {
          width: 4px;
          height: 4px;
          margin: 0 1px;
        }
      }
    }

    .link {
      padding-left: $unit;
      color: var(--c_button-link-label);
    }

    .get-help {
      padding-top: $unit * 3;
      line-height: 1.5rem;
      a {
        color: var(--c_header-link);
        &.link {
          padding-left: 0px;
        }
      }
      a:hover {
        transition: color 0.15s ease;
        color: var(--c_header-link-hover);
      }
    }
  }
  h5 {
    color: var(--c_page-header-title);
    padding-bottom: calc($unit/2);
  }
}
