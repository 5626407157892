@import "../../../styles/variables";

.xw-endpoint {
  padding: $unit * 2 $unit * 2 0;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .xw-page-footer {
  }
  .xw-endpoint-edit {
    flex-grow: 1;
    .xw-input-field {
      width: 400px;
    }
    .xw-select {
      margin-bottom: 24 * $ptToEm;
    }
  }
  hr {
    margin: ($unit * 2) 0;
  }
}

.xw-endpoint-edit {
  padding: $unit * 2;

  .xw-textarea {
    display: flex;
    > textarea {
      width: 100%;
    }
  }

  .xw-file-input-field{
    margin: 0 0 $unit*2 0;
  }

}

.xw-endpoint-delete-modal {
  .modal-header {
    background-color: var(--c_modal-header-bg);
  }
  .endpoint-header {
    display: flex;
    flex-flow: row nowrap;
    .endpoint-name {
      color: var(--c_title-special-text);
      font-weight: 700;
      font-size: $unit * 2;
      padding-left: $unit;
    }
  }
  .actions {
    flex: 1 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    .stats {
      display: flex;
      .count {
        color: var(--c_text-title);
        font-weight: bold;
        padding-left: calc($unit/3);
        padding-right: calc($unit/3);
      }
    }
    .close {
      padding: 0;
      &:hover .xw-icon {
        fill: var(--c_button-link-label-hover);
        transition: fill 0.15s ease;
      }
    }
  }

  .warning {
    font-size: $ptToEm * 16;
    color: var(--c_modal-body-text);
  }

  .xw-page-caption {
    display: flex;
    flex-flow: column nowrap;
    .caption-1 {
      display: flex;
      padding-bottom: calc($unit/2);
    }
    .count {
      color: var(--c_text-title);
      font-weight: bold;
      padding-left: calc($unit/3);
      padding-right: calc($unit/3);
    }
  }

  .endpoint-section {
    .application-policy {
      padding-bottom: $unit;
      .application-type {
        display: flex;
        padding-bottom: calc($unit);
        svg {
          fill: var(--c_button-link-icon);
        }
        .application-label {
          padding-left: calc($unit/2);
          font-size: 18 * $ptToEm;
          color: var(--c_text-title);
        }
      }
      .policy-summary {
        padding-bottom: calc($unit/2);
        .policy {
          padding-bottom: calc($unit/2);
        }
      }
    }
  }
  section {
    margin-bottom: 24 * $ptToEm;

    .xw-label-value {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 4 * $ptToEm;
      label {
        font-size: $ptToEm * 14;
        padding-right: 8 * $ptToEm;
        line-height: 14 * $ptToEm;
        color: var(--c_modal-body-text);
      }
    }
    .endpoint {
      .xw-label-value {
        label,
        .value {
          font-size: $ptToEm * 16;
        }
      }
    }
    .rules {
      .xw-label-value {
        .value > span > span {
          color: var(--c_text);
        }
      }
    }
  }
}
