@import "variables";

h1 {
  font-size: 36 * $ptToEm;
  font-weight: 400;
  line-height: 50 * $ptToEm;
}
h2 {
  font-size: 24 * $ptToEm;
  font-weight: 400;
  line-height: 33 * $ptToEm;
}
h3 {
  font-size: 22 * $ptToEm;
  font-weight: 400;
  line-height: 30 * $ptToEm;
}
h4 {
  font-size: 20 * $ptToEm;
  font-weight: 400;
  line-height: 28 * $ptToEm;
}
h5,
.title {
  font-size: 18 * $ptToEm;
  font-weight: 400;
  line-height: 25 * $ptToEm;
}

h6 {
  font-size: 16 * $ptToEm;
  font-weight: 400;
  line-height: 22 * $ptToEm;
}

p {
  margin-bottom: $unit;
  font-size: 14 * $ptToEm;
}

.small {
  font-size: 12 * $ptToEm;
}
.tiny {
  font-size: 11 * $ptToEm;
}

.bold {
  font-weight: 700;
}

.light {
  font-weight: 300;
}

.fine-print {
  font-size: 10 * $ptToEm;
}

.field-label,
.field > label,
.xw-input-field > label {
  color: var(--c_textInput-label);

  font-size: var(--s_font-normal);
  line-height: 18px;
}

.status-enabled {
  color: var(--c_text-status-enabled);
}
.status-disabled {
  color: var(--c_text-status-disabled);
}
.status-warning {
  color: var(--c_text-warning);
}
.status-error {
  color: var(--c_text-error);
}
