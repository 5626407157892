@import "../../../styles/variables";

.xw-add-KGF {
  .xw-stepwizard {
    .xw-filter {
      padding: $unit;
    }
    .page-content-left {
      padding: $unit * 2 0 58px;
    }
  }
}
