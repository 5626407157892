@import "../../styles/variables";

.xw-count-down {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 392px;
  .header {
    padding: $unit;
    height: 36px;
    background-color: var(--c_card-bg);
  }
  .content {
    background-color: var(--c_countdown-recent);
    .active {
      fill: var(--c_countdown-active);
      height: 100%;
    }

    .warning {
      fill: var(--c_countdown-expiring);
      height: 100%;
    }

    .expired,
    .nodata {
      fill: var(--c_card-expander-bg);
      height: 36px;
      padding: $unit;
      color: var(--c_countdown-expired);
      font-weight: 700;
      text-transform: uppercase;
    }
    .progress {
      fill: var(--c_card-expander-bg);
    }
    .count-down-text {
      padding-left: calc($unit / 2);
      &.recent {
        fill: var(--c_countdown-recent);
      }
      &.text {
        fill: var(--c_countdown-active);
      }
      &.warning {
        fill: var(--c_countdown-expiring);
      }
      &.expiring {
        fill: var(--c_countdown-expired);
      }
    }
  }
}
