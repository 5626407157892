@import "../../styles/variables";

.xw-page-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: $footerHeight;
  background-color: var(--c_page-footer-bg);
  border-top: 1px solid var(--c_page-footer-border);

  > .left,
  > .right {
    display: inline-flex;
    height: 100%;
    align-items: center;
  }
  > .right {
    float: right;
    flex-flow: row-reverse nowrap;
    margin-right: $unit * 2;
  }
  > .left {
    float: left;
    flex-flow: row nowrap;
    margin-left: $unit * 2;
  }
}
