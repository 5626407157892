@import "../../styles/variables";

.xw-policy-details {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  &.page-padding {
    padding-bottom: 0;
  }

  .fields {
    display: flex;
    flex-flow: row nowrap;
    .xw-label-value {
      margin: 0 0 0 $unit * 3;
      &:first-child {
        margin-left: 0;
      }
      .policy-endpoints {
        .small {
          font-size: 0.75rem;
          color: var(--c_text);
          padding: 0 calc($unit / 2);
        }
      }
    }
  }

  .xw-policy-overview {
    overflow-y: auto;
    padding: $unit * 2 0 0 0;
    .peer-count-violation {
      display: flex;
      flex-flow: column nowrap;
      flex: 1 1 100%;
      .content {
        display: flex;
      }
    }
  }

  .policy-prefixes,
  .policy-asns,
  .policy-peers,
  .policy-alarms {
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    flex: 1 1 100%;
    .xw-data-table {
      height: 100%;
    }
    .table-actions {
      margin-top: 12px;
    }
    .xw-activeAlarms-new-table,
    .xw-alarmAcknowledged-new-table {
      min-height: 0;
      flex: 1 1 100%;
      .xw-data-table {
        height: 100%;
        .actions {
          display: flex;
          margin-left: 8px;
          min-width: fit-content;
        }
      }
    }
  }
}