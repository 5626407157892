@import "../../styles/variables";
.xw-double-trend-chart {
  display: flex;
  flex-flow: column nowrap;
  .chart-section {
    flex: 1 0;
    position: relative;
    > svg {
      position: absolute;
      height: 100%;
      width: 100%;
      > g {
        pointer-events: none;
        user-select: none;
      }
      path.line {
        fill: none;
        stroke-width: 2px;
      }

      path.area {
        &.top {
          fill: url("#doubleChartGradientTop");
          &.series-0 {
            fill: url("#doubleChartSeries0Top");
          }
          &.series-1 {
            fill: url("#doubleChartSeries1Top");
          }
        }
        &.bottom {
          fill: url("#doubleChartGradientBottom");
          &.series-0 {
            fill: url("#doubleChartSeries0Bottom");
          }
          &.series-1 {
            fill: url("#doubleChartSeries1Bottom");
          }
        }
      }

      .axis.y {
        text {
          font-size: 12px;
          fill: var(--c_chart-title);

          .unit {
            font-size: 10px;
            fill: var(--c_chart-label);
            font-weight: 700;
          }
        }
      }

      .axis.x {
        .xTick {
          text {
            font-size: 11px;
            &.time {
              fill: var(--c_chart-label);
            }
            &.date {
              fill: var(--c_text);
              font-weight: 700;
            }
          }
        }
      }

      .axis rect {
        fill: var(--c_chart-tick);
      }
      .axis line {
        stroke: var(--c_chart-tick);
      }

      .currentHover {
        transition: transform 0.15s ease;
        line {
          stroke: var(--c_trend-hover);
          stroke-width: 1px;
        }
        path {
          fill: var(--c_trend-hover);
        }
        .hover-wedge {
          fill: var(--c_content-bg);
          stroke-width: 2px;
          transition: transform 0.15s ease;
        }
        .hover-wedge.top {
          stroke: var(--c_chart-double-top);
        }
        .hover-wedge.bot {
          stroke: var(--c_chart-double-bottom);
        }
      }

      foreignObject.left {
        text-align: right;
      }

      .chartLabels {
        font-size: 18 * $ptToEm;
        font-weight: 700;
      }
    }

    .xw-button.left,
    .xw-button.right {
      position: absolute;
      margin: 0;

      .xw-icon {
        position: relative;
      }
    }
    .xw-button.left .xw-icon {
      left: -1px;
    }
    .xw-button.right .xw-icon {
      left: 1px;
    }

    label.side-label {
      position: absolute;
      font-size: 18 * $ptToEm;
      font-weight: 700;
    }
  }

  .color-top {
    color: var(--c_chart-double-top);
    .value {
      color: var(--c_chart-double-top);
    }
  }
  .stroke-top {
    stroke: var(--c_chart-double-top);
  }
  .fill-top {
    fill: var(--c_chart-double-top);
  }

  .color-bottom {
    color: var(--c_chart-double-bottom);
    .value {
      color: var(--c_chart-double-bottom);
    }
  }
  .stroke-bottom {
    stroke: var(--c_chart-double-bottom);
  }
  .fill-bottom {
    fill: var(--c_chart-double-bottom);
  }

  g.select-range-masks rect.mask {
    fill: var(--c_content-bg);
    opacity: 0.65;
  }

  &.range-select > .chart-section {
    cursor: ew-resize;
  }
}
