@import "variables";

.scrollbar-small {
  // Below two settings are for Firefox only
  scrollbar-color: var(--c_scrollbar-thumb-small) var(--c_scrollbar-track-small);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--c_scrollbar-thumb-small);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: var(--c_scrollbar-track-small);
  }
  &::-webkit-scrollbar-thumb:vertical:hover {
    background: var(--c_scrollbar-thumb-small-hover);
  }
}

.scrollbar-large {
  // Below two settings are for Firefox only
  scrollbar-color: var(--c_scrollbar-thumb-large) var(--c_scrollbar-track-large);
  scrollbar-width: auto;
  &::-webkit-scrollbar {
    width: 12px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--c_scrollbar-thumb-large);
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-track {
    background: var(--c_scrollbar-track-large);
  }
  &::-webkit-scrollbar-thumb:vertical:hover {
    background: var(--c_scrollbar-thumb-large-hover);
    border: 3px solid transparent;
    background-clip: content-box;
  }
}

.scrollbar-small,
.scrollbar-large {
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
