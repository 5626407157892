@import "../../styles/variables";

.xw-help-link {
  display: inline-block;
  font-size: 12 * $ptToEm;
  color: var(--c_header-link);
  line-height: 26px;
  height: 26px;

  .xw-icon {
    margin-left: calc($unit / 2);
    fill: var(--c_button-link-icon);
  }

  a::before {
    content: " | ";
    color: var(--c_header-link-separator);
    font-weight: 700;
    margin: 0 calc($unit / 3);
  }
  a {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  a:hover {
    transition: color 0.15s ease;
    color: var(--c_header-link-hover);
  }
}
