@import "../../styles/variables";
@import "chart.scss";

.xw-dual-axis-chart {
  position: relative;
  display: flex;
  flex-flow: column;
  background-color: var(--c_daul-axis-chart-bg);
  flex: 1 1 100%;
  max-height: 386px;

  > .chart-section {
    width: 100%;
    min-height: 180px;
    flex: 1 1 100%;
    position: relative;
    .xw-page-loader {
      background-color: transparent;
    }
    > svg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      pointer-events: all;
      cursor: col-resize;

      &.disabled {
        opacity: 0.1;
        pointer-events: none;
      }

      path.line,
      path.line-hover-area {
        fill: none;
      }

      path.line {
        stroke-width: 2px;
      }

      path.line-hover-area {
        pointer-events: stroke;
        stroke-width: $unit;
        cursor: pointer;
      }

      .line-marker {
        pointer-events: none;
      }

      .axis.left,
      .axis.right > rect {
        fill: var(--c_chart-tick);
      }

      rect.hover-data-point-rect {
        fill: none;

        &:hover {
          fill: rgba(255, 255, 255, 0.1);
        }
      }

      g.hover-data-rects-disable {
        pointer-events: none;
      }

      line.hover-top-end {
        stroke-width: 1px;
        stroke: var(--c_chart-dual-hover-default);
        stroke-dasharray: 4;
      }

      text {
        fill: var(--c_text-title);
        font-size: 12 * $ptToEm;
      }

      @for $i from 0 through 4 {
        .dualAxisDataArea#{$i} {
          fill: url("#dualAxisDataArea#{$i}");
          transition: fill 0.15s ease;
        }

        .dual-axis-line-stroke-#{$i} {
          stroke: var(--c_chart-dual-area-#{$i});
        }

        .axis-text-#{$i} {
          fill: var(--c_chart-dual-area-#{$i});
        }

        .line-marker-#{$i} {
          stroke-width: 1px;
          stroke: var(--c_chart-marker-border);
          fill: var(--c_chart-dual-area-#{$i});
        }

        .hover-horizontal-line-#{$i} {
          stroke-width: 1px;
          stroke: var(--c_chart-dual-area-#{$i});
          stroke-dasharray: 4;
        }

        .hover-vertical-line-#{$i} {
          stroke-width: 1px;
          stroke: var(--c_chart-dual-area-#{$i});
          stroke-dasharray: 4;
        }
      }
    }
  }

  > .top-section {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: var(--c_daul-axis-chart-header-bg);
    height: $unit * 7;
    border-bottom: 1px solid var(--c_bgp-updates-border);

    .actions {
      display: inline-flex;
      flex: 0 0 auto;
      flex-flow: row nowrap;
      padding: $unit;
    }

    > .time-summary-and-legend {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      min-width: 0;
      flex-flow: row;
      align-items: center;

      .total-values {
        color: var(--c_bgp-updates-legend-lbl);
        margin-left: 10px;
        .xw-bytes-text {
          display: flex;
          align-items: flex-end;
          .value {
            font-size: 25 * $ptToEm;
          }
        }
      }

      .xw-label-value {
        margin-bottom: 0;
        padding: 0 20px;

        > label {
          color: var(--c_bgp-updates-legend-lbl);
          font-size: 20 * $ptToEm;
          line-height: 20 * $ptToEm;
        }

        .value {
          color: var(--c_bgp-updates-legend-lbl);
        }
      }

      .separator {
        width: 1px;
        background-color: var(--c_ui-divider);
        margin: 10px 0;
        height: $unit * 5;
        padding: 0;
      }

      > .legend {
        display: flex;
        gap: 5px;
        flex-flow: column;
        overflow: hidden;
        padding: 0 20px;
        margin-bottom: 0;

        .series {
          flex: 0 1 auto;
          border-radius: calc($unit / 2);
          display: flex;
          justify-content: space-between;
          align-items: center;
          @for $i from 0 through 4 {
            .legend-series-lbl-#{$i} {
              color: var(--c_chart-dual-area-#{$i});
              font-size: 16 * $ptToEm;
              line-height: 14 * $ptToEm;
            }
          }

          .legend-series-val {
            font-size: 16 * $ptToEm;
            line-height: 14 * $ptToEm;
            color: var(--c_bgp-updates-legend-lbl);
            padding-left: 24px;
          }
        }
      }
    }
  }

  > .pan-section {
    position: relative;

    &.disabled {
      opacity: 0.25;
      pointer-events: none;
    }

    .actions-left {
      position: absolute;
      bottom: $unit;
      left: $unit;
    }

    .actions-right {
      position: absolute;
      bottom: $unit;
      right: $unit;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
    }
  }
}
