@import "../../../styles/variables";

.xw-product-offerings {
  display: flex;
  flex-direction: column;
  .toggle {
    display: flex;
    flex: 0 0 auto;
  }
  .xw-toggle {
    flex: 0 0 600px;
    padding-top: 2 * $unit;
  }
}
