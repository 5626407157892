@import "../../../styles/variables";

.xw-numeric-input {
  .steppers {
    position: absolute;
    right: calc($unit / 3);
    top: 0;
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .xw-button {
      width: $unit * 1.5;
      height: $unit * 1.25;
      padding: 0;
      margin: 0;
      .xw-icon {
        margin: 0 3px;
        fill: var(--c_icon-input-action);
      }
      &:hover {
        .xw-icon {
          fill: var(--c_icon-hover);
          transition: fill 0.15s ease;
        }
      }
      &.up .xw-icon {
        transform: rotate(180deg);
      }
    }
  }
}
