@import "../../../styles/variables";

.xw-device-changes-table {
  .xw-data-table {
    height: 100%;
    .table-actions {
      margin: 0 24px;
    }
    .selected-time {
      .xw-label-value {
        color: var(--c_text-emphasis);
        margin: 0;
      }
    }
  }
}
