@import "../../../styles/variables";

.xw-page-interface-edit {
  .page-content {
    margin: 0 $unit * 2;

    .xw-select {
      width: 50%;
      min-width: 384px;
      .xw-dropdown-menu {
        padding-top: $unit;
        width: 85%;
      }
    }
    .cir-label {
      display: flex;
      margin-top: $unit * 2;
      align-items: center;
      .cir-title {
        margin-left: 4px;
        font-weight: 400;
        font-size: 18 * $ptToEm;
        color: var(--c_page-header-title);
      }
    }
    .cir-info {
      font-weight: 300;
      margin: $unit 0px;
      .cir-val {
        color: var(--c_header-logo);
      }
    }
    .cir-settings {
      display: flex;
      .xw-numeric-input {
        margin-right: $unit * 2;
      }
      .xw-select {
        width: 17%;
        min-width: 260px;
      }
      .xw-input-field {
        width: 260px;
        max-width: 260px;
      }
    }
  }
}
