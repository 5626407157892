@import "../../styles/variables";

.xw-update-value {
  .update-label-Delete {
    color: var(--c_modification_Delete);
  }
  .update-label-Add {
    color: var(--c_modification_Create);
  }
}
