.xw-alarm-overview-peer-card-header {
  display: flex;
  align-items: center;

  .xw-alarm-overview-peer-card-header-toggle {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
  }
}

.xw-dashboard-page .xw-alarm-overview-peer-card .content .xw-geo-map {
  flex: 1 1 auto;
}

.xw-alarm-overview-peer-card #violation-peer_table {
  flex: 1 1 auto;
}
