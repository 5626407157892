@import "variables";

body {
  --s_font-normal: #{14 * $ptToEm};
  --s_font-small: #{10 * $ptToEm};

  color: var(--c_text);
  background-color: var(--c_content-bg);
  font-family: CiscoSans, Arial, sans-serif;
  font-size: var(--s_font-normal);
}

.transition-wrapper {
  position: relative;
  z-index: 0;
  width: 100%;
  flex: 1 1 100%;
  overflow: hidden;
  overscroll-behavior-y: auto;
}

.main-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--c_content-bg);

  &.with-sub-nav {
    left: $sidebar-width + $sub-nav-width;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.display-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.xw-hidden {
  visibility: hidden;
}

.well {
  background-color: var(--c_well-bg);
  border: 1px solid var(--c_well-border);
  box-sizing: border-box;
  border-radius: 2px;
  flex-grow: 1;
  padding: $unit ($unit * 2);
  margin: $unit 0;
}

.page-padding {
  padding: $page-padding;
}

.input-hint {
  font-size: 10 * $ptToEm;
  line-height: 15 * $ptToEm;
}

.xw-page-caption {
  font-size: 14 * $ptToEm;
  line-height: 18 * $ptToEm;
  color: var(--c_caption_text);
  padding: $unit 0;
}

// Range Slider
$thumb-radius: 16px !default;
$thumb-height: 16px !default;
$thumb-width: 16px !default;
$thumb-border-width: 2px !default;
$track-width: 100% !default;
$track-height: 2px !default;
$track-border-width: 0 !default;
$track-radius: 2px !default;

@mixin track {
  cursor: default;
  box-sizing: border-box;
  height: $track-height;
  transition: all 0.2s ease;
  width: $track-width;
}

@mixin thumb {
  background: var(--c_rangeInput-thumb-bg);
  border: $thumb-border-width solid var(--c_rangeInput-thumb-border);
  border-radius: $thumb-radius;
  box-sizing: border-box;
  cursor: default;
  height: $thumb-height;
  width: $thumb-width;
}

[type="range"] {
  -webkit-appearance: none;
  background: transparent;
  margin: calc($thumb-height / 2) 0;
  width: $track-width;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: var(--c_rangeInput-track-bg-focus);
    }

    &::-ms-fill-lower {
      background: var(--c_rangeInput-track-bg);
    }

    &::-ms-fill-upper {
      background: var(--c_rangeInput-track-bg-focus);
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;
    background: var(--c_rangeInput-track-bg);
    border: $track-border-width solid var(--c_rangeInput-track-border);
    border-radius: $track-radius;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: (
      calc(-#{$track-border-width} * 2 + $track-height) / 2 -
        calc($thumb-height / 2)
    );
  }

  &::-moz-range-track {
    @include track;
    background: var(--c_rangeInput-track-bg);
    border: $track-border-width solid var(--c_rangeInput-track-border);
    border-radius: $track-radius;
    height: calc($track-height / 2);
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: calc(($thumb-height / 2)) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: var(--c_rangeInput-track-bg);
    border: $track-border-width solid var(--c_rangeInput-track-border);
    border-radius: ($track-radius * 2);
  }

  &::-ms-fill-upper {
    background: var(--c_rangeInput-track-bg);
    border: $track-border-width solid var(--c_rangeInput-track-border);
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb;
    margin-top: calc($track-height / 4);
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }
}

.theme-high-contrast {
  .link,
  .theme-high-contrast .xw-help-link a,
  .xw-breadcrumbs a,
  .btn-add-filter,
  .btn-edit-filter {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 1440px) {
  .xw-dashboard-page {
    .widget.alarm-table {
      .table-title-container {
        height: $unit * 5;
        padding: 0 $unit * 2;
      }
      .xw-activeAlarms-table {
        top: $unit * 5;
      }
    }
    .widget.jump {
      .xw-input-field {
        .hint {
          white-space: nowrap;
        }
      }
    }
    .xw-active-alarms-country {
      .content {
        .xw-geo-map {
          height: 100px;
        }
      }
    }
    .xw-card {
      .title {
        height: $unit * 3;
        font-size: 16 * $ptToEm;
      }
      > .content {
        padding: $unit;
      }

      &.link-card {
        .content a {
          font-size: 13 * $ptToEm;
        }
      }

      &.xw-active-alarms-country {
        .content {
          padding: 0;
        }
      }
    }
    .xw-donut-chart {
      > svg {
        width: 75px !important;
        height: 75px !important;
        .graph {
          transform: scale(0.5);
          transform-origin: bottom right;
        }
      }
    }
  }
}
.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

hr {
  height: 1px;
  border: none;
  background-color: var(--c_hr);
  margin: $unit 0;
}

a.link {
  color: var(--c_text-link);
  &:hover,
  &:focus {
    color: var(--c_text-hover);
    transition: color 0.15s ease;
  }
  &:focus {
    text-decoration: underline;
  }
}

.spacer {
  flex-grow: 1;
}

.pre-wrap {
  white-space: pre-wrap;
}
