@import "../../../styles/variables";

.xw-asn-ip-pfx-search {
  .xw-dropdown-menu button {
    display: flex;
    .as-name {
      flex: 0 1 50%;
      text-align: start;
    }
    .as-number {
      right: $unit * 1.5;
      top: calc($unit / 2);
      display: flex;
      flex: 0 0 25%;
    }
    .no-match {
      display: flex;
      flex: 1 0 100%;
    }
  }
}
