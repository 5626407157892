@import "../../../../styles/variables";

.xw-aws-landing {
  padding-top: 70 * $ptToEm;
  align-items: flex-start;
}

.xw-aws-in-progress {
  position: relative;
  align-self: center;
  flex: 1;
  width: 100%;
  .loading-wrapper {
    position: unset;
    height: 100%;
  }
}
