@import "../../../styles/variables";

.xw-api-nav {
  flex: 0 0 200px;
  background: var(--c_mainNav-bg);
  margin: 1px 1px 0 0;
  padding: 0 24px 24px;
  overflow: hidden;

  .link-header {
    font-size: $ptToEm * 18;
    font-weight: 300;
    margin: 24px 0 0 0;
    color: var(--c_mainNav-text-heading);
  }

  ul {
    margin: 3px 0 0 0;
  }

  li {
    font-size: $ptToEm * 12;
  }

  .link:link,
  .link:visited,
  .link:active {
    text-decoration: none;
    font-size: $ptToEm * 14;
    line-height: 28px;
    color: var(--c_mainNav-text);
    font-weight: 400;
    &.active {
      color: var(--c_mainNav-text-active);
    }
  }

  .link:hover {
    color: var(--c_mainNav-text-hover);
  }
}
