@import "../../styles/variables";

.revoke-token-modal {
  .content {
    padding: 32 24px;

    ul {
      padding: 24px;
      background: var(--c-table-background);
      margin: 24px 0;
      border: 1px solid var(--c_filter_box-close-background);
    }

    li {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      span:nth-child(1) {
        color: var(--c_cdg-table-highlight-text);
      }
      span:nth-child(3) {
        color: var(--c_text-title);
      }
      margin-bottom: 24px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
