@import "../../styles/variables";

.xw-peerId {
  border: none;
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  color: var(--c_peer-id-text);
  background-color: var(--c_peer-id-background);
  .xw-icon {
    fill: var(--c_peer-id-text);
    vertical-align: top;
  }
}
