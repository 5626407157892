@import "../../styles/variables";

.xw-card.xw-rule-card {
  > .title {
    .active-alarms {
      font-size: 12 * $ptToEm;
      padding: calc($unit / 2) 0;
      font-weight: 700;
      color: var(--c_text-link);
      &:hover {
        transition: color 0.15s ease;
        color: var(--c_text-hover);
      }

      .xw-chip {
        display: inline;
        font-weight: 400;
        margin-right: calc($unit / 2);
      }
    }
  }
  > .content {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    padding-bottom: 0;

    .xw-label-value {
      flex: 1 1 50%;
    }
  }
  &.rule-disabled {
    > .title {
      background: var(--c_card-disabled);
      .header {
        color: var(--c_card-disabled-header);
      }
      .disabled-status {
        font-size: 12 * $ptToEm;
        font-weight: 700;
        color: var(--c_card-disabled-text);
        padding: calc($unit / 2) 0;
      }
    }
  }

  .active-alarms {
    > .xw-chip {
      transition: all 0.5s ease-in;
    }
  }
}
