@import "../../styles/variables";

.process-modal-processing {
  text-align: center;
  margin-top: 40px;
  .xw-processing-loader {
    margin: auto;
    padding: $unit 0;
  }
  .title {
    color: var(--c_processing_modal_processing_title);
    font-size: 20 * $ptToEm;
    line-height: 18 * $ptToEm;
    padding-bottom: $unit;
  }
  .message {
    color: var(--c_processing_modal_processing_message);
    font-size: 15px;
    line-height: 20px;
  }
}
