@import "../../styles/variables";

.xw-section-expander {
  .section-header {
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr 1fr;
    .header {
      font-size: 36 * $ptToEm;
      line-height: 49 * $ptToEm;
      font-weight: 700;
      color: var(--c_expander-header);
      text-transform: uppercase;
    }
    .xw-button {
      text-align: left;
      min-width: unset;
      padding: 0;
      .xw-icon {
        margin-right: $unit;
        width: 24px;
        height: 24px;
        fill: var(--c-expander-plus);
        &.rotate-270 {
          transition: transform 20ms ease-out;
        }
        &.rotate-45 {
          transition: transform 75ms ease-in;
        }
      }
    }
  }

  .node-enter {
    opacity: 0;
  }
  .node-enter-active {
    opacity: 1;
    transition: transform 200ms ease-in, opacity 200ms ease-in;
  }
  .node-exit {
    opacity: 1;
  }
  .node-exit-active {
    opacity: 0;
    transition: transform 200ms ease-out, opacity 200ms ease-out;
  }
}
