@import "../../../styles/variables";

.xw-range-selector {
  height: 2px;
  margin: 2%;
  position: relative;
  .range-rail {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--c_range-rail-bg);
    border-radius: 1px;
  }
  .range-handle {
    position: absolute;
    margin-left: -11px;
    margin-top: -9px;
    z-index: 2;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid var(--c_range-handle-border);
    background-color: var(--c_range-handle-bg);

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      right: 2px;
      top: 2px;
      background-color: var(--c_range-handle-after-border);
      border-radius: 50%;
      opacity: 0;
    }

    &:hover {
      border: 2px solid var(--c_range-handle-hover-border);
      &::after {
        opacity: 1;
      }
    }
    &:active {
      border: 1px solid var(--c_range-handle-active-border);
      &::after {
        background-color: var(--c_range-handle-active-after);
      }
      .range-handle-label {
        color: var(--c_range-handle-label-active);
      }
    }
    &.disabled {
      background: var(--c_range-handle-disabled-bg);
      pointer-events: none;
      border: 2px solid transparent;
      &:hover {
        border: 2px solid transparent;
      }
      &::after {
        opacity: 0;
      }
    }

    .range-handle-label {
      text-align: center;
      position: absolute;
      top: 23px;
      width: 100px;
      left: calc(50% - 50px);
      font-size: 12 * $ptToEm;
      line-height: 16 * $ptToEm;
      color: var(--c_range-handle-label);
    }
    &.disabled .range-handle-label {
      color: var(--c_range-handle-label-disabled);
    }
  }

  .range-track {
    position: absolute;
    height: 2px;
    z-index: 1;
    background-color: var(--c_range-track-bg);
    border-radius: 7px;
    cursor: pointer;
  }

  &:hover {
    .range-track {
      background-color: var(--c_range-hover-range-track);
    }
    .range-handle {
      border: 2px solid var(--c_range-hover-range-handle-border);
      &.disabled {
        border: 2px solid transparent;
      }
    }
    .range-rail {
      background-color: var(--c_range-rail-hover-bg);
    }
  }
  &.disabled {
    .range-track,
    .range-rail,
    .range-handle {
      background-color: var(--c_range-disabled-all);
      pointer-events: none;
      cursor: none;
    }
    .range-handle {
      border: 2px solid var(--c_range-disabled-all);
      .range-handle-label {
        color: var(--c_range-handle-label-disabled);
      }
    }
  }
}
