@import "../../styles/variables";

.xw-monitor-data-modal {
  .xw-modal {
    .modal-header {
      background-color: var(--c_policy-create-modal-header-background);
      .title {
        color: var(--c_policy-select-modal-title);
      }
    }
    .modal-footer {
      background-color: var(--c_policy-create-modal-footer-background);
    }
  }
  .table-queue {
    display: flex;
    flex: 1;
    min-height: 92%;
    .table-container {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      .modal-header {
        height: 110px;
      }
      .added-queue {
        display: flex;
        .text {
          padding-right: calc($unit / 2);
          text-transform: uppercase;
          color: var(--c_policy-select-added-queue-text);
        }
        .success {
          fill: var(--c_policy-select-added-queue-icon);
        }
      }
      .add-queue {
        display: flex;
        font-weight: bold;
        .text {
          padding-right: calc($unit / 2);
          text-transform: uppercase;
          color: var(--c_policy-select-add-queue-text);
        }
        .xw-icon {
          fill: var(--c_policy-select-add-queue-icon);
        }
      }
      .add-filter-results {
        display: flex;
        justify-content: flex-end;
        .xw-button {
          font-weight: bold;
          align-items: center;
          background: transparent;
          padding: $unit;
          border: none;
          .text {
            padding-right: calc($unit / 2);
            text-transform: uppercase;
            color: var(--c_policy-select-add-queue-text);
          }
          .xw-icon {
            fill: var(--c_policy-select-add-queue-icon);
          }
        }
      }
    }
  }
  .xw-queue {
    margin-left: $unit;
    flex: 0 0 17%;
    height: unset;
    .header-box {
      height: 110px;
      .subtitle {
        padding-bottom: calc($unit/6);
      }
    }
    .list {
      flex: 0 1 80%;
    }
  }
  .title {
    padding-bottom: calc($unit / 2);
  }
  .sub-title > div {
    padding-bottom: calc($unit / 2);
    &.select-subtitle {
      margin-left: $unit * 2;
      line-height: 20 * $ptToEm;
      ol {
        list-style: decimal;
        li {
          padding-bottom: calc($unit / 2);
        }
      }
    }
    &.available {
      display: flex;
      flex-flow: row nowrap;
      flex: 1 0 100%;
      line-height: 28 * $ptToEm;
      .total {
        color: var(--c_textInput-label-required);
        font-weight: 700;
      }
      .selected {
        color: var(--c_text-emphasis);
        font-weight: 700;
      }
    }
  }
  .xw-filter {
    padding: $unit * 2;
    flex-flow: row wrap;
    background: var(--c_modal-dialog-bg);
  }
  .modal-dialog {
    width: 98%;
    height: 90%;
    max-height: 90%;
    background: var(--c_header-bg);
    .xw-data-table {
      height: 100%;
    }
  }
}
