@import "../../styles/variables";

.xw-queue {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--c_queue-bg);
  border: 1px solid var(--c_queue-item-border);

  height: 800px;
  width: 208px;

  .header-box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: $unit * 2 $unit;
    height: 130px;
    flex: 0 0 auto;
    background-color: var(--c_queue-head-bg);

    .title {
      color: var(--c_queue-title);
      font-size: 24 * $ptToEm;
      margin-bottom: 2px;
    }

    .subtitle,
    .available {
      color: var(--c_queue-subtitle);
      font-size: 15 * $ptToEm;
      line-height: 30px;
    }
    .actual {
      color: var(--c_queue-actual-num);

      &.error {
        color: var(--c_queue-actual-num-error);
      }
    }
  }

  .list {
    flex: 1 1 auto;
    overflow-y: scroll;
    padding: $unit * 2 10px $unit * 2 $unit;
    display: flex;
    flex-direction: column;
    gap: $unit;
    position: relative;

    .item {
      background-color: var(--c_queue-item);
      box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      padding: $unit * 1.5 $unit;
      position: relative;
      transform-origin: center;

      .xw-label-value {
        margin: 0 0 $unit 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .xw-button {
        top: 0;
        right: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        padding: 0;
        min-width: 0;

        .xw-icon {
          fill: var(--c_queue-item-icon);
          width: $unit;
          height: $unit;
        }

        &:hover {
          .xw-icon {
            fill: var(--c_text-error);
          }
        }
      }
    }

    --slideTrans: all 250ms ease;

    .slide-enter {
      opacity: 0;
      transform: scale(0.5);
      & ~ .item {
        transform: translateY(-110%);
        transition: all 0s linear;
      }
    }

    .slide-enter-active {
      opacity: 1;
      transform: scale(1);
      transition: var(--slideTrans);
      & ~ .item {
        transform: translateY(0%);
        transition: var(--slideTrans);
      }
    }

    .slide-exit {
      opacity: 1;
      transform: translateY(0) scale(1);

      & ~ .item {
        transform: translateY(-110%);
        transition: var(--slideTrans);
      }
    }
    .slide-exit-active {
      opacity: 0;
      transform: translateY(-30%) scale(0.5);
      transition: var(--slideTrans);
    }
  }

  .no-items {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
