@import "../../styles/variables";

.xw-pause-prefixes {
  .xw-modal {
    .modal-dialog {
      min-height: 270px;
    }
    .modal-body {
      z-index: unset;
      overflow-y: unset;
      overflow-x: unset;
    }
  }
}
