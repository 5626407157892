@import "../../styles/variables";

.xw-donuts {
  display: flex;
  flex-wrap: nowrap;
  margin: $unit * 2;
  flex: 0 0 auto;

  .xw-donuts-item {
    flex: 1 1 1px;
    margin-right: $unit * 2;
    min-width: 0;
    min-height: 0;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    .field {
      margin: 0;
    }
  }
}
