@import "../../../styles/variables";

#reporting-peer-table {
  min-height: 490px;

  .table-actions {
    height: 36px;
    margin-top: 12px;
    .entity-value {
      font-size: 18 * $ptToEm;
      width: 100%;
    }
  }

  // align first column with modal title
  > .tbody .cell:first-child,
  > .header .cell:first-child {
    padding-left: $unit * 2;
  }
}
