@import "../../styles/variables";

.pre-signup {
  padding-top: 70 * $ptToEm;
  align-items: flex-start;
  .title {
    padding-bottom: 24 * $ptToEm;
  }
  .pre-signup-request {
    font-weight: 400;
    .header {
      font-size: 18 * $ptToEm;
      line-height: 27 * $ptToEm;
    }
    .link-description {
      padding: 0 4 * $ptToEm;
    }

    .pre-signup-content {
      a {
        color: #7ED1F6;
        font-weight: 900;
      }
      .pre-signup-direction {
        color: #EDF1F2;
        font-size: 18 * $ptToEm;
      }
      .pre-signup-description {
        line-height: 19 * $ptToEm;
        padding-bottom: 24 * $ptToEm;
        color: #D4D8D9;
      }
      .pre-signup-help-description {
        color: #EDF1F2;
        padding-bottom: 24 * $ptToEm;
      }

      ol {
        padding-bottom: 24 * $ptToEm;
        list-style-type: decimal;
        li {
          padding: 12 * $ptToEm 0;
          font-size: 13 * $ptToEm;
          align-items: center;
          margin-left: 14 * $ptToEm;
          color: #D4D8D9;
          span {
            padding-right: calc($unit / 4);
          }
        }
        ::marker {
          color: white;
          font-weight: bold;
        }
      }

    }
  }
  .inline-bolded {
    margin: 0 4 * $ptToEm;
    color: white;
    font-weight: bold;
  }
}
