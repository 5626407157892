@import "../../../../styles/variables";

.xw-aws-registration {
  padding-top: 70 * $ptToEm;
  align-items: flex-start;
  .content {
    width: 60%;
  }
  .aws-header-description {
    line-height: 19 * $ptToEm;
    padding-bottom: calc($unit * 2);
  }
  .xw-input-field {
    margin-bottom: $unit;
  }
  .end-user-email {
    padding-top: $unit * 2;
    padding-bottom: $unit;
  }
  .copyright {
    width: 70%;
  }
  .help {
    display: flex;
    flex-direction: column;
    width: 60%;
    .header {
      font-size: 18 * $ptToEm;
      line-height: 23 * $ptToEm;
      padding-bottom: calc($unit/2);
    }
    .description {
      font-size: 12 * $ptToEm;
    }
    padding-bottom: calc($unit * 3);
  }
  .footer {
    padding-top: $unit;
  }
  .field {
    margin-bottom: $ptToEm;
  }
}
.entitlementConfirmationModal {
  .entitlement-container {
    display: flex;
    align-items: center;
    .entitlement-id {
      font-size: 24 * $ptToEm;
      font-weight: 400;
      line-height: 33 * $ptToEm;
      color: var(--c_text-title);
      padding-top: calc($unit * 2);
      padding-bottom: calc($unit * 2);
      overflow-wrap: anywhere;
      display: flex;
      flex-direction: column;
    }
    .xw-tooltip-trigger {
      padding-left: calc($unit * 3);
    }
    button {
      min-width: 175px;
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    line-height: 18 * $ptToEm;
    .content {
      display: flex;
      flex-direction: column;
      ul {
        list-style: disc;
      }
      li {
        display: flex;
        list-style: disc;
        align-items: center;
        padding-top: calc($unit * 2);
        padding-bottom: $unit;
        &:before {
          content: "-";
          padding-right: $unit;
        }
        .note {
          padding-top: calc($unit/2);
          line-height: 18px;
        }
        span {
          padding-right: calc($unit / 4);
        }
      }
    }
  }
}
