@import "../../../../styles/variables";
.node-details-modal-wrapper {
  .location {
    padding: 0 $unit * 2;
    .content {
      display: flex;
      flex-flow: row wrap;

      .xw-label-value {
        flex: 0 0 50%;
      }
    }
  }
  .history {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 100%;
    > header {
      padding: 0 $unit * 2;
    }
    .custom-filter-controls-section {
      padding: 0;
    }
    .xw-data-table {
      min-height: 200px;
    }
  }
}
