@import "../../styles/variables";

#productFeedbackModal {
  .xw-textarea {
    width: 100%;
    textarea {
      width: 100%;
    }
  }
}
