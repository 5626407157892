@import "../../styles/variables";

.xw-column-chart {
  width: 100%;
  min-width: 128px;
  min-height: 200px;
  position: relative;

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // pointer-events: none;

    @for $i from 0 through 4 {
      .column-bar-area-#{$i} {
        fill: url("#columnBarArea#{$i}");
        transition: fill 0.15s ease;

        &:hover {
          fill: var(--c_chart-column-bg-#{$i});
        }
      }
    }

    text {
      fill: var(--c_text-title);
      font-size: 12 * $ptToEm;
    }

    .axis rect {
      fill: url(#chartTickX);
    }
  }

  .bar-wrapper {
    cursor: pointer;

    .value {
      display: none;
    }
    &:hover {
      .value {
        display: block;
      }
    }
  }

  > .legend {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden;

    .series {
      flex: 0 1 auto;
      margin-right: $unit * 2;
      height: 12px;

      .color {
        display: inline-block;
        height: $unit;
        width: $unit;
        border-radius: calc($unit / 2);
        margin-right: calc($unit / 2);
      }

      @for $i from 0 through 4 {
        .legend-area-bg-#{$i} {
          background-color: var(--c_chart-column-bg-#{$i});
        }
      }
    }
  }

  .no-data {
  }

  &.empty {
    min-height: 30px;
    height: 30px;
  }
}
