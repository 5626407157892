@import "../../styles/variables";

.xw-report-detail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  &.page-padding {
    padding-bottom: 0;
  }

  .fields {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .xw-label-value {
      margin: 0 0 0 $unit * 3;
      padding-right: $unit * 4;
      padding-bottom: $unit * 2;
      a {
        span {
          color: var(--c_button-link-label);
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
    .xw-textarea,
    .xw-textarea textarea {
      width: 80%;
    }
  }
  .report-section {
    padding: $unit * 2;
    overflow-x: auto;
    .xw-page-section {
      padding-bottom: $unit;
    }
    .report-endpoints {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      min-height: 60px;
      background-color: var(--c_card-bg);
      position: relative;
      padding-right: 52px;
      border-radius: 3px;
      margin-bottom: calc($unit / 2);
      transition: opacity 0.15s ease;
      .endpoint-row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        flex: 1 1 100%;
        .xw-label-value {
          margin-bottom: 0;
          padding-right: $unit;
          padding-left: $unit;
          min-width: 200px;
          .value {
            overflow-wrap: anywhere;
          }
        }
      }
      .small {
        font-size: 0.75rem;
        color: var(--c_text);
        padding: 0 calc($unit / 2);
      }
    }
  }
}
