@import "../../styles/variables";
.xw-traffic-drilldown {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  .filters {
    display: flex;
    flex-flow: row nowrap;
    margin: $unit * 1.5 $unit * 2;
    align-items: center;
    height: 72px;
    .xw-toggle {
      margin-right: $unit * 2;
      .toggle-option {
        width: 150px;
      }
    }
    .xw-form {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      .xw-input-field {
        position: relative;
        top: $unit;
      }
      .xw-button {
        height: $unit * 4;
      }
    }
    .spacer {
      flex-grow: 1;
    }
    .xw-trafficDatePicker {
      width: 382px;
    }
  }
  .xw-drilldown-table {
    flex-grow: 1;
    .xw-utilization-icon {
      margin-right: calc($unit / 2);
    }
  }
}
