@import "../../../styles/variables";
.xw-page-interface-detail {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .xw-traffic-double-trend-chart {
    position: absolute;
    top: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $unit $unit * 2;
  }
}
