@import "../../styles/variables";

.xw-accordion {
  > .xw-button {
    flex: 0 0 auto;
    display: flex;
    background-color: var(--c_accordion-header-background);
    border: 1px solid var(--c_accordion-content-border);
    width: 100%;
    height: auto;
    margin: 0;
    padding: $unit;
    > .header-icon {
      display: flex;
      padding: $unit;
      background-color: var(--c_accordion-icon-background);
      border: 1px solid var(--c_accordion-icon-box-border);
      border-radius: 2px;
      margin-right: $unit;
      .xw-icon {
        fill: var(--c_accordion-icon-color);
      }
    }
    > .header-title {
      display: flex;
      flex: 1 1 auto;
      flex-flow: column nowrap;
      color: var(--c_accordion-title-text);
      font-size: 24 * $ptToEm;
      font-weight: 300;
      align-items: flex-start;
      margin-right: $unit;
      margin-top: calc($unit / 4);
      .subtitle {
        color: var(--c_accordion-subtitle-text);
        font-size: 14 * $ptToEm;
        text-align: left;
        p {
          padding-top: calc($unit / 4);
          margin-bottom: 0;
        }
      }
    }
    &:disabled {
      opacity: 0.6;
      .header-icon .xw-icon {
        fill: var(--c_accordion-icon-disabled);
      }
      .header-title {
        opacity: 0.5;
        color: var(--c_accordion-title-text-disabled);
      }
    }

    .actions {
      display: inline-flex;
      flex: 0 0 auto;
      flex-flow: row nowrap;
      margin: calc($unit / 4) calc($unit / 4) 0 0;
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    position: relative;
    border: 1px solid var(--c_accordion-content-border);
    padding: $unit;
    box-sizing: border-box;
    background-color: var(--c_accordion-content-background);

    .xw-label-value {
      margin-bottom: $unit;
      min-height: 33px;
    }
  }

  // content transition
  .node-enter {
    opacity: 0;
  }
  .node-enter-active {
    opacity: 1;
    transition: transform 200ms ease-in, opacity 200ms ease-in;
  }
  .node-exit {
    opacity: 1;
  }
  .node-exit-active {
    opacity: 0;
    transition: transform 200ms ease-out, opacity 200ms ease-out;
  }
}
