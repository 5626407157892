@import "../../styles/variables";

.xw-alarms-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .xw-tabs {
    margin: $unit 0 0 0;
    &:before {
      display: block;
      flex: 1 1 $unit;
      content: " ";
      display: block;
      border-bottom: 1px solid var(--c_tabs-bottom-border);
    }
  }
  .xw-alarmHistory-table {
    position: unset;
  }
  .xw-activeAlarms-new-table,
  .xw-alarmAcknowledged-new-table {
    min-height: 0;
    flex: 1 1 100%;
    .xw-data-table {
      height: 100%;
      .actions {
        display: flex;
        margin-left: 8px;
        min-width: fit-content;
      }
    }
  }
}
.xw-alarms,
.prefix-alarms,
.asn-alarms,
.peer-alarms,
.policy-alarms {
  .custom-filter-controls-section > span {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .xw-toggle {
    .rail .options > button {
      min-width: 150px;
    }
  }

  &.filtered {
    height: 100%;
    display: flex;
    flex-direction: column;

    .filter-list {
      flex: 0 0 auto;
      padding: $unit * 2;
      margin: 0;
      background-color: var(--c-table-background);
      border-bottom: 1px var(--c-table-header) solid;

      .title {
        font-weight: 300;
        font-size: 18 * $ptToEm;
        line-height: 20px;
        color: var(--c_filterby-label);
      }

      .items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .item {
          padding: 4px 6px;
          border-radius: 3px;
          background-color: var(--c_filterby-item-bg);
          color: var(--c_filterby-item-text);
          margin: 6px 6px 0 0;
          font-size: 12 * $ptToEm;

          &.type {
            background-color: transparent;
            padding: 4px 0;
            font-weight: 700;
            color: var(--c_filterby-item-title);
          }
        }

        .calc-text {
          margin: 0 calc($unit / 2);
        }
      }
    }

    .xw-activeAlarms-table {
      min-height: 0;
      flex: 1 1 100%;
    }
  }
}

.alarmHistoryTable-update-label-Active {
  color: var(--c_modification_Delete);
}
.alarmHistoryTable-update-label-Cleared {
  color: var(--c_modification_Create);
}
.alarmHistoryTable-update-label-Unconfigured {
  color: var(--c_modification_Create);
}
.alarmHistoryTable-update-label-Snoozed {
  color: var(--c_modification_Edit);
}
.alarmHistoryTable-update-label-Acknowledged {
  color: var(--c_modification_Edit);
}
.alarmHistoryTable-update-label-Configured {
  color: var(--c_modification_Edit);
}

#alarmHistory-table,
#alarm-details-alarmHistory-table {
  padding-top: $unit;
}

.xw-alarmHistory-table {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.xw-table .notes-button {
  margin: auto !important;
  > svg {
    fill: var(--c_button-link-label);
  }
  &:hover > svg {
    transition: fill 0.15s ease;
    fill: var(--c_button-link-label-hover);
  }
}

.xw-alarm-notes-modal {
  white-space: pre-wrap;
}

#policy-alarmHistory-table {
  height: 100%;
}
