// use rems for better screen scaling of font size, but convert from pt
// because that's more familiar to humans
$ptToEm: 0.0625rem;

$unit: 12px;
$input-height: $unit * 4;

$zSidebar: 5;
$zDropdown: 10;
$zTooltip: 20;
$zModalRoot: 2;
$zDrawer: 3;
$zToasterRoot: 4;
$header-height: 72px;

$sidebar-width: 180px;
$sub-nav-width: 174px;

$footerHeight: 58px;
$page-padding: $unit $unit * 2;
$page-left-right-padding: 0 $unit * 2;
$page-top-bottom-padding: $unit 0;
$table-title-height: 65px;
