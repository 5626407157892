@import "../../../styles/variables";

.xw-alarm-details {
  .xw-page-header {
    .title-section {
      .xw-alarm-status-value {
        display: flex;
        gap: 5px;
        align-items: baseline;
        .time {
          font-size: 0.75rem;
          color: var(--c_textInput-value);
          font-weight: 350;
        }
      }
      .sub-title-section {
        .sub-title {
          text-transform: none;
        }
      }
    }
  }

  .alarm-overview.xw-page-section {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;

    header {
      margin: 0 0 $unit * 2 0;
      flex: 0 0 auto;
    }

    > .content {
      flex: 1 1 100%;
      position: relative;
      display: flex;
      .xw-data-table {
        flex: 1;
      }
    }
  }

  .xw-card-list {
    .xw-card {
      .title {
        > h3 {
          color: var(--c_card-subtitle-text);
          font-size: 14 * $ptToEm;
          line-height: 19 * $ptToEm;
        }
      }
    }
  }

  .xw-card {
    .title {
      .xw-label-value {
        label {
          color: var(--c_card-subtitle-text);
        }
        .value {
          color: var(--c_card-title-text);
        }
      }
    }
  }

  .xw-alarm-overview {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    padding-bottom: $unit * 2;
    overflow-y: auto;
    overflow-x: hidden;

    &.as-new-prefix-violation {
      .prefixAlarmDetailsOverviewDescription {
        padding: 2 * $unit;
        .myPeersCount {
          color: var(--c_text-warning);
          font-size: 24px;
          font-weight: 700;
        }
      }

      .observed {
        min-height: 400px;
      }

      .xw-alarm-overview-card {
        .xw-page-loader {
          background-color: transparent;
          .loading-wrapper {
            top: $unit;
            right: calc(50% - 25px); // loader width is 50px
            .xw-processing-loader {
              width: 50px;
            }
          }
        }
      }
    }

    &.prohibited-ip-prefix-violation {
      .alarm-cards .xw-alarm-overview-card .padded-label-content {
        line-height: 20 * $ptToEm;
        padding: $unit * 2;
      }
    }

    .fields {
      display: flex;
      flex-flow: row wrap;
      .xw-label-value {
        flex-basis: 25%;
      }
    }
    .meta {
      flex: 0 0 auto;
      padding-top: $unit * 2;
      display: flex;
      justify-content: flex-start;

      .xw-label-value {
        margin-left: $unit * 3;

        &:first-child {
          margin-left: 0;
        }

        .xw-level {
          .high-level-icon {
            margin-top: calc($unit / 4);
          }
        }
      }
    }
    .alarm-cards {
      display: grid;
      flex-flow: row wrap;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      box-sizing: border-box;
      place-items: stretch;
      place-content: stretch;
      grid-gap: 12px;
      margin: 0;
      width: 100%;
      padding-right: $unit;

      &.only-expected {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0;
      }
      .content {
        padding: 0;
        .custom-filter-controls-section {
          padding: 0;
        }
      }
      .xw-card {
        margin: 0;

        &.peer-count {
          .content {
            padding: 24px;
            flex: 0 0 auto;
            .peer-count-violation {
              flex: 1 1 auto;
              margin-bottom: $unit * 2;

              header {
                margin-top: 0;
              }

              .content {
                display: flex;
                padding: 0;
                .xw-label-value {
                  flex: 1 1 100%;
                }
              }
            }
          }
        }
        .xw-peer-root-dns-alarm-card {
          display: flex;
          flex-direction: row;
          padding: 12px 24px;
          div {
            display: flex;
            flex-direction: column;
            flex: 1 1 50%;
          }
        }
      }

      .suspicious-edge-card {
        grid-area: 1/1/2/3;

        .xw-data-table {
          height: 100%;
        }

        .padded-label-content {
          padding: 12px 24px;
        }
      }

      .xw-valuesWithTooltip {
        .xw-tooltip-trigger {
          display: inline;
        }
      }

      .expected {
        grid-area: 1/1/1/1;

        .xw-data-table {
          height: 100%;
        }

        .padded-label-content {
          padding: 12px 24px;
        }
      }

      .observed {
        grid-area: 1/2/1/2;

        .xw-data-table {
          height: 100%;
        }

        .padded-label-content {
          padding: 12px 24px;
          &.peer-down,
          .prohibited-ip-prefix {
            display: flex;
            .xw-label-value {
              flex: 1 1 50%;
            }
          }
        }
      }
      .sub-prefix {
        .content {
          display: flex;
        }
      }

      .peer-table {
        grid-area: 2/1/2/3;
        min-height: 450px;
        .content {
          flex-flow: row;
          display: flex;
          .peer-left {
            flex: 1 1 auto;
            display: flex;
            min-width: 50%;
            .xw-data-table {
              width: 100%;
            }
          }
          .map-right {
            position: relative;
            flex: 1 1 auto;
            min-width: 50%;
            min-height: 50%;
            .xw-geo-map {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
  .table-section {
    .table-title {
      background-color: var(--c_card-title-bg);
      color: var(--c_card-title-text);
      font-size: 1.25rem;
      font-weight: 300;
      padding: 12px 24px;
      height: 66px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }
    .custom-filter-controls-section {
      padding: 0;
    }
  }
  .xw-bgpupdates,
  .prefix-looking-glass-section {
    padding-top: $unit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    flex: 1 1 auto;
    .xw-data-table {
      height: 100%;
    }
  }
  .xw-bgpupdates {
    .notification {
      color: var(--c_text-warning);
      width: 100%;
    }
  }
  .xw-prohibited-ip-pfx-violation {
    .record-count-msg {
      display: flex;
      flex: 0 0 auto;
      justify-content: flex-end;
      margin-top: $unit;
      color: var(--c_text-status-notice);
    }

    .xw-data-table {
      position: absolute;
      top: $unit * 6;
      bottom: 0;
      left: -24px;
      right: 0;
      width: calc(100% + 48px);
    }
  }

  .subprefix-violation {
    .alarm-cards {
      grid-template-columns: repeat(2, 1fr);

      .expected {
        grid-area: 1/1/1/1;
      }

      .observed {
        grid-area: 1/2/4/2;

        #observed-subprefix-violation-table_table {
          height: 100%;
        }

        .subprefixAlarmDetailsOverviewDescription {
          padding: 2 * $unit;
          .subprefixCount {
            color: var(--c_text-warning);
            font-size: 24px;
            font-weight: 700;
          }
          .xw-processing-loader {
            max-width: 56px;
            height: 36px;
            position: relative;
            left: calc(50% - 32px);
            top: calc(50% - 10px);
          }
        }
        .xw-data-table {
          height: 100%;
        }
      }

      .xw-table .no-pointer-event {
        pointer-events: inherit;
      }

      .peer-table {
        grid-area: 2/1/4/1;
      }
    }
  }
}
