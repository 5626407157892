@import "../../styles/variables";

#editTagsModal {
  min-height: 450px;
  .modal-body {
    padding: $unit * 2;
    > .error {
      margin-top: $unit;
    }
    .xw-dropdown-menu.open {
      max-height: 186px;
    }
  }
}
