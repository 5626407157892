@import "../../../styles/variables";

.xw-manage-KGF {
  display: flex;
  flex-direction: column;
  height: 100%;
  .xw-data-table {
    height: 100%;
    flex: 1 1 auto;
    .table-actions {
      justify-content: flex-start;
      .selected-files {
        display: flex;
        flex-flow: row nowrap;
        font-size: 16 * $ptToEm;
        font-weight: 300;
        line-height: 28 * $ptToEm;
        padding-left: $unit;
        flex: 1 1 100%;
        .text {
          color: var(--c_page-header-label);
          padding-right: $unit;
        }
        .total {
          color: var(--c_textInput-label-required);
          font-weight: 700;
        }
        .selected {
          color: var(--c_text-emphasis);
          font-weight: 700;
        }
      }
    }
  }
  .xw-filter {
    padding-left: $unit * 2;
  }
}
