@import "../../styles/variables";

.xw-add-cdg {
  .enrollment-token {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin: 0 -24px;

    .xw-page-caption,
    .table-actions {
      padding-left: 24px;
      padding-right: 24px;
    }
    .xw-data-table {
      flex: 1 1 auto;
    }
    .table-actions .actions {
      flex-shrink: 0;
      display: flex;
    }
    .xw-data-table .xw-button {
      padding: 0 24px;
      margin: 0;
    }
    .xw-data-table .xw-button .xw-icon {
      margin-left: 6px;
    }
    .xw-button.link {
      padding: 0;
      margin: 0 0 0 24px;
    }
    .xw-data-table .table-actions {
      margin: 0 0 12px 0;
    }
  }
  .device-information {
    width: 50%;
    .xw-textarea,
    textarea {
      width: 100%;
    }
    .cdg-type-select {
      margin-bottom: $unit * 2;
      .xw-input-field > span.hint {
        color: var(--c_text-emphasis);
      }
    }
  }
  .registration {
    min-height: 150px;
  }
  .gateway-install-help-footer {
    line-height: 19px;
    .xw-button {
      margin-top: $unit;
      margin-left: 0;
      width: 300px;
      height: 38px;
      .xw-icon {
        position: relative;
        top: 2px;
        margin: 0 6px 0 0;
      }
    }
  }
  .review-ntw-interfaces {
    .xw-page-section > header .title {
      display: flex;
      .highlight {
        color: var(--c_title-special-text);
        font-weight: 700;
      }
    }
    .xw-textarea {
      width: 50%;
    }
    textarea {
      width: 100%;
    }
  }
}

.xw-cdg-edit {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 0;
  .edit-fields {
    overflow-y: auto;
    flex-grow: 1;
    margin-bottom: $footerHeight;
    .fields {
      width: 50%;
      .xw-textarea,
      textarea {
        width: 100%;
      }
      .cdg-type-select {
        margin-bottom: $unit * 2;
        .xw-input-field > span.hint {
          color: var(--c_text-emphasis);
        }
      }
    }
    .static-routing-section {
      margin-top: $unit * 3;
      width: 100%;
      .xw-textarea {
        width: 50%;
      }
      textarea {
        width: 100%;
      }
    }
  }
}

.xw-cdg-details {
  .xw-page-header {
    .header {
      .sub-title {
        color: var(--c_section-header-emphasis);
      }
    }
    .separator {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .xw-cdg-overview {
    overflow-y: auto;
    .status {
      margin-top: $unit * 2;
    }
    .fields {
      display: flex;
      flex-flow: row wrap;
      margin-top: $unit * 2;

      &:first-child {
        margin-top: 0;
      }

      .xw-label-value {
        margin: 0 $unit * 3 0 0;
        min-width: 1px;
      }
    }

    .donut-chart {
      section {
        display: flex;
        flex-flow: row;
      }
      .donut-vertical-separator {
        border-right: 1px dashed var(--c_section-header-separator);
        margin: 0 36px;
      }
    }

    .xw-card {
      .title {
        > h3 {
          color: var(--c_card-subtitle-text);
          font-size: 14 * $ptToEm;
          line-height: 19 * $ptToEm;
        }
        .link {
          color: var(--c_text-link);
          margin-left: calc($unit/2);
          font-size: 14 * $ptToEm;
          line-height: 16 * $ptToEm;
        }
      }
    }

    .xw-label-value {
      .value div {
        padding-bottom: 12 * $ptToEm;
        .status-warning {
          color: var(--c_text-warning);
        }
      }
    }

    .application-cards {
      .trust-card {
        margin-left: 0;
        margin-right: 0;
      }
      .flow-card {
        margin-left: 0;
        margin-right: 0;
        .donut-chart {
          section {
            display: flex;
            flex-flow: row;
          }
        }
        .donut-vertical-separator {
          border-right: 1px dashed var(--c_header-link-separator);
          margin: 0 36px;
        }
        .discarded-data-section,
        .stats-section {
          header {
            margin-bottom: 0;
            .spacer {
              background-color: var(--c_header-link-separator);
            }
          }
          .section-sub-label {
            color: var(--c_card-title-text);
            font-size: 12 * $ptToEm;
            line-height: 16 * $ptToEm;
          }
        }
      }
    }
    .no-application-card {
      button {
        margin: 16px 0;
      }
    }
  }
}

.xw-cdg-connectivity-status,
.xw-label-value .value {
  .status-warning {
    font-weight: 700;
    color: var(--c_text-warning);
  }
  .connectivity-ok {
    color: var(--c_text-ok);
  }
  .connectivity-error {
    color: var(--c_text-error);
  }
  .connectivity-notice {
    color: var(--c_text-status-notice);
  }
}

.xw-label-value .value .xw-cdg-connectivity-status div {
  font-weight: 700;
}

.xw-cdg-link-devices {
  position: relative;
  left: -24px;
  width: calc(100% + 48px);
  flex: 1 1 100%;
  .xw-data-table {
    height: 100%;
    .table-actions {
      margin: $unit $unit * 2 calc($unit / 2) $unit * 2;
    }
    .actions {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      > .xw-button {
        min-width: 120px;
      }
      > .xw-button {
        min-width: 170px;
      }
    }
  }
  .xw-status-value {
    color: var(--c_text-red);
    .status-label-Connected {
      color: var(--c_text-ok);
    }
    .status-label-link {
      color: var(--c_text-link);
    }
    .disabled {
      color: var(--c_text-unknown);
    }
  }
}

#linkTrustDevicesModal {
  .modal-body {
    display: flex;
    flex-direction: column;
  }
}
.linkTrustDevicesModal-modal-wrapper {
  .linkTrustDevices-table {
    min-height: 350px;
  }
}
.linkDevicesModal-modal-wrapper {
  .xw-data-table {
    min-height: 300px;
  }
}

#linkFlowDevicesModal {
  .modal-body {
    display: flex;
    flex-direction: column;
  }
}
.linkFlowDevicesModal-modal-wrapper {
  .linkFlowDevices-table {
    min-height: 350px;
  }
}
