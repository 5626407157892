@import "../../../styles/variables";

.xw-trial-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  .title {
    .count {
      padding-left: $unit;
    }
  }
  .trial-progress {
    display: flex;
    flex-direction: row;
    padding-bottom: $unit;
    .xw-count-down {
      .sub-header {
        text-transform: uppercase;
        color: var(--c_text-title);
      }
      .no-data {
        padding: $unit;
      }
    }
    &.available {
      align-items: flex-end;
      .xw-button {
        padding-left: $unit * 4;
        padding-bottom: $unit;
        height: 100%;
        margin-left: 0;
      }
    }
    .stats {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .xw-button.link {
        align-items: inherit;
        margin-bottom: 0;
        padding-left: $unit * 4;
        padding-bottom: $unit;
        line-height: 1;
        height: 100%;
      }
      .opener-container {
        display: flex;
      }
      .remaining {
        display: flex;
        flex-direction: row;
        padding-left: $unit * 4;
        padding-bottom: $unit;
        .count {
          font-weight: bold;
          color: var(--c_section-header-emphasis);
        }
      }
    }
  }
}
