@import "../../../styles/variables";

.xw-button {
  position: relative;
  margin: calc($unit / 2) 4px;
  width: auto;
  min-width: 125px;
  height: 34px;
  padding: 0 24px;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: var(--c_button-label);
  border-radius: 2px;
  user-select: none;

  &.fit {
    min-width: unset;
    padding: 0 $unit;
  }

  &.primary {
    color: var(--c_button-primary-label);
    background: var(--c_button-primary);
    border: 1px solid var(--c_button-primary-border);
    &:hover {
      background-color: var(--c_button-primary-hover);
    }
    &:disabled {
      border: 1px solid var(--c_button-primary-disabled-border);
      pointer-events: none;
      opacity: 0.5;
    }
    &:active {
      background-color: var(--c_button-primary-pressed);
      border: 1px solid var(--c_button-primary-pressed-border);
    }
  }

  &.secondary {
    color: var(--c_button-secondary-label);
    background: var(--c_button-secondary);
    border: 1px solid var(--c_button-secondary-border);
    &:hover {
      background-color: var(--c_button-secondary-hover);
      color: var(--c_button-secondary-hover-label);
      .xw-icon {
        fill: var(--c_button-secondary-hover-label);
      }
    }
    &:disabled {
      border: 1px solid var(--c_button-secondary-disabled-border);
      pointer-events: none;
      opacity: 0.5;
    }
    &:active {
      background-color: var(--c_button-secondary-pressed);
      border: 1px solid var(--c_button-secondary-pressed-border);
    }

    .xw-icon {
      fill: var(--c_button-secondary-label);
    }
  }

  &.inline {
    display: inline-block;
  }

  &.compact {
    height: unset;
    padding: unset;
  }

  &.link {
    background: transparent;
    border: none;
    color: var(--c_button-link-label);
    text-align: left;
    min-width: unset;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    &:hover {
      color: var(--c_button-link-label-hover);
      transition: color 0.15s ease;
    }
    &:disabled {
      color: var(--c_button-link-label-disabled);
      cursor: default;
    }
    .xw-icon {
      margin-left: calc($unit / 2);
      fill: var(--c_button-link-icon);
    }
  }

  &.danger {
    color: var(--c_button-danger-label);
    background-color: var(--c_button-danger);
    border: 1px solid var(--c_button-danger-border);
    &:hover {
      background-color: var(--c_button-danger-hover);
    }
  }

  &.outline {
    border-radius: 3px;
    border: 1px solid var(--c_button-outline-border);
    height: auto;
    padding: $unit;

    h3 {
      font-size: 24 * $ptToEm;
      color: var(--c_button-outline-title);
    }

    p {
      // text-align: left;
      color: var(--c_button-outline-text);
      font-size: 12 * $ptToEm;
      margin: $unit 0;
    }

    &:hover {
      transition: border-color 0.15s ease;
      border-color: var(--c_button-outline-border-hover);
      h3 {
        transition: color 0.15s ease;
        color: var(--c_button-outline-title-hover);
      }
    }
  }

  &.circle {
    padding: 0;
    min-width: unset;
    width: $unit * 2;
    height: $unit * 2;
    border-radius: $unit;
    background-color: var(--c_button-secondary);
    border: 1px solid var(--c_button-secondary-border);
    .xw-icon {
      fill: var(--c_button-secondary-label);
    }
    &:hover {
      transition: all 0.15s ease;
      background-color: var(--c_button-secondary-hover);
      .xw-icon {
        transition: all 0.15s ease;
        fill: var(--c_icon-hover);
      }
    }
    &:disabled {
      border: 1px solid var(--c_button-secondary-disabled-border);
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &.no-margin {
    margin: 0;
  }
}

.xw-detail-buttons {
  display: flex;
  flex-flow: row wrap;
  padding: $unit 0;

  .xw-button {
    display: inline-flex;
    flex-flow: column nowrap;
    flex: 1 1;
  }
}
