@import "../../styles/variables";

.xw-diff-node {
  background-color: var(--c_difftree-bg);

  > .row {
    position: relative;
    display: flex;
    align-items: center;
    margin: 1px 0;
    line-height: $unit * 3;
    height: $unit * 3;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--c_difftree-row-bg);
      z-index: 0;
      opacity: 1;
      transition: opacity 0.15s ease;
    }

    &:hover::after {
      background-color: var(--c_difftree-row-bg-hover);
    }
  }

  > .children {
    display: none;
  }

  &.open {
    > .children {
      display: block;
    }
  }

  &.parent-change {
    > .row {
      &::after {
        background-color: var(--c_difftree-parent-change);
        opacity: 0.065;
      }

      &:hover::after {
        opacity: 0.1;
      }

      .indicator {
        background-color: var(--c_difftree-parent-change);
      }
    }

    &.open > .row {
      &::after {
        background-color: var(--c_difftree-parent-change);
        opacity: 0.045;
      }

      &:hover::after {
        opacity: 0.065;
      }

      .indicator {
        opacity: 0.2;
      }
    }
  }

  &.add {
    > .row {
      &::after {
        background-color: var(--c_difftree-add);
        opacity: 0.065;
      }

      &:hover::after {
        opacity: 0.1;
      }

      .values .value.two {
        color: var(--c_difftree-add);
      }

      .indicator {
        background-color: var(--c_difftree-add);
      }
    }

    &.open > .row {
      &::after {
        background-color: var(--c_difftree-add);
        opacity: 0.045;
      }

      &:hover::after {
        opacity: 0.065;
      }

      .indicator {
        opacity: 0.2;
      }
    }
  }

  &.delete {
    > .row {
      &::after {
        background-color: var(--c_difftree-delete);
        opacity: 0.065;
      }

      &:hover::after {
        opacity: 0.1;
      }

      .values .value.one {
        color: var(--c_difftree-delete);
      }

      .indicator {
        background-color: var(--c_difftree-delete);
      }
    }

    &.open > .row {
      &::after {
        background-color: var(--c_difftree-delete);
        opacity: 0.045;
      }

      &:hover::after {
        opacity: 0.065;
      }

      .indicator {
        opacity: 0.2;
      }
    }
  }

  &.change {
    > .row {
      &::after {
        background-color: var(--c_difftree-change);
        opacity: 0.065;
      }

      &:hover::after {
        opacity: 0.1;
      }

      .values .value.two {
        color: var(--c_difftree-change);
      }

      .indicator {
        background-color: var(--c_difftree-change);
      }
    }

    &.open > .row {
      &::after {
        background-color: var(--c_difftree-change);
        opacity: 0.045;
      }

      &:hover::after {
        opacity: 0.065;
      }

      .indicator {
        opacity: 0.2;
      }
    }
  }

  &.change.delete,
  &.change.add,
  &.delete.add {
    > .row {
      &::after {
        background-color: var(--c_difftree-modified);
        opacity: 0.065;
      }

      &:hover::after {
        opacity: 0.1;
      }

      .values .value.two {
        color: var(--c_difftree-modified);
      }

      .indicator {
        background-color: var(--c_difftree-modified);
      }
    }

    &.open > .row {
      &::after {
        background-color: var(--c_difftree-modified);
        opacity: 0.045;
      }

      &:hover::after {
        opacity: 0.065;
      }

      .indicator {
        opacity: 0.2;
      }
    }
  }

  .values {
    display: flex;
    flex: 1 1 100%;
  }

  .value {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    flex: 1 1 0;
    font-size: $ptToEm * 14;

    &.small {
      flex: 0.75 1 0;
    }

    .disc {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 9px;
      background-color: var(--c_difftree-change);
    }

    .triangle {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 4px 6.9px 4px;
      border-color: transparent transparent var(--c_difftree-change) transparent;
    }

    .xw-icon {
      width: 9px;
      height: 9px;

      &.minus {
        fill: var(--c_difftree-delete);
      }

      &.plus {
        fill: var(--c_difftree-add);
      }
    }
  }

  .indicator {
    position: relative;
    z-index: 1;
    flex: 0 0 auto;
    width: calc($unit / 2);
    height: 100%;
    background-color: var(--c_difftree-indicator);
    transition: opacity 0.15s ease;
  }

  .label {
    position: relative;
    z-index: 1;
    flex: 0 0 350px;
    min-width: 0;
    box-sizing: border-box;
    font-weight: bold;
    color: var(--c_difftree-text);
    margin-right: calc($unit / 2);
    font-size: $ptToEm * 14;
    line-height: 18px;
    margin-left: $unit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .expander {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: $unit * 3;
    height: 100%;
    background-color: var(--c_difftree-row-bg);
    border-right: 1px solid var(--c_difftree-bg);

    &.null {
    }

    > .xw-icon {
      flex: 0 0 12px;
    }
  }

  .num-children {
    display: inline-block;
    margin-left: calc($unit / 2);
    color: var(--c_difftree-text-secondary);
  }

  &.group {
    .expander {
      background-color: var(--c_difftree-expander-bg);

      &.btn:hover {
        background-color: var(--c_difftree-expander-bg-hover);
      }
    }
  }
}
