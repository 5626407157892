@import "../../../styles/variables";

.xw-dateTimeRefresh {
  display: flex;
  position: relative;
  z-index: 100;
  min-width: 377px;

  .xw-trafficDatePicker {
    position: relative;
    z-index: 100;
    margin-right: 1px;
    min-width: 286px;
    flex: 1 1 auto;
  }

  .refresh-btn {
    flex: 0 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12 * $ptToEm;
    background-color: var(--c_button-secondary);
    border: 1px solid var(--c_button-secondary-border);
    height: $unit * 4;
    padding: 0 $unit;
    border-radius: 2px;
    transition: border-color 0.15s ease;
    width: 104px;

    &.hide {
      display: none;
    }

    .xw-icon {
      position: relative;
      z-index: 2;
      margin-right: calc($unit / 2);
    }

    .content {
      position: relative;
      z-index: 2;
    }

    .label {
      color: var(--c_timeRange-refresh-label);
      white-space: nowrap;
    }

    .time {
      color: var(--c_timeRange-refresh-time);
      font-weight: 700;
      white-space: nowrap;
    }

    .loading-bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: var(--c_timeRange-refresh-bg);
      transform: scaleX(0);
      transform-origin: left;

      &.animate {
        transform: scaleX(1);
      }
    }

    &:hover {
      border-color: var(--c_button-primary-border);
    }
  }
}
