@import "../../../styles/variables";

.alarm-notes {
  min-height: 128px;
  .xw-textarea,
  .xw-textarea textarea {
    width: 100%;
  }

  .xw-page-section > header {
    height: 34px;
  }

  .xw-page-section > content p {
    white-space: pre-wrap;
  }

  .actions {
    display: flex;
    flex-flow: row nowrap;

    .xw-button {
      margin: 0 4px;
    }
  }
}
