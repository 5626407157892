@import "../../styles/variables";

.xw-system-object {
  .title {
    color: var(--c_policy-create-modal-sub-title-text);
  }
  .sub-title {
    color: var(--c_policy-create-modal-sub-title-sub-text);
  }
  .policy-type-btn {
    width: 100px;
    height: 100px;
    background-color: var(--c_button-secondary);
    color: var(--c_policy-create-modal-policy-type-text);
    margin: $unit 0 $unit 0;
    min-width: unset;
    padding: unset;
    border-radius: 10px;
    &.active {
      border-color: var(--c_policy-create-modal-object-border);
      border-style: solid;
      border-width: 1px;
      cursor: none;
      pointer-events: none;
    }
  }
}
