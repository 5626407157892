@import "../../styles/variables";

.endpoint-modal {
  .modal-body {
    overflow: visible !important;
    .endpoint-modal-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 120px;
      padding: 20px;
        .xw-select {
        .xw-dropdown-menu {
          height: 100px;
        }
      }
    }
  }
  .modal-footer{
    z-index: 1;
   }
}

.createEndpointModal {
  .xw-modal {
    .modal-body .modal-body-loader {
      height: 200px;
    }
  }
}

.xw-express {
  .xw-stepwizard {
    .xw-page-header {
      border-bottom: 1px solid var(--c_section-header-separator);
    }
    .page-content {
      position: absolute;
      top: 102px;
      right: 0;
      bottom: 0;
      left: 0;

      .page-content-left {
        display: flex;
        flex-direction: column;
        padding-bottom: 4 * $unit;
        overflow-y: auto;
      }
    }
  }

  .xw-page-caption {
    flex: 0 0 auto;
    padding: 0;
  }

  #asn-prefix {
    .custom-filter-controls-section {
      display: none;
    }
  }

  .asn-prefix {
    padding: $unit 0;
  }

  #notification-endpoints_table {
    .custom-filter-controls-section {
      padding-left: 0;
    }
  }

  .asn-header {
    flex: 0 0 auto;
    font-size: 16 * $ptToEm;
    line-height: 12 * $ptToEm;
    color: var(--c_caption_text);
    padding: $unit 0 4;
    .bold {
      font-weight: 18 * $ptToEm;
    }
  }
  .pfx-stats {
    flex: 0 0 auto;
    font-size: 12 * $ptToEm;
    line-height: 12 * $ptToEm;
    color: var(--c_caption_text);
    padding-top: 2px;
    .selected {
      padding-top: 2px;
      color: var(--c_express_selected);
    }
    .warning {
      padding-top: 2px;
      color: var(--c_text-warning);
    }
  }

  #express-prefix-review, #express-endpoint-review {
    min-height: 350px;
  }

  .xw-data-table {
    flex: 1 1 100%;
    padding-top: 4px;

    .table-actions {
      margin: 0 24px 12px 0;
    }
  }

  .description {
    padding-bottom: $unit * 2;
    color: var(--c_text-title);
  }

  .content {
    padding-bottom: $unit * 2;

    .cards {
      display: flex;

      .xw-card {
        margin: 0;
        &:last-child {
          margin-left: $unit * 2;
        }
      }
    }
    .xw-rule-card {
      flex-basis: 100%;
    }
  }

  .xw-page-section {
    display: flex;
    flex-direction: column;

    .xw-table-wrapper {
      .custom-filter-controls-section {
        display: none;
      }
    }
  }

  .prefix {
    .innerScrollContainer {
      min-height: 401px;
    }
  }

  .error-section-header {
    color: var(--c_text-error);
    font-size: 20 * $ptToEm;
    padding: $unit 0 0.5 * $unit 0;
  }
  .error-header {
    color: var(--c_text-error);
    padding: $unit 0 0 0;
  }
  .error-description {
    color: var(--c_text-title);
    padding: $unit 0;
  }
  .error-action {
    .xw-button {
      margin-left: 0;
    }
  }
}
