@import "../../../styles/variables";

.xw-check-input {
  display: flex;
  .xw-checkbox {
    margin: 20px $unit * 3 0 0;
    input {
      height: 16px;
      width: 16px;
    }
    label {
      white-space: nowrap;
    }
  }
  .check-input-children {
    width: 100%;
  }
  .inactive {
    pointer-events: none;
    opacity: 0.5;
  }
}
