@import "../../../../styles/variables";
.xw-drawer {
  > header {
    color: var(--c_text-red);
  }
  .content {
    .value {
      overflow-wrap: break-word;
    }
  }
}
