@import "../styles/variables";

.xw-dropdown-menu {
  transform-origin: top left;
  transform-style: preserve-3d;
  position: absolute;
  background-color: var(--c_dropdown-menu-bg);
  padding: ($unit) ($unit * 1.5);
  font-size: 12 * $ptToEm;
  border: 1px solid var(--c_dropdown-menu-border);
  border-radius: 0 0 2px 2px;
  box-shadow: 0px 4px 12px var(--c_dropdown-menu-shadow);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  z-index: $zDropdown;
  min-height: $unit * 2;

  .xw-checkbox > label {
    font-size: 12 * $ptToEm;
  }

  a,
  button {
    display: block;
    padding: calc($unit / 2) 0;
    color: var(--c_dropdown-menu-link);
    height: 28px;

    &:hover,
    &:focus {
      color: var(--c_dropdown-menu-link-hover);
    }
  }

  &.open {
    opacity: 1;
    transition: opacity 0.2s ease;
    // disabled this transition as it was triggering weird layout bugs in chrome (not FF surprisingly)
    // using a fade in instead
    // // https://developers.google.com/web/updates/2017/03/performant-expand-and-collapse
    // will-change: transform;
    // contain: content;

    // animation: 0.2s verticalExpand step-end;

    // & > * {
    //   will-change: transform;
    //   contain: content;
    //   animation: 0.2s verticalCollapse step-end;
    // }
  }

  &:not(.open) {
    visibility: hidden;
    opacity: 0;
  }
}
