@import "../../styles/variables";

.xw-stepwizard .xw-data-table.pending-enrollment-table {
  margin: 0 -24px;
  height: 100%;

  .actions {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 24px;
    justify-content: space-between;
  }
  .row-actions {
    display: flex;
    align-items: center;

    .action {
      color: var(--c_value-text);
      font-weight: 700;
      padding: 0;
      min-width: auto;
      height: auto;
      display: flex;
      align-items: center;
      svg {
        position: relative;
        bottom: 1px;
        margin-left: 6px;
      }
      &:disabled {
        color: var(--c_button-link-label-disabled);
      }
    }
    .revoke-action {
      margin-left: 12px;
    }
  }

  .table-actions {
    margin-top: 0;
    .xw-dateTimeRefresh {
      min-width: auto;
    }
  }
  .spacer,
  .xw-trafficDatePicker {
    display: none;
  }
}

.xw-data-table.pending-enrollment-table {
  .yes {
    fill: var(--c_cdg-allow);
    margin-left: 6px;
  }
  .no {
    fill: var(--c_cdg-revoke);
    margin-left: 6px;
  }
}

.xw-cdgs-page {
  .xw-data-table.pending-enrollment-table {
    & .table-actions {
      flex-direction: column;
      align-items: flex-end;
      .xw-dateTimeRefresh {
        min-width: auto;
      }
      .xw-trafficDatePicker {
        display: none;
      }
    }
    .row-actions {
      display: flex;
      align-items: center;

      .action {
        padding: 0;
        min-width: auto;
        height: auto;
        display: flex;
        align-items: center;
        color: var(--c_value-text);
        font-weight: 700;
        svg {
          position: relative;
          bottom: 1px;
          margin-left: 6px;
        }
        &:disabled {
          color: var(--c_button-link-label-disabled);
        }
      }
      .revoke-action {
        margin-left: 12px;
      }
    }
    .actions {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 24px;
      justify-content: flex-end;
      .xw-page-caption {
        flex-grow: 1;
      }
    }

    .yellow {
      color: var(--c_text-warning);
    }

    .red {
      color: var(--c_text-error);
    }
  }
}
