@import "../../styles/variables";

.xw-ignored-prefixes {
  padding: $unit $unit * 2;
  overflow-y: auto;
  position: relative;

  .xw-card.xw-ignored-prefix {
    margin: 0 0 1px 0;
    position: relative;

    > .content {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: calc($unit / 2) $unit * 2;
      .pip {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: calc($unit / 2);
        background-color: var(--c_hr);
        border-radius: 3px 0 0 3px;
      }
      .link {
        font-size: 20 * $ptToEm;
      }
      .spacer {
        flex-grow: 1;
      }
    }
  }
}
