@import "../../styles/variables";
.xw-data-table {
  margin-top: $unit;
  flex: 1 1;
  .capacity-cir-container {
      display: flex;
      .xw-tooltip.open {
          max-width: 200px;
          white-space: normal;
        }
    }
}
