.xw-rx-tx-chart {
  width: 100%;
  height: 100%;
  min-width: 128px;
  min-height: 64px;
  position: relative;

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .series {
      transition: opacity 0.2s ease;
      &.fade-out {
        opacity: 0.2;
      }
    }

    .axis rect {
      fill: url(#chartTickX);
    }
    path.line,
    path.line-hover-area {
      fill: none;
    }
    path.line {
      stroke-width: 2px;
    }

    .xw-bytes-text {
      text-align: right;
      line-height: 24px;
    }
  }
}
