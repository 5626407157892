// THEMES
@import "themes/dark.scss";
@import "themes/light.scss";
@import "themes/high-contrast.scss";

// GLOBAL
@import "reset";
@import "base";
@import "fonts";
@import "text";
@import "scrollbar";
@import "animation";

#root {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  min-width: 1024px;
  min-height: 768px;
  overflow: auto;
}

#modal-root {
  position: relative;
  z-index: $zModalRoot;
}

#__SVG_SPRITE_NODE__ {
  width: 0;
  height: 0;
}

#app-loader {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--c_content-bg);

  .loading-wrapper {
    top: 50%;
    right: calc(50% - 50px); // loader width is 100px
    position: absolute;

    .loading-text {
      margin: $unit 0 0 0;
      text-align: center;
      color: var(--c_text);
      animation: 1.1s ease-in-out 0s infinite glow;
    }
  }

  .xw-processing-loader {
    width: 100px;

    .rect {
      fill: var(--c_processing_modal_loader);
    }
  }
}

@keyframes glow {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
