@import "../../styles/variables";

.xw-level {
  display: flex;
  width: 70px;
  .level-label {
    color: var(--c_level-label);
    padding-right: 8px;
    align-items: flex-start;
    flex: 2 1;
    font-size: 12 * $ptToEm;
    line-height: 14 * $ptToEm;
  }

  .low-level-icon,
  .med-level-icon,
  .high-level-icon,
  .level-none-icon {
    width: 6px;
    height: 12px;
    align-items: flex-end;
    border-radius: 1px;
  }

  .low-level-icon {
    background-color: var(--c_level-icon-bg);
    border: 1px solid var(--c_low-level-icon-border);
    border-bottom: 3px solid var(--c_low-level-icon-border-bottom);
  }
  .med-level-icon {
    background-color: var(--c_level-icon-bg);
    border: 1px solid var(--c_med-level-icon-border);
    border-bottom: 6px solid var(--c_med-level-icon-border-bottom);
  }
  .high-level-icon {
    background-color: var(--c_high-level-icon-bg);
    align-items: flex-end;
  }
  .level-none-icon {
    background-color: var(--c_none-level-icon-bg);
    border: 1px solid var(--c_level-high-border-all);
  }
}
