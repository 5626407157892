@import "../../../../styles/variables";

.xw-device-details-status {
  .xw-page-header {
    padding: 0 $unit * 2;
  }
  .xw-status-flowchart {
    margin: 0 $unit * 2;
  }
}
