@import "../../styles/variables";

.xw-alarm-status-value {
  .update-label-Active {
    color: var(--c_modification_Delete);
  }
  .update-label-Cleared {
    color: var(--c_modification_Create);
  }
  .update-label-Unconfigured {
    color: var(--c_modification_Create);
  }
  .update-label-Snoozed {
    color: var(--c_modification_Edit);
  }
  .update-label-Acknowledged {
    color: var(--c_modification_Edit);
  }
  .update-label-Configured {
    color: var(--c_modification_Edit);
  }
  .time {
    font-size: 12 * $ptToEm;
  }
}
