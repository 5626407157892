@import "../../../styles/variables";

.interface_policy_association-modal-wrapper {
  .xw-modal {
    .modal-header {
      background-color: var(--c_filter-modal-header-background);
    }
    .modal-footer {
      background-color: var(--c_filter-modal-footer-background);
    }
  }
}

.xw-tracked-interfaces {
  .actions {
    display: flex;
  }
  .xw-content-suppress {
    &.interfaces {
      .device {
        color: var(--c_value-text);
        font-weight: bold;
        padding-right: calc($unit/3);
      }
      .interface {
        padding-left: calc($unit/3);
        color: var(--c_value-text);
      }
    }
    > div {
      display: flex;
      padding-right: calc($unit);
    }
    .link {
      min-width: 110px;
    }
  }
}
