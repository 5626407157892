@import "../../../styles/variables";

.xw-importExport-configuration {
  .xw-stepwizard {
    .xw-page-header {
      border-bottom: 1px solid var(--c_section-header-separator);
    }
    .page-content .page-content-left {
      .xw-table-wrapper {
        margin: $unit * 2 0;
      }
      .custom-filter-controls-section {
        display: none;
      }
    }
  }

  .preview-tabs {
    flex: 1 1 100%;
    width: 100%;
    .xw-page-tabs {
      height: 100%;
      .xw-data-table {
        padding-top: $unit;
        padding-bottom: $unit;
        display: flex;
        flex: 1 1 auto;
        flex-flow: column nowrap;
        height: 100%;
      }
    }
  }

  .file-selection-page {
    width: 100%;
    .caution-text {
      color: var(--c_text-error);
      font-size: 24 * $ptToEm;
      line-height: 33 * $ptToEm;
    }
    .xw-file-input-field {
      width: 300px;
    }
    .file-sample {
      padding: 0;
      margin: $unit * 3 0;
    }
  }

  .links {
    display: block;
    margin: ($unit * 3) 0 0;

    .link-wrapper {
      display: block;

      .link {
        display: block;
        padding: calc($unit / 2) 0;
        margin: 0;
        height: auto;
        font-size: $ptToEm * 14;
        color: var(--c_text-link);
        width: 100%;

        &:hover {
          color: var(--c_text-hover);
        }
      }
    }
  }
}
