@import "../../styles/variables";

.policy-alarm-history-tab {
  .custom-filter-controls-section {
    position: absolute;
    padding-top: $unit * 1.5;
  }

  .xw-dateTime {
    padding-top: $unit * 1.5;
    padding-bottom: $unit * 1.5;
  }

  #policy-alarmHistory-table {
    padding-top: $unit;
  }
}
