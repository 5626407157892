@import "../../styles/variables";

.xw-chart {
  @for $i from 0 through 5 {
    .data-fill-#{$i} {
      fill: var(--c_chart-data-#{$i});
    }
    .data-text-#{$i} {
      color: var(--c_chart-data-#{$i});
    }
    .row-text-#{$i} {
      color: var(--c_chart-row-#{$i});
    }
    .row-bg-#{$i} {
      background: var(--c_chart-row-#{$i});
    }
    .greyout-#{$i} {
      opacity: 0.2;
    }
    .pointer-#{$i} {
      cursor: pointer;
    }
    .data-stroke-#{$i} {
      stroke: var(--c_chart-data-#{$i});
    }
    .line-stroke-#{$i} {
      stroke: var(--c_chart-area-#{$i});
    }
    .line-marker-#{$i} {
      stroke-width: 1px;
      stroke: var(--c_chart-marker-border);
      fill: var(--c_chart-area-#{$i});
      &.emphasis {
        stroke-width: 2px;
      }
    }
    .line-bg-#{$i} {
      background-color: var(--c_chart-data-#{$i});
    }
    .area-bg-#{$i} {
      background-color: var(--c_chart-area-#{$i});
    }
    .p95-bg-#{$i} {
      fill: var(--c_chart-area-#{$i});
      opacity: 0.1;
    }
    .data-bg-#{$i} {
      background-color: var(--c_chart-data-#{$i});
    }
    .data-area-#{$i} {
      fill: url("#dataArea#{$i}");
    }
    .data-area-inv-#{$i} {
      fill: url("#dataAreaInv#{$i}");
    }

    .area-text-#{$i} {
      color: var(--c_chart-area-#{$i});
    }

    .area-fill-#{$i} {
      fill: var(--c_chart-area-#{$i});
    }
  }

  .axis line {
    stroke: var(--c_chart-tick);
    stroke-width: 1px;
    pointer-events: none;
  }

  .no-data {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text {
      color: var(--c-table-nodata-text);
      font-size: 24 * $ptToEm;
      line-height: 33 * $ptToEm;
    }
    .sub-text {
      color: var(--c-table-overlay-subtext);
    }
  }
}
