@import "../../../../styles/variables";

.xw-mismatched-files-modal {
  .xw-modal {
    .modal-dialog {
      width: 1024px;
      overflow-x: hidden;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.xw-mismatched-files {
  .toggle {
    padding: $unit;
    button:last-child {
      width: 250px;
    }
  }
  .fields {
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 12px 24px 0 24px;
  }
  .hash-mismatch {
    display: inline-flex;
    align-items: center;
    color: var(--c_text-emphasis);
    .value {
      color: var(--c_text-emphasis);
    }
    .compare-hash {
      flex: 0 1 50%;
    }
  }
  .hash-match {
    display: inline-flex;
    align-items: center;
    .compare-hash {
      flex: 0 1 50%;
    }
  }
  .checkbox-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px var(--c_device-capabilities-check-active) solid;
    border-radius: 100px;
    box-sizing: border-box;
    margin-right: calc($unit/3);
    &.large {
      width: 16px;
      height: 16px;
    }
    &.small {
      width: 12px;
      height: 12px;
    }
    .xw-icon {
      fill: var(--c_device-capabilities-check-active);
      transition: all 0.15s ease;
      &.large {
        width: 16px;
        height: 16px;
      }
      &.small {
        width: 12px;
        height: 12px;
      }
    }
    &.mismatch-icon,
    &.mismatch {
      border: 1px var(--c-table-switch-active-fill) solid;
      background-color: var(--c_text-emphasis);
      .xw-icon {
        fill: var(--c-table-switch-active-fill);
        padding: 2px;
        &.large {
          width: 16px;
          height: 16px;
        }
        &.small {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .xw-page-section {
    .header {
      display: flex;
      color: var(--c_page-header-title);
      padding: $unit ($unit * 2) 0 ($unit * 2);
      margin-bottom: 0;
      .sub-title {
        padding-left: $unit;
        align-items: center;
        display: flex;
        font-size: 16 * $ptToEm;
        line-height: 22 * $ptToEm;
        font-weight: 300;
      }
    }
  }
  .xw-data-table {
    height: 250px;
  }
}
