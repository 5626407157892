@import "../../styles/variables";

.hwSwChanges-Modal {
  .xw-modal {
    .modal-dialog {
      width: 1024px;
      overflow-x: hidden;
      height: 50%;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .hwSwChanges-Table {
        height: 100%;
        .xw-data-table {
          height: 100%;
        }
      }
    }
  }
}

.xw-diff-tree {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: var(--c_difftree-text);
  min-height: 197px;

  > .header {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    font-size: $ptToEm * 14;
    height: $unit * 4;
    font-weight: 700;
    background-color: var(--c_difftree-header-bg);
    color: var(--c_difftree-header-text);
    padding: 0;

    .spacer {
      flex: 0 0 auto;

      &.toggle {
        width: $unit * 3 + 1px;
      }

      &.indicator {
        width: 6px;
      }
    }

    .col {
      flex: 1 1 0;

      &.label {
        flex: 0 0 auto;
        width: 350px;
        margin-right: calc($unit / 2);
        margin-left: $unit;
      }

      &.condensed {
        flex: 0.75 1 0;
      }
    }
    .exceeded-limit-label {
      font-size: $ptToEm * 12;
      color: var(--c_text-error);
    }
  }

  .nodes {
    flex: 1 1 100%;
    overflow-y: auto;
    background-color: var(--c_difftree-bg);
    .xw-overlay {
      position: relative;
      height: 100%;
      width: 100%;
      .subText {
        display: flex;
        align-items: center;
        .xw-button {
          padding: 0px;
          margin: 0 4px 0 4px;
          font-size: 14px;
          height: 100%;
          .changeModalBtn {
            align-self: center;
            justify-self: center;
            padding: 0;
            margin: 0;
            font-size: 14px;
          }
        }
      }
      .hwSwChanges-Modal {
        width: 600px;
      }
    }
  }

  .tree-footer {
    display: flex;
    justify-content: space-between;
    padding: $unit;
    background-color: var(--c_difftree-footer-bg);
  }

  .legend {
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }

  .legend-item {
    display: flex;
    align-items: center;
    margin-right: $unit * 2;

    .box {
      width: $unit;
      height: $unit;
      margin-right: calc($unit / 2);
    }

    .label {
      font-size: 12px;
      font-weight: 700;
      color: var(--c_difftree-text-legend);
    }

    &.add .box {
      background-color: var(--c_difftree-add);
    }

    &.delete .box {
      background-color: var(--c_difftree-delete);
    }

    &.change .box {
      background-color: var(--c_difftree-change);
    }

    &.mix .box {
      background-color: var(--c_difftree-modified);
    }
  }

  .diff-tree-export {
    .xw-button {
      margin: 0;
      height: $unit;
      font-size: 12 * $ptToEm;
      font-weight: 700;
      .xw-icon {
        width: 10px;
        height: 10px;
      }
    }
  }
}
