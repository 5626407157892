@import "../../styles/variables";

.xw-page-header {
  background-color: var(--c_content-bg);
  padding: $unit $unit * 2 0;

  &.with-tabs {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  }

  > .header {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: $unit 0 $unit * 2;
    .title-section {
      display: flex;
      flex: 1 1 auto;
      min-width: 0;
      flex-flow: column nowrap;
      .title-with-status {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row nowrap;
        h1 {
          color: var(--c_page-header-title);
          font-size: 24 * $ptToEm;
          line-height: 33 * $ptToEm;
          font-weight: 300;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .status-label {
          font-size: 16 * $ptToEm;
          font-weight: 700;
          line-height: 33 * $ptToEm;
          margin-left: calc($unit / 2);
          align-self: center;
          margin-top: 5px;
          .status-label-Ok {
            color: var(--c_text-ok);
          }
          .status-label-NotOk {
            color: var(--c_text-error);
          }
        }
      }
      .sub-title-section {
        display: flex;
        flex-flow: row nowrap;
        .sub-title-prefix {
          font-weight: bold;
        }
        .sub-link {
          flex: 0 0 auto;
          white-space: nowrap;
          margin-right: calc($unit / 2);
          color: var(--c_text-link);
          margin-top: 4px;
        }
        .sub-title {
          flex: 0 1 auto;
          margin-right: calc($unit / 2);
          margin-top: 4px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .tags {
          flex-grow: 1;
          flex: 1 1 auto;
          min-width: 75px; // size of view all tags text
          height: 18px;

          .chip-container {
            display: flex;
            overflow-y: hidden;
            white-space: nowrap;
            .chip {
              display: inline-block;
              margin: 0 6px 6px 0;
              text-align: center;
              flex: 0 1 auto;
              background-color: var(--c_page-header-chip-bg);
              border: 1px solid var(--c_page-header-chip-border);
              height: 18px;
              position: relative;
              border-radius: 100px;
              color: var(--c_chip-text);
              padding: 3px 12px;
              font-size: 0.75rem;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 100%;
            }
            .xw-button {
              margin: 0;
              padding: 0;
              height: 20px;
              font-size: 12 * $ptToEm;
              text-decoration: none;
            }
          }
        }
      }
    }

    .action-section {
      display: inline-flex;
      flex: 0 0 auto;
      flex-flow: row nowrap;
    }
  }
  .separator {
    border-bottom: 1px solid var(--c_section-header-separator);
    margin-bottom: $unit * 2;
  }
}

.tagsModal-modal-wrapper {
  .xw-modal {
    .modal-body .chip {
      margin: 0 6px 6px 0;
      text-align: center;
      display: table;
      background-color: var(--c_chip-bg-0);
      height: 18px;
      position: relative;
      border-radius: 100px;
      color: var(--c_chip-text);
      padding: 3px 12px;
      font-size: 0.75rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
}

.theme-high-contrast .tagsModal-modal-wrapper {
  .xw-modal {
    .modal-body .chip {
      background-color: transparent;
      border: 1px solid var(--c_chip-bg-0);
    }
  }
}
