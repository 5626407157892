@import "../../../styles/variables";

.xw-common-ccta-tier {
  display: flex;
  .fp-header {
    font-size: 12px;
    align-self: center;
    text-transform: capitalize;
  }
}
