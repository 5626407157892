@import "../../styles/variables";

.xw-notification-trigger {
  .xw-notification-summary {
    .actions {
      > button {
        min-width: 180px;
      }
    }
  }
}
