@import "../../../styles/variables";

.xw-known-good-files {
  height: 100%;
  display: flex;
  flex-direction: column;
  .xw-kgf-table {
    flex: 1 1 auto;
  }
  .actions {
    display: flex;
    padding-left: $unit;
  }
}
