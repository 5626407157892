@import "../../styles/variables";

.xw-component-library {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: row wrap;
  background-color: var(--c_content-bg);
  padding: 24px;

  > .component-wrapper {
    width: 100%;
  }

  > header {
    select {
      width: 200px;
    }
  }

  #theme {
    margin-bottom: 20px;
  }

  .ui-section {
    width: 100%;
    box-sizing: border-box;
    > h3 {
      color: var(--c_ui-title);
    }
    > p {
      display: block;
      border-bottom: 1px var(--c_ui-divider) solid;
      padding: 0 0 $unit 0;
    }
    .xw-ui-section {
      margin: ($unit * 2) 0 ($unit * 4);

      > .xw-input-field {
        width: 50%;
      }
      .xw-ui-banner-section {
        margin: 5px 0;
      }

      &.queue-section {
        display: flex;
        gap: 50px;
      }
    }
  }

  .icons {
    display: flex;
    flex-flow: row wrap;
    .icon-container {
      margin: calc($unit / 2);
      flex-basis: 144px;

      .xw-icon {
        vertical-align: middle;
      }

      span {
        margin-left: calc($unit / 2);
        font-size: 12 * $ptToEm;
      }
    }
  }

  .selectionControl {
    display: block;
    width: 100%;
  }

  .scrollbars .scrollbar-container {
    border: 1px var(--c_textInput-line) solid;
    width: 300px;
    height: 100px;
    overflow-x: auto;
    padding: 2px;
    overflow-y: scroll;
  }

  .tab-section {
    ul + div {
      margin: 10px 0 0 10px;
    }
  }

  .xw-expander-card {
    position: relative;
    left: -$unit;
    width: 373px;
  }
  .xw-line-chart {
    height: 416px;
  }
  .xw-row-chart {
    height: 112px;
    margin: $unit 0;
    &[data-qa="plagues"] {
      height: 472px;
    }
  }
  .xw-sankey-chart {
    height: 212px;
  }

  .small-sparklines {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    left: calc(-#{$unit} / 2);
    width: calc(100% + #{$unit});
    .xw-sparkline {
      margin: calc($unit / 2);
      flex-basis: 64px;
      box-sizing: border-box;
      height: 24px;
    }
  }
  .xw-double-trend-chart {
    height: 500px;
  }

  .circle-buttons {
    display: flex;
    flex-flow: row nowrap;
  }

  .date-tab-section .xw-dateTime .custom-date-button > .children {
    margin: $unit * 2 0;
    color: var(--c_button-label);
    padding: $unit $unit * 2;
    background-color: var(--c_button-secondary);
  }

  .refresh-value {
    margin: $unit 0 0 0;
    .value {
      font-size: 14 * $ptToEm;

      .label {
        color: var(--c_text-title);
        padding-right: 6px;
      }
    }
    .epoch {
      font-size: 10 * $ptToEm;
      margin-bottom: calc($unit/2);
    }
  }

  .page-header-section {
    .xw-page-header {
      &.with-tabs {
        position: sticky;
      }

      .status-label-Ok {
        color: var(--c_text-ok);
      }

      .status-label-NotOk {
        color: var(--c_text-error);
      }
    }
  }

  .title-input {
    flex-grow: 1;
  }

  .ellipsize-sample-container {
    resize: horizontal;
    overflow: hidden;
  }

  .license-text {
    font-size: 18px;
    color: #fff;
    padding: $unit * 2 0;
  }

  .accordion-section {
    .fixed-width {
      width: 700px;
      .custom-actions {
        display: inline;
        .placeholder {
          font-size: small;
          color: var(--c_textInput-label);
        }
      }
    }
  }
}

#lineCurveTtype .rail .options button {
  width: 168px;
}

#neverGonnaGiveYouUp ul > li {
  align-items: baseline;
}

#mockFlowPrefixTable,
#asnPrefixTable {
  height: 746px;
  left: -$unit * 2;
  right: $unit * 1.5;
  width: calc(100% + #{$unit * 3.5});
  resize: both;
}

#exampleGauges {
  display: flex;
  flex-flow: row wrap;
  .exampleGaugeWrapper {
    height: 184px - 24px;
    width: 184px;
    position: relative;
    resize: both;
    overflow: hidden;

    > .xw-gauge {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}
